import {
  Create,
  Datagrid,
  DateField,
  FunctionField,
  List,
  TextField,
  UrlField,
} from "react-admin";

import { withStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

import {  WebsiteReferenceField } from "../website";
import Form from "../outreachOrder/Form";

import { RESOURCE } from "../constants";


const MaybeUl = ({ list, missing }) => {
  if (!list) return missing
  return <ul>
    {list.map(item => <li key={item}>{item}</li>)}
  </ul>
}

export const CreateOutreachOrder = withStyles({
  accordionTitle: { width: "100%", borderBottom: "1px solid #888" },
  create: { flexGrow: 1 },
})(({ id, record, classes, ...props }) => {
  return [
    <div key='DNS_INFO'>
      <h3>IPs:</h3>
      <MaybeUl list={record.dns_data?.ip} missing="No IPs found" />

      <h3>NameSevers:</h3>
      <MaybeUl list={record.dns_data?.ns} missing="No NameServer found" />
        
    </div>,
    <Accordion key="create">
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <h2 className={classes.accordionTitle}>Make a request</h2>
      </AccordionSummary>
      <AccordionDetails>
        <Create
          {...props}
          title={" "}
          className={classes.create}
          resource={RESOURCE.outreachLinkbuildingOrder}
          transform={(data) => ({ outreach_site: record.id, ...data })}
        >
          <Form outreachSiteId={id} />
        </Create>
      </AccordionDetails>
    </Accordion>,
    <List
      empty={<div />}
      key="list"
      title={" "}
      hasBulkActions={false}
      filter={{ outreach_site: record.id }}
      {...props}
      resource={RESOURCE.outreachLinkbuildingOrder}
      bulkActionButtons={null}
      actions={null}
    >
      <Datagrid isRowSelectable={() => false}>
        <WebsiteReferenceField label="website" source="website" />
        <TextField source="status" />
        <FunctionField
          render={(record) =>
            `${record.price ? record.price + " " : "-"}${record.currency}`
          }
        />
        <FunctionField
          label="Payment Status"
          render={(record) =>
            record.date_paid ? (
              <TextField
                source={"paid"}
                record={{ paid: `Paid on ${record.date_paid}` }}
              />
            ) : record.waiting_for_payment ? (
              <TextField source={"paid"} record={{ paid: "Pending Payment" }} />
            ) : (
              <TextField
                source={"paid"}
                record={{ paid: "Payment not requested" }}
              />
            )
          }
        />
        <UrlField target="_blank" source="link" />
        <TextField source="linking_page" emptyText="-" />
        <UrlField target="_blank" source="google_drive" emptyText="-" />
        <DateField source="live" emptyText="-" />
        <DateField source="created" emptyText="-" />
      </Datagrid>
    </List>,
  ];
});
