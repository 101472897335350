import React from "react";
import {
  Admin,
  Resource,
  ListGuesser,
  ShowGuesser,
  EditGuesser,
} from "react-admin";

import { authProvider } from "../auth/authProvider";
import { ADMIN, WRITER, RESOURCE, GROUPS, ROLE } from "./constants";

import { PageShow, PageCreate, PageEdit } from "./page/page";
import { ContentShow } from "./content/content";
import { ContentList } from "./content/ContentList";
import { Create as ContentCreate } from "./content";
import { makeContentTemplate } from "./content/template";

import { WriterWrite } from "./content/writerWrite";

import { EditorContentReview } from "./content/editor/EditorContentReview";
import {
  WebsiteList,
  WebsiteShow,
  WebsiteEdit,
  WebsiteCreate,
  WebsiteEditRedirect,
} from "./website/website";

import { Layout, theme } from "./Layout";

import { Dashboard } from "./dashboard/dashboard";

import { WriterShow, WriterSelfEdit } from "./user";
import { makeWriterAdmin } from "./writer/admin";

import { makeOutreachLinkbuildingSite } from "./outreachLinkbuildingSite";
import { makeOutreachMonthlyPlan } from "./outreachMonthlyPlan";
import { makeoutreachLinkbuildingOrder } from "./outreachLinkbuildingOrder";
import makeSales from "./sales";
import { makeProduct } from "./product/makeProduct";
import { makeNotifications } from "./notifications";
import { makeFinanceWriter, makeFinanceOutreachSite } from "./Finance";
import { Provider } from "react-redux";

import { history, dataProvider, store } from "./reusable/store";
import { makeFlatPages } from "./flat_pages/makeFlatpage";
import makePBN from "./pbn";
import makeYtg from "./yourtext_guru";
import makeContentRefusal from "./content/refusal/makeContentRefusal";
import makeContentSection from "./content/section/makeContentSection";
import { NotificationsList } from "./notifications/messages";
import makeProofReaderAdmin from "./proofreader/admin";
import makeVassistantAdmin from "./vassistant/admin";
import makeVassistantTask from "./vassistant/tasks";
import { makeFinanceProofreader } from "./Finance/Proofreader/makeFinanceProofreader";
import { makeFinanceVAssistant } from "./Finance/VAssistant/makeFinanceVAssistant";
import { makeContentTemplateV2 } from "./content/content_template";
import { makeContentFeedback } from "./content/feedback/makeContentFeedback";
import { makeOutreachLinkbuildingDuplicateIP } from "./outreachLinkbuildingDuplicateIP";
import { makeOutreachLinkbuildingRedirection } from "./outreachLinkbuildingRedirection";
import { makeYoutube, makeYoutubeOrder, makeYoutubeFee } from "./youtube";
import makeHosting from "./hosting";
import { makeOutreachLinkbuildingLatestContent } from "./outreachLinkbuildingLatestContent/makeOutreachLinkbuildingLatestContent";
import { makeWebsiteStats } from "./stats";
import CustomLoginPage from "../auth/loginPage";
import { makeContentCharacter } from "./content/content_character/make";
import { makeOutreachPIC } from "./outreachPIC";

const App = () => {
  return (
    <Provider store={store}>
      <Admin
        theme={theme}
        disableTelemetry
        dashboard={Dashboard}
        dataProvider={dataProvider}
        authProvider={authProvider()}
        loginPage={CustomLoginPage}
        history={history}
        layout={Layout}
      >
        {(permissions) => {
          return [
            makeContentRefusal(permissions),
            makeContentTemplateV2(permissions),
            makeContentCharacter(permissions),
            makeContentFeedback(permissions),
            makeContentSection(permissions),
            makeFinanceWriter(permissions),
            makeFinanceProofreader(permissions),
            makeFinanceVAssistant(permissions),
            makeOutreachLinkbuildingSite(permissions),
            makeoutreachLinkbuildingOrder(permissions),
            makeOutreachLinkbuildingDuplicateIP(permissions),
            makeOutreachLinkbuildingRedirection(permissions),
            makeOutreachPIC(permissions),
            makeOutreachLinkbuildingLatestContent(permissions),
            makeOutreachMonthlyPlan(permissions),
            makeFinanceOutreachSite(permissions),
            makeContentTemplate(permissions),
            makeWriterAdmin(permissions),
            makeProofReaderAdmin(permissions),
            makeVassistantAdmin(permissions),
            makeVassistantTask(permissions),
            makeFlatPages(permissions),
            makeNotifications(permissions),
            makeSales(permissions),
            makeProduct(permissions),
            makePBN(permissions),
            makeYtg(permissions),
            makeYoutubeOrder(permissions),
            makeYoutubeFee(permissions),
            makeYoutube(permissions),
            makeHosting(permissions),
            makeWebsiteStats(permissions),
            <Resource key={RESOURCE.niche} name={RESOURCE.niche} />,
            <Resource key={RESOURCE.vertical} name={RESOURCE.vertical} />,

            /**
             * Websites
             */
            [ROLE.admin, ROLE.staff].includes(permissions.role) ? (
              <Resource
                key={RESOURCE.website}
                name={RESOURCE.website}
                // list={WebsiteList}
                show={WebsiteShow}
                edit={WebsiteEditRedirect}
                // create={WebsiteCreate}
                options={{ label: "Websites" }}
              />
            ) : (
              <Resource key={RESOURCE.website} name={RESOURCE.website} />
            ),

            [ROLE.admin, ROLE.staff].includes(permissions.role) ? (
              <Resource
                key={RESOURCE.websiteWithStats}
                name={RESOURCE.websiteWithStats}
                list={WebsiteList}
                show={WebsiteShow}
                edit={WebsiteEdit}
                create={WebsiteCreate}
                options={{ label: "Websites" }}
              />
            ) : null,

            /**
             * Markets
             */
            permissions.role === ADMIN ? (
              <Resource
                key={RESOURCE.market}
                name={RESOURCE.market}
                options={{ label: "Markets" }}
                list={ListGuesser}
                show={ShowGuesser}
              />
            ) : (
              <Resource key={RESOURCE.market} name={RESOURCE.market} />
            ),
            <Resource key={RESOURCE.writerStats} name={RESOURCE.writerStats} />,
            <Resource
              key={RESOURCE.proofreaderStats}
              name={RESOURCE.proofreaderStats}
            />,
            <Resource
              key={RESOURCE.integratorStats}
              name={RESOURCE.integratorStats}
            />,
            <Resource key={RESOURCE.POStats} name={RESOURCE.POStats} />,
            <Resource
              key={RESOURCE.WebsiteStats}
              name={RESOURCE.WebsiteStats}
            />,
            <Resource
              key={RESOURCE.YoutubeStats}
              name={RESOURCE.YoutubeStats}
            />,
            <Resource
              key={RESOURCE.YoutubeOrderStats}
              name={RESOURCE.YoutubeOrderStats}
            />,
            // <Resource
            //     key={ RESOURCE.flatPages }
            //     name={ RESOURCE.flatPages }
            //     list={ ListGuesser }
            // />,

            /**
             * Pages
             */
            permissions.groups.includes(GROUPS.integrators) ? (
              <Resource
                key={RESOURCE.page}
                name={RESOURCE.page}
                // list={ [ ROLE.admin, ROLE.staff ].includes(permissions.role) ? IntegratorPageList : IntegratorPageList }
                create={PageCreate}
                edit={PageEdit}
                show={PageShow}
                expand={<pageShow />}
              />
            ) : permissions.role === ADMIN ||
              permissions.groups.includes(GROUPS.editors) ? (
              <Resource
                key={RESOURCE.page}
                name={RESOURCE.page}
                // list={ PageList }
                edit={PageEdit}
                create={PageCreate}
                show={PageShow}
                expand={<pageShow />}
              />
            ) : (
              <Resource key={RESOURCE.page} name={RESOURCE.page} />
            ),

            /**
             * User resource
             */
            <Resource
              key={RESOURCE.user}
              name={RESOURCE.user}
              show={ShowGuesser}
            />,
            /**
             * Content Minimal resource
             */
            <Resource
              key={RESOURCE.contentMinimal}
              name={RESOURCE.contentMinimal}
            />,

            /**
             * Orders
             */
            permissions.groups.includes(GROUPS.vAssistant) ? (
              <Resource
                key={RESOURCE.order}
                name={RESOURCE.order}
                options={{ label: "Content requested to you" }}
                list={null}
                show={ContentShow}
                edit={EditorContentReview}
              />
            ) : null,
            permissions.groups.includes(GROUPS.proofreader) ? (
              <Resource
                key={RESOURCE.order}
                name={RESOURCE.order}
                options={{ label: "Content requested to you" }}
                list={null}
                show={ContentShow}
                edit={EditorContentReview}
              />
            ) : null,
            permissions.groups.includes(GROUPS.editors) ||
            permissions.groups.includes(GROUPS.linkbuilding) ||
            permissions.groups.includes(GROUPS.integrators) ||
            permissions.role === ROLE.admin ? (
              <Resource
                key={RESOURCE.order}
                name={RESOURCE.order}
                options={{ label: "Ordered Content" }}
                list={ContentList}
                show={ContentShow}
                create={
                  permissions.groups.includes(GROUPS.freelances)
                    ? undefined
                    : ContentCreate
                }
                edit={EditorContentReview}
              />
            ) : permissions.role === WRITER ? (
              <Resource
                key={RESOURCE.order}
                name={RESOURCE.order}
                options={{ label: "Content requested to you" }}
                list={ContentList}
                show={ContentShow}
                edit={WriterWrite}
              />
            ) : (
              <Resource key={RESOURCE.order} name={RESOURCE.order} />
            ),
            permissions.role === WRITER && (
              <Resource
                key={RESOURCE.notifications.feedback}
                name={RESOURCE.notifications.feedback}
                options={{ label: "Notifications" }}
                list={NotificationsList}
              />
            ),
            <Resource
              key={RESOURCE.contentAssignIntegrator}
              name={RESOURCE.contentAssignIntegrator}
            />,
            /**
             * Integrators
             */
            permissions.groups.includes(GROUPS.integrators) &&
            permissions.groups.includes(GROUPS.freelances) ? (
              <Resource
                key={RESOURCE.integrators}
                name={RESOURCE.integrators}
              />
            ) : permissions.groups.includes(GROUPS.integrators) ? (
              <Resource
                key={RESOURCE.integrators}
                name={RESOURCE.integrators}
                options={{ label: "Integrators" }}
                list={ListGuesser}
                show={ShowGuesser}
                edit={EditGuesser}
              />
            ) : null,
            /**
             * Integration Orders
             */

            permissions.groups.includes(GROUPS.integrators) &&
            permissions.groups.includes(GROUPS.freelances) ? (
              <Resource
                key={RESOURCE.integrationOrder}
                name={RESOURCE.integrationOrder}
                edit={WriterSelfEdit}
                show={WriterShow}
              />
            ) : permissions.groups.includes(GROUPS.integrators) ? (
              <Resource
                key={RESOURCE.integrationOrder}
                name={RESOURCE.integrationOrder}
                options={{ label: "Orders for Integrators" }}
                list={ListGuesser}
                show={ShowGuesser}
                edit={EditGuesser}
              />
            ) : null,
          ];
        }}
      </Admin>
    </Provider>
  );
};

export default App;
