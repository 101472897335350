import { List, Datagrid, TextField, Filter, downloadCSV } from "react-admin";
import jsonExport from "jsonexport/dist";

import { RESOURCE } from "../../constants";

import { WebsiteReferenceInput } from "../../website";
import {
  dayFromToday,
  DateRangeDialog,
  todayISOString,
  ChipArrayField,
  getLocale,
} from "../../reusable";
import { MarketReferenceInput } from "../../market";

const Filters = (props) => (
  <Filter {...props}>
    <DateRangeDialog
      label={"Activity Date"}
      sourceBefore={"before"}
      sourceAfter={"after"}
      alwaysOn
      resettable
    />
    <WebsiteReferenceInput
      allowEmpty={false}
      source="website"
      alwaysOn
      resettable
    />
    <MarketReferenceInput
      allowEmpty={false}
      source="market"
      alwaysOn
      resettable
    />
  </Filter>
);

const exporter = async (stats, fetchRelatedRecords) => {
  const data = stats.map((entry) => ({
    username: entry.username,
    markets: entry.markets
      // .map(market=>markets[market]?.country)
      .filter((country) => !!country)
      .join(", "),
    "Content Ordered": entry.ordered_content,
    "Content Published": entry.pages_published,
    "Spent (EUR)": entry.spent.toLocaleString(getLocale()) || 0,
  }));
  jsonExport(
    data,
    {
      headers: [
        "username",
        "markets",
        "Content Ordered",
        "Content Published",
        "Spent (EUR)",
      ],
    },
    (err, csv) =>
      !err
        ? downloadCSV(csv, `editor-stats.${todayISOString()}`)
        : console.error(err),
  );
};

export const POStats = () => (
  <List
    title={" "}
    basePath={RESOURCE.POStats}
    resource={RESOURCE.POStats}
    syncWithLocation={false}
    filters={<Filters />}
    filterDefaultValues={{
      after: dayFromToday(30),
    }}
    exporter={exporter}
    sort={{ field: "ordered_content", order: "DESC" }}
  >
    <Datagrid>
      <TextField source="id" />
      <TextField source="username" />
      <ChipArrayField source={"markets"} />
      <TextField source="ordered_content" label="Orders" />
      <TextField source="pages_published" label="Published" />
      <TextField source="spent" label="Spent" />
    </Datagrid>
  </List>
);
