import { Chip, Drawer, withStyles } from "@material-ui/core";
import {
  SaveButton,
  Toolbar,
  usePermissions,
  useRecordContext,
} from "react-admin";

import { useStyles } from "../../useStyles";
import { MarkAsCompleteButton } from "./buttons/MarkAsComplete";
import { MarkAsApproveButton } from "./buttons/MarkAsApproved";
import { TASK_STATUS } from "../../constants";

export const VAToolbar = withStyles({
  backdrop: {
    zIndex: 100,
  },
  chip: {
    marginRight: ".5rem",
  },
})(({ ...props }) => {
  const classes = useStyles();
  const record = useRecordContext();
  const { permissions, loading } = usePermissions();

  const vassistant = permissions?.groups?.includes("vassistant");
  const showCompleteButton =
    vassistant && record?.task_status === TASK_STATUS.in_progress;

  const editor = permissions?.groups?.includes("editor");
  const showApproveButton =
    editor &&
    (record?.task_status === TASK_STATUS.in_progress ||
      record?.task_status === TASK_STATUS.finished);

  if (loading || !record) return null;

  return (
    <Drawer className={classes.bottomBar} variant="permanent" anchor="bottom">
      <Toolbar {...props}>
        <SaveButton {...props} redirect={false} submitOnEnter={true} />
        {editor && record?.task_quote && (
          <>
            <Chip label={`Task Quote: ${record?.task_quote}`} />
          </>
        )}
        {record?.hours_worked && (
          <Chip
            label={`Hours Worked: ${record?.hours_worked} `}
            color="secondary"
          />
        )}
        {showCompleteButton && (
          <MarkAsCompleteButton record={record} {...props} />
        )}
        {showApproveButton && (
          <MarkAsApproveButton record={record} {...props} />
        )}
      </Toolbar>
    </Drawer>
  );
});
