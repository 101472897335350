const ALPHA = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
const NUMBERS = "0123456789";
const SYMBOLS = "!@#$%^&*_-+=";

export const generatePassword = (
  length,
  alpha = true,
  numbers = true,
  symbols = true
) => {
  const characters =
    (alpha ? ALPHA : "") + (numbers ? NUMBERS : "") + (symbols ? SYMBOLS : "");
  let password = "";
  for (let i = 0; i < length; i++) {
    password += characters.charAt(
      Math.floor(Math.random() * characters.length)
    );
  }
  return password;
};

export default generatePassword;
