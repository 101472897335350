import { EditButton } from "react-admin";
import React from "react";
import RequestContent from "./RequestContent";
import { Tooltip } from "@material-ui/core";
import BrandStatus from "./BrandStatus";
import { CopyBrandId } from "./CopyBrandId";

export const Actions = (props: any) => {
  return (
    <>
      <CopyBrandId {...props} />
      <Tooltip title={"Edit deal"}>
        <span>
          <EditButton {...props} />
        </span>
      </Tooltip>
      <RequestContent {...props} />
      <BrandStatus {...props} />
    </>
  );
};

export default Actions;
