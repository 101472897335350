import { fetchUtils } from "react-admin";
import { PERMISSION, GROUPS } from "./constants";
import { usePermissions } from "react-admin";

export const useHasPermission = (permission) => {
  const { permissions, loading, error } = usePermissions();
  if (loading || error) {
    return {hasPermission: undefined, loading, error};
  }
  const hasPermission = permissions.permission.split(",").includes(permission);
  return {hasPermission, loading, error};
}

export const useInGroup = (group) => {
  const { permissions, loading } = usePermissions();
  if (loading) {
    return undefined, true;
  }
  return permissions.groups.split(",").includes(group), false;
}

export const getCookie = (name) => {
  let cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
};

export const httpClient = (url, options = {}) => {
  // HTTP CLIENT, all the HTTP request will be handled by it.
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }

  //  CSRF token, if it is the case.
  options.headers.set("X-CSRFToken", getCookie("csrftoken"));

  return fetchUtils.fetchJson(url, options);
};

export const get = (url) => httpClient(url, { method: "GET" });
export const post = (url, data, json = true, options = {}) =>
  httpClient(url, {
    method: "POST",
    body: json ? JSON.stringify(data) : data,
    ...options,
  });

export const patch = (url, data, json = true, options = {}) =>
  httpClient(url, {
    method: "PATCH",
    body: json ? JSON.stringify(data) : data,
    ...options,
  });
