import React from "react";
import { Edit as RaEdit, EditProps } from "react-admin";
import { Form } from "./Form";

export const Edit = (props: EditProps): React.ReactElement => {
  const transform = (data: any) => {
    /**
     * invoice should only contain File. It it contains the string of the
     * file name, just delete it
     */
    if (typeof data.invoice === "string") {
      delete data.invoice;
    }

    // Update the fees and remove the websites and youtubes if they are not required
    const { fees } = data.data;
    if (fees !== undefined) {
      const updatedFees = fees.map((fee: any) => {
        const { type, youtubes, websites } = fee;
        if (type === "WEBSITE" && youtubes) {
          const { youtubes, ...rest } = fee; // Remove youtubes
          return rest;
        }
        if (type === "YOUTUBE" && websites) {
          const { websites, ...rest } = fee; // Remove websites
          return rest;
        }
        return fee;
      });
      data.data.fees = updatedFees;
    }

    return {
      ...data,
      data: JSON.stringify(data.data),
    };
  };
  return (
    <RaEdit {...props} transform={transform} mutationMode="pessimistic">
      <Form />
    </RaEdit>
  );
};

export default Edit;
