import React from "react";
import { Create as RaCreate } from "react-admin";
import { Form } from "./Form";

const Instructions = () => {
  return (
    <>
      <h2>Instructions</h2>
      <p>
        This page will be used to choose the websites that will be used for
        redirection.
      </p>
      <p>
        Here Slag is what the users will be put after this url{" "}
        <strong>https://machineapain.org/redirect/?query=</strong>
      </p>
      <p>
        The Slags can be 2 type:
        <ul>
          <li>
            Outreach Slag: This type of slag starts with <strong>spot_</strong>{" "}
            and then the website short name. This will choose the latest 10
            outreach guest posts of the chosen website.
          </li>
          <li>
            Content Slag: This type of slag starts with just the website short
            name. This will choose the latest 10 published contents of the
            chosen website.
          </li>
        </ul>
      </p>
      <h3>Example:</h3>
      <p>Website: https://moneyradar.org</p>
      <p>
        Choosen Short Name: <strong>mr</strong>
      </p>
      <p>
        If you want to Setup Outrech Redirection put the slag as{" "}
        <strong>spot_mr</strong> or if you want to setup content redirection
        simpley put <strong>mr</strong>
      </p>
    </>
  );
};

export const Create = (props) => {
  return (
    <>
      <Instructions />
      <RaCreate {...props}>
        <Form />
      </RaCreate>
    </>
  );
};

export default Create;
