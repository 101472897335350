import { Resource } from "react-admin";

import { RESOURCE, GROUPS } from "../../constants";
import { List } from "./List";

/**
 * @param {*} permissions
 * @returns
 */
export const makeFinanceOutreachSite = (permissions) =>
  permissions.groups.includes(GROUPS.finance)
    ? [
        <Resource
          name={RESOURCE.outreachFinanceSite}
          key={RESOURCE.outreachFinanceSite}
          options={{ label: "Linkbuilding" }}
          list={List}
        />,
      ]
    : [
        <Resource
          name={RESOURCE.outreachFinanceSite}
          key={RESOURCE.outreachFinanceSite}
        />,
      ];
