import React from "react";
import { Resource as RAResource } from "react-admin";
import YouTubeIcon from "@material-ui/icons/YouTube";
import Create from "./Create";
import List from "./List";
import Edit from "./Edit";
import { RESOURCE, PERMISSIONS } from "../constants";

export const makeYoutubeOrder = (permissions: any): React.ReactElement => {
  const permission = permissions?.permission || [];
  return (
    <RAResource
      name={RESOURCE.order}
      options={{ label: "Youtube Order" }}
      list={permission.includes(PERMISSIONS.view) ? List : undefined}
      edit={permission.includes(PERMISSIONS.edit) ? Edit : undefined}
      create={permission.includes(PERMISSIONS.create) ? Create : undefined}
      key={RESOURCE.list}
      icon={YouTubeIcon}
    />
  );
};
