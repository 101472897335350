import { Resource } from "react-admin";

import { RESOURCE, ROLE } from "../constants";
import content from "./content";
import { PBNIcon } from "../reusable/icons";
export { PBNReferenceInput } from "./inputs";

/**
 * For some reason the APP want the resource to be instantiated with this make factory.
 * @param {*} permissions
 * @param {*} key
 * @returns
 */
export const makePBN = (permissions) => {
  const permission = permissions?.permission || "";
  const canView =
    permissions?.role === ROLE.admin ||
    permission.split(",").filter((x) => x.includes("pbn.view_pbncontent"))
      .length > 0;
  return [
    <Resource
      name={RESOURCE.pbn}
      key={RESOURCE.pbn}
      options={{ label: "PBN" }}
    />,
    <Resource
      name={RESOURCE.pbnContent}
      key={RESOURCE.pbnContent}
      list={canView ? content.List : null}
      icon={PBNIcon}
      options={{ label: "PBN" }}
    />,
  ];
};

export default makePBN;
