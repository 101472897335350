import { SLACK_CHANNELS } from "../constants";
export { ADMIN } from "../constants";
export const API_URL = "/api";
export const RESOURCE: string = "sales/licences";

export const SLACK_CHANNEL_DEFAULT = SLACK_CHANNELS[0];

const MODEL = "licence";

export const PERMISSIONS = {
  create: `sales.add_${MODEL}`,
  view: `sales.view_${MODEL}`,
  edit: `sales.change_${MODEL}`,
  delete: `sales.delete_${MODEL}`,
};

export { SLACK_CHANNELS, SLACK_CHOICES } from "../constants";
