import {
  ChipField,
  ArrayField,
  SingleFieldList,
  ChipFieldProps
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import {FC} from "react";


export interface ChipArrayFieldProps {
  link?: boolean;
  chipProps?: ChipFieldProps;
  record?: any;
  source: string;
  [key: string]: any;
}


interface ChipListFieldProps {
  record?: any;
  link?: boolean;
  chipProps: ChipFieldProps;
  [key: string]: any;
}


const useStyles = makeStyles(() => ({
  chipArray: {
    "&>div": {
      marginBottom: 0,
      alignItems: "center",
    },
    "& .MuiChip-root": {
      height: "24px",
    },
  },
}));
/**
 * A field display showing an array of chips.
 * @param {bool} link - whether is a list of links.
 * @param {ChipProps} chipProps
 * @param {} props
 * @returns {JSX.Element|null}
 * @constructor
 */
export const ChipArrayField: FC<ChipArrayFieldProps> = ({ link, chipProps = {}, ...props }) => {
  const { record, source } = props;
  const classes = useStyles();

  if (!(record && record[source])) return null;

  return (
    <span className={classes.chipArray}>
      <ArrayField {...props}>
        <SingleFieldList linkType={false}>
          <ChipListField link={link} chipProps={chipProps} />
        </SingleFieldList>
      </ArrayField>
    </span>
  );
};

/**
 * Internal representation.
 * @param record?: the record
 * @param link: bool
 * @param chipProps: ChipProps
 * @returns {JSX.Element|null}
 * @constructor
 */
const ChipListField: FC<ChipListFieldProps> = ({ record=null, link=false, chipProps }) => {
  if (!record) {
    return null;
  }

  if (link) {
    // @ts-ignore
    chipProps.component = "a";
    // @ts-ignore
    chipProps.href = record;
    // @ts-ignore
    chipProps.target = "_blank";
    chipProps.clickable = true;
  }
  return <ChipField record={{ id: record }} source="id" {...chipProps} />;
};

ChipArrayField.defaultProps = {
  label: "",
  addLabel: true,
};

export default ChipArrayField;
