import { ReferenceInput, AutocompleteInput, usePermissions } from "react-admin";
import { FC } from "react";
import { PERMISSIONS, RESOURCE } from "../constants";

export const LicenceReferenceInput: FC<any> = (props) => {
  const { permissions } = usePermissions();
  if (permissions?.permission?.includes(PERMISSIONS.view)) {
    return (
      <ReferenceInput
        // enableGetChoices={({ q }) => {
        //   return q ? q.length > 1 : false;
        // }}
        reference={RESOURCE}
        {...props}
      >
        <AutocompleteInput
          optionText={"name"}
          onSelect={(e) => console.log(e)}
        />
      </ReferenceInput>
    );
  }
  return null;
};

export default LicenceReferenceInput;
