import {RESOURCE} from '../../constants';
import { useGetOne, Identifier, useRecordContext } from 'react-admin';

/**
 * Get the rate of the writer and the rate of the order
 * @param {Identifier} id
 * @returns {Object} { loaded: boolean, error: Error, content: string, writer: string, sameRate: boolean, rate: string, writerRates: Array }
 * @example
 * const { loaded, error, writer, sameRate, rate, writerRates } = useGetRate(id);
 * if (error) return <Error />;
 * if (!loaded) return <Loading />;
 * return <Component content={content} writer={writer} sameRate={sameRate} rate={rate} writerRates={writerRates} />;
**/
export default (id: Identifier) => {
    const record = useRecordContext();
    if (!id && record) {
        id = record.id;
    }
    const content = useGetOne(RESOURCE.order, id, { enabled: !!id });
    // start loading only if the content is loaded
    const writer = useGetOne(RESOURCE.writer, content.data?.writer, { enabled: !!content.data?.writer });
    // errr handing & loading
    if (content.error || writer.error) {
        return { loaded: false, error: content.error || writer.error };
    }
    if (content.loading || writer.loading) {
        return { loaded: false };
    }

    return {
        loaded: true,
        // The currency of the order
        currency: writer.data?.currency,
        // The rate for the content
        content: content.data?._writer_rate || content.data?.writer_rate,
        // The rate for the writer
        writer: writer.data?.rate,
        // Whether the rates are the same
        sameRate: [
            // The default rate
            parseFloat(writer.data?.rate), 
            // Check also the other rates
            ...(writer.data?.rates || []).map(parseFloat)
        ].includes(parseFloat(content.data?._writer_rate || content.data?.writer_rate || writer.data?.rate)),
        // The final rate used, note that `content.data?.writer_rate` should be the same as `writer.data?.rate`
        rate: content.data?._writer_rate || content.data?.writer_rate || writer.data?.rate,
        // In some cases there might be other rates for the writer
        writerRates: writer.data?.rates,
    };

}