import { useGetOne } from "react-admin";
import { RESOURCE } from "../constants";

const useGetYTG = (id: string | null) => {
  const { data, loading, error } = useGetOne(RESOURCE, id || 0, {
    enabled: !!id,
  });
  return {
    data,
    loading,
    error,
  };
};

export default useGetYTG;
