import { useState } from "react";
import { SelectInput, ReferenceInput } from "react-admin";

import { RESOURCE } from "../../constants";

export const VerticalReferenceInput = ({ source, label, ...props }) => {
  const [clicked, setClicked] = useState(false);
  return (
    <span onClick={() => setClicked(true)}>
      <ReferenceInput
        label={props.label ? props.label : "Vertical"}
        source={props.source ? props.source : "vertical"}
        reference={RESOURCE.vertical}
        enableGetChoices={({ q }) => {
          return clicked || (q ? q.length > 0 : false);
        }}
        allowEmpty={false}
        {...props}
      >
        <SelectInput allowEmpty={false} optionText="name" optionValue="id" />
      </ReferenceInput>
    </span>
  );
};

VerticalReferenceInput.defaultProps = {
  label: "Vertical",
  addLabel: true,
};
