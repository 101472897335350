import {
  TextInput,
  NumberInput,
  SelectInput,
  useNotify,
  required,
} from "react-admin";

import {
  ArrayTextInput,
  generatePassword,
  HorizontalGroup,
} from "../../reusable";
import { MarketReferenceInput } from "../../market";
import { useStyles } from "../../useStyles";
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";
import { Chip } from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { useState } from "react";
import { WRITER_TYPE_CHOICES } from "../../constants";

const validateMultipleRate = (values) => {
  if (!values || typeof values !== "object") return undefined;
  for (const value of values) {
    if (value.trim().split(" ").length > 1) {
      return "Enter Each Rate on newline!";
    }
    if (isNaN(parseFloat(value))) {
      return "Enter a valid number!";
    }
  }
  return undefined;
};

const validateFavouriteSubjects = (values) => {
  if (!values || typeof values !== "object") return undefined;
  for (const value of values) {
    if (value.length >= 30) {
      return "Favourite Subjects should be less than 30 Characters!";
    }
  }
  return undefined;
};

const Form = ({ withPassword = false, editMode = false, props }) => {
  const classes = useStyles();
  const notify = useNotify();
  const [password, setPassword] = useState(generatePassword(14));
  return [
    <HorizontalGroup
      key={"Personal information"}
      label={"Personal information"}
    >
      <TextInput
        className={classes.big}
        source="username"
        validate={required()}
        autoComplete="off"
      />
      <TextInput
        className={classes.big}
        source="first_name"
        autoComplete="off"
      />
      <TextInput
        className={classes.big}
        source="last_name"
        autoComplete="off"
      />
    </HorizontalGroup>,
    withPassword ? (
      <HorizontalGroup key={"password"} label={"Set the password"}>
        <TextInput
          className={classes.bigAndBigger}
          type={"password"}
          source={"user_password"}
          label={"Password"}
          onChange={(e) => setPassword(e.target.value)}
          initialValue={password}
          validate={required()}
          autoComplete="off"
        />
        <CopyToClipboard
          text={password}
          onCopy={() => notify(`Password copied to clipboard.`)}
        >
          <Chip
            clickable
            variant={"outlined"}
            icon={<FileCopyIcon />}
            label={"Copy"}
          />
        </CopyToClipboard>
      </HorizontalGroup>
    ) : null,
    <HorizontalGroup key={"Contact Information"} label={"Contact Information"}>
      <TextInput className={classes.big} source="skype" label="Skype/IM" />
      <TextInput className={classes.bigger} source="email" type="email" />
    </HorizontalGroup>,
    <HorizontalGroup label={"Writer Information"} key={"Writer Information"}>
      <HorizontalGroup>
        <SelectInput source="writer_type" choices={WRITER_TYPE_CHOICES} />
        <NumberInput
          onWheel={(e) => e.target.blur()}
          className={classes.big}
          source="weekly_quota"
          label="Weekly Quota"
        />
      </HorizontalGroup>
      <ArrayTextInput
        className={classes.big}
        source="favorite_subjects"
        label="Favorite Subjects"
        validate={validateFavouriteSubjects}
      />
      <NumberInput
        onWheel={(e) => e.target.blur()}
        className={classes.big}
        source="words_per_week"
      />
      <MarketReferenceInput className={classes.big} validate={required()} />
    </HorizontalGroup>,
    <HorizontalGroup
      label={"Financial Information"}
      key={"Financial Information"}
    >
      <NumberInput
        onWheel={(e) => e.target.blur()}
        className={classes.big}
        source="rate"
        validate={required()}
      />
      <ArrayTextInput
        className={classes.big}
        source="rates"
        label="Optional Multiple Rates"
        helperText="Put multiple Writer rates each rate in new line."
        validate={validateMultipleRate}
      />
      <TextInput
        className={classes.big}
        source="currency"
        disabled={editMode} /* Disable in the Edit Mode */
        validate={required()}
        initialValue={"EUR"}
      />
      <HorizontalGroup>
        <ArrayTextInput
          className={classes.bigAndBigger}
          source="content_template_rates"
          label="Content Template Rates"
          helperText="Put multiple Content Template rates each rate in new line."
          validate={validateMultipleRate}
        />
      </HorizontalGroup>
    </HorizontalGroup>,

    <TextInput
      className={classes.bigAndBigger}
      multiline
      source="payment_instruction"
      key="payment_instruction"
      validate={required()}
    />,
  ];
};

export const makeForm = (withPassword = false) => (
  <Form withPassword={{ withPassword }} />
);
export default Form;
