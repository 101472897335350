import * as React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import CheckIcon from "@material-ui/icons/Check";
import {
  SaveButton,
  Toolbar,
  useNotify,
  fetchStart,
  fetchEnd,
  useRedirect,
  Loading,
  useRecordContext,
  Error,
} from "react-admin";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

import TextField from "@material-ui/core/TextField";

import { API_URL } from "../../constants";
import { httpClient } from "../../utils";
import { APPROVED, STATUS } from "../../constants";
import { RejectContentButton } from "./RejectContentButton";
import { Dialog, DialogContent, FormGroup, Switch } from "@material-ui/core";
import Slider from "@material-ui/core/Slider";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import SentimentVerySatisfiedIcon from "@material-ui/icons/SentimentVerySatisfied";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import Typography from "@material-ui/core/Typography";
import DialogContentText from "@material-ui/core/DialogContentText";
import {
  EstimatedQuoteField,
  TemplatePriceField,
  useEstimate,
} from "../ContentList";
import { HorizontalGroup } from "../../reusable";
import useGetRate from "../utils/useGetRate";
import { useRecordWriter } from "../writer/useWriter";

export const ReviewToolbar = (props) => {
  return (
    <Toolbar>
      <TemplatePriceField record={props.record} />
      <EstimatedQuoteField estimate record={props.record} />
      <SaveButton disabled={props.pristine} />
      <ApproveButton
        disabled={!props.pristine || props.record.status !== STATUS.review}
      />
      <RejectContentButton
        disabled={!props.pristine || props.record.status !== STATUS.review}
      />
    </Toolbar>
  );
};

/**
 * Accepts the content
 * @param {*} param0
 */
export const ApproveButton = ({ basePath, record, disabled }) => {
  const [loading, setLoading] = useState(false);
  const writer = useRecordWriter();
  const rateHook = useGetRate();
  const {rate}  = rateHook;
  const [open, setOpen] = useState(false);
  const [toPay, setToPay] = useState("2");
  const estimate = useEstimate(record, true, false, true);
  const realEstimate = useEstimate(record, true);
  const requested = useEstimate(record, true, true);
  const [quote, setQuote] = useState(estimate.value || 0);
  const [quoteBreakdown, setQuoteBreakdown] = useState("");
  const [templatePrice, setTemplatePrice] = useState(false);
  const handleToPayChange = (value) => {
    setToPay(value);
    setTemplatePrice(false);
    if (value === "1") {
      setQuote(estimate.value);
      setQuoteBreakdown(
        `Requested Words: ${record.requested_words} + ${
          record.requested_words * 0.25
        } Words | Writer Rate: ${
          rate
        } / 100 words`,
      );
    } else if (value === "2") {
      setQuote(realEstimate.value);
      setQuoteBreakdown(
        `Total Written Words: ${
          record.total_words_written
        } Words | Writer Rate: ${
          rate
        } / 100 words`,
      );
    } else if (value === "3") {
      setQuote(requested.value);
      setQuoteBreakdown(
        `Requested Words: ${record.requested_words} Words | Writer Rate: ${
          rate
        } / 100 Words`,
      );
    }
  };

  const handleTemplatePriceChange = (value) => {
    setTemplatePrice(value);
    const parsedQuote = parseFloat(quote);
    const writer_template_rate = parseFloat(record.writer_template_rate);

    if (value) {
      setQuote(Number(parsedQuote + writer_template_rate).toFixed(2));
    } else {
      setQuote(Number(parsedQuote - writer_template_rate).toFixed(2));
    }
  };

  const [vote, setVote] = useState(3);
  const voteMarks = [
    {
      value: 1,
      label: <SentimentVeryDissatisfiedIcon />,
    },
    {
      value: 3,
      label: <DragIndicatorIcon />,
    },
    {
      value: 5,
      label: <SentimentVerySatisfiedIcon />,
    },
  ];
  const [feedback, setFeedBack] = useState("");
  const [handleClose, handleOpen] = [
    () => {
      setOpen(false);
    },
    () => {
      setOpen(true);
      handleToPayChange("2");
    },
  ];

  const dispatch = useDispatch();
  const notify = useNotify();
  const redirect = useRedirect();
  if (record.status === APPROVED) return null;

  const handleClick = () => {
    const path = `${API_URL}${basePath}/${record.id}/approve/`;
    setLoading(true);
    dispatch(fetchStart()); // start the global loading indicator
    httpClient(path, {
      method: "POST",
      body: JSON.stringify({
        vote,
        feedback,
        quote: quote ? parseFloat(quote) : 0,
        payment_method: toPay,
        template_price: templatePrice,
      }),
    })
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json;
      })
      .catch((e) => {
        notify(`Error: ${e}`, "warning");
        return Promise.reject();
      })
      .then(({ status, valid }) => {
        redirect("/");
        notify(`Content ${record.id} approved.`);
      })
      .finally(() => {
        setLoading(false);
        dispatch(fetchEnd());
      });
  };
  
  if (!rateHook.loaded) return  <Loading />;
  if (rateHook.error) return <Error error={ rateHook.error } /> ;
  return (
    <span>
      <Button
        startIcon={<CheckIcon />}
        disabled={disabled || loading}
        id="content-accept-btn"
        // Activates feedback form.
        // onClick={ setOpen }
        onClick={handleOpen}
        color="primary"
        variant="contained"
      >
        {"Accept"}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Approve</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Optionally, you can fill this form to rate the content.
          </DialogContentText>
          <label>
            <Typography gutterBottom>Please rate the content</Typography>
          </label>
          <Slider
            value={vote}
            step={1}
            marks={voteMarks}
            min={1}
            max={5}
            onChange={(e, value) => {
              setVote(value);
            }}
          />

          <TextField
            id={"feedback"}
            multiline
            maxRows={4}
            fullWidth
            value={feedback}
            label="Optionally write some feedback here."
            helperText={"This feedback will be visible to the writer."}
            onChange={(e) => setFeedBack(e.target.value)}
          />
          <TextField
            fullWidth
            value={quote}
            type="number"
            disabled
            helperText={`Amount to be paid for the article (${realEstimate.currency}).`}
            // label={`Article cost (${estimate.currency}).`}
            inputProps={{
              id: "content-quote",
              inputMode: "numeric",
              pattern: "[0-9]*",
            }}
            onChange={(e) => {
              setQuote(e.target.value);
            }}
          />
          <TextField
            fullWidth
            value={quoteBreakdown}
            type="text"
            helperText={"Amount Breakdown"}
          />
          <FormControl component="fieldset">
            <FormLabel component="legend">What should be paid ( {quote} {writer.data.currency} )</FormLabel>
            <RadioGroup
              aria-label="whatToPay"
              name="whatToPay"
              value={toPay}
              onChange={(e) => {
                handleToPayChange(e.target.value);
              }}
            >
              <FormControlLabel
                id={"default-rule"}
                value={"1"}
                control={<Radio />}
                label="Use default rule, up to 25% words more than requested"
              />
              <FormControlLabel
                id={"requested-words"}
                value={"3"}
                control={<Radio />}
                label="Pay only requested words"
              />
              <FormControlLabel
                id={"all-words"}
                value={"2"}
                control={<Radio />}
                label="Pay for all the words"
              />
            </RadioGroup>
          </FormControl>
          <HorizontalGroup>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Switch
                    id={"template-paid"}
                    checked={templatePrice}
                    onChange={(e) => {
                      handleTemplatePriceChange(e.target.checked);
                    }}
                    name="Should The writer will be paid for template?"
                    inputProps={{
                      "aria-label":
                        "Should The writer will be paid for template?",
                    }}
                  />
                }
                label="Should The writer will be paid for template?"
              />
            </FormGroup>
          </HorizontalGroup>
          <HorizontalGroup></HorizontalGroup>
          <DialogActions>
            <Button
              id={"btn-accept-final"}
              startIcon={<CheckIcon />}
              // disabled={disabled || loading}
              onClick={handleClick}
              color="primary"
              variant="contained"
            >
              {"Accept"}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </span>
  );
};
