import { FunctionField, ReferenceField } from "react-admin";

import { RESOURCE } from "../../../constants";
import { Typography } from "@material-ui/core";

export const ContentCharacterReferenceField = ({
  source,
  label,
  reference,
  ...props
}) => {
  return (
    <ReferenceField
      label={label ? label : "Content Character"}
      source={source ? source : "structure.content_characture"}
      reference={reference ? reference : RESOURCE.contentCharacter}
      link={false}
      {...props}
    >
      <>
        <FunctionField
          render={(record) => {
            return (
              <>
                <Typography variant="h6">
                  <strong>{record.display_name}</strong>
                </Typography>
                <Typography variant="body2">{record.instructions}</Typography>
              </>
            );
          }}
        />
      </>
    </ReferenceField>
  );
};
ContentCharacterReferenceField.defaultProps = {
  label: "Content Character",
  addLabel: true,
};
