import { ReferenceArrayInput, AutocompleteArrayInput } from "react-admin";
import { useState } from "react";

import { RESOURCE } from "../../constants";

export const WriterReferenceArrayInput = ({
  source,
  label,
  helperText,
  ...props
}) => {
  const [clicked, setClicked] = useState(false);
  return (
    <span onClick={() => setClicked(true)}>
      <ReferenceArrayInput
        source={source ? source : "writer"}
        label={label ? label : "Writer"}
        reference={RESOURCE.writer}
        filterToQuery={(searchText) => ({ full_name: searchText })}
        enableGetChoices={({ q }) => {
          return clicked || (q ? q.length > 1 : false);
        }}
        allowEmpty={false}
        {...props}
      >
        <AutocompleteArrayInput
          allowEmpty={false}
          optionText="full_name"
          helperText={helperText}
          optionValue="id"
        />
      </ReferenceArrayInput>
    </span>
  );
};

WriterReferenceArrayInput.defaultProps = {
  label: "Writer",
  addLabel: true,
};
