import { Error } from "react-admin";
import { ContentList } from "../ContentList";

/**
 * Render the list of content for the writer.
 * @param {*} writer
 * @param {string} status
 * @returns
 */
export const ProofreaderContentList = ({ proofreader, status, ...props }) => {
  const filter = { proofreader: proofreader.id };
  if (status !== undefined) filter.status = status;
  if (proofreader === undefined)
    return <Error error={"No Proofreader found!"} />;
  return <ContentList filter={filter} {...props} />;
};
