import {
  TextField,
  List,
  Datagrid,
  NumberField,
  ShowButton,
  EditButton,
  ReferenceField,
  FunctionField,
  Pagination,
  DateField,
} from "react-admin";

import { WebsiteFilterList } from "./WebsiteFilterList";
import { WebsiteFilter } from "./WebsiteFilter";
import { RESOURCE } from "../../constants";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  ratio: {
    "& small": {
      fontSize: ".5rem",
      fontWeight: "bold",
    },
  },
}));

/**
 *
 * @param {number} ratio
 */
const rgbaMaker = (ratio) => {
  const colors = {
    red: 0,
    green: 0,
    blue: 0,
    alpha: 1,
  };
  const baselineRed = 150;
  const baselineGreen = 150;
  if (ratio < 0) {
    colors.red = Math.floor(Math.min(baselineRed * (1 - ratio / 100), 255));
    colors.green = Math.floor(Math.max(baselineGreen * (1 + ratio / 100), 0));
  } else {
    colors.green = Math.floor(Math.min(baselineGreen * (1 + ratio / 100), 200));
    colors.red = Math.floor(Math.max(baselineRed * (1 - ratio / 100), 0));
  }
  return `rgba(${colors.red},${colors.green},${colors.blue},${colors.alpha})`;
};

const Ratio = ({ first, second }) => {
  const classes = useStyles();
  if (!first && !second) {
    return <span className={classes.ratio}>-</span>;
  }
  const ratio = Math.round((100 * (second + 1)) / (first + 1) - 100);
  const symbols = [
    ["↑", "darkGreen"],
    ["↗", "green"],
    ["→", "gray"],
    ["↘", "orange"],
    ["↓", "red"],
  ];
  const numbers = [80, 15, -15, -60, -101];
  const symbol = numbers
    .map((number, index) => (ratio > number ? symbols[index] : null))
    .filter((x) => !!x)[0];
  const color = rgbaMaker(ratio);
  return (
    <span className={classes.ratio}>
      {first || 0} → <b>{second || 0}</b>
      <wbr />{" "}
      <small style={{ color: color }} className={symbol[1]}>
        ({ratio} %) {symbol[0]}
      </small>
    </span>
  );
};
const WebsitePagination = (props) => (
  <Pagination rowsPerPageOptions={[25, 50, 100, 200]} {...props} />
);
export const WebsiteList = (props) => (
  <List
    {...props}
    aside={<WebsiteFilterList />}
    filters={<WebsiteFilter />}
    pagination={<WebsitePagination />}
    perPage={25}
    sort={{ field: "visitors", order: "DESC" }}
    filter={{ deleted: false }}
  >
    <Datagrid rowClick="edit">
      <TextField source="domain" />
      <ReferenceField
        label="Vertical"
        source="vertical"
        reference={RESOURCE.vertical}
        link={false}
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        label="Niche"
        source="niche"
        reference={RESOURCE.niche}
        link={false}
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField source="type" />
      <FunctionField
        source="visitors"
        render={(record) => (
          <Ratio
            first={record.visitors_previous_period}
            second={record.visitors}
          />
        )}
        label={"Avg. Visitors per day"}
      />
      <FunctionField
        source="outbound_clicks"
        render={(record) => (
          <Ratio
            first={record.outbound_clicks_previous_period}
            second={record.outbound_clicks}
          />
        )}
        label={"Avg. Outbound per day"}
      />
      <NumberField source="spent_in_content" />
      <DateField source="last_content_published" label="Last PBN Published" />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);
