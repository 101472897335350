import { Resource } from "react-admin";

import { RESOURCE } from "../constants";

import { List } from "./List";
import BulkCreate from "./BulkCreate";
import InsertCommentIcon from "@material-ui/icons/InsertComment";

/**
 * For some reason the APP want the resource to be instantiated with this make factory.
 * @param {*} permissions
 * @param {*} key
 * @returns
 */
export const makeoutreachLinkbuildingOrder = (permissions) => {
  const canView =
    permissions?.permission?.includes("outreach.view_outreach") || false;
  const canCreate =
    permissions?.permission?.includes("outreach.add_outreach") || false;
  return [
    canView ? (
      <Resource
        name={RESOURCE.outreachLinkbuildingOrder}
        key={RESOURCE.outreachLinkbuildingOrder}
        options={{ label: "Link Database" }}
        list={List}
        create={canCreate ? BulkCreate : undefined}
        icon={InsertCommentIcon}
      />
    ) : (
      <Resource
        name={RESOURCE.outreachLinkbuildingOrder}
        key={RESOURCE.outreachLinkbuildingOrder}
      />
    ),
  ];
};
