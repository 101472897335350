/**
 * Calculate written words, stripping html tags.
 * @param {string} text
 * @returns {number|*}
 */

import GraphemeSplitter from "grapheme-splitter";

export const calculateWrittenWords = (text, noSpaces = false) => {
  if (!text) return 0;

  const div = noSpaces ? "" : " ";
  const punctuation = " !\"#$%&'()*+,-–—―‒./:;<=>?@[\\]^_`{|}~ \t\n\x0b\x0c";
  // Remove all ``punctuation`` in ``text`` replacing each ``punctuationChar`` with ``div```.
  const txt = punctuation
    .split("")
    .reduce(
      (txt, punctuationChar) => txt.replaceAll(punctuationChar, div),
      text,
    );
  if (noSpaces) {
    const graphemeSplitter = new GraphemeSplitter();
    return graphemeSplitter.splitGraphemes(txt).length;
  }
  const words = txt
    // Transform each line in a list of words
    .split(div)
    .filter((word) => word.trim() !== "");
  return words.length;
};
