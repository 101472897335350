import {useCallback} from "react";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
// import SimpleUploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import installImageUploadAdapter from "../ckeditor/ImageUploadAdapter";

const CkEditorLazy = ({ onReady, ...props }) => {
  const handleReady = useCallback((editor)=>{
    if (onReady) {
      onReady(editor);
    }
    installImageUploadAdapter(editor);
  }, [onReady])
  return (
    <CKEditor
      editor={DecoupledEditor}
      {...props}
      onReady={handleReady}
    />
  );
};
export default CkEditorLazy;
