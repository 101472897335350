import decodeJwt from "jwt-decode";
import { API_URL } from "../components/constants";
import * as Sentry from "@sentry/browser";

const parseToken = async (refresh, access) => {
  /*
    Parse the JWT token and add its content to the localStorage.
    */
  let decoded = decodeJwt(access);
  return Promise.all([
    localStorage.setItem("access_token", access),
    localStorage.setItem("refresh_token", refresh),
    localStorage.setItem("groups", decoded.groups),
    localStorage.setItem("permissions", decoded.permissions),
    localStorage.setItem("role", decoded.role),
    localStorage.setItem("user_id", decoded.user_id),
    localStorage.setItem("username", decoded.username),
  ]);
};

const unsetToken = () => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("groups");
  localStorage.removeItem("permissions");
  localStorage.removeItem("role");
  localStorage.removeItem("user_id");
  localStorage.removeItem("username");
};

export const refreshToken = async () => {
  const refresh_token = localStorage.getItem("refresh_token");
  if (refresh_token === null) throw new Error("No refresh token.");

  const request = new Request(`${API_URL}/token/refresh/`, {
    method: "POST",
    body: JSON.stringify({ refresh: refresh_token }),
    headers: new Headers({ "Content-Type": "application/json" }),
  });
  return fetch(request)
    .then((response) => {
      if (response.status === 401) {
        unsetToken();
        throw new Error("Your session expired, please login again");
      }
      if (response.status < 200 || response.status >= 300) {
        const reason = response.body.reason
          ? response.body.reason
          : response.body.non_field_errors
          ? response.body.non_field_errors[0]
          : response.statusText;
        throw new Error(reason);
      }
      return response.json();
    })
    .then(({ refresh, access }) => {
      return parseToken(refresh, access);
    });
};

const getPermissionsFromLocalStorage = () => ({
  permission: localStorage.getItem("permissions"),
  groups: localStorage.getItem("groups"),
  role: localStorage.getItem("role"),
  user_id: localStorage.getItem("user_id"),
  username: localStorage.getItem("username"),
});

export const authProvider = (options) => {
  return {
    login: async (data) => {
      const request = new Request(`${API_URL}/token/`, {
        method: "POST",
        body: JSON.stringify({ ...data }),
        headers: new Headers({ "Content-Type": "application/json" }),
      });
      return fetch(request)
        .then((response) => {
          if (response.status < 200 || response.status >= 300) {
            throw new Error(response.statusText);
          }

          return response.json();
        })
        .catch((error) => {
          return Promise.reject(error);
        })
        .then(({ refresh, access }) => {
          return parseToken(refresh, access);
        });
    },
    // login: async ({ username, password }) => {
    //   const request = new Request(`${API_URL}/token/`, {
    //     method: "POST",
    //     body: JSON.stringify({ username, password }),
    //     headers: new Headers({ "Content-Type": "application/json" }),
    //   });
    //   return fetch(request)
    //     .then((response) => {
    //       if (response.status < 200 || response.status >= 300) {
    //         throw new Error(response.statusText);
    //       }

    //       return response.json();
    //     })
    //     .catch((error) => {
    //       return Promise.reject(error);
    //     })
    //     .then(({ refresh, access }) => {
    //       return parseToken(refresh, access);
    //     });
    // },
    checkError: (error) => {},
    checkAuth: async (params) => {
      const request = new Request(`${API_URL}/auth/users/me/`, {
        method: "GET",
      });
      const response = await fetch(request);

      const data = await response.json();
      return data.id ? Promise.resolve() : Promise.reject();
    },
    logout: () => {
      const request = new Request(`${API_URL}/token/`, {
        method: "DELETE",
      });
      const redirect = () => {
        return Promise.resolve("/login");
      };

      unsetToken();
      return fetch(request).catch(redirect).then(redirect);
    },
    getIdentity: async () => {
      const request = new Request(`${API_URL}/auth/users/me/`, {
        method: "GET",
      });
      const response = await fetch(request);
      const data = await response.json();
      return { id: data.id, fullName: data.username };
    },
    getPermissions: async () => {
      let permissions = getPermissionsFromLocalStorage();
      if (!permissions.username || !permissions.groups) {
        try {
          await refreshToken();
        } catch {
          return Promise.reject("Expired session");
        }

        permissions = getPermissionsFromLocalStorage();
      }
      Sentry.configureScope((scope) => scope.setUser({ ...permissions }));
      return permissions
        ? Promise.resolve(permissions)
        : Promise.reject("No permissions set.");
    },
  };
};
