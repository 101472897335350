import React from "react";
import { Resource as RAResource } from "react-admin";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import { RESOURCE, PERMISSIONS, FINANCEPERMISSIONS } from "./constants";

import List from "./List";
import Edit from "./Edit";
import Create from "./Create";

/**
 * For some reason the APP want the resource to be instantiated with this make factory.
 * @param {*} permissions
 * @param {*} key
 * @returns
 */
export const makeBrands = (permissions: any): React.ReactElement => {
  const permission = permissions?.permission || [];
  return (
    <RAResource
      name={RESOURCE}
      options={{ label: "Brands" }}
      list={
        permission.includes(PERMISSIONS.view) ||
        permission.includes(FINANCEPERMISSIONS.view)
          ? List
          : undefined
      }
      edit={
        permission.includes(PERMISSIONS.edit) ||
        permission.includes(FINANCEPERMISSIONS.edit)
          ? Edit
          : undefined
      }
      create={
        permission.includes(PERMISSIONS.create) ||
        permission.includes(FINANCEPERMISSIONS.create)
          ? Create
          : undefined
      }
      key={RESOURCE}
      icon={BusinessCenterIcon}
    />
  );
};

export default makeBrands;
