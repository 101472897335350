import React from "react";
import { Create as RaCreate, CreateProps } from "react-admin";
import { Form } from "./Form";

export const Create = (props: CreateProps): React.ReactElement => {
  const transform = (data: any) => {
    return {
      ...data,
      data: JSON.stringify(data.data),
    };
  };
  return (
    <RaCreate {...props} transform={transform}>
      <Form />
    </RaCreate>
  );
};

export default Create;
