/**
 * Shows the username and whether it is a freelancer or not.
 * @param record {{ id: integer, username: string, is_freelance: boolean}}
 * @returns {string}
 */
export const showUser = (record) => {
  if (!record) return undefined;
  const isFreelance = record.is_freelance ? " (Freelance)" : "";
  return `${record.full_name}${isFreelance}`;
};

export default showUser;
