import * as React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import CheckIcon from "@material-ui/icons/Check";
import { useNotify, fetchStart, fetchEnd, useRedirect } from "react-admin";

import TextField from "@material-ui/core/TextField";

import { API_URL } from "../../constants";
import { httpClient } from "../../utils";
import { APPROVED } from "../../constants";
import {
  Dialog,
  DialogContent,
  FormControlLabel,
  FormGroup,
  Switch,
} from "@material-ui/core";
import Slider from "@material-ui/core/Slider";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";
import SentimentVerySatisfiedIcon from "@material-ui/icons/SentimentVerySatisfied";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import Typography from "@material-ui/core/Typography";
import DialogContentText from "@material-ui/core/DialogContentText";
import { HorizontalGroup } from "../../reusable";

/**
 * Accepts the content
 * @param {*} param0
 */
export const ProofreaderApproveButton = ({ basePath, record, disabled }) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  // for proofreader we want to pay for all the words, so option 2.
  const [toPay, setToPay] = useState(2);
  const [templatePrice, setTemplatePrice] = useState(false);

  const handleToPayChange = (value) => {
    setToPay(value);
  };

  const [vote, setVote] = useState(4);
  const voteMarks = [
    {
      value: 1,
      label: <SentimentVeryDissatisfiedIcon />,
    },
    {
      value: 3,
      label: <DragIndicatorIcon />,
    },
    {
      value: 5,
      label: <SentimentVerySatisfiedIcon />,
    },
  ];
  const [feedback, setFeedBack] = useState("");
  const [handleClose, handleOpen] = [
    () => {
      setOpen(false);
    },
    () => {
      setOpen(true);
      handleToPayChange("2");
    },
  ];

  const dispatch = useDispatch();
  const notify = useNotify();
  const redirect = useRedirect();
  if (record.status === APPROVED) return null;

  const handleClick = () => {
    const path = `${API_URL}${basePath}/${record.id}/approve/`;
    setLoading(true);
    dispatch(fetchStart()); // start the global loading indicator
    httpClient(path, {
      method: "POST",
      body: JSON.stringify({
        vote,
        feedback,
        quote: null,
        payment_method: toPay,
        template_price: templatePrice,
      }),
    })
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json;
      })
      .catch((e) => {
        notify(`Error: ${e}`, "warning");
        return Promise.reject();
      })
      .then(({ status, valid }) => {
        redirect("/");
        notify(`Content ${record.id} approved.`);
      })
      .finally(() => {
        setLoading(false);
        dispatch(fetchEnd());
      });
  };
  return (
    <span>
      <Button
        id="proofreader-accept-btn"
        startIcon={<CheckIcon />}
        disabled={disabled || loading}
        // Activates feedback form.
        // onClick={ setOpen }
        onClick={handleOpen}
        color="primary"
        variant="contained"
      >
        {"Accept"}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Approve</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Optionally, you can fill this form to rate the content.
          </DialogContentText>
          <label>
            <Typography gutterBottom>Please rate the content</Typography>
          </label>
          <Slider
            value={vote}
            step={1}
            marks={voteMarks}
            min={1}
            max={5}
            onChange={(e, value) => {
              setVote(value);
            }}
          />

          <TextField
            multiline
            maxRows={4}
            fullWidth
            value={feedback}
            id="feedback"
            label="Optionally write some feedback here."
            helperText={"This feedback will be visible to the writer."}
            onChange={(e) => setFeedBack(e.target.value)}
          />
          <HorizontalGroup>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Switch
                    checked={templatePrice}
                    onChange={(e) => {
                      setTemplatePrice(e.target.checked);
                    }}
                    name="Should The writer will be paid for template?"
                    inputProps={{
                      "aria-label":
                        "Should The writer will be paid for template?",
                    }}
                  />
                }
                label="Should The writer will be paid for template?"
              />
            </FormGroup>
          </HorizontalGroup>
          <DialogActions>
            <Button
              id="pr-accept-final-btn"
              startIcon={<CheckIcon />}
              // disabled={disabled || loading}
              onClick={handleClick}
              color="primary"
              variant="contained"
            >
              {"Accept"}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </span>
  );
};
