import React from "react";
import { usePermissions } from "react-admin";
import MakeSeen from "./MakeSeen";
import { GROUPS } from "../../constants";

export const Actions = (props) => {
  const permissions = usePermissions();
  const IPResolvers = permissions.loading
    ? null
    : permissions.permissions.groups.includes(GROUPS.IPResolvers);

  if (IPResolvers) {
    return (
      <>
        <MakeSeen {...props} />
      </>
    );
  }
  return null;
};

export default Actions;
