export const API_URL = "/api";
export const RESOURCE: string = "product/dixit_product";

const MODEL = "dixitproduct";

export const PERMISSIONS = {
  create: `sales.add_${MODEL}`,
  view: `sales.view_${MODEL}`,
  edit: `sales.change_${MODEL}`,
  delete: `sales.delete_${MODEL}`,
};
