import { TextInput } from "react-admin";

/**
 * Shows an array as a textField
 * @param props
 * @returns
 */
export const ArrayTextInput = (props) => (
  <TextInput
    parse={(keywords) => {
      return Array.isArray(keywords)
        ? keywords
        : keywords === null
        ? []
        : keywords
            // replace all the newlines with commas.
            .split("\n")
            .join(",")

            // split by commas
            .split(",")

            // remove empty spaces
            .map((r) => r);
    }}
    format={(input) => {
      return input?.join ? input.join("\n") : input === undefined ? "" : input;
    }}
    multiline
    {...props}
  />
);
