import React from "react";
import { ListGuesser, Resource as RAResource } from "react-admin";
import { RESOURCE, PERMISSIONS } from "./constants";
import FindInPageIcon from "@material-ui/icons/FindInPage";
import List from "./List";

/**
 * For some reason the APP want the resource to be instantiated with this make factory.
 * @param {*} permissions
 * @param {*} key
 * @returns
 */
export const make = (permissions: any): React.ReactElement => {
  const permission = permissions?.permission || [];
  return (
    <RAResource
      name={RESOURCE}
      list={permission.includes(PERMISSIONS.view) ? List : undefined}
      options={{ label: "WP Links" }}
      key={RESOURCE}
      icon={FindInPageIcon}
    />
  );
};

export default make;
