import {
  NumberInput,
  ReferenceArrayInput,
  SimpleForm,
  TextInput,
  AutocompleteArrayInput,
  useDataProvider,
} from "react-admin";

import { RESOURCE } from "../constants";

export const Form = (props) => {
  const dataProvider = useDataProvider();
  // const [value, setValue] = React.useState(filter || '');

  return (
    <SimpleForm {...props}>
      <TextInput source="domain" required />
      <ReferenceArrayInput
        source="markets"
        reference={RESOURCE.market}
        sort={{ field: "country", order: "ASC" }}
      >
        <AutocompleteArrayInput
          optionText={(record) => `${record.country} (${record.language})`}
          optionValue="id"
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        source="accepted_topics"
        reference={RESOURCE.outreachTopics}
        sort={{ field: "name", order: "ASC" }}
      >
        <AutocompleteArrayInput
          optionText={"name"}
          optionValue="id"
          // create={(<div>Add new topic:</div>}
          onCreate={(value) => {
            return dataProvider
              .create(RESOURCE.outreachTopics, { data: { name: value } })
              .then(({ data }) => data);
          }}
        />
      </ReferenceArrayInput>

      <h3>Ahref</h3>
      <NumberInput
        onWheel={(e) => e.target.blur()}
        source="ahref.domain_rating"
      />
      <NumberInput
        onWheel={(e) => e.target.blur()}
        source="ahref.total_keywords"
      />
      <NumberInput
        onWheel={(e) => e.target.blur()}
        source="ahref.total_traffic"
      />
      <h3>Majestic</h3>
      <NumberInput
        onWheel={(e) => e.target.blur()}
        source="majestic.trust_flow"
      />
      <NumberInput
        onWheel={(e) => e.target.blur()}
        source="majestic.citation_flow"
      />
      <TextInput source="majestic.topical_trust_flow" />
      <NumberInput
        onWheel={(e) => e.target.blur()}
        source="majestic.referring_domain"
      />
      <h3>Other info</h3>
      <TextInput source="email" type="email" />
      <NumberInput onWheel={(e) => e.target.blur()} source="price" />
      <TextInput source="currency" />
    </SimpleForm>
  );
};

// export const Form = makeForm();
