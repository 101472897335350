/**
 * Similar to fetchRelatedRecords but ignores nulls.
 * @param fetchRelatedRecords
 * @param data
 * @param field
 * @param res
 * @returns {Promise<*>}
 */
export const fetchRelatedNoNull = async (
  fetchRelatedRecords,
  data,
  field,
  res
) => {
  const dt = data.map((entry) => {
    const result = {};
    const values = Array.isArray(entry[field]) ? entry[field] : [entry[field]];
    result[field] = values.filter((value) => !!value);
    return result;
  });
  return await fetchRelatedRecords(dt, field, res);
};
