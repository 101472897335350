import * as React from "react";
import { useState } from "react";

import { Card, CardHeader } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";

import { FormSpy, useFormState } from "react-final-form";

import {
  Edit,
  Create,
  SimpleForm,
  ReferenceInput,
  SelectInput,
  TextInput,
  DateField,
  TextField,
  useGetList,
  Loading,
  Error,
  ArrayInput,
  ArrayField,
  SimpleFormIterator,
  NumberInput,
  ReferenceField,
  useNotify,
  useRefresh,
  DateTimeInput,
  AutocompleteInput,
  maxLength,
  BooleanInput,
} from "react-admin";
// import TextField from '@material-ui/core/TextField';
import { Fragment } from "react";

import { ContentEditToolbar } from "./edit/contentEditToolbar";

import { YtgField } from "../content/ytg/ytgField";
import { RESOURCE, STATUS, CONTENT_TYPE_CHOICES } from "../constants";
import { useStyles } from "../useStyles";
import { ArrayTextInput, AdvancedTextInput } from "../reusable";
import { MarketReferenceInput } from "../market";
import { CountedTextField } from "../reusable";
import { pagePathUniqueValidator } from "./validators";

export const PageEdit = (sourceProps) => {
  /**
   * Edit page for the page, with a session to create an order.
   */
  const notify = useNotify();
  const refresh = useRefresh();

  const onSuccess = () => {
    refresh();
    notify(`Success`);
  };
  const props = {
    ...sourceProps,
    onSuccess: onSuccess,
    mutationMode: "pessimistic",
  };

  const [pristine, setPristine] = useState(true);
  return [
    <Edit actions={null} key="page" {...props}>
      <SimpleForm>
        <TextField source="full_url" />

        <ParentInput />
        <ReferenceField
          label="Go to parent"
          source="parent"
          reference={RESOURCE.page}
          link={"edit"}
        >
          <TextField source="path" />
        </ReferenceField>

        <ReferenceField
          label="Parent keywords"
          source="parent"
          reference={RESOURCE.page}
          link={false}
        >
          <ArrayField source="keywords">
            <TagsField source={"name"} />
          </ArrayField>
        </ReferenceField>

        <MarketReferenceInput />

        <TextInput source="path" validate={pagePathUniqueValidator} />

        <ArrayTextInput
          required
          source="keywords"
          label={
            "Page keywords separate with commas or new a line, the first will be used as guide for YTG"
          }
          fullWidth
          multiline
        />

        <DateField label="Date created" source="date_created" />
        <TextField source="status" />
        <FormSpy
          subscription={{ pristine: true, valid: true }}
          onChange={({ pristine, valid }) => setPristine(pristine && valid)}
        />
      </SimpleForm>
    </Edit>,
    <ContentForm disabled={pristine} {...props} key="content" />,
  ];
};

export const ParentInput = (props) => {
  const { values } = useFormState();
  return (
    <ReferenceInput
      label="Parent"
      source="parent"
      reference={RESOURCE.page}
      filter={{ website: values.website }}
      {...props}
    >
      <AutocompleteInput optionText="full_url" />
    </ReferenceInput>
  );
};
ParentInput.defaultProps = {
  label: "Select parent page",
  addLabel: true,
};

const TagsField = ({ ids, data, ...props }) => {
  const classes = useStyles();
  if (ids.length === 0)
    return (
      <Typography variant="body1" gutterBottom>
        No keywords in the parent page! You can add one clicking on it.
      </Typography>
    );
  return ids.map((id, idx) => (
    <Chip key={idx} label={data[id]} className={classes.chippedList} />
  ));
};

const ContentForm = ({ disabled, hasKeywords, ...props }) => {
  const { ids, loading, error } = useGetList(
    RESOURCE.order,
    { page: 1, perPage: 1 },
    { field: "id", order: "DESC" },
    {
      webpage: props.id,
      status__in: JSON.stringify([
        STATUS.planned,
        STATUS.requested,
        STATUS.review,
      ]),
    },
  );

  const transform = (formData) => {
    const data = {
      ...formData,
      webpage: parseInt(props.id),
    };
    return data;
  };
  if (!disabled)
    return (
      <Card>
        <CardHeader
          title={"Please save the page form first"}
          subheader={
            "Make sure there is at least a single keyword in the page."
          }
        />
      </Card>
    );
  if (loading) return <Loading />;
  if (error) return <Error />;
  if (!ids.length) {
    return (
      <Create
        {...props}
        actions={null}
        basePath={props.basePath}
        transform={transform}
        resource={RESOURCE.order}
      >
        <SimpleForm>
          <ContentInnerForm />
        </SimpleForm>
      </Create>
    );
  }
  return (
    <Edit
      {...props}
      id={ids[0]}
      aside={<YtgField aside />}
      actions={null}
      mutationMode="pessimistic"
      basePath={props.basePath}
      resource={RESOURCE.order}
      transform={transform}
    >
      <SimpleForm toolbar={<ContentEditToolbar />}>
        <ContentInnerForm />
      </SimpleForm>
    </Edit>
  );
};

const ContentInnerForm = () => {
  return (
    <Fragment>
      <h3>Setup an order for this page</h3>
      <h4>Prepare the content</h4>
      <TextInput
        type="url"
        source={"google_docs"}
        target="_blank"
        label="Template in GDOCS"
        validate={maxLength(150)}
        fullWidth
      />
      <SelectInput
        source="type"
        choices={CONTENT_TYPE_CHOICES}
        initialValue="review"
        fullWidth
      />
      <h4>The content structure</h4>
      <AdvancedTextInput source="content" fullWidth label="content" />
      <h4>The content metadata</h4>
      <CountedTextField
        fullWidth={true}
        label={"Meta Title"}
        source="meta_title"
        suggestedLength={60}
        maximumLength={150}
      />
      <CountedTextField
        suggestedLength={180}
        maximumLength={400}
        fullWidth={true}
        label={"Meta Description"}
        source="meta_description"
      />
      <BooleanInput source="editable_metas" label={"Editable Meta"} />
      <h4>Additional content to prepare</h4>
      <NumberInput
        onWheel={(e) => e.target.blur()}
        fullWidth={true}
        source="structure.number_of_forces_and_weakness"
        label={"Number of forces and weakness"}
        initialValue={0}
        min={0}
        max={5}
      />
      <NumberInput
        onWheel={(e) => e.target.blur()}
        fullWidth={true}
        source="structure.number_of_reviews"
        label={"Number of reviews"}
        initialValue={0}
        min={0}
        max={5}
      />

      <h4>Further info for the writer</h4>
      <TextInput
        multiline
        fullWidth={true}
        label={"Reader Intent"}
        source="structure.reader_intent"
      />
      <ArrayInput
        source="examples"
        label={"A few urls that can be used as reference."}
      >
        <SimpleFormIterator>
          <TextInput fullWidth label="Example Url"></TextInput>
        </SimpleFormIterator>
      </ArrayInput>

      <h4>Order details</h4>
      <ReferenceInput
        label="Writer"
        source="writer"
        reference="content/writer"
        filterToQuery={(searchText) => ({ full_name: searchText })}
      >
        <AutocompleteInput fullWidth optionText="full_name" />
      </ReferenceInput>
      <NumberInput
        onWheel={(e) => e.target.blur()}
        fullWidth
        source="requested_words"
        step="50"
        initialValue="350"
      />
      <DateTimeInput
        fullWidth
        label="Expected date of arrival"
        source="content_eta"
      />
    </Fragment>
  );
};
