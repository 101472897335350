// import {createSvgIcon} from "@material-ui/core/utils/index";
import SvgIcon from "@material-ui/core/SvgIcon";

import { ReactComponent as GoogleLogoSVGCut } from "./GoogleLogoSVGCut.svg";

export const GoogleLogoCut = (props) => (
  <SvgIcon component={GoogleLogoSVGCut} {...props} />
);

export default GoogleLogoCut;
