import { Resource } from "react-admin";
import { RESOURCE, GROUPS } from "../../constants";

import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import { List } from "./List";
import { Create } from "./Create";
import { Edit } from "./Edit";

export const makeVassistantTask = (permissions: any) => {
  const props = {
    name: RESOURCE.vAssistantTask,
    key: RESOURCE.vAssistantTask,
  };
  const groups = [
    GROUPS.editors,
    GROUPS.linkbuilding,
    GROUPS.outreach,
    GROUPS.productOwners,
    GROUPS.vAssistant,
  ];
  const contained = groups.some((group) => permissions.groups.includes(group));
  if (contained) {
    return (
      <Resource
        {...props}
        options={{ label: "Virtual Assistant Tasks" }}
        create={Create}
        list={List}
        edit={Edit}
        icon={PermIdentityIcon}
      />
    );
  }
  return null;
};

export default makeVassistantTask;
