import * as React from "react";
import {
  DateInput,
  DateField,
  Error,
  Loading,
  fetchStart,
  fetchEnd,
  useNotify,
  useRefresh,
} from "react-admin";
import {
  Grid,
  Card,
  CardContent,
  CardHeader,
  makeStyles,
  Paper,
  Button,
  Typography,
  Box,
} from "@material-ui/core";
import LoopIcon from "@material-ui/icons/Loop";
import { YtgChart } from "../ytg/ytgChart";
import { useGetYTG } from "../../yourtext_guru/hooks";
import { useCountWordsGetTotal } from "../../reusable/store/countWords";
import { useDispatch } from "react-redux";
import { API_URL } from "../../constants";
import { httpClient } from "../../utils";

const useStyles = makeStyles((theme) => ({
  marginTop: {
    marginTop: "1.5rem",
  },
  form: {
    "& .ql-editor": {
      minHeight: "300px",
    },
  },
  accordion: {
    width: "98%",
    margin: "16px!important",
  },
  // a light pink for a light alert
  wrongWordsCount: {
    backgroundColor: "#fee",
  },
  okWordsCount: {
    backgroundColor: "#fff",
  },
  dateInput: {
    width: "100%",
  },
  dateContent: {
    "& .MuiCardContent-root": {
      margin: 0,
      paddingTop: 0,
      paddingBottom: 0,
    },
    "& .MuiFormHelperText-root": {
      display: "none",
    },
    "& .MuiInputBase-root": {
      paddingBottom: ".2rem",
    },
  },
  paper: {
    width: "100%",
    backgroundColor: "#fef2f2",
  },
  yellowPaper: {
    width: "100%",
    backgroundColor: "#fffbeb",
  },
  box: {
    width: "100%",
    padding: "2rem",
  },
}));

export const InfoGrid = ({ record, ref, notForm, isFreelance, ...props }) => {
  const classes = useStyles();
  return (
    <Grid
      className={classes.marginTop}
      container
      justifyContent="center"
      spacing={1}
    >
      <Grid container xs={12} item spacing={1}>
        <Grid item xs={2}>
          <Card>
            <CardHeader subheader={"Requested Words"}></CardHeader>
            <CardContent>{record.requested_words}</CardContent>
          </Card>
        </Grid>

        <Grid item xs={2}>
          {notForm ? (
            <WrittenWordsCard record={record} />
          ) : (
            <WrittenWords record={record} />
          )}
        </Grid>

        <Grid item xs={2}>
          <Card>
            <CardHeader subheader={"Date Created"}></CardHeader>

            <CardContent>{record.date_created.substring(0, 10)}</CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card>
            <CardHeader subheader={"Deadline for Integrator"}></CardHeader>

            <CardContent>
              {record?.integrator_eta?.substring(0, 10)}
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={3}>
          <Card className={notForm ? undefined : classes.dateContent}>
            <CardHeader subheader={"Deadline"}></CardHeader>
            <CardContent>
              {notForm ? (
                record.content_eta?.substring(0, 10)
              ) : (
                <DateInput
                  disabled={isFreelance}
                  label="Expected date of arrival"
                  className={classes.dateInput}
                  source="content_eta"
                  initialValue={record.content_eta ? record.content_eta : null}
                />
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};

const YTG_STATUS = {
  unknown: "",
  requested: "requested",
  too_early: "too_early",
  generation_error: "generation_error",
  ok: "ok",
};

const YTGStatus = ({ status, record, props }) => {
  const classes = useStyles();
  const [ytgLoading, setYtgLoading] = React.useState(false);
  const dispatch = useDispatch();
  const notify = useNotify();
  const refresh = useRefresh();

  const createYTG = React.useCallback(() => {
    const path = `${API_URL}${props.basePath}/${record.id}/createYTG/`;
    setYtgLoading(true);
    dispatch(fetchStart()); // start the global loading indicator
    httpClient(path, { method: "POST" })
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response);
        }
        return response.json;
      })
      .catch((e) => {
        const reason = e.body?.reason ? e.body.reason : e.message;
        return Promise.reject(`warning: ${reason}`);
      })
      .then(
        ({ status, valid }) => {
          notify(`The Request has been submitted`);
        },
        (e) => notify(e, "info"),
      )
      .finally(() => {
        setYtgLoading(false);
        dispatch(fetchEnd()); // stop the global loading indicator
        refresh();
      });
  }, [props.basePath, record.id, dispatch, refresh, notify]);

  if (!record || !status) {
    return null;
  }
  if (ytgLoading) {
    return <Loading />;
  }

  if (status === YTG_STATUS.unknown || status === YTG_STATUS.ok) {
    return null;
  }

  if (status === YTG_STATUS.requested || status === YTG_STATUS.too_early) {
    return (
      <Paper className={classes.yellowPaper}>
        <Box
          className={classes.box}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Typography variant="h5" color="textPrimary">
            <strong>🚧 Your Text Guru Guide Generation in process</strong>
          </Typography>
          <Typography variant="p" color="textPrimary">
            Please come back after new minutes.
          </Typography>
        </Box>
      </Paper>
    );
  }

  return (
    <Paper className={classes.paper}>
      <Box
        className={classes.box}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Typography variant="h5" color="error">
          <strong>Your Text Guru Graph Generation Failed!</strong>
        </Typography>
        <Typography variant="p" color="textPrimary">
          <p>
            Your Text Guru generation can fail due to using wrong formatted
            keywords. Try Changing the Keywords and then recreate a new graph.
          </p>
        </Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<LoopIcon />}
          onClick={createYTG}
        >
          Re Create YTG Graph
        </Button>
      </Box>
    </Paper>
  );
};

export const ValidationGrid = ({ record, ref, ...props }) => {
  const { data, loading, error } = useGetYTG(record.yourtext_guru_id);

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;

  return (
    <Grid container justifyContent="center" spacing={1}>
      <YTGStatus status={data?.status} record={record} props={props} />
      <Grid item xs={12}>
        <YtgChart
          chartData={data?.ytg_chart}
          guide={data?.guide}
          soseo={data?.soseo}
          dseo={data?.dseo}
          ref={ref}
        />
      </Grid>
      <Grid container xs={12} item spacing={1}>
        <Grid item xs={3}>
          <Card>
            <CardHeader subheader={"Copyscape Count"}></CardHeader>
            <CardContent>
              <p>
                {record.copyscape_count !== null
                  ? record.copyscape_count
                  : "Not counted"}
              </p>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={3}>
          <Card>
            <CardHeader subheader={"Copyscape Link"}></CardHeader>
            <CardContent>
              <p>
                {record.copyscape_url !== "" ? (
                  <a
                    href={record.copyscape_url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Available
                  </a>
                ) : (
                  "Not available"
                )}
              </p>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card>
            <CardHeader subheader={"SEO Danger"}></CardHeader>
            <CardContent>
              <p>{record.dseo || "Not available"}</p>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={3}>
          <Card>
            <CardHeader subheader={"SEO Value"}></CardHeader>
            <CardContent>
              <p>{record.soseo || "Not available"}</p>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};

const WrittenWordsCard = ({ record, writtenWords }) => {
  const classes = useStyles();
  // Content coming from the server4
  const requested = record.requested_words;
  const within = (written) =>
    requested * 1.1 >= written && written >= requested * 0.9;
  // Get the editor element.
  const written = writtenWords ? writtenWords : record.total_words_written;
  const valid = within(written);
  return (
    <Card className={valid ? classes.okWordsCount : classes.wrongWordsCount}>
      <CardHeader subheader={"Written Words"}></CardHeader>
      <CardContent>{written}</CardContent>
    </Card>
  );
};

const WrittenWords = ({ record }) => {
  /**
   * Hooks up the ql-editor and observes its changes.
   */
  // Get the editor element.
  const written = useCountWordsGetTotal();
  return <WrittenWordsCard record={record} writtenWords={written} />;
};
