export const RESOURCE: string = "sales/links";
export { ADMIN } from "../constants";

const MODEL = "link";
const FINANCEMODEL = "financelink";

export const PERMISSIONS = {
  create: `sales.add_${MODEL}`,
  view: `sales.view_${MODEL}`,
  edit: `sales.change_${MODEL}`,
  delete: `sales.delete_${MODEL}`,
};

export const FINANCEPERMISSIONS = {
  view: `sales.view_${FINANCEMODEL}`,
};

export const CHANNEL = [
  { id: "", name: "ANY" },
  { id: "website", name: "Website" },
  { id: "youtube", name: "Youtube" },
  { id: "facebook", name: "Facebook" },
  { id: "twitter", name: "Twitter" },
  { id: "twitch", name: "Twitch" },
  { id: "reddit", name: "Reddit" },
  { id: "other", name: "Other" },
];

export const DEAL = [
  { id: "hybrid", name: "Hybrid" },
  { id: "cpa", name: "CPA" },
  { id: "revenue_share", name: "Revenue Share" },
  { id: "cpl", name: "CPL" },
  { id: "with_link", name: "With link" },
  { id: "without_link", name: "No Link" },
];

export const PRODUCT = [
  { id: 1, name: "Casino" },
  { id: 2, name: "Sportbetting" },
  { id: 3, name: "Poker" },
];
