// Improved TypeScript code with component naming, error handling, and accessibility enhancements

import { PERMISSION } from '../../constants';
import { useHasPermission } from '../../utils';
import { Loading, useRecordContext, useUpdate, useResourceContext } from 'react-admin';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import SyncIcon from '@material-ui/icons/Sync';
import useGetRate from '../utils/useGetRate';
import Alert from '@material-ui/lab/Alert'; // Import for error handling

const useStyles = makeStyles((theme: Theme) => {
    return ({
    root: {
      maxWidth: '50em',
      margin: 'auto',
      fontSize: '1.2em',
      backgroundColor: theme.palette.warning.light
    },
    bullet: {
      margin: theme.spacing(0.5), // Use theme spacing
      transform: 'scale(0.8)',
      "& th": {
        textAlign: 'right',
        paddingRight: theme.spacing(2), // Use theme spacing
      },
    },
})
});

const RateDiscrepancyCard = ({...props}) => {
    const classes = useStyles();
    const resource = useResourceContext();
    const record = useRecordContext({...props});
    const rate = useGetRate(record?.id);
    const { hasPermission, loading, error } = useHasPermission(PERMISSION.writerRate.change); // Assume error state is possible
    const [sync, { loading: syncLoading, error: syncError }] = useUpdate(
        resource, record.id, {...record, _writer_rate: rate.writer},
    );

    if (error || syncError) {
        return <Alert severity="error">Error: {error?.message || syncError?.message}</Alert>; // Display error message
    }
    if (loading || !rate.loaded) {
        return <Loading />;
    }
    if (!hasPermission || !record || !rate.rate) {
        return null;
    }

    if (!rate.sameRate) {
        return (
            <Card className={classes.root} aria-label="Rate Discrepancy Information">
                <CardContent>
                    <h3>Warning! Rate Discrepancy!</h3>
                    <p>
                        This might happen when a writer rate has been updated,
                        or other operations have taken place (e.g. the order has been reassigned)
                        to avoid unintended side effects the sync needs to be done manually.
                    </p>
                    <table className={classes.bullet}>
                        <tbody>
                            <tr>
                                <th scope="row">Writer rate</th>
                                <td> {rate.writer} {rate.currency} </td>
                            </tr>
                            <tr>
                                <th scope="row">Rate applied to this order</th>
                                <td> {rate.rate} {rate.currency} </td>
                            </tr>
                        </tbody>
                    </table>
                    
                    <OtherRates rates={rate.writerRates} currency={rate.currency} />
                    <p>Click on Sync rate to set the rate to the writer default rate ({rate.writer} {rate.currency}).</p>
                </CardContent>
                <CardActions>
                    <Button size="small" variant="contained" color="primary" onClick={sync} disabled={syncLoading}>
                        <SyncIcon/> Sync rate
                    </Button>
                </CardActions>
            </Card>
        );
    }
    return null;
};

const OtherRates = ({rates, currency}: {rates: string[], currency: string}) => {
    if (!rates || rates.length === 0) {
        return null;
    }
    return (
        <>
            <p>This writer has also other active rates, none of which matching this order:</p>
            <ul>
                {rates.map((r, i) => <li key={i}>{r} {currency}</li>)}
            </ul>
        </>
    );
};

export default RateDiscrepancyCard;