import React from "react";
import { Resource as RAResource } from "react-admin";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import { RESOURCE } from "./constants";

/**
 * For some reason the APP want the resource to be instantiated with this make factory.
 * @param {*} permissions
 * @param {*} key
 * @returns
 */
export const makeContentSection = (permissions) => {
  // const permission = permissions.permission;
  return (
    <RAResource
      name={RESOURCE}
      options={{ label: "ContentSections" }}
      key={RESOURCE}
      icon={BusinessCenterIcon}
    />
  );
};

export default makeContentSection;
