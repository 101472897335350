import * as React from "react";

import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  Datagrid,
  ReferenceField,
  ShowButton,
  ReferenceManyField,
  Loading,
  Error,
  EditButton,
} from "react-admin";

import { WebpageAside } from "../../content/edit/WebpageAside";
import { ContentShowLayout } from "../../content/show/ContentShow";
import { useContentByPage } from "../../content/useContent";
import { RESOURCE, STATUS } from "../../constants";
import { WriterReferenceField } from "../../writer";
import { ChipArrayField } from "../../reusable";
import { UserReferenceField } from "../../user";

export const PageShow = (props) => {
  const { loaded, error, data } = useContentByPage(props.id, {
    status: STATUS.approved,
  });

  if (error) return <Error error={error} />;
  if (!loaded) return <Loading />;
  if (data.length > 0) {
    return (
      <Show {...props} aside={<Aside content={data[0]} />}>
        <Layout content={data[0]} />
      </Show>
    );
  }
  return (
    <Show {...props} aside={data ? <Aside content={data[0]} /> : null}>
      <SimpleShowLayout>
        <ReferenceField
          label="Website"
          source="website"
          reference={RESOURCE.website}
        >
          <TextField source="domain" />
        </ReferenceField>
        <TextField source="status" />
        <ChipArrayField source="keywords" />
        <TextField source="path" />
        <DateField label="Date Created" source="date_created" />

        <ReferenceManyField reference={RESOURCE.order} target={"webpage"}>
          <Datagrid>
            <TextField source="id" />
            <WriterReferenceField />
            <UserReferenceField source={"created_by"} label={"Requested by"} />
            <DateField label="Date Created" source="date_created" />
            <TextField source={"status"} />
            <TextField source="requested_words" />
            <TextField source="words_written" />
            <ShowButton />
            <EditButton />
          </Datagrid>
        </ReferenceManyField>
      </SimpleShowLayout>
    </Show>
  );
};

const Aside = ({ record, content }) => <WebpageAside record={content} />;

const Layout = ({ record, content, ...props }) => (
  <ContentShowLayout record={content} {...props} />
);
