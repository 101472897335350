import { useState } from "react";
import { ReferenceInput, AutocompleteInput } from "react-admin";

import { RESOURCE } from "../../constants";

export const WebsiteReferenceInput = ({
  source,
  label,
  helperText,
  prefetch,
  ...props
}) => {
  const [clicked, setClicked] = useState(false);
  return (
    <span onClick={() => setClicked(true)}>
      <ReferenceInput
        source={source ? source : "website"}
        label={label ? label : "Website"}
        reference={RESOURCE.website}
        sortBy={"domain"}
        sort={{ field: "domain", order: "ASC" }}
        enableGetChoices={({ q }) => {
          return clicked || (q ? q.length > 1 : false) || prefetch;
        }}
        allowEmpty={false}
        {...props}
      >
        <AutocompleteInput
          optionValue="id"
          optionText={(record) => {
            if (!record) {
              return undefined;
            }
            return record.deleted
              ? `${record.domain} (Deleted)`
              : `${record.domain}`;
          }}
          helperText={helperText}
          allowEmpty={false}
        />
      </ReferenceInput>
    </span>
  );
};

WebsiteReferenceInput.defaultProps = {
  label: "Website",
  addLabel: true,
};
