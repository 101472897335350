import { Show as RAShow, SimpleShowLayout, TextField } from "react-admin";
import { NoFormAdvancedTextInput } from "../reusable";
import { Links, Video } from "./utils";

export const Show = (props) => (
  <RAShow {...props}>
    <SimpleShowLayout>
      <TextField source={"title"} component={"h2"} variant={"h3"} label={""} />
      <TextField
        source={"description"}
        component={"p"}
        variant={"p"}
        label={""}
      />
      <Links />
      <Video />
      <NoFormAdvancedTextInput mimetize source={"data.content"} label={""} />
    </SimpleShowLayout>
  </RAShow>
);

export default Show;
