import * as React from "react";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

import {
  AppBar as RaAppBar,
  UserMenu,
  MenuItemLink,
  Layout as RaLayout,
  usePermissions,
} from "react-admin";

import { WRITER, RES_WRITER } from "../constants";
import MenuItem from "@material-ui/core/MenuItem";
import { LayoutMenu } from "./Menu";
import { NotificationMenuItem } from "../notifications";
export { theme } from "./Theme";

const ConfigurationMenu = React.forwardRef(({ onClick }, ref) => {
  const { permissions, loading } = usePermissions();
  if (loading) return null;
  return permissions.role === WRITER ? (
    <MenuItemLink
      ref={ref}
      to={`/${RES_WRITER}/${permissions.user_id}`}
      primaryText="User settings"
      leftIcon={<AccountCircleIcon />}
      onClick={onClick} // close the menu on click
    />
  ) : null;
});

const MyUserMenu = (props) => {
  return [
    <NotificationMenuItem key={"1"} />,
    <UserMenu key={"2"} {...props}>
      <MenuItem>
        <ConfigurationMenu />
      </MenuItem>
    </UserMenu>,
  ];
};

const AppBar = (props) => (
  <RaAppBar {...props} userMenu={<MyUserMenu />}>
    <LayoutMenu asMenu />
  </RaAppBar>
);

export const Layout = (props) => (
  <RaLayout {...props} menu={LayoutMenu} appBar={AppBar} />
);
