import { Resource, usePermissions } from "react-admin";

import { RESOURCE, GROUPS } from "../constants";

import { List } from "./List";
import EventNoteIcon from "@material-ui/icons/EventNote";
const RES = RESOURCE.outreachMonthlyPlan;

/**
 * For some reason the APP want the resource to be instantiated with this make factory.
 * @param {*} permissions
 * @param {*} key
 * @returns
 */
export const makeOutreachMonthlyPlan = (permissions) => {
  const canView =
    permissions?.permission?.includes("outreach.view_outreach") || false;

  return canView ? (
    <Resource
      name={RES}
      options={{ label: "Outreach Monthly Plan" }}
      list={List}
      key={RES}
      icon={EventNoteIcon}
    />
  ) : undefined;
};
