export const checkCanApproveContent = (record, permissions) => {
  const user_permissions =
    permissions && permissions?.permission && permissions.permission.split(",");

  return (
    record &&
    user_permissions &&
    (user_permissions?.includes(`gtm_auth.can_approve_content`) ||
      user_permissions?.includes(
        `gtm_auth.can_approve_content_${record.market_iso_code}`,
      ))
  );
};
