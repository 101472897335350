import { useState } from "react";
import Typography from "@material-ui/core/Typography";
import {
  Button,
  DateInput,
  SimpleForm,
  usePermissions,
  Loading,
  Toolbar as RAToolbar,
} from "react-admin";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";

import { useStyles } from "../../../useStyles";
import { GROUPS, STATUS } from "../../../constants";
import { getDayFromNow } from "../../../reusable";
import { DialogContent } from "@material-ui/core";
import { ActionButton } from "../../../reusable";
import { UserReferenceInput } from "../../../user";

export const BulkTrelloCreate = ({ selectedIds, filterValues }) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const { permissions, loading } = usePermissions();
  const handleClose = () => {
    setOpen(false);
  };
  if (loading || !permissions?.groups) return <Loading />;
  if (
    !permissions.groups.includes(GROUPS.integrators) ||
    permissions.groups.includes(GROUPS.freelances)
  ) {
    return null;
  }
  if (filterValues["status"] !== STATUS.approved) return null;
  return (
    <span>
      <Button
        variant="contained"
        label={"Create Trello Cards for Integration"}
        onClick={() => setOpen(true)}
      />
      <Dialog
        aria-labelledby="create-integration-trello-dialog-title"
        aria-describedby="create-integration-trello-dialog-description"
        maxWidth={"lg"}
        className={classes.dialogForm}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle id={"create-integration-trello-dialog-title"}>
          Create cards on trello .
          <Button label={"Close"} onClick={() => setOpen(false)} />
        </DialogTitle>
        <SimpleForm toolbar={<Toolbar selectedIds={selectedIds} />}>
          <Content selectedIds={selectedIds} />
        </SimpleForm>
      </Dialog>
    </span>
  );
};

const Content = ({ selectedIds, ...props }) => (
  <DialogContent id={"create-integration-trello-dialog-description"}>
    <Typography>
      The cards will be created for for {selectedIds.length} pages, one card for
      each product.
    </Typography>
    <UserReferenceInput
      source={"integrator"}
      {...props}
      filter={{ groups__name: GROUPS.integrators }}
    />
    {/* <DateInput
      source={"expected_date_of_arrival"}
      {...props}
      initialValue={getDayFromNow(7).toLocaleDateString()}
    /> */}
  </DialogContent>
);

const Toolbar = ({ selectedIds, ...props }) => (
  <RAToolbar {...props}>
    <ActionButton
      variant="contained"
      method={"contentCreateTrello"}
      label={"Save"}
      data={{ orders: selectedIds }}
    />
  </RAToolbar>
);
