export { outreachOrderStatus } from "./outreachOrderStatus";

export const OUTREACH_PROJECT = {
  default: "default",
  pr_project: "pr_project",
};

export const OUTREACH_PROJECT_CHOICES = Object.keys(OUTREACH_PROJECT).map(
  (key) => ({
    id: key,
    name: OUTREACH_PROJECT[key],
  }),
);
