import {
  Datagrid,
  ReferenceManyField,
  useCreate,
  useRecordContext,
  useListContext,
  useNotify,
  Pagination,
} from "react-admin";
import { RESOURCE as DEALS } from "../constants";
import { MarketEditableReferenceField } from "../../../market/fields/MarketlReferenceField";
import { EditableWebsiteReferenceField } from "../../../website/fields/WebsiteReferenceField";
import EditableTextField from "../../../reusable/fields/EditableTextField";
import Actions, {
  AffiliateLinkCopyAction,
  LinkCopyAction,
  PrettyLinkCopyAction,
} from "./Actions";
import React, { FC } from "react";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import Channel from "../components/Channel";
import EditableNumberField from "../../../reusable/fields/EditableNumberField";
import { makeStyles } from "@material-ui/core/styles";
import { EditableYoutubeReferenceField } from "../../../youtube/fields";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { EditableAccountReferenceField } from "../../accounts/input";
import { EditableDateField } from "../../../reusable/fields";
import { NotesToolTip } from "./List";
import { HorizontalGroup } from "../../../reusable";
import { ReferencedLinksList } from "../../lists/List/ReferencedLinksList";

interface AddNewProps {
  brand: string | number;
}

const useStyles = makeStyles((theme) => {
  return {
    field: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      wordBreak: "break-word",
    },
    textBreak: {
      wordBreak: "break-word",
    },
    flex: {
      display: "flex",
      justifyContent: "space-between",
    },
  };
});

/**
 * Will allow to create the item
 * @param string
 * @constructor
 */
const AddNew: FC<AddNewProps> = ({ brand }) => {
  const [create, status] = useCreate();
  const notify = useNotify();
  const { refetch } = useListContext();
  const handleCreate = React.useCallback(() => {
    create(DEALS, { brand: brand });
  }, [brand, create]);
  React.useEffect(() => {
    if (status.loaded) {
      if (status.error) {
        notify(status.error, "error");
      } else {
        refetch();
      }
    }
  }, [status, refetch, notify]);
  return (
    <div>
      <Button onClick={handleCreate} startIcon={<AddIcon />}>
        Add new
      </Button>
    </div>
  );
};

/**
 * Used in the deals.
 *
 * props is automatically assigned so the type does not really
 *  matter.
 */
export const ReferencedList: FC<any> = (props) => {
  const record = useRecordContext();
  return (
    <ReferenceManyField
      fullWidth
      label=""
      sort={{ field: "market", order: "ASC" }}
      reference={DEALS}
      target="brand"
      perPage={100}
      pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
    >
      <DG brand={record.id} />
    </ReferenceManyField>
  );
};

const DG: FC<any> = ({ brand, ...props }) => {
  const classes = useStyles();
  const notify = useNotify();

  return (
    <div>
      <div className={classes.flex}>
        <AddNew brand={brand} />
        <span>
          <CopyToClipboard
            text={"€"}
            onCopy={() => notify(`'€' copied to clipboard.`)}
          >
            <Button variant="outlined">Copy €</Button>
          </CopyToClipboard>
        </span>
      </div>
      <Datagrid
        {...props}
        rowStyle={(record, index) => {
          return {
            backgroundColor: record.end_date ? "#fef08a" : "white",
          };
        }}
      >
        <NotesToolTip />
        <MarketEditableReferenceField source={"market"} />
        <EditableTextField source={"data.admin_fee"} label={"Admin Fee"} />
        <EditableTextField
          source={"data.effective_revenue_share"}
          label={"Effective Revenue Share"}
        />
        <EditableTextField
          source={"data.revenue_share"}
          label={"Revenue Share"}
        />
        <EditableTextField source={"data.cpa"} label={"CPA"} />
        <EditableTextField source={"data.baseline"} label={"Baseline"} />
        <EditableNumberField
          source="data.is_baseline_cumulative"
          label="Basline Cumulative"
          choices={[
            { id: 1, name: "✅" },
            { id: 2, name: "❌" },
            { id: 3, name: "-" },
          ]}
        />
        <EditableTextField source={"data.min_dep"} label={"Min Dep"} />
        <EditableTextField source={"data.currency"} label={"Currency"} />
        <EditableNumberField
          source="data.is_bl_cumulative"
          label="Bl Cumulative"
          choices={[
            { id: 1, name: "✅" },
            { id: 2, name: "❌" },
            { id: 3, name: "-" },
          ]}
        />
        <EditableNumberField
          source="data.nnco"
          label="NNCO"
          choices={[
            { id: 1, name: "✅" },
            { id: 2, name: "❌" },
            { id: 3, name: "-" },
          ]}
        />
        <EditableDateField source={"closing_date"} label={"Closing Date"} />
        <EditableDateField source={"starting_date"} label={"Starting Date"} />
        <EditableDateField source={"end_date"} label={"End Date"} />
        <EditableTextField
          sortable={false}
          source={"data.imp_notes"}
          label={"⚠️ Important Notes"}
        />
        <EditableTextField
          sortable={false}
          source={"data.regular_notes"}
          label={"Notes"}
        />

        <Actions label={"Actions"} />
      </Datagrid>
    </div>
  );
};

export default ReferencedList;
