export const RESOURCE: string = "yourtext_guru/content";

const MODEL = "YTGuru";

// DANGER: check the `ytg` part and see if this is currenct or not
export const PERMISSIONS = {
  create: `ytg.add_${MODEL}`,
  view: `ytg.view_${MODEL}`,
  edit: `ytg.change_${MODEL}`,
  delete: `ytg.delete_${MODEL}`,
};
