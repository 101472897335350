import * as React from "react";
import { Card, CardContent } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import EditIcon from "@material-ui/icons/Edit";
import { makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";

import { Loading, TextInput } from "react-admin";

import { PageDetails } from "../../page/show/details";
import { usePage } from "../../page/usePage";
import { RESOURCE } from "../../constants";
import { MarketReferenceInput } from "../../market";
import { ArrayTextInput, HorizontalGroup } from "../../reusable";
import { WebsiteReferenceInput } from "../../website";
import { useStyles as useCommonStyles } from "../../useStyles";

const useStyles = makeStyles((theme) => ({
  button: {
    width: "100%",
    justifyContent: "flex-end",
    background: "#f5f5f5",
  },
  dialog: {
    "& .MuiDialog-paper": {
      width: "80%",
    },
  },
}));

export const WebpageDetails = ({ record, editable }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const options = !record.webpage ? { enabled: false } : undefined;
  const { loaded, data } = usePage(record.webpage, options);
  const handleToggle = () => setOpen(!open);
  return (
    <span>
      {editable && !!record.webpage ? (
        <Button
          id="page-edit-btn"
          startIcon={open ? <ClearIcon /> : <EditIcon />}
          color="primary"
          className={classes.button}
          onClick={handleToggle}
        >
          {open ? "Cancel" : "Edit"}
        </Button>
      ) : null}
      {open ? (
        loaded && data ? (
          <PageEdit
            page={data}
            content={record}
            resource={RESOURCE.page}
            basePath={RESOURCE.page}
          />
        ) : (
          <Loading />
        )
      ) : (
        <PageDetails
          record={data}
          keywords={record.keywords}
          structuredContent={record.structure.structuredContent}
          market={record.market}
          fullUrl={record.full_url}
          loading={!!record.webpage ? !loaded : false}
        />
      )}
    </span>
  );
};

const PageEdit = ({ page, content, ...props }) => {
  const classes = useCommonStyles();
  return (
    <Card>
      <CardContent>
        <HorizontalGroup label={"Url"}>
          <WebsiteReferenceInput
            {...props}
            source={"webpage.website"}
            initialValue={page.website}
            className={classes.big}
          />
          <TextInput
            {...props}
            label="Path"
            source="webpage.path"
            initialValue={page.path}
            className={classes.bigger}
          />
        </HorizontalGroup>
        <HorizontalGroup label={"Details"}>
          <MarketReferenceInput
            {...props}
            source="market"
            initialValue={content.market || page.market}
            className={classes.big}
          />
          <ArrayTextInput
            required
            source="keywords"
            initialValue={content.keywords || page.keywords}
            label={"Keywords."}
            className={classes.bigger}
            multiline
          />
        </HorizontalGroup>
        <HorizontalGroup
          label={"Parent"}
          subtitle={"Ignore if you change the path."}
        >
          <TextInput
            {...props}
            label="Parent"
            source="webpage.parent_path"
            initialValue={page.parent_path}
            className={classes.big}
            helperText={"Ignored if you change the page path."}
          />

          <ArrayTextInput
            {...props}
            label="Parent keyword"
            source="webpage.parent_keywords"
            initialValue={page.parent_keywords}
            className={classes.bigger}
            helperText={"Ignored if you change the parent path."}
          />
        </HorizontalGroup>
      </CardContent>
    </Card>
  );
};
