import { Typography } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";

export const DisplayLiveUsers = ({ activeUsers }) => {
  return (
    <>
      <Typography variant="h6"> Live Users </Typography>
      <List component="ul">
        {activeUsers.map((user, index) => (
          <>
            <ListItem>
              <ListItemText key={index} primary={user} />
            </ListItem>
            <Divider />
          </>
        ))}
      </List>
    </>
  );
};
