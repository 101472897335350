import { ReferenceArrayInput, AutocompleteArrayInput } from "react-admin";
import { withStyles } from "@material-ui/core/styles";

import { RESOURCE } from "../../constants";
import { useState } from "react";

export const VerticalReferenceArrayInput = withStyles({
  select: {
    "& .MuiSelect-root": {
      minWidth: "6rem",
    },
  },
})(({ source, label, classes, ...props }) => {
  const [clicked, setClicked] = useState(false);

  return (
    <span onClick={() => setClicked(true)}>
      <ReferenceArrayInput
        source={source ? source : "verticals"}
        label={label ? label : "vertical"}
        reference={RESOURCE.vertical}
        sort={{ field: "name", order: "ASC" }}
        enableGetChoices={({ q }) => {
          return clicked || (q ? q.length > 0 : false);
        }}
        allowEmpty={false}
        {...props}
      >
        <AutocompleteArrayInput
          className={classes.select}
          optionText="name"
          optionValue="id"
          allowEmpty={false}
        />
      </ReferenceArrayInput>
    </span>
  );
});

VerticalReferenceArrayInput.defaultProps = {
  label: "Verticals",
  addLabel: true,
};
