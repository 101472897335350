import Drawer from "@material-ui/core/Drawer";
import Grid from "@material-ui/core/Grid";

import { useStyles } from "./useStyles";

import { calculateWrittenWords } from "./utils";
import Chip from "@material-ui/core/Chip";
import PriorityHighOutlinedIcon from "@material-ui/icons/PriorityHighOutlined";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import {
  SaveButton,
  usePermissions,
  useRecordContext,
  useResourceContext,
} from "react-admin";
import { GROUPS, STATUS } from "../../constants";

import { withStyles } from "@material-ui/styles";
import { Toolbar } from "react-admin";
import { useEffect, useState } from "react";
import {
  useCountWordsGetKeywordsData,
  useCountWordsReset,
  useTotalWords,
  useTotalWordsNoForm,
} from "../../reusable/store/countWords";
import { makeKeywords } from "../../reusable";
import { MarkAsPublishedButton } from "../buttons/MarkAsPublished";
import MarkAsDoubleChecked from "../buttons/MarkAsDoubleChecked";
import { checkCoupemonde } from "../utils/checkCoupemonde";
import { IconButton, Tooltip, Typography, makeStyles } from "@material-ui/core";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

const Item = ({ label, value, invalid }) => {
  const useStyles = makeStyles(() => ({
    error: {
      color: "red",
    },
  }));
  const classes = useStyles();
  return (
    <Grid item>
      {label}&nbsp;
      <Chip
        label={value}
        className={invalid ? classes.error : null}
        color={invalid ? "default" : "primary"}
        variant="outlined"
        icon={
          invalid === true ? (
            <PriorityHighOutlinedIcon />
          ) : invalid === false ? (
            <CheckOutlinedIcon />
          ) : null
        }
      />
    </Grid>
  );
};

const highlightedCounter = (noSpaces = false) => {
  return calculateWrittenWords(window.getSelection().toString(), noSpaces);
};

const CommonItems = ({
  mainContent,
  reviews,
  forces,
  weakness,
  requested,
  meta_data,
  mainKeywordDensity,
  ...props
}) => {
  const [highlighted, setHighlighted] = useState(0);
  const totalWords =
    mainContent +
    reviews +
    weakness +
    forces +
    (meta_data !== null ? meta_data : 0);
  /**
   * If the total words are more than 25% of the requested words, or less than 100 words of the requested words,
   * then the total words are invalid.
   */
  const invalid =
    totalWords > requested + requested * 0.25 || totalWords < requested - 100;
  // const fields = [
  //     <Item key='mainContent' label='Main Content' value={`${totalWords}/${requested}`} invalid={invalid} />,
  //     <Item key='highlight' label='Highlighted' value={highlighted} />,
  //     // <Item key='reviews' label='Reviews' value={reviews} />,
  //     // <Item key='weakness' label='Weakness' value={weakness} />,
  //     // <Item key='forces' label='Forces' value={forces} />,
  // ]
  // if (meta_data !== null) {fields.push(<Item key='meta' label='Meta Data' value={meta_data} />)}
  useEffect(() => {
    const handleHighlight = () =>
      setHighlighted(highlightedCounter(props.record?.language_has_no_spaces));
    window.addEventListener("mouseup", handleHighlight);
    // avoid a memory leak, and remove the event listener.
    return () => {
      window.removeEventListener("mouseup", () => handleHighlight);
    };
  });
  return (
    <Grid item xs style={{ fontSize: 11 }}>
      <Grid
        container
        spacing={2}
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Item
          label="Written words"
          value={`${totalWords}/${requested}`}
          invalid={invalid}
        />
        <Tooltip
          title={
            <>
              <Typography fontSize={20}>
                {`Words Written by Writers: ${totalWords}`}
              </Typography>
            </>
          }
        >
          <IconButton>
            <HelpOutlineIcon />
          </IconButton>
        </Tooltip>
        <Item label="Highlighted Words" value={highlighted} />
        <Item label="Main Keyword Density" value={`${mainKeywordDensity} %`} />
      </Grid>
    </Grid>
  );
};

const BottomBarElement = ({ stats, toolbar, style, ...props }) => {
  return (
    <Grid container alignItems="center" style={style}>
      <CommonItems {...stats} />
    </Grid>
  );
};

const BottomToolbarElement = withStyles({
  root: {
    flexWrap: "wrap",
    margin: 0,
    padding: "0 1vw",
  },
})(
  ({
    stats,
    templatePriceField,
    quoteField,
    saveButton,
    validateButton,
    submitButton,
    classes,
    approveButton,
    rejectButton,
    createYTGButton,
    revokeContent,
    va_timer,
    ...props
  }) => {
    const { permissions } = usePermissions();
    const record = useRecordContext();

    const coupemondeFlag = checkCoupemonde(record, permissions);

    const flag =
      record &&
      permissions?.groups?.includes &&
      (permissions.groups.includes(GROUPS.editors) ||
        permissions.groups.includes(GROUPS.proofreader) ||
        permissions.groups.includes(GROUPS.integrators));

    return (
      <Toolbar className={classes.root} {...props}>
        <CommonItems {...stats} />
        {va_timer ? va_timer : null}
        {templatePriceField ? templatePriceField : null}
        {quoteField ? quoteField : null}
        {saveButton !== undefined ? saveButton : <SaveButton />}
        {createYTGButton !== undefined ? createYTGButton : null}
        {validateButton !== undefined ? validateButton : null}
        {submitButton ? submitButton : null}
        {flag | coupemondeFlag &&
        ![
          STATUS.approved,
          STATUS.integration,
          STATUS.published,
          STATUS.double_checked,
        ].includes(record.status) &&
        approveButton
          ? approveButton
          : null}
        {flag &&
        ![
          STATUS.approved,
          STATUS.integration,
          STATUS.published,
          STATUS.double_checked,
        ].includes(record.status) &&
        rejectButton
          ? rejectButton
          : null}
        {flag ? (
          record.status === STATUS.approved && record.type !== "pbn" ? (
            <MarkAsPublishedButton content={record} />
          ) : record.status === STATUS.published && record.type !== "pbn" ? (
            <MarkAsDoubleChecked content={record} />
          ) : null
        ) : null}
        {revokeContent ? revokeContent : null}
      </Toolbar>
    );
  },
);

export const BottomBar = ({ noForm, ...props }) => {
  return noForm ? <BottomBarNoForm {...props} /> : <BottomBarForm {...props} />;
};

const BottomBarForm = ({ record, withToolbar, ...props }) => {
  const classes = useStyles();
  useCountWordsReset([record.id]);

  // Initializes the variables
  // NOTE: Formspy re-renders, so it is ok to not use UseState
  const stats = useTotalWords();
  const countwordsData = useCountWordsGetKeywordsData();
  // the index 0 is the main keyword
  const keyword = Object.values(countwordsData).find((value) => value.id === 0);
  const number_ = keyword?.total ? (keyword.count / keyword.total) * 100 : 0;
  stats.mainKeywordDensity = Number.parseFloat(number_).toPrecision(2);
  return (
    <Drawer className={classes.bottomBar} variant="permanent" anchor="bottom">
      {/*<BottomBarElement stats={stats} />*/}
      {withToolbar ? (
        <BottomToolbarElement stats={stats} record={record} {...props} />
      ) : (
        <BottomBarElement
          stats={stats}
          style={{ padding: "0.5rem 1rem", margin: 0, maxWidth: "99vw" }}
        />
      )}
    </Drawer>
  );
};

const BottomBarNoForm = ({ record, withToolbar, noForm = false, ...props }) => {
  const classes = useStyles();

  // Reset the counter everytime the record.id (and thereby the record) changes, this means
  // that when someone switch to another record the counter will reset.
  useCountWordsReset([record.id]);

  // Initializes the variables
  // NOTE: Formspy re-renders, so it is ok to not use UseState
  const stats = useTotalWordsNoForm(record);
  stats.mainKeywordDensity = makeKeywords(
    record.keywords,
    record.structure?.structuredContent,
    true,
  )[0];
  return (
    <Drawer className={classes.bottomBar} variant="permanent" anchor="bottom">
      {/*<BottomBarElement stats={stats}  />*/}
      {withToolbar ? (
        <BottomToolbarElement stats={stats} record={record} {...props} />
      ) : (
        <BottomBarElement
          stats={stats}
          style={{ padding: "0.5rem 1rem", margin: 0, maxWidth: "99vw" }}
        />
      )}
    </Drawer>
  );
};

export default BottomBar;
