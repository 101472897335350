import * as React from "react";

import Grid from "@material-ui/core/Grid";

import Typography from "@material-ui/core/Typography";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { CountedTextField } from "../../reusable";

export const Reviews = ({ disabled, ...props }) => {
  if (!props.record.structure.number_of_reviews) return null;
  const inputs = [
    ...Array(props.record.structure.number_of_reviews).keys(),
  ].map((idx) => (
    <Grid key={`reviews.${idx}`} item xs={12}>
      <CountedTextField
        words
        multiline
        suggestedLength={50}
        maximumLength={60}
        disabled={disabled}
        initialValue={""}
        fullWidth
        validate={[]}
        source={`reviews.${idx}`}
        label={"Review"}
      />{" "}
    </Grid>
  ));
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="reviews-content"
        id="reviews-header"
      >
        <Typography variant={"h5"}>{"Sentences & Reviews"}</Typography>,
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={1}>
          {inputs}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
