import { Error } from "react-admin";
import { ContentList } from "../ContentList";

/**
 * Render the list of content for the writer.
 * @param {*} writer
 * @param {string} status
 * @returns
 */
export const WriterContentList = ({ writer, status, ...props }) => {
  const filter = { writer: writer.id };
  if (status !== undefined) filter.status = status;
  if (writer === undefined) return <Error error={"No writer found!"} />;
  return <ContentList filter={filter} {...props} />;
};
