// Got from https://stackoverflow.com/a/65008608
import {useState, useEffect, MutableRefObject} from 'react';



/*
Flip on/off if it gets on the screen.
 */
export const useOnScreen = (ref: MutableRefObject<HTMLElement | null>, ) => {
  const [isIntersecting, setIntersecting] = useState(false)

  const observer = new IntersectionObserver(
    ([entry]) => setIntersecting(entry.isIntersecting)
  )

  useEffect(() => {
    if (!ref.current) return
    observer.observe(ref.current)
    // Remove the observer as soon as the component is unmounted
    return () => { observer.disconnect() }
  }, [observer, ref])

  return isIntersecting
}


/*
Like useOnScreen but flip only once.
 */
export const useOnScreenOnce = (ref: MutableRefObject<HTMLElement | null>, ) => {
  const onScreen = useOnScreen(ref);
  const [once, setOnce] = useState(false);

  useEffect(() => {
     if(!once && onScreen){
       setOnce(onScreen);
     }
  }, [onScreen, setOnce, once]);

  return once
}
