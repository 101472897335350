import React from "react";
import {
  Edit as RaEdit,
  EditProps,
  TextInput,
  SimpleForm,
  useRecordContext,
  usePermissions,
  useRefresh,
  Loading,
  DateInput,
  DateField,
} from "react-admin";
import { AdvancedTextInput, HorizontalGroup } from "../../reusable";
import { VAToolbar } from "./ToolBar";
import { dataProvider } from "../../reusable/store";
import { RESOURCE, VASSISTANT_TASK_STATUS } from "../../constants";
import { Box, Button, Paper, TextField, Typography } from "@material-ui/core";
import { VAssistantReferenceInput } from "../inputs/VAssistantReferenceInput";

const AdvanceTextEditor = () => (
  <AdvancedTextInput
    data-testid="instructions-textinput"
    source="data.instructions"
    label={"Task Description"}
    fullWidth
    multiline
    countWords={false}
  />
);

const DisplayTextInput = ({ isVAssistant, ...props }) => {
  const { record } = props;
  const [loading, setLoading] = React.useState(false);
  const refresh = useRefresh();
  if (!isVAssistant) {
    return <AdvanceTextEditor />;
  }

  const saveContent = async (e) => {
    e.preventDefault();
    setLoading(true);

    const data = {
      task_started: new Date().toISOString(),
      task_status: "in_progress",
    };

    await dataProvider.update(RESOURCE.vAssistantTask, {
      id: record.id,
      data,
    });

    setLoading(false);
    refresh();
  };

  return record && record.task_started ? (
    <AdvanceTextEditor />
  ) : (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Paper>
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Typography variant="h5" color="textPrimary">
              <strong>Your Text Editor has been hidden!</strong>
            </Typography>
            <Typography variant="h6" color="textPrimary">
              <p>
                Please Press <strong>View Editor</strong> for the Editors to
                appear. Keep in mind that the PMs will be notified and keep
                track of when you have started writing or reviewing the content.
              </p>
            </Typography>
            <Button
              id="writer-start-writing"
              onClick={saveContent}
              variant="contained"
              color="primary"
            >
              View Editor
            </Button>
          </Box>
        </Paper>
      )}
    </>
  );
};

export const Edit = (props: EditProps): React.ReactElement => {
  const { permissions, loading } = usePermissions();

  const isVassistant = permissions?.groups?.includes("vassistant");

  if (loading) return <Loading />;

  return (
    <RaEdit {...props} mutationMode="optimistic">
      <SimpleForm toolbar={<VAToolbar />}>
        <HorizontalGroup>
          <TextInput source="task_name" />
          <VAssistantReferenceInput
            label={"Virtual Assistant"}
            disabled={isVassistant}
            source="vassistant"
          />
          <TextInput disabled source="task_status" />
        </HorizontalGroup>
        <HorizontalGroup>
          <DateInput disabled={isVassistant} source="task_eta" />
        </HorizontalGroup>
        <DisplayTextInput isVAssistant={isVassistant} />
      </SimpleForm>
    </RaEdit>
  );
};

export default Edit;
