import { useState } from "react";
import { ReferenceInput, AutocompleteInput } from "react-admin";

import { RESOURCE } from "../../constants";

export const MarketReferenceInput = ({
  source = "market",
  label = "Market",
  helperText = "",
  emptyValue = "",
  prefetch,
  ...props
}) => {
  const [clicked, setClicked] = useState(false);

  return (
    <span onClick={() => setClicked(true)}>
      <ReferenceInput
        source={source}
        label={label}
        reference={RESOURCE.market}
        sort={{ field: "country", order: "ASC" }}
        enableGetChoices={({ q }) => {
          return clicked || (q ? q.length > 1 : false) || prefetch;
        }}
        {...props}
      >
        <AutocompleteInput
          optionText={(market) =>
            market ? `${market.country} (${market.language})` : "-"
          }
          optionValue="id"
          helperText={helperText}
          // emptyValue={emptyValue}
        />
      </ReferenceInput>
    </span>
  );
};

MarketReferenceInput.defaultProps = {
  label: "Market",
  addLabel: true,
};
