export const dayFromToday = (days) =>
new Date(
  new Date(
     new Date() - days * 24 * 3600 * 1000
  ).toISOString().slice(0,10)
).toISOString();

export const getDayFromNow = (days) => {
  const date = new Date();
  date.setDate(date.getDate() + days);
  const date_ = new Date(date.toISOString().slice(0,10));
  return date_;
};

export const todayISOString = () => new Date().toISOString().slice(0, 10);
