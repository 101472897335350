const fontBackgroundColorConfig = {
  colors: [
    {
      color: "hsl(0, 0%, 0%)",
      label: "Black",
    },
    {
      color: "hsl(0, 0%, 90%)",
      label: "Light grey",
    },
    {
      color: "hsl(0, 0%, 100%)",
      label: "White",
      hasBorder: true,
    },
    {
      color: "hsl(0, 75%, 60%)",
      label: "Red",
    },
    {
      color: "hsl(30, 75%, 60%)",
      label: "Orange",
    },
    {
      color: "hsl(60, 75%, 60%)",
      label: "Yellow",
    },
    {
      color: "hsl(90, 75%, 60%)",
      label: "Light green",
    },
    {
      color: "hsl(120, 75%, 60%)",
      label: "Green",
    },
    {
      color: "hsl(150, 75%, 60%)",
      label: "Aquamarine",
    },
    {
      color: "hsl(180, 75%, 60%)",
      label: "Turquoise",
    },
    {
      color: "hsl(210, 75%, 60%)",
      label: "Light blue",
    },
    {
      color: "hsl(240, 75%, 60%)",
      label: "Blue",
    },
    {
      color: "hsl(270, 75%, 60%)",
      label: "Purple",
    },
  ],
};

const fontColorConfig = {
  colors: [
    {
      color: "hsl(0, 0%, 0%)",
      label: "Black",
    },
    {
      color: "hsl(0, 0%, 30%)",
      label: "Dim grey",
    },
    {
      color: "hsl(0, 0%, 60%)",
      label: "Grey",
    },
    {
      color: "hsl(0, 0%, 90%)",
      label: "Light grey",
    },
    {
      color: "hsl(0, 0%, 100%)",
      label: "White",
      hasBorder: true,
    },
    {
      color: "hsl(0, 75%, 60%)",
      label: "Red",
    },
    {
      color: "hsl(30, 75%, 60%)",
      label: "Orange",
    },
    {
      color: "hsl(60, 75%, 60%)",
      label: "Yellow",
    },
    {
      color: "hsl(90, 75%, 60%)",
      label: "Light green",
    },
    {
      color: "hsl(120, 75%, 60%)",
      label: "Green",
    },
    {
      color: "hsl(150, 75%, 60%)",
      label: "Aquamarine",
    },
    {
      color: "hsl(180, 75%, 60%)",
      label: "Turquoise",
    },
    {
      color: "hsl(210, 75%, 60%)",
      label: "Light blue",
    },
    {
      color: "hsl(240, 75%, 60%)",
      label: "Blue",
    },
    {
      color: "hsl(270, 75%, 60%)",
      label: "Purple",
    },
    {
      color: "hsl(101, 89%, 36%)",
      label: "Dark Green",
    },
  ],
};

export const configuration = {
  heading: {
    options: [
      { model: "paragraph", title: "Paragraph", class: "ck-heading_paragraph" },
      {
        model: "heading1",
        view: "h1",
        title: "Heading 1",
        class: "ck-heading_heading1",
      },
      {
        model: "heading2",
        view: "h2",
        title: "Heading 2",
        class: "ck-heading_heading2",
      },
      {
        model: "heading3",
        view: "h3",
        title: "Heading 3",
        class: "ck-heading_heading3",
      },
      {
        model: "heading4",
        view: "h4",
        title: "Heading 4",
        class: "ck-heading_heading4",
      },
    ],
  },
  table: {
    contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
  },
  fontColor: fontColorConfig,
  fontBackgroundColor: fontBackgroundColorConfig,
};

export default configuration;
