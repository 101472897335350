import { ReferenceField, TextField } from "react-admin";

import { RESOURCE } from "../../constants";
import React from "react";
// eslint-disable-next-line no-unused-vars
import get from "lodash/get";
import { EditableReferenceField } from "../../reusable/fields/EditableReferenceField";

export const WebsiteReferenceField = (props) => (
  <ReferenceField
    label="website"
    source="website"
    {...props}
    reference={RESOURCE.website}
  >
    <TextField source={"domain"} />
  </ReferenceField>
);

export const EditableWebsiteReferenceField = ({
  source = "website",
  label = "Website",
  sort = { field: "domain", order: "ASC" },
  showSource = "domain",
  helperText = "",
  optionText = "domain",
  ...props
}) => {
  return (
    <EditableReferenceField
      source={source}
      label={label}
      reference={RESOURCE.website}
      sort={sort}
      link={(props) => {
        // redirects to the full edit view with stats.
        const id = get(props, source);
        return `/${RESOURCE.websiteWithStats}/${id}/`;
      }}
      inputProps={{
        helperText: helperText,
        optionText: optionText,
      }}
      fieldProps={{
        source: showSource,
      }}
      {...props}
    />
  );
};
WebsiteReferenceField.defaultProps = {
  label: "Website",
  addLabel: true,
};
