import { Create as RACreate } from "react-admin";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { withStyles } from "@material-ui/core/styles";

import { Form } from "./Form";

const styles = {
  root: {
    "& .flex": {
      display: "flex",
    },
    "& .MuiFilledInput-inputMarginDense": {
      minHeight: "2.3rem",
    },
    "& .ra-input-markets": {
      flexGrow: 1,
      "&>div": {
        width: "100%",
      },
    },
    "& .create-page": {
      width: "100%",
    },
    "& .simple-form>div": {
      display: "flex",
    },
  },
};

export const Create = ({ id, ...props }) => {
  return (
    <RACreate {...props} component="div" redirect="list">
      <Form />
    </RACreate>
  );
};

export const AccordionCreate = withStyles(styles)(({ classes, ...props }) => (
  <Accordion>
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <h1>Add a new domain</h1>
    </AccordionSummary>
    <AccordionDetails>
      <Create {...props} />
    </AccordionDetails>
  </Accordion>
));
