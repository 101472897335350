import React from "react";
import { Edit as RaEdit, SimpleForm, TextInput, EditProps } from "react-admin";

export const Edit = (props: EditProps): React.ReactElement => {
  return (
    <RaEdit {...props}>
      <SimpleForm>
        <TextInput source={"name"} label={"Network Name"} />
        <TextInput source={"link"} label={"Link"} />
      </SimpleForm>
    </RaEdit>
  );
};

export default Edit;
