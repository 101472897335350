import React from "react";
import { Card as MuiCard, CardContent, withStyles } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { FilterList, FilterListItem } from "react-admin";

import { STATUS, CONTENT_TYPE } from "../../constants";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import BlockIcon from "@material-ui/icons/Block";

const Card = withStyles((theme) => ({
  root: {
    [theme.breakpoints.up("sm")]: {
      order: -1, // display on the left rather than on the right of the list
      width: "15em",
      marginRight: "1em",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}))(MuiCard);

export const ContentListAside = () => {
  const [open, setOpen] = React.useState(true);

  return !open ? (
    <Card style={{ width: "2rem" }} onClick={() => setOpen(true)}>
      <p style={{ textAlign: "right" }}>
        <KeyboardArrowRightIcon />
      </p>
    </Card>
  ) : (
    <Card>
      <p style={{ textAlign: "right" }} onClick={() => setOpen(false)}>
        <KeyboardArrowLeftIcon />
      </p>
      <CardContent>
        <FilterList label="Content Status" icon={<SearchIcon />}>
          <FilterListItem
            label={"Requested"}
            value={{ status: STATUS.requested }}
          />
          <FilterListItem
            label={"In Review"}
            value={{ status: STATUS.review }}
          />
          <FilterListItem
            label={"VA Reviewed"}
            value={{ status: STATUS.va_reviewed }}
          />
          <FilterListItem
            label={"Approved / To integrate"}
            value={{ status: STATUS.approved }}
          />
          <FilterListItem
            label={"Integrator Assigned"}
            value={{ status: STATUS.integration }}
          />
          <FilterListItem
            label={"Published"}
            value={{ status: STATUS.published }}
          />
          <FilterListItem
            label={"Double Checked"}
            value={{ status: STATUS.double_checked }}
          />
        </FilterList>
        <FilterList label="Content Type" icon={<SearchIcon />}>
          <FilterListItem
            label={"review"}
            value={{ type: CONTENT_TYPE.review }}
          />
          <FilterListItem
            label={"optimization"}
            value={{ type: CONTENT_TYPE.optimization }}
          />
          <FilterListItem
            label={"linkbuilding"}
            value={{ type: CONTENT_TYPE.linkbuilding }}
          />
          <FilterListItem
            label={"Video"}
            value={{ type: CONTENT_TYPE.video }}
          />
          <FilterListItem label={"pbn"} value={{ type: CONTENT_TYPE.pbn }} />
          <FilterListItem
            label={"Other"}
            value={{ type: CONTENT_TYPE.other }}
          />
        </FilterList>
      </CardContent>
    </Card>
  );
};
