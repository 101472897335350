import {
  List as RAList,
  Datagrid,
  TextField,
  EmailField,
  NumberField,
  ShowButton,
  SearchInput,
} from "react-admin";

import { Pay } from "./Pay";

const filtersList = [<SearchInput source={"q"} alwaysOn />];

export const List = (props) => {
  return (
    <RAList
      {...props}
      filters={filtersList}
      sort={{ field: "pending_amount", order: "DESC" }}
    >
      <Datagrid rowClick="expand" expand={<Pay />}>
        <TextField source="id" />
        <EmailField source="username" label={"Email"} />
        <NumberField source="pending_amount" label={"Pending"} />
        <TextField source="currency" />
        <ShowButton />
      </Datagrid>
    </RAList>
  );
};
