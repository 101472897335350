import {
  List as RAList,
  Datagrid,
  DateField,
  TextField,
  Button,
  useRefresh,
} from "react-admin";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { ENDPOINTS } from "./endpoints";
import { httpClient } from "../../utils";
import { makeStyles } from "@material-ui/core/styles";
import { Tooltip, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  notificationMessage: { whiteSpace: "pre-wrap" },
  notificationMessageLink: {
    color: "inherit",
    "&:hover": {
      textDecoration: "none",
    },
  },
  tooltip: {
    whiteSpace: "pre-wrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxHeight: "32rem",
  },
}));

export const List = (props) => {
  return (
    <RAList
      {...props}
      actions={false}
      bulkActionButtons={false}
      isRowSelectable={() => false}
    >
      <Datagrid
        sort={{ field: "date_created", order: "DESC" }}
        rowStyle={(record) =>
          props.countState[0]?.hide.includes(record.id)
            ? { display: "none" }
            : null
        }
      >
        <MessageField />
        <DateField source={"date_created"} label={"Date"} />
        <MarkAsSeenButton countState={props.countState} />
      </Datagrid>
    </RAList>
  );
};

const MessageField = (props) => {
  const classes = useStyles();
  const { record } = props;
  const { data } = record;

  const renderNotificationMessage = (message) => {
    if (message) {
      if (message.length > 280) {
        return (
          <>
            <p>{message.slice(0, 280)}...</p>
            <hr />
            <strong>Click on the Content to load full review</strong>
          </>
        );
      } else {
        return <>{message}</>;
      }
    }
  };

  const element = (
    <Tooltip
      title={
        <Typography className={classes.tooltip} fontSize={24}>
          {renderNotificationMessage(record.message)}
        </Typography>
      }
      placement="left"
    >
      <span>
        <TextField
          {...props}
          source={"data.message"}
          label={"Message"}
          className={classes.notificationMessage}
        />
      </span>
    </Tooltip>
  );

  if (data.link) {
    return (
      // <Tooltip title="Add" placement="left">
      <a
        href={data.link}
        target={"_blank"}
        rel={"noreferrer nofollow"}
        className={classes.notificationMessageLink}
      >
        {element}
      </a>
      // </Tooltip>
    );
  }
  return element;
};

const MarkAsSeenButton = ({ record, countState, ...props }) => {
  const [, countStateDispatch] = countState;
  const refresh = useRefresh();
  const onClick = () => {
    const asyncCall = async () => {
      const request = httpClient(ENDPOINTS.markAsSeen(record.id), {
        method: "POST",
      });
      countStateDispatch({
        action: "reduce",
        hide: record.id,
        callback: () => refresh(),
      });
      await request;
    };
    asyncCall().catch((error) => console.error(error));
  };
  return (
    <Button label={"Seen"} endIcon={<VisibilityIcon />} onClick={onClick} />
  );
};
