export const RESOURCE: string = "sales/account";
export { ADMIN } from "../constants";

const MODEL = "account";

export const PERMISSIONS = {
  create: `sales.add_${MODEL}`,
  view: `sales.view_${MODEL}`,
  edit: `sales.change_${MODEL}`,
  delete: `sales.delete_${MODEL}`,
};
