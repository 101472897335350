import { ReferenceArrayInput, AutocompleteArrayInput } from "react-admin";
import { withStyles } from "@material-ui/core/styles";

import { RESOURCE } from "../../constants";
import { useState } from "react";

export const WebsiteReferenceArrayInput = withStyles({
  select: {
    "& .MuiSelect-root": {
      minWidth: "6rem",
    },
  },
})(({ source, label, classes, ...props }) => {
  const [clicked, setClicked] = useState(false);

  return (
    <span onClick={() => setClicked(true)}>
      <ReferenceArrayInput
        source={source ? source : "websites"}
        label={label ? label : "Websites"}
        reference={RESOURCE.website}
        sort={{ field: "domain", order: "ASC" }}
        enableGetChoices={({ q }) => {
          return clicked || (q ? q.length > 1 : false);
        }}
        allowEmpty={false}
        {...props}
      >
        <AutocompleteArrayInput
          id={source ? source : "websites"}
          allowEmpty={false}
          optionText={(record) => {
            if (!record) {
              return undefined;
            }
            return record.deleted
              ? `${record.domain} (Deleted)`
              : `${record.domain}`;
          }}
          // className={ classes.select }
          optionValue="id"
        />
      </ReferenceArrayInput>
    </span>
  );
});

WebsiteReferenceArrayInput.defaultProps = {
  label: "Websites",
  addLabel: true,
};

export default WebsiteReferenceArrayInput;
