import * as React from "react";

import { Card, CardHeader, CardContent } from "@material-ui/core";
import { usePermissions, Loading } from "react-admin";
import { WriterDashboard } from "./WriterDashboard";
import { GROUPS } from "../constants";
import { EditorDashboard } from "./EditorDashboard/EditorDashboard";
import { IntegratorDashboard } from "./IntegratorDashboard";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import { makeStyles } from "@material-ui/core/styles";

import { TabPanel } from "./TabPanel";
import { ProofReaderDashboard } from "./ProofReaderDashboard";
import { VAssistantDashboard } from "./VAssistantDashboard";
// import { LinkbuildingDashboard } from "../LinkBuilding";

const a11yProps = (index) => {
  return {
    id: `dashboard-tab-${index}`,
    "aria-controls": `dashboard-tabpanel-${index}`,
  };
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

export const Dashboard = () => {
  const classes = useStyles();
  let index = 0;
  const [value, setValue] = React.useState(index);

  const { permissions, loading } = usePermissions();
  if (loading) return <Loading />;
  const isWriter = permissions.groups.includes(GROUPS.writers) ? index++ : null;
  const isProofReader = permissions.groups.includes(GROUPS.proofreader)
    ? index++
    : null;
  const isVAssistant = permissions.groups.includes(GROUPS.vAssistant)
    ? index++
    : null;
  const isEditor = permissions.groups.includes(GROUPS.editors) ? ++index : null;
  const isIntegrator = permissions.groups.includes(GROUPS.integrators)
    ? ++index
    : null;
  const [found, foundMany] = [index !== 0, index >= 1];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // if not element was found let's use a plain element with username.
  if (!found) {
    return (
      <Card>
        <CardHeader title="Welcome to the GreenTomatoMedia Eden" />
        <CardContent>{`Hello ${permissions.username}.`}</CardContent>
      </Card>
    );
  }

  return (
    <div className={classes.root}>
      {foundMany ? (
        <>
          <AppBar position="static" color="primary">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              <Tab label="Home" {...a11yProps(0)} />
              {isWriter !== null ? (
                <Tab label="Articles" {...a11yProps(isWriter)} />
              ) : null}
              {isProofReader !== null ? (
                <Tab label="Articles" {...a11yProps(isProofReader)} />
              ) : null}
              {isVAssistant !== null ? (
                <Tab label="Articles" {...a11yProps(isVAssistant)} />
              ) : null}
              {isEditor !== null ? (
                <Tab label="Dashboard" {...a11yProps(isEditor)} />
              ) : null}
              {isIntegrator !== null ? (
                <Tab label="Integration" {...a11yProps(isIntegrator)} />
              ) : null}
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <CardHeader title="Welcome to the GreenTomatoMedia Eden" />
            <CardContent>{`Hello ${permissions.username}.`}</CardContent>
          </TabPanel>
        </>
      ) : null}
      {isWriter !== null ? (
        <TabPanel value={value} index={isWriter}>
          <WriterDashboard
            key={"writer dashboard"}
            username={permissions.username}
            user_id={permissions.user_id}
          />
        </TabPanel>
      ) : null}
      {isProofReader !== null ? (
        <TabPanel value={value} index={isProofReader}>
          <ProofReaderDashboard
            key={"Proof Reader Dashboard"}
            username={permissions.username}
            user_id={permissions.user_id}
          />
        </TabPanel>
      ) : null}
      {isVAssistant !== null ? (
        <TabPanel value={value} index={isVAssistant}>
          <VAssistantDashboard
            key={"Proof Reader Dashboard"}
            username={permissions.username}
            user_id={permissions.user_id}
          />
        </TabPanel>
      ) : null}
      {isEditor !== null ? (
        <TabPanel value={value} index={isEditor}>
          <EditorDashboard
            key={"editor dashboard"}
            userId={permissions.user_id}
          />
        </TabPanel>
      ) : null}
      {isIntegrator !== null ? (
        <TabPanel value={value} index={isIntegrator}>
          <IntegratorDashboard
            key={"integrator dashboard"}
            permissions={permissions}
          />
        </TabPanel>
      ) : null}
    </div>
  );
};
