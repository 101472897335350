import { useState } from "react";
import { ReferenceInput, AutocompleteInput } from "react-admin";

import { RESOURCE } from "../../constants";

export const WriterReferenceInput = ({
  source,
  label,
  helperText,
  required,
    prefetch,
  ...props
}) => {
  const [clicked, setClicked] = useState(false);
  return (

    <span 
      onClick={() => setClicked(true)}
    >
    <ReferenceInput
      required
      source={source ? source : "writer"}
      label={label ? label : "Writer"}
      reference={RESOURCE.writer}
      filterToQuery={(searchText) => ({ full_name: searchText })}
      enableGetChoices={({full_name})=>{
        return clicked || !! full_name || prefetch
      }}
      {...props}
    >
      <AutocompleteInput
        required
        optionText="full_name"
        helperText={helperText}
        optionValue="id"
      />
    </ReferenceInput>
    </span>
  )
};

WriterReferenceInput.defaultProps = {
  label: "Writer",
  addLabel: true,
};
