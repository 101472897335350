import { useDispatch } from "react-redux";

import Button from "@material-ui/core/Button";

import { useRedirect, useNotify, fetchStart, fetchEnd } from "react-admin";

import { ACTIONS } from "../../../constants";
import { httpClient } from "../../../utils";
import CheckIcon from "@material-ui/icons/Check";
import * as React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";

export const MarkAsCompleteButton = ({ record }: { record: any }) => {
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [hours, setHours] = React.useState("0");
  const redirect = useRedirect();
  const dispatch = useDispatch();
  const notify = useNotify();

  const [handleClose, handleOpen] = [
    () => {
      setOpen(false);
    },
    () => {
      setOpen(true);
    },
  ];

  const markAsComplete = () => {
    dispatch(fetchStart()); // start the global loading indicator
    setLoading(true);
    httpClient(ACTIONS.vAssistantTask.complete(record.id), {
      method: "POST",
      body: JSON.stringify({
        hours_worked: hours,
      }),
    })
      .then(
        () => {
          redirect("/");
          notify(`Task ${record.id} is set as Complete. Thanks!`);
        },
        (reason) => {
          if (reason?.status === 412) {
            notify(
              "Wrong status for the Task, " +
                `expected ${reason.body?.expected} found ${reason.body?.found}`,
              "error",
            );
          } else {
            notify("Unknown Error", "error");
          }
        },
      )
      .finally(() => {
        dispatch(fetchEnd()); // stop the global loading indicator
        setLoading(false);
      });
  };
  return (
    <span>
      <Button
        startIcon={<CheckIcon />}
        onClick={handleOpen}
        disabled={loading}
        color="secondary"
        variant="contained"
      >
        {loading ? "Setting task to Complete ..." : "Mark as Completed"}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Mark Task As Completed</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Write the Number of Hours you worked on this task.
          </DialogContentText>
          <TextField
            required
            type="number"
            fullWidth
            value={hours}
            id="hours"
            label="Number of Hours"
            helperText={"Number of Hours worked on this task"}
            onChange={(e) => setHours(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            startIcon={<CheckIcon />}
            onClick={markAsComplete}
            color="secondary"
            variant="contained"
          >
            {"Accept"}
          </Button>
        </DialogActions>
      </Dialog>
    </span>
  );
};
