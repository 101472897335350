import React, { Suspense, lazy } from "react";
import { Loading } from "react-admin";

const ListEl = lazy(() => import("./List"));

export const List = (props) => (
  <Suspense fallback={<Loading />}>
    <ListEl {...props} />
  </Suspense>
);
