import * as React from "react";

import { EditProps, Edit as RAEdit } from "react-admin";

import LinksReferenceList from "./List/LinksReferenceList";
import Form from "./Form";

export const LandingPageEdit = (props: EditProps) => {
  const [selectedData, setSelectedData] = React.useState([]);

  return (
    <>
      <RAEdit {...props} mutationMode="pessimistic">
        <Form {...props} edit={true} selectedData={selectedData} />
      </RAEdit>
      <LinksReferenceList setSelectedData={setSelectedData} />
    </>
  );
};
