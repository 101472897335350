import { useRef, createElement } from 'react';
import { Loading } from 'react-admin'
import { Card as MUICard, CardHeader, CardContent } from "@material-ui/core";
import {useOnScreenOnce} from "../reusable/other/useOnScreen";

export const makeCard = (title, subtitle, child) => (
  <Card key={title} title={title} subheader={subtitle} child={child} />

);

/*
Delay the API calls, wait for the content to be visible.

This should reduce the perceived startup time as without it will
make a few pretty complex aggregations.
 */
const Card = ({title, subtitle, child}) => {
    const ref = useRef(null);

    const hasBeenVisible = useOnScreenOnce(ref);

    return (
        <MUICard style={{ marginTop: "1rem" }} ref={ref}>
            <CardHeader title={title} subheader={subtitle} />
            <CardContent style={{minHeight:'400px'}}>{ hasBeenVisible ? child :  <div /> }</CardContent>
        </MUICard>
    )
};

