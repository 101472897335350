import React from "react";
import makeDixitProduct from "./dixitProduct/make";
import makeLandingPage from "./landingPage";
import makeGlobalLandingPage from "./globalLandingPage";
export const makeProduct = (permissions: any): React.ReactElement[] => {
  return [
    makeDixitProduct(permissions),
    makeLandingPage(permissions),
    makeGlobalLandingPage(permissions),
  ];
};
