import React from "react";
import { Resource as RAResource } from "react-admin";
import LinkIcon from "@material-ui/icons/Link";
import { RESOURCE, PERMISSIONS, FINANCEPERMISSIONS } from "./constants";
import List from "./List/List";

/**
 * For some reason the APP want the resource to be instantiated with this make factory.
 * @param {*} permissions
 * @param {*} key
 * @returns
 */
export const make = (permissions: any): React.ReactElement => {
  const permission = permissions?.permission || [];
  return (
    <RAResource
      name={RESOURCE}
      list={
        permission.includes(PERMISSIONS.view) ||
        permission.includes(FINANCEPERMISSIONS.view)
          ? List
          : undefined
      }
      options={{ label: "Links" }}
      key={RESOURCE}
      icon={LinkIcon}
    />
  );
};

export default make;
