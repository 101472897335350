export const API_URL = "/api";
export const RESOURCE = "content/refusals";

const MODEL = "ContentRefusals";

// DANGER: check the `ytg` part and see if this is currenct or not
export const PERMISSIONS = {
  create: `content_refusals.add_${MODEL}`,
  view: `content_refusals.view_${MODEL}`,
  edit: `content_refusals.change_${MODEL}`,
  delete: `content_refusals.delete_${MODEL}`,
};
