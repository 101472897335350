import React from "react";
import {
  Edit as RaEdit,
  SimpleForm,
  TextInput,
  EditProps,
  useNotify,
  useRecordContext,
  ReferenceManyField,
  Datagrid,
  useCreate,
  useListContext,
} from "react-admin";
import AddIcon from "@material-ui/icons/Add";
import { EditableTextField, HorizontalGroup } from "../../reusable";
import { UserReferenceInput } from "../../user";
import { RESOURCE } from "../constants";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import Actions from "./Actions";
import EditableNumberField from "../../reusable/fields/EditableNumberField";
import { YoutubeReferenceInput } from "../inputs";

const useStyles = makeStyles((theme) => {
  return {
    fullWidth: {
      width: "100%",
    },
    field: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      wordBreak: "break-word",
    },
    textBreak: {
      wordBreak: "break-word",
    },
  };
});

const AdditionalCosts = () => {
  const record = useRecordContext();
  return (
    <ReferenceManyField
      fullWidth
      label=""
      reference={RESOURCE.fee}
      target="youtube_order"
      sort={{ field: "date_created", order: "DESC" }}
    >
      <DG youtube={record.id} />
    </ReferenceManyField>
  );
};

const AddNew = ({ youtube }) => {
  const [create, status] = useCreate();
  const notify = useNotify();
  const { refetch } = useListContext();
  const handleCreate = React.useCallback(() => {
    create(RESOURCE.fee, { cost: 0, youtube_order: youtube });
  }, [youtube, create]);

  React.useEffect(() => {
    if (status.loaded) {
      if (status.error) {
        notify(status.error, "error");
      } else {
        refetch();
      }
    }
  }, [status, refetch, notify]);
  return (
    <div>
      <Button
        color="primary"
        variant="contained"
        onClick={handleCreate}
        startIcon={<AddIcon />}
      >
        Add new
      </Button>
    </div>
  );
};

const DG = ({ youtube, ...props }) => {
  const classes = useStyles();
  return (
    <div className={classes.fullWidth}>
      <AddNew youtube={youtube} />
      <Datagrid {...props}>
        <EditableTextField
          source="fee_type"
          choices={[
            { id: "content", name: "content" },
            { id: "voice_over", name: "voice_over" },
            { id: "editing", name: "editing" },
            { id: "design", name: "design" },
            { id: "other", name: "other" },
          ]}
        />
        <EditableNumberField source="cost" />
        <EditableTextField source="currency" />
        <Actions />
      </Datagrid>
    </div>
  );
};

export const Edit = (props: EditProps): React.ReactElement => {
  return (
    <RaEdit mutationMode="undoable" redirect="list" {...props}>
      <SimpleForm>
        <HorizontalGroup>
          <TextInput source="title" label="Title" />
          <YoutubeReferenceInput
            source="youtube_channel"
            label="Youtube Channel"
          />
          <UserReferenceInput source="created_by" label="Created By" />
        </HorizontalGroup>
        <HorizontalGroup label="Additional Costs">
          <AdditionalCosts />
        </HorizontalGroup>
      </SimpleForm>
    </RaEdit>
  );
};

export default Edit;
