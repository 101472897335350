import * as React from "react";

import {
  BooleanInput,
  NumberInput,
  TextInput,
  required,
  maxLength,
  SelectInput,
  FormDataConsumer,
  ArrayInput,
  SimpleFormIterator,
  useRecordContext,
} from "react-admin";

import { WebsiteReferenceInput } from "../../website";
import { MarketReferenceInput } from "../../market";
import {
  HorizontalGroup,
  ArrayTextInput,
  CountedTextField,
  AdvancedTextInput,
} from "../../reusable";
import { WriterReferenceInput } from "../../writer";
import { useStyles } from "../../useStyles";
import { CONTENT_TYPE_CHOICES } from "../../constants";
import { CONTENT_WORDS } from "../constants";
import { YoutubeReferenceInput } from "../../youtube/inputs";

const req = [required()];

export const Form = ({ props }) => {
  const classes = useStyles();
  const record = useRecordContext();
  const [product, setProduct] = React.useState(record?.product);

  return (
    <>
      <HorizontalGroup label="Create Content Template">
        <TextInput
          validate={req}
          source="template_name"
          className={classes.bigAndBigger}
        />
      </HorizontalGroup>
      <HorizontalGroup label="Order For">
        <WriterReferenceInput
          source={"writer"}
          className={classes.bigAndBigger}
          label="Writer"
          helperText="The writer"
          resettable
        />
      </HorizontalGroup>

      <HorizontalGroup>
        <SelectInput
          source="type"
          choices={CONTENT_TYPE_CHOICES}
          className={classes.big}
        />
      </HorizontalGroup>
      <HorizontalGroup label="Select Product">
        <SelectInput
          source={"product"}
          variant="filled"
          className={classes.select}
          onChange={(e) => setProduct(e.target.value)}
          choices={[
            { id: "website", name: "Website" },
            { id: "youtube", name: "Youtube" },
          ]}
        />
        {product === "youtube" ? (
          <HorizontalGroup label="Select Youtube">
            <YoutubeReferenceInput
              className={classes.big}
              source={"youtube"}
              label="Youtube"
              prefetch
            />
          </HorizontalGroup>
        ) : (
          <>
            <HorizontalGroup label="Webpage details">
              <WebsiteReferenceInput
                source={"website"}
                className={classes.big}
                label="Website"
                helperText="Website"
                validate={req}
                prefetch
              />
              <ArrayTextInput
                source={"paths"}
                className={classes.bigger}
                label={"Paths and keyword"}
                helperText="Line separated path keyword (e.g. /example my keyword)."
                validate={req}
              />
            </HorizontalGroup>
          </>
        )}
      </HorizontalGroup>
      <MarketReferenceInput
        source={"market"}
        className={classes.bigAndBigger}
        helperText="Market for the page"
        resettable
      />
      <ReaderIntent
        multiline
        className={classes.bigAndBigger}
        label={"Reader Intent"}
        source="structure.reader_intent"
        helperText="What is the reader looking for?"
        // This will send the field value as empty string `""` instead of `null`
        // when the field is submitted empty.
        // More info at: https://stackoverflow.com/a/63128037/11128874
        parse={(value) => value}
        // initialValue={formData.template?.structure?.reader_intent}
      />

      <HorizontalGroup>
        <ArrayTextInput
          source={"examples"}
          label={"Example urls"}
          className={classes.bigAndBigger}
          helperText={"Reference urls, use a new line for each one"}
        />
      </HorizontalGroup>
      <ArrayTextInput
        source={"keywords"}
        label={"Keywords"}
        className={classes.bigAndBigger}
        helperText={
          "keywords, [keyword] will be replaced with the one specified in the path, use a new line for each one"
        }
        validate={req}
      />

      {/* Parent page details */}
      <HorizontalGroup label="Parent Page details" {...props}>
        <TextInput
          source={"parent.keyword"}
          label="Parent Keyword"
          className={classes.big}
          helperText="Keyword of the parent, usually it autodetects"
          parse={(value) => value}
        />
        <TextInput
          source={"parent.path"}
          label="Parent page"
          className={classes.bigger}
          helperText="Not needed if the path is in the form /parent/child"
          parse={(value) => value}
        />
      </HorizontalGroup>

      <HorizontalGroup label="Content Meta">
        <CountedTextField
          source={"meta_title"}
          label={"Meta Title"}
          className={classes.bigAndBigger}
          helperText="Content Meta Title"
          suggestedLength={60}
          maximumLength={150}
          suggestedWithin={10}
          parse={(value) => value}
        />
      </HorizontalGroup>
      <CountedTextField
        suggestedLength={180}
        maximumLength={400}
        suggestedWithin={20}
        className={classes.bigAndBigger}
        multiline
        fullWidth
        label={"Meta Description"}
        source="meta_description"
        parse={(value) => value}
        // initialValue={formData.template?.meta_description}
      />
      <BooleanInput
        source="editable_metas"
        label={"Editable Meta"}
        className={classes.bigAndBigger}
        helperText={"Should the writer edit the meta fields?"}
        // initialValue={formData.template?.editable_metas}
      />
      <HorizontalGroup label="The Content">
        <FormDataConsumer>
          {({ formData }) => {
            // This is working such as an effect, and will be used to set the requested words
            if (formData.content) {
              const structuredContent =
                formData.content.structure?.structuredContent?.reduce(
                  (acc, entry) => acc + (entry?.words || 0),
                  0,
                ) || 0;
              const structure = formData.content.structure;
              const otherInputs = structure
                ? (structure.pros || 0) * CONTENT_WORDS.pros +
                  (structure.cons || 0) * CONTENT_WORDS.pros +
                  (structure.number_of_reviews || 0) * CONTENT_WORDS.pros
                : 0;
              const metas = formData.content.editable_metas
                ? CONTENT_WORDS.meta
                : 0;
              formData.content.requested_words =
                structuredContent + otherInputs + metas;
            }
            return null;
          }}
        </FormDataConsumer>
        <NumberInput
          onWheel={(e) => e.target.blur()}
          fullWidth
          source="structure.pros"
          label={"Number of Pros"}
          // initialValue={formData.template?.structure.pros || 0}
          min={0}
          max={5}
        />
        <NumberInput
          onWheel={(e) => e.target.blur()}
          fullWidth
          source="structure.cons"
          label={"Number of cons"}
          min={0}
          max={5}
        />
        <NumberInput
          onWheel={(e) => e.target.blur()}
          fullWidth
          source="structure.number_of_reviews"
          label={"Number of reviews"}
          min={0}
          max={5}
        />
      </HorizontalGroup>
      <TextInput
        type="url"
        source={"google_docs"}
        target="_blank"
        label="Template in GDOCS"
        validate={maxLength(150)}
        fullWidth
        parse={(value) => value}
      />

      <FormDataConsumer>
        {({ formData }) => (
          <AdvancedTextInput
            source="structure.instructions"
            label={"Instructions"}
            fullWidth
            multiline
            countWords={false}
            // initialValue={
            //     formData.template?.structure.templateData?.instructions ?
            //     formData.template?.structure.templateData?.instructions :
            //     formData.template?.structure.instructions
            // }
          />
        )}
      </FormDataConsumer>

      <ArrayInput
        source={"structure.structuredContent"}
        validate={required()}
        label={"Content Fields"}
        className={classes.contentArrayInput}
      >
        <SimpleFormIterator>
          <HorizontalGroup>
            <TextInput
              source={"title"}
              label={"Title"}
              className={classes.bigger}
              validate={req}
            />
            <NumberInput
              onWheel={(e) => e.target.blur()}
              source={"words"}
              label={"number of words"}
              className={classes.small}
              step={400}
              min={0}
              validate={req}
              resettable
            />
            <NumberInput
              onWheel={(e) => e.target.blur()}
              source={"position"}
              label={"Section Order"}
              className={classes.small}
              step={10}
              min={0}
              resettable
              helperText={"Order the content sections"}
              validate={req}
            />
          </HorizontalGroup>
          <AdvancedTextInput source="content" label={" "} fullWidth />
        </SimpleFormIterator>
      </ArrayInput>
    </>
  );
};

/**
 * I implemented this only to have the label on top
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ReaderIntent = (props) => <TextInput {...props} />;
ReaderIntent.defaultProps = { addLabel: true, label: "Reader Intent" };
