import { HorizontalGroup } from "../reusable";
import { OutreachSiteReferenceInput } from "../outreachLinkbuildingSite";
import {
  BooleanInput,
  FunctionField,
  NumberInput,
  RadioButtonGroupInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import { outreachOrderStatus } from "./constants";
import Button from "@material-ui/core/Button";
import ForwardIcon from "@material-ui/icons/Forward";
import { WebsiteReferenceInput } from "../website";
import { useStyles } from "../useStyles";

const WaitingForPayment = (props) => {
  const { record } = props;
  if (record?.date_paid) return null;
  return (
    <BooleanInput source="waiting_for_payment" label={"Ask for payment"} />
  );
};

export const Form = ({ outreachSiteId, ...props }) => {
  const classes = useStyles();

  return (
    <SimpleForm {...props}>
      {outreachSiteId ? null : (
        <HorizontalGroup label={"Guest site"}>
          <OutreachSiteReferenceInput
            source={"outreach_site"}
            className={classes.big}
            initialValue={outreachSiteId}
            defaultValue={outreachSiteId}
          />

          <NumberInput
            onWheel={(e) => e.target.blur()}
            source="price"
            className={classes.big}
          />
          <TextInput source="currency" className={classes.small} />
        </HorizontalGroup>
      )}
      <HorizontalGroup label={"Status"}>
        <RadioButtonGroupInput
          className={classes.big}
          source="status"
          choices={outreachOrderStatus.choices}
          defaultValue={outreachOrderStatus.requested}
        />
      </HorizontalGroup>
      <WaitingForPayment className={classes.big} />

      <HorizontalGroup label={"Guest post title"}>
        <TextInput
          source="h1"
          label={"title"}
          multiline
          className={classes.bigAndBigger}
        />
      </HorizontalGroup>
      <HorizontalGroup label={"Google doc"}>
        <TextInput
          source="google_drive"
          multiline
          className={classes.bigAndBigger}
        />
        <FunctionField
          render={(record) =>
            record.google_drive ? (
              <Button
                href={record.google_drive}
                variant="contained"
                startIcon={<ForwardIcon />}
                target="_blank"
              >
                GO
              </Button>
            ) : null
          }
        />
      </HorizontalGroup>
      <HorizontalGroup label={"Linking page, if available"}>
        <TextInput
          source="linking_page"
          multiline
          className={classes.bigAndBigger}
        />
        <FunctionField
          render={(record) =>
            record.linking_page ? (
              <Button
                href={record.linking_page}
                variant="contained"
                startIcon={<ForwardIcon />}
                target="_blank"
              >
                GO
              </Button>
            ) : null
          }
        />
      </HorizontalGroup>

      <HorizontalGroup label={"link"}>
        <WebsiteReferenceInput source="website" className={classes.big} />
        <TextInput
          source="link"
          label={"Link target"}
          className={classes.big}
        />
        <TextInput source="link_type" className={classes.small} />
        <FunctionField
          render={(record) =>
            record.link ? (
              <Button
                href={record.link}
                variant="contained"
                startIcon={<ForwardIcon />}
                target="_blank"
              >
                GO
              </Button>
            ) : null
          }
        />
      </HorizontalGroup>

      <TextInput
        source="link_anchor"
        className={classes.bigAndBigger}
        multiline
      />
    </SimpleForm>
  );
};

export default Form;
