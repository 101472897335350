import React from "react";
// import Edit from "./Edit";
import {
  CHANNEL_TYPE_CHOICES,
  CHANNEL_VERTICALS,
  LINK_TYPE_CHOICES,
  RESOURCE,
} from "../constants";
import { RESOURCE as ROOT_RESOURCE } from "../../constants";
import { todayISOString } from "../../reusable";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";

import {
  List as RAList,
  Datagrid,
  ListProps,
  TextField,
  downloadCSV,
  SearchInput,
  TopToolbar,
  FilterButton,
  CreateButton,
  ExportButton,
  useResourceContext,
  useRefresh,
  EditButton,
  useRecordContext,
  TextInput,
  SelectInput,
} from "react-admin";

import jsonexport from "jsonexport/dist";
import { UserReferenceField, UserReferenceInput } from "../../user";
import { Button, Dialog } from "@material-ui/core";
import Create from "./Create";
import EditableNumberField from "../../reusable/fields/EditableNumberField";
import EditableTextField from "../../reusable/fields/EditableTextField";

const filters = [
  <SearchInput source="q" alwaysOn />,
  <UserReferenceInput
    source={"owner"}
    label={"Owner"}
    helperText={""}
    emptyValue={"All"}
    resettable
    alwaysOn
    allowEmpty={false}
  />,
  <TextInput source="geo" label="GEO" alwaysOn />,
  <TextInput source="phone_number" label="Phone Number" alwaysOn />,
  <SelectInput
    label="Link Type"
    source="links_type"
    choices={LINK_TYPE_CHOICES}
    emptyValue="All"
    allowEmpty={false}
    resettable
    alwaysOn
  />,
  <SelectInput
    label="Channel Type"
    source="channel_type"
    choices={CHANNEL_TYPE_CHOICES}
    emptyValue="All"
    allowEmpty={false}
    resettable
    alwaysOn
  />,
  <TextInput source="account_player" label="Account Player" alwaysOn />,
  <SelectInput
    label="Channel Vertical"
    source="channel_vertical"
    choices={CHANNEL_VERTICALS}
    emptyValue="All"
    allowEmpty={false}
    resettable
    alwaysOn
  />,
];

const Toolbar = () => {
  const resource = useResourceContext();
  const refresh = useRefresh();
  const [show, setShow] = React.useState<string>("");

  const handleSuccess = React.useCallback(() => {
    refresh();
    setShow("");
  }, [refresh, setShow]);
  const setHandleClick = React.useCallback(
    (what: string) => (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      setShow(what);
    },
    [setShow],
  );
  return (
    <>
      <TopToolbar>
        <FilterButton />

        <CreateButton onClick={setHandleClick("create")} />
        <ExportButton />
      </TopToolbar>
      <Dialog
        fullWidth
        // @ts-ignore - probably a MUI bug, it works fine
        maxWidth={"90%"}
        onClose={setHandleClick("")}
        open={show === "create"}
      >
        <Create
          resource={resource}
          basePath={resource}
          onSuccess={handleSuccess}
        />
      </Dialog>
    </>
  );
};

const ShowYoutubeLink = (props: any) => {
  const record = useRecordContext(props);

  return record && record.data?.channel_link ? (
    <Button
      size="small"
      color="primary"
      startIcon={<OpenInNewIcon />}
      href={record.data?.channel_link}
      target="_blank"
    >
      Youtube Link
    </Button>
  ) : null;
};

export const List = (props: ListProps): React.ReactElement => {
  return (
    <RAList
      filters={filters}
      actions={<Toolbar />}
      exporter={exporter}
      bulkActionButtons={false}
      perPage={25}
      sort={{ field: "owner_email", order: "ASC" }}
      filter={{ deleted: false }}
      {...props}
    >
      <Datagrid>
        <TextField source="channel_name" label="Name" />
        <EditableTextField
          source="data.channel_type"
          label="Channel Type"
          choices={CHANNEL_TYPE_CHOICES}
        />
        <UserReferenceField source="owner" label="Owner" />
        <TextField source="owner_email" label="Email" />
        {/* <EditableTextField source="comment" label="Comment" /> */}
        <EditableTextField source="data.mobile_number" label="Mobile Number" />
        <EditableTextField source="data.geo" label="GEO" />
        <EditableNumberField
          source="data.active"
          label="Active"
          choices={[
            { id: 1, name: "✅" },
            { id: 2, name: "❌" },
          ]}
        />
        <EditableNumberField
          source="data.uploaded"
          label="Uploaded"
          choices={[
            { id: 1, name: "✅" },
            { id: 2, name: "❌" },
          ]}
        />
        <EditableNumberField
          source="data.phone_verified"
          label="Phone Verification"
          choices={[
            { id: 1, name: "✅" },
            { id: 2, name: "❌" },
          ]}
        />
        {/* <EditableNumberField
          source="data.verification_step"
          label="Verification Step"
          choices={[
            { id: 1, name: "2 Step" },
            { id: 2, name: "3 Step" },
          ]}
        /> */}
        <ShowYoutubeLink source="data.channel_link" label="Youtube Link" />
        <EditableTextField
          source="data.links_affiliation"
          label="Links Affiliation"
        />
        <EditableTextField
          source="data.links_type"
          label="Links Type"
          choices={LINK_TYPE_CHOICES}
        />
        <EditableTextField
          source="data.account_player"
          label="Accounts Player"
        />
        <EditableTextField
          source="data.channel_vertical"
          label="Channel Vertical"
          choices={CHANNEL_VERTICALS}
        />
        <EditableTextField source="data.channel_id" label="Channel ID" />
        <EditButton label="Edit" />
      </Datagrid>
    </RAList>
  );
};

const exporter = async (stats: any, fetchRelatedRecords: any) => {
  const [owner] = await Promise.all([
    fetchRelatedRecords(stats, "owner", ROOT_RESOURCE.user),
  ]);

  const data = stats.map((entry: any) => {
    return {
      "Channel Name": entry.channel_name,
      "Channel Type": entry.data.channel_type,
      Owner: owner[entry.owner]?.username,
      Email: entry.owner_email,
      "Mobile Number": entry.data.mobile_number,
      GEO: entry.data.geo,
      "Channel Link": entry.data.channel_link,
      "Link Affiliation": entry.data.links_affiliation,
      "Link Type": entry.data.links_type,
      "Channel Vertical": entry.data.channel_vertical,
      "Account Player": entry.data.account_player,
    };
  });

  jsonexport(
    data,
    {
      headers: [
        "Channel Name",
        "Channel Type",
        "Owner",
        "Email",
        "Mobile Number",
        "GEO",
        "Channel Link",
        "Link Affiliation",
        "Link Type",
      ],
    },
    (err: any, csv: any) =>
      !err
        ? downloadCSV(csv, `youtube-list.${todayISOString()}`)
        : console.error(err),
  );
};

export default List;
