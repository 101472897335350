import {
  BooleanInput,
  Datagrid,
  DateField,
  FunctionField,
  ListProps,
  List as RAList,
  SearchInput,
  TextField,
} from "react-admin";
import { WebsiteReferenceField } from "../../website";

const filters = [
  <SearchInput source="q" alwaysOn />,
  <BooleanInput
    source="link_exists"
    label="Link Doesn't Exist on Eden"
    alwaysOn
  />,
];

enum LinkStatus {
  not_checked = "Not Checked",
  link_exists = "Link Exists",
  link_does_not_exist = "Link Does Not Exist",
}

const linkExists = (record) => {
  const data = record.data;
  if (!data.hasOwnProperty("link_found")) return LinkStatus.not_checked;
  return data.link_found
    ? LinkStatus.link_exists
    : LinkStatus.link_does_not_exist;
};

export default function List(props: ListProps) {
  return (
    <RAList filters={filters} {...props}>
      <Datagrid
        rowStyle={(record) => {
          const status = linkExists(record);
          if (
            status === LinkStatus.link_exists ||
            status === LinkStatus.not_checked
          ) {
            return {
              backgroundColor: "white",
            };
          } else {
            return {
              backgroundColor: "#FDE6E6",
            };
          }
        }}
      >
        <WebsiteReferenceField source="website" />
        <TextField source="pretty_link" />
        <DateField source="updated" />
        <FunctionField
          label="Status"
          render={(record) => <>{linkExists(record)}</>}
        />
      </Datagrid>
    </RAList>
  );
}
