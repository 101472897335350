import React from "react";
// import Edit from "./Edit";
import { RESOURCE } from "../constants";
import { EditableTextField, todayISOString } from "../reusable";

import {
  List as RAList,
  Datagrid,
  ListProps,
  TextField,
  downloadCSV,
  SearchInput,
  SelectInput,
  TopToolbar,
  FilterButton,
  CreateButton,
  ExportButton,
  useResourceContext,
  useRefresh,
  EditButton,
} from "react-admin";

import jsonexport from "jsonexport/dist";
import { UserReferenceField, UserReferenceInput } from "../user";
import { Dialog } from "@material-ui/core";
import Create from "./Create";

import { HOSTING_STATUS_CHOICES } from "./constants";

const filters = [
  <SearchInput source="q" alwaysOn />,
  <SelectInput
    emptyText={"All"}
    source="status"
    choices={HOSTING_STATUS_CHOICES}
    alwaysOn
  />,
];

const Toolbar = () => {
  const resource = useResourceContext();
  const refresh = useRefresh();
  const [show, setShow] = React.useState<string>("");

  const handleSuccess = React.useCallback(() => {
    refresh();
    setShow("");
  }, [refresh, setShow]);
  const setHandleClick = React.useCallback(
    (what: string) => (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      setShow(what);
    },
    [setShow],
  );
  return (
    <>
      <TopToolbar>
        <FilterButton />

        <CreateButton onClick={setHandleClick("create")} />
        <ExportButton />
      </TopToolbar>
      <Dialog
        fullWidth
        // @ts-ignore - probably a MUI bug, it works fine
        maxWidth={"90%"}
        onClose={setHandleClick("")}
        open={show === "create"}
      >
        <Create
          resource={resource}
          basePath={resource}
          onSuccess={handleSuccess}
        />
      </Dialog>
    </>
  );
};

export const List = (props: ListProps): React.ReactElement => {
  return (
    <RAList
      filters={filters}
      actions={<Toolbar />}
      exporter={exporter}
      perPage={25}
      sort={{ field: "updated", order: "DESC" }}
      {...props}
    >
      <Datagrid>
        <TextField source="hosting_name" label="Hosting" />
        <TextField source="hosting_username" label="Hosting Username" />
        <TextField source="cpanel_username" label="CPanel Username" />
        <TextField source="cpanel_url" label="CPanel URL" />
        <TextField source="host_pricing" label="Host Pricing" />
        <TextField source="status" label="Status" />
        <TextField source="notes" label="Notes" />
        <EditButton label="Edit" />
      </Datagrid>
    </RAList>
  );
};

const exporter = async (stats: any, fetchRelatedRecords: any) => {
  const data = stats.map((entry: any) => {
    return {
      HostingName: entry.hosting_name,
      HostingUsername: entry.hosting_username,
      CPanelUsername: entry.cpanel_username,
      CPanelURL: entry.cpanel_url,
      HostPricing: entry.host_pricing,
      Status: entry.status,
      Notes: entry.notes,
    };
  });

  jsonexport(
    data,
    {
      headers: [
        "HostingName",
        "HostingUsername",
        "CPanelUsername",
        "CPanelURL",
        "HostPricing",
        "Status",
        "Notes",
      ],
    },
    (err: any, csv: any) =>
      !err
        ? downloadCSV(csv, `hosting-list.${todayISOString()}`)
        : console.error(err),
  );
};

export default List;
