import React, { useState } from "react";

import {
  List as RAList,
  Datagrid,
  ListProps,
  ReferenceField,
  TextField,
  downloadCSV,
  useNotify,
  useRecordContext,
  useListContext,
} from "react-admin";
import { useHistory } from "react-router-dom";
import WarningIcon from "@material-ui/icons/Warning";
import Edit from "../Edit";
import EditableTextField from "../../../reusable/fields/EditableTextField";
import { MarketReferenceField } from "../../../market/fields/MarketlReferenceField";
import { RESOURCE as BRAND } from "../../brands/constants";
import filters from "./Filters";
import { EditableWebsiteReferenceField } from "../../../website/fields/WebsiteReferenceField";
import Channel from "../components/Channel";
import EditableNumberField from "../../../reusable/fields/EditableNumberField";
import { RESOURCE } from "../../../constants";
import jsonexport from "jsonexport/dist";
import { todayISOString } from "../../../reusable";
import { makeStyles } from "@material-ui/core/styles";
import { EditableYoutubeReferenceField } from "../../../youtube/fields";
import { Button, IconButton, Tooltip } from "@material-ui/core";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { EditableAccountReferenceField } from "../../accounts/input";
import { EditableDateField } from "../../../reusable/fields";
import { LinkCopyAction } from "./Actions";
import { PRODUCT } from "../constants";

const useStyles = makeStyles((theme) => {
  return {
    horizontalScroll: {
      width: "130vw",
    },
    field: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      wordBreak: "break-word",
    },
    textBreak: {
      wordBreak: "break-word",
    },
    alignedRight: {
      display: "flex",
      justifyContent: "space-between",
      // flexDirection: "row-reverse",
    },
  };
});

export const NotesToolTip = () => {
  const record = useRecordContext();
  if (!record) return null;
  if (!record.data?.imp_notes) return null;
  return (
    <Tooltip title={record.data?.imp_notes}>
      <IconButton>❗️</IconButton>
    </Tooltip>
  );
};

export const List = (props: ListProps): React.ReactElement => {
  const classes = useStyles();
  const notify = useNotify();
  const history = useHistory();
  const [sortOptions, setSortOptions] = useState([
    {
      field: "-rs",
      order: "DESC",
    },
  ]);

  const handleClear = () => {
    history.push("/sales/deals");
    window.location.reload();
  };

  const handleVariant = (field: string) => {
    const index = sortOptions.findIndex((option) => option.field === field);
    if (index === -1) {
      return "outlined";
    }
    return "contained";
  };

  const handleOrdering = (field: string) => () => {
    setSortOptions((prev) => {
      const index = prev.findIndex((option) => option.field === field);
      if (index === -1) {
        return [...prev, { field, order: "DESC" }];
      }
      prev.splice(index, 1);
      return [...prev];
    });
  };

  return (
    <RAList
      {...props}
      exporter={exporter}
      filters={filters}
      filterDefaultValues={{
        deal_product: "1",
      }}
      sort={{
        field: sortOptions.map((option) => option.field).join(","),
        order: sortOptions.map((option) => option.order).join(","),
      }}
    >
      <>
        <span className={classes.alignedRight}>
          <div>
            <Button variant="outlined" onClick={handleClear}>
              Clear
            </Button>
            <Button
              variant={handleVariant("-rs")}
              onClick={handleOrdering("-rs")}
            >
              Sort By RS
            </Button>
            <Button
              variant={handleVariant("-cpa")}
              onClick={handleOrdering("-cpa")}
            >
              Sort BY CPA
            </Button>
          </div>
          <CopyToClipboard
            text={"€"}
            onCopy={() => notify(`'€' copied to clipboard.`)}
          >
            <Button variant="outlined">Copy €</Button>
          </CopyToClipboard>
        </span>
        <Datagrid
          className={classes.horizontalScroll}
          expand={<Edit />}
          rowStyle={(record, index) => {
            console.log("ISB", record.is_brand_closed);
            return {
                backgroundColor: record.end_date ? "#fef08a" :  record?.is_brand_closed ? "#fca5a5" : "white",
            };
          }}
        >
          <NotesToolTip />
          <ReferenceField reference={BRAND} source={"brand"} sortable={false}>
            <TextField source={"name"} />
          </ReferenceField>

          <MarketReferenceField source={"market"} sortable={false} />
          <EditableTextField
            source={"data.admin_fee"}
            label={"Admin Fee"}
            sortable={false}
          />
          <EditableTextField
            source={"data.effective_revenue_share"}
            label={"Effective Revenue Share"}
            sortable={false}
          />
          <EditableTextField
            source={"data.revenue_share"}
            label={"Revenue Share"}
            sortable={false}
          />
          <EditableTextField
            source={"data.cpa"}
            label={"CPA"}
            sortable={false}
          />
          <EditableTextField
            source={"data.baseline"}
            label={"Baseline"}
            sortable={false}
          />
          <EditableNumberField
            sortable={false}
            source="data.is_baseline_cumulative"
            label="Basline Cumulative"
            choices={[
              { id: 1, name: "✅" },
              { id: 2, name: "❌" },
              { id: 3, name: "-" },
            ]}
          />
          <EditableTextField
            sortable={false}
            source={"data.min_dep"}
            label={"Min Dep"}
          />
          <EditableTextField
            sortable={false}
            source={"data.currency"}
            label={"Currency"}
          />
          <EditableNumberField
            sortable={false}
            source="data.is_bl_cumulative"
            label="Bl Cumulative"
            choices={[
              { id: 1, name: "✅" },
              { id: 2, name: "❌" },
              { id: 3, name: "-" },
            ]}
          />
          <EditableNumberField
            sortable={false}
            source="data.nnco"
            label="NNCO"
            choices={[
              { id: 1, name: "✅" },
              { id: 2, name: "❌" },
              { id: 3, name: "-" },
            ]}
          />
          <EditableDateField source={"closing_date"} label={"Closing Date"} />
          <EditableDateField source={"starting_date"} label={"Starting Date"} />
          <EditableDateField source={"end_date"} label={"End Date"} />
          <EditableTextField
            sortable={false}
            source={"data.imp_notes"}
            label={"⚠️ Important Notes"}
          />
          <EditableTextField
            sortable={false}
            source={"data.regular_notes"}
            label={"Notes"}
          />
        </Datagrid>
      </>
    </RAList>
  );
};

const exporter = async (stats: any, fetchRelatedRecords: any) => {
  const clearDate = (value) => {
    if (value) {
      return value.split("T")[0];
    }
    return "";
  };
  const [brand, market] = await Promise.all([
    fetchRelatedRecords(stats, "brand", BRAND),
    fetchRelatedRecords(stats, "market", RESOURCE.market),
  ]);

  const data = stats.map((entry: any) => ({
    Brand: brand[entry.brand]?.name,
    Market: market[entry.market]?.country,
    "Admin Fee": entry.data?.admin_fee,
    "Effective Revenue Share": entry.data?.effective_revenue_share,
    "Revenue Share": entry.data?.revenue_share,
    CPA: entry.data?.cpa,
    Baseline: entry.data?.baseline,
    "Baseline Cumulative": entry.data?.is_baseline_cumulative,
    "Min Dep": entry.data?.min_dep,
    Currency: entry.data?.currency,
    "Bl Cumulative": entry.data?.is_bl_cumulative,
    NNCO: entry.data?.nnco,
    "Closing Date": clearDate(entry?.closing_date),
    "Starting Date": clearDate(entry?.starting_date),
    "End Date": clearDate(entry?.end_date),
  }));

  jsonexport(
    data,
    {
      headers: [
        "Brand",
        "Market",
        "Admin Fee",
        "Effective Revenue Share",
        "Revenue Share",
        "CPA",
        "Baseline",
        "Baseline Cumulative",
        "Min Dep",
        "Currency",
        "Bl Cumulative",
        "NNCO",
        "Closing Date",
        "Starting Date",
        "End Date",
      ],
    },
    (err, csv) =>
      !err
        ? downloadCSV(csv, `sales-deal.${todayISOString()}`)
        : console.error(err),
  );
};

export default List;
