import React from "react";
import { Resource as RAResource } from "react-admin";
import YouTubeIcon from "@material-ui/icons/YouTube";
import { RESOURCE, PERMISSIONS } from "../constants";

import List from "./List";
import Edit from "./Edit";
import Create from "./Create";

/**
 * For some reason the APP want the resource to be instantiated with this make factory.
 * @param {*} permissions
 * @param {*} key
 * @returns
 */
export const makeYoutube = (permissions: any): React.ReactElement => {
  const permission = permissions?.permission || [];
  return (
    <RAResource
      name={RESOURCE.list}
      options={{ label: "Youtube Channels" }}
      list={permission.includes(PERMISSIONS.view) ? List : undefined}
      edit={permission.includes(PERMISSIONS.edit) ? Edit : undefined}
      create={permission.includes(PERMISSIONS.create) ? Create : undefined}
      key={RESOURCE.list}
      icon={YouTubeIcon}
    />
  );
};
