import {
  Edit as RAEdit,
  Create as RACreate,
  SimpleForm,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  BooleanInput,
  required,
} from "react-admin";
import { TabbedGroup, AdvancedTextInput, HorizontalGroup } from "../reusable";

import { WriterReferenceArrayInput } from "../writer";
import { MarketReferenceArrayInput } from "../market";

const makeSimpleForm = () => (
  <SimpleForm>
    <HorizontalGroup label={"Visibility"} />

    <BooleanInput
      fullWidth
      source={"data.allowAllWriters"}
      label={"Show this card to all the writers"}
      initialValue={false}
      helperText={
        "If this flag is True all the writers will be able to see the content"
      }
    />
    <MarketReferenceArrayInput
      fullWidth
      source={"data.markets"}
      label={"Markets"}
      helperText={
        "All the writers from these markets will be able to see the content"
      }
    />
    <WriterReferenceArrayInput
      fullWidth
      source={"data.writers"}
      label={"Writers"}
      helperText={
        "Additionally, all these writers will be able to see the content"
      }
    />
    <HorizontalGroup label={"Guidelines"} />
    <TextInput
      fullWidth
      source={"title"}
      label={"Title"}
      validate={[required()]}
      variant={"standard"}
      defaultValue={""}
    />

    <TextInput
      fullWidth
      source={"data.description"}
      label={"Description"}
      helperText={"A small description of the content."}
    />
    <TabbedGroup
      id={"information"}
      elements={[
        {
          label: "Links",
          element: [
            <ArrayInput source={"data.links"} label={"Links"}>
              <SimpleFormIterator>
                <TextInput
                  key={"linkAnchor"}
                  fullWidth
                  source="linkAnchor"
                  label={"Anchor text"}
                  helperText={"Anchor text"}
                />
                <TextInput
                  key={"link"}
                  fullWidth
                  source="link"
                  label={"Link"}
                  helperText={"Link of the resource"}
                />
              </SimpleFormIterator>
            </ArrayInput>,
          ],
        },
        {
          label: "Video",
          element: (
            <TextInput
              multiline
              fullWidth
              source="data.video"
              label={"Video"}
              helperText={"Embedded code of the video."}
            />
          ),
        },
        {
          label: "Rich Text",
          element: (
            <AdvancedTextInput fullWidth source={"data.content"} label={""} />
          ),
        },
      ]}
    />
  </SimpleForm>
);

export const Edit = (props) => <RAEdit {...props}>{makeSimpleForm()}</RAEdit>;

export const Create = (props) => (
  <RACreate {...props}>{makeSimpleForm()}</RACreate>
);

export default Edit;
