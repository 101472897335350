import {
  Datagrid,
  ReferenceManyField,
  useCreate,
  useRecordContext,
  useListContext,
  useNotify,
  Pagination,
  FunctionField,
  TextField,
  DateField,
} from "react-admin";
import { RESOURCE as LINKS } from "../constants";
import { MarketEditableReferenceField } from "../../../market/fields/MarketlReferenceField";
import React, { FC } from "react";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { EditableAccountReferenceField } from "../../accounts/input";
import { EditbaleDealsReferenceField } from "../../deals/inputs/EditableDealReferenceInput";
import { EditableWebsiteReferenceField } from "../../../website/fields/WebsiteReferenceField";
import { EditableYoutubeReferenceField } from "../../../youtube/fields";
import { EditableTextField } from "../../../reusable";
import { LinkCopyAction } from "../../listingFees/Actions";
import Actions, { DeleteDealButton } from "./Actions";
import { BrandReferenceField } from "../../brands/fields";
import { IsClickTrackedField } from "./List";

const useStyles = makeStyles((theme) => {
  return {
    field: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      wordBreak: "break-word",
    },
    textBreak: {
      wordBreak: "break-word",
    },
    flex: {
      display: "flex",
      justifyContent: "space-between",
    },
  };
});

/**
 * Will allow to create the item
 * @param string
 * @constructor
 */
const AddNew = ({ brand }: { brand: string }) => {
  const [create, status] = useCreate();
  const notify = useNotify();
  const { refetch } = useListContext();
  const handleCreate = React.useCallback(() => {
    create(LINKS, { brand: brand });
  }, [brand, create]);

  React.useEffect(() => {
    if (status.loaded) {
      if (status.error) {
        notify(status.error, "error");
      } else {
        refetch();
      }
    }
  }, [status, refetch, notify]);

  return (
    <div>
      <Button onClick={handleCreate} startIcon={<AddIcon />}>
        Add New
      </Button>
    </div>
  );
};

/**
 * Used in the deals.
 *
 * props is automatically assigned so the type does not really
 *  matter.
 */
export const ReferencedLinksList: FC<any> = () => {
  const record = useRecordContext();
  return (
    <ReferenceManyField
      fullWidth
      label=""
      sort={{ field: "market", order: "ASC" }}
      reference={LINKS}
      target="brand"
      perPage={100}
      pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
    >
      <DG brand={record.id} record={record} />
    </ReferenceManyField>
  );
};

const DG: FC<any> = ({ brand, record, ...props }) => {
  const classes = useStyles();
  const notify = useNotify();

  const render = React.useCallback(
    (record: any) =>
      `${record?.market_country} - ${record?.data?.revenue_share} + ${record?.data?.cpa} + ${record?.data?.baseline}`,
    [],
  );

  return (
    <div>
      <div className={classes.flex}>
        <AddNew brand={brand} />
        <span>
          <CopyToClipboard
            text={"€"}
            onCopy={() => notify(`'€' copied to clipboard.`)}
          >
            <Button variant="outlined">Copy €</Button>
          </CopyToClipboard>
        </span>
      </div>
      <Datagrid
        {...props}
        rowStyle={(record, index) => {
          return {
            backgroundColor: record.deal_end_date ? "#fef08a" : "white",
          };
        }}
      >
        <DateField source={"created"} label={"Created"} />
        <BrandReferenceField source={"brand"} />
        <EditbaleDealsReferenceField
          optionText={render}
          sortBy={"id"}
          field={<FunctionField render={render} />}
          filter={{ status: "active", brand: brand }}
          sort={{ field: "id", order: "ASC" }}
          inputProps={{
            optionText: render,
          }}
          source={"deal"}
        />
        <DeleteDealButton label="" />
        <MarketEditableReferenceField source={"market"} />
        <EditableWebsiteReferenceField
          className={classes.textBreak}
          source={"website"}
        />
        <EditableYoutubeReferenceField
          className={classes.textBreak}
          source={"youtube"}
        />
        <EditableAccountReferenceField
          className={classes.textBreak}
          source={"account"}
        />
        <EditableTextField
          className={classes.textBreak}
          source={"campaign"}
          label={"Campaign"}
        />
        <EditableTextField
          source={"product"}
          label={"Product"}
          choices={[
            { id: 1, name: "Casino" },
            { id: 2, name: "Sportbetting" },
            { id: 3, name: "Poker" },
            { id: 4, name: "Finance" },
          ]}
        />
        {/* <EditableTextField source={"link"} label={"Affiliate link"} /> */}
        <TextField source={"link"} label={"Affiliate link"} />
        <LinkCopyAction label="Copy" prettyLink={false} />
        <TextField source={"pretty_link"} label={"Pretty Link"} />
        {/* <EditableTextField source={"pretty_link"} label={"Pretty Link"} /> */}
        <LinkCopyAction label="Copy" prettyLink={true} />
        <EditableTextField source={"data.notes"} label={"Notes"} />
        <IsClickTrackedField source={"is_click_tracked"} />
        <Actions label="Actions" />
      </Datagrid>
    </div>
  );
};

export default ReferencedLinksList;
