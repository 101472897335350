import { SLACK_CHANNELS } from "../../sales/constants";
export { ADMIN } from "../../sales/constants";
export const API_URL = "/api";
export const RESOURCE: string = "product/global_lp";

export const SLACK_CHANNEL_DEFAULT = SLACK_CHANNELS[0];

const MODEL = "globallandingpage";

export const PERMISSIONS = {
  create: `sales.add_${MODEL}`,
  view: `sales.view_${MODEL}`,
  edit: `sales.change_${MODEL}`,
  delete: `sales.delete_${MODEL}`,
};

export { SLACK_CHANNELS, SLACK_CHOICES } from "../../sales/constants";

export const GEOS = [
  { id: "default", name: "Default" },
  {
    id: "BD",
    name: "BD",
  },
  {
    id: "US",
    name: "US",
  },
  {
    id: "CA",
    name: "CA",
  },
  {
    id: "GB",
    name: "GB",
  },
  {
    id: "ES",
    name: "ES",
  },
  {
    id: "MX",
    name: "MX",
  },
  {
    id: "CL",
    name: "CL",
  },
  {
    id: "PE",
    name: "PE",
  },
  {
    id: "CO",
    name: "CO",
  },
  {
    id: "AR",
    name: "AR",
  },
  {
    id: "AU",
    name: "AU",
  },
  {
    id: "NZ",
    name: "NZ",
  },
  {
    id: "ZA",
    name: "ZA",
  },
  {
    id: "IN",
    name: "IN",
  },
];
