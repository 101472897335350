import React, { useState } from "react";

import { InputAdornment, Typography } from "@material-ui/core";

import { TextInput, maxLength, useInput } from "react-admin";

import { calculateWrittenWords } from "../functions/text";

const getState = (
  withLength,
  maximumLength,
  suggestedLength,
  suggestedWithin
) => {
  if (withLength >= maximumLength) {
    return {
      color: "red",
      length: withLength,
    };
  } else if (withLength < suggestedLength) {
    return {
      color: "grey",
      length: withLength,
    };
  } else if (
    suggestedWithin &&
    withLength <= suggestedLength + suggestedWithin
  ) {
    return {
      color: "green",
      length: withLength,
    };
  } else {
    return {
      color: "orange",
      length: withLength,
    };
  }
};

/**
 * Adds a counter to a text Input
 * @param suggestedLength: integer, suggested length
 * @param maximumLength: integer, maximum length (default to  suggested length)
 * @param suggestedWithin: integer, if defined it will be green between
 *  maximumLength and suggestedLength +suggestedWithin
 * @param props: TextInputProps
 * @returns {TextInput}
 * @constructor
 */
export const CountedTextField = ({
  suggestedLength,
  maximumLength,
  words,
  suggestedWithin = 0,
  ...props
}) => {
  const { input } = useInput(props);
  const { value } = input;
  const length = value ? value.toString().length : 0;

  const [counterState, setCounterState] = useState({
    color: "grey",
    length: length,
  });

  const inputProps = props.InputProps || { endAdornment: undefined };
  const actualMaximumLength = maximumLength ? maximumLength : suggestedLength;
  const maximumSuggestedLength = suggestedWithin ? suggestedLength + suggestedWithin : actualMaximumLength;

  React.useEffect(() => {
    setCounterState(
      getState(length, maximumLength, suggestedLength, suggestedWithin)
    );
  }, [length, maximumLength, suggestedLength, setCounterState, suggestedWithin]);
  const { endAdornment } = inputProps;
  // inputProps.maxLength = _maximumLength;

  if (endAdornment || !suggestedLength) {
    return <TextInput {...props} />;
  }

  if (maximumSuggestedLength !== suggestedLength) {
    props.helperText = `${
      props.helperText || props.label
    } (between ${suggestedLength}/${maximumSuggestedLength} ${
      words ? "words" : "characters"
    }).`;
  } else {
    props.helperText = `${
      props.helperText || props.label
    } (up to ${maximumSuggestedLength} ${words ? "words" : "characters"}).`;
  }

  inputProps.endAdornment = (
    <InputAdornment position="end">
      <Typography variant="body2" style={{ color: counterState.color }}>
        {counterState.length} / {suggestedLength}-{maximumSuggestedLength}
      </Typography>
    </InputAdornment>
  );

  return (
    <TextInput
      parse={(value) => (value ? value : "")}
      {...props}
      validate={[
        words ? wordsValidation(actualMaximumLength) : maxLength(actualMaximumLength),
      ]}
      InputProps={{ ...inputProps, maxLength: actualMaximumLength }}
    />
  );
};

const wordsValidation = (length) => (value, allValues) => {
  if (value && calculateWrittenWords(value) > length) {
    return `Must be less than ${length} words.`;
  }
  return undefined;
};
