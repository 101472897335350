import { ReferenceField, TextField } from "react-admin";

import { RESOURCE } from "../constants";
import React from "react";
// eslint-disable-next-line no-unused-vars
import get from "lodash/get";
import { EditableReferenceField } from "../../reusable/fields/EditableReferenceField";

export const YoutubeReferenceField = (props) => (
  <ReferenceField
    label="Youtube"
    source="youtube"
    {...props}
    reference={RESOURCE.list}
  >
    <TextField source={"channel_name"} />
  </ReferenceField>
);

export const EditableYoutubeReferenceField = ({
  source = "youtube",
  label = "Youtube",
  sort = { field: "channel_name", order: "ASC" },
  showSource = "channel_name",
  helperText = "",
  optionText = "channel_name",
  ...props
}) => {
  return (
    <EditableReferenceField
      source={source}
      label={label}
      reference={RESOURCE.list}
      sort={sort}
      inputProps={{
        helperText: helperText,
        optionText: optionText,
      }}
      fieldProps={{
        source: showSource,
      }}
      {...props}
    />
  );
};

YoutubeReferenceField.defaultProps = {
  label: "Youtube",
  addLabel: true,
};
