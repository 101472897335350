import * as React from "react";

import { EditProps, Edit as RAEdit } from "react-admin";

import Form from "./Form";
import LandingPageReferenceList from "./List/LandingPageList";

export const LandingPageEdit = (props: EditProps) => {
  const [selectedData, setSelectedData] = React.useState([]);

  return (
    <>
      <RAEdit {...props} mutationMode="pessimistic">
        <Form {...props} edit={true} selectedData={selectedData} />
      </RAEdit>
      <LandingPageReferenceList setSelectedData={setSelectedData} />
    </>
  );
};
