// in src/posts.js
import * as React from "react";

import { makeStyles } from "@material-ui/core/styles";

import {
  TextField,
  DateField,
  Datagrid,
  List,
  SimpleForm,
  Edit,
  ReferenceField,
  ShowButton,
  EditButton,
  usePermissions,
  Loading,
  TextInput,
} from "react-admin";

import { WRITER } from "../constants";

import { ContentListAside } from "./list/ContentListAside";
export { ContentShow } from "./show/ContentShow";

const useStyles = makeStyles((theme) => ({
  form: {
    "& .ql-editor": {
      minHeight: "300px",
    },
  },
}));

export const ContentList = (props) => {
  const permissions = usePermissions();
  if (permissions.loading) return <Loading />;
  const filter = {};
  if (permissions.permissions.role === WRITER) {
    filter.writer = permissions.permissions.user_id;
  }

  return (
    <List {...props} aside={<ContentListAside />}>
      <Datagrid>
        <TextField source="id" />
        <TextField source="full_url" />
        <ReferenceField
          label="Writer"
          source="writer"
          reference="content/writer"
        >
          <TextField source="username" />
        </ReferenceField>
        <DateField source="date_created" />
        <ShowButton />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export const ContentEdit = (props) => {
  const classes = useStyles();

  return (
    <Edit {...props}>
      <SimpleForm className={classes.form}>
        <TextField source="id" />

        <ReferenceField label="Page" source="page" reference="page">
          <TextField source="full_url" />
        </ReferenceField>

        <ReferenceField
          label="Writer"
          source="writer"
          reference="content/writer"
        >
          <TextField source="username" />
        </ReferenceField>
        <TextInput label="content" source="content" multiline />

        <DateField label="Date created" source="date_created" />
      </SimpleForm>
    </Edit>
  );
};
