import * as React from "react";

import { CreateProps, Create as RACreate } from "react-admin";

import Form from "./Form";
import LandingPageReferenceList from "./List/LandingPageList";

export const LandingPageCreate = (props: CreateProps) => {
  const [selectedData, setSelectedData] = React.useState([]);

  return (
    <>
      <RACreate {...props}>
        <Form {...props} selectedData={selectedData} edit={false} />
      </RACreate>
      <LandingPageReferenceList setSelectedData={setSelectedData} />
    </>
  );
};
