import React from "react";
import {
  Create as RaCreate,
  SimpleForm,
  TextInput,
  CreateProps,
  useNotify,
  useRedirect,
  required,
  PasswordInput,
} from "react-admin";

import { RESOURCE } from "./constants";
import { UserReferenceInput } from "../user";
import { HorizontalGroup } from "../reusable";

export const Create = (props: CreateProps): React.ReactElement => {
  const notify = useNotify();
  const redirect = useRedirect();
  return (
    <RaCreate
      onSuccess={() => {
        notify("Hosting Created Successfully!");
        redirect(`/${RESOURCE}`);
      }}
      {...props}
    >
      <SimpleForm>
        <TextInput source={"hosting_name"} label={"Hosting Name"} />
        <TextInput source={"hosting_username"} label={"Hosting Username"} />
        <PasswordInput source={"hosting_password"} label={"Hosting Password"} />
      </SimpleForm>
    </RaCreate>
  );
};

export default Create;
