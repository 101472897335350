import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import * as React from "react";

export const PBNLinkExists = ({ doesExist }) => {
  if (doesExist === null || doesExist === undefined) return null;

  return (
    <Tooltip title={doesExist ? "Link Exists" : "Link does not Exist"}>
      <span>
        <IconButton disabled aria-label="Link">
          {doesExist ? (
            <CheckBoxIcon style={{ color: "green" }} />
          ) : (
            <ReportProblemIcon style={{ color: "orange" }} />
          )}
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default PBNLinkExists;
