import React from "react";
import { ListGuesser, Resource as RAResource } from "react-admin";
import YouTubeIcon from "@material-ui/icons/YouTube";
import { PERMISSIONS, RESOURCE } from "../constants";

export const makeYoutubeFee = (permissions: any): React.ReactElement => {
  const permission = permissions?.permission || [];
  return (
    <RAResource
      name={RESOURCE.fee}
      options={{ label: "Youtube Fee" }}
      list={permission.includes(PERMISSIONS.view) ? ListGuesser : undefined}
      key={RESOURCE.fee}
      icon={YouTubeIcon}
    />
  );
};
