import * as React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CheckIcon from "@material-ui/icons/Check";
import { useNotify, fetchStart, fetchEnd, useRefresh } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  Input,
  MenuItem,
  Select,
} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import DialogContentText from "@material-ui/core/DialogContentText";
import { dataProvider } from "../../reusable/store";
import { red } from "@material-ui/core/colors";
import { RESOURCE } from "../../constants";

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(red[600]),
    backgroundColor: red[600],
    "&:hover": {
      backgroundColor: red[800],
    },
  },
}));

/**
 * This button Revokes the Content.
 * The content with status of `approved` or `in review`
 * can be revarted to a previous state.
 */
export const RevokeContentButton = ({ basePath, record, disabled }) => {
  const [status, setStatus] = React.useState("requested");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [handleClose, handleOpen] = [
    () => {
      setOpen(false);
    },
    () => {
      setOpen(true);
    },
  ];
  const dispatch = useDispatch();
  const notify = useNotify();
  const refresh = useRefresh();
  const classes = useStyles();

  const handleStatusChange = React.useCallback(
    (e) => setStatus(e.target.value),
    [setStatus],
  );
  const handleClick = () => {
    console.log(status);
    setLoading(true);
    dispatch(fetchStart()); // start the global loading indicator
    dataProvider
      .update(RESOURCE.order, {
        id: record.id,
        data: {
          approved_by: null,
          content_approved: null,
          status: status,
        },
      })
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json;
      })
      .catch((e) => {
        notify(`Error: ${e}`, "warning");
        return Promise.reject();
      })
      .finally(() => {
        setLoading(false);
        setOpen(false);
        dispatch(fetchEnd());
        refresh();
      });
  };
  return (
    <span>
      <Button
        startIcon={<HighlightOffIcon />}
        disabled={disabled || loading}
        onClick={handleOpen}
        className={classes.root}
        variant="contained"
        id="btn-revoke-content"
      >
        {"Revoke Content"}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Revoke Content</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Revoke this content from{" "}
            <b>
              <i>{record.status}</i>
            </b>
          </DialogContentText>
          <label>
            <Typography gutterBottom>
              Please Select the Updated Content Status
            </Typography>
          </label>
          <DialogContent>
            <Select
              id="status-select"
              label={"Updated Status"}
              value={status}
              onChange={handleStatusChange}
              input={<Input />}
              required
            >
              <MenuItem key={`requested`} value={"requested"}>
                {"Requested"}
              </MenuItem>
              {record.status !== "review" && (
                <MenuItem key={`review`} value={"review"}>
                  {"Review"}
                </MenuItem>
              )}
            </Select>
          </DialogContent>
          <DialogActions>
            <Button
              id="btn-revoke-content-final"
              startIcon={<CheckIcon />}
              // disabled={disabled || loading}
              onClick={handleClick}
              color="primary"
              variant="contained"
            >
              {"Revoke"}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </span>
  );
};
