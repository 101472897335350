import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { calculateWrittenWords } from "../functions";
import { useFormState } from "react-final-form";
import { CONTENT_WORDS } from "../../content/constants";

export const reset = "COUNT_WORDS_RESET";
export const add = "COUNT_WORDS_ADD";
const getDefault = () => ({ sections: {}, sectionsKeywords: {}, total: 0 });

export default function countWords(state = getDefault(), action) {
  switch (action.type) {
    case reset:
      return getDefault();
    case add:
      const newState = { ...state };
      newState.sections[action.name] = action.writtenWords;
      newState.sectionsKeywords[action.name] = action.writtenKeywords;
      newState.total = Object.values(state.sections).reduce((a, b) => a + b, 0);
      return newState;
    default:
      return state;
  }
}

/**
 * Used to reset the counter.
 * @param {any[]} trigger: UseEffect trigger
 */
export const useCountWordsReset = (trigger = []) => {
  const dispatch = useDispatch();
  useEffect(
    () =>
      dispatch({
        type: reset,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...trigger, dispatch],
  );
};

/**
 * Returns a callback that sets the number of wrds in the given label
 * @returns {function(*=, *=): {name: string, type: string, value: number}}
 */
export const useCountWordsAdd = () => {
  const dispatch = useDispatch();
  return (writtenWords = 0, label = "", writtenKeywords = 0) =>
    dispatch({
      type: add,
      name: label,
      writtenWords: writtenWords,
      writtenKeywords: writtenKeywords,
    });
};

/**
 * Get the total words written.
 * @returns {integer}
 */
export const useCountWordsGetTotal = () => {
  return useSelector((state) => state.countWords.total);
};

/**
 * Get the total keywords written.
 * @returns {integer}
 */
export const useCountWordsGetKeywords = () => {
  const countWords = useCountWords();
  return countWords.sectionsKeywords;
};

/**
 * Get the total keywords data.
 * @returns {object}
 */
export const useCountWordsGetKeywordsData = () => {
  const sectionsKeywords = useCountWordsGetKeywords();
  const keywordsData = {};
  const columns = ["total", "count", "density"];

  Object.values(sectionsKeywords).forEach((entry) =>
    Object.keys(entry).forEach((key, index) => {
      if (!keywordsData[key]) {
        keywordsData[key] = {};
        keywordsData[key]["id"] = entry[key].id ?? index;
        columns.forEach((col) => (keywordsData[key][col] = entry[key][col]));
      } else {
        columns.forEach((col) => {
          keywordsData[key][col] += entry[key][col];
        });
      }
    }),
  );
  return keywordsData;
};
/**
 * Get the total keywords written.
 * @returns {integer}
 */
export const useCountWords = () => {
  return useSelector((state) => state.countWords);
};

/**
 * Get the total words, including other stuff.
 */
export const useTotalWords = () => {
  const { values } = useFormState();
  return calculateTotalWord(values, useCountWordsGetTotal());
};

/**
 * Get the total words, including other stuff.
 */
export const useTotalWordsNoForm = (record) =>
  calculateTotalWord(record, useCountWordsGetTotal());

const calculateTotalWord = (record, mainContent) => {
  const stats = {};
  stats.reviews = calculateArrayWords(record.reviews);
  stats.forces = calculateArrayWords(record.forces);
  stats.weakness = calculateArrayWords(record.weakness);
  stats.mainContent =
    mainContent !== null && mainContent !== undefined && mainContent !== 0
      ? mainContent
      : record.words_written;

  stats.meta_data = record.editable_metas
    ? calculateWrittenWords(record.meta_description) +
      calculateWrittenWords(record.meta_title)
    : null;
  stats.totalWords =
    stats.reviews +
    stats.forces +
    stats.weakness +
    stats.mainContent +
    stats.meta_data;
  stats.requested =
    record.requested_words +
    (record.structure?.pros * CONTENT_WORDS.pros || 0) +
    (record.structure?.cons * CONTENT_WORDS.cons || 0) +
    (record.structure?.number_of_reviews * CONTENT_WORDS.reviews || 0) +
    (record.structure?.editable_metas ? CONTENT_WORDS.meta : 0);
  return stats;
};

const calculateArrayWords = (array) =>
  array
    ? array
        .map((words) => calculateWrittenWords(words))
        .reduce((a, b) => a + b, 0)
    : 0;
