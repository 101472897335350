import React, { ReactElement } from "react";
// @ts-ignore
import get from "lodash/get";
// @ts-ignore
import set from "lodash/set";
import MuiTextField from "@material-ui/core/TextField";
import {
  TextFieldProps as RATextFieldProps,
  useRecordContext,
  useResourceContext,
  useUpdate,
  FunctionField,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  field: {
    width: "100%",
    display: "block",
  },
  fieldEmpty: {
    width: "100%",
    display: "block",
    opacity: 0.5,
    color: theme.palette.grey[500],
  },
  errorText: {
    color: "red",
  },
}));

export interface EditableDateFieldProps extends RATextFieldProps {
  partialUpdate?: boolean;
  multiline?: boolean;
  coerce?: CallableFunction;
  additionalData?: any;
  before?: React.ReactElement;
}

/**
 * An expansion to ReactAdmin Text field that allows in place
 * edit and update.
 *
 * To be used in a ReactAdmin DataGrid.
 * @param props
 * @constructor
 */
export const EditableDateField = (
  props: EditableDateFieldProps,
): ReactElement | null => {
  // deconstruct props.
  const {
    additionalData = {},
    partialUpdate,
    multiline,
    coerce = (v: any): any => {
      return v ? v : null;
    },
    before,
    ...rest
  } = props;
  const { source } = rest;
  const record = useRecordContext();
  const resource = useResourceContext();
  const { id } = record || { id: "" };

  // Get the styles and the update handler.
  const classes = useStyles();
  const [update, { loading, error }] = useUpdate();

  // States.
  const [updateValue, setUpdateValue] = React.useState<boolean>(false);

  const [value, setValue] = React.useState<string>(get(record, source, ""));

  // Callbacks
  const handleChange = React.useCallback(
    (e) => {
      setValue(e.target.value);
    },
    [setValue],
  );
  const handleBlur = React.useCallback(() => {
    if (!source) return;
    const data: any = partialUpdate ? {} : record;
    set(data, source, coerce(value));
    Object.keys(additionalData).forEach((k) =>
      set(data, k, coerce(additionalData[k])),
    );
    setUpdateValue(false);
    update(resource, id, data);
  }, [
    resource,
    update,
    coerce,
    partialUpdate,
    id,
    source,
    value,
    record,
    additionalData,
  ]);
  const handlePressEnter = React.useCallback(
    (event) => (event?.key === "Enter" && !multiline ? handleBlur() : null),
    [handleBlur, multiline],
  );

  const setDateValue = (dateTime: string) => {
    // Example dateTime: 2023-08-23T04:09:59.153730
    if (dateTime && dateTime.length > 10) {
      return dateTime.split("T")[0];
    }
    return dateTime;
  };

  // conditions, no id or record, return nothing.
  if (!id || !record) return null;

  if (error) {
    return <span className={classes.errorText}>Error!</span>;
  }

  if (loading) {
    return <span>Loading...</span>;
  }

  // if updateValue is true, return an input element.
  if (updateValue) {
    return (
      <span>
        {before}
        <MuiTextField
          className={classes.field}
          value={setDateValue(value)}
          fullWidth
          type={"date"}
          multiline={multiline}
          onBlur={handleBlur}
          onChange={handleChange}
          onKeyPress={handlePressEnter}
          // label={label}
        />
      </span>
    );
  }

  // By default return a text field.
  return (
    <span>
      {before}
      <FunctionField
        className={value ? classes.field : classes.fieldEmpty}
        {...rest}
        source={"source"}
        render={(record) =>
          record && source && record[source]
            ? record[source].slice(0, 10)
            : "Click to Edit..."
        }
        onClick={() => setUpdateValue(true)}
      />
    </span>
  );
};

export default EditableDateField;
