import {
  Create as RACreate,
  SimpleForm,
} from "react-admin";

import Form from "./Form";
const Create = (props) => {
  return (
    <RACreate {...props}>
      <SimpleForm>
        <Form withPassword />
      </SimpleForm>
    </RACreate>
  );
};

export default Create;
