import {
  ReferenceArrayInput,
  AutocompleteArrayInput,
  SelectArrayInput,
} from "react-admin";
import { withStyles } from "@material-ui/core/styles";

import { RESOURCE } from "../../constants";
import { useState } from "react";

export const MarketReferenceArrayInput = withStyles({
  select: {
    "& .MuiSelect-root": {
      minWidth: "6rem",
    },
  },
})(({ source, label, classes, ...props }) => {
  const [clicked, setClicked] = useState(false);

  return (
    <span onClick={() => setClicked(true)}>
      <ReferenceArrayInput
        source={source ? source : "market"}
        label={label ? label : "Markets"}
        reference={RESOURCE.market}
        sort={{ field: "country", order: "ASC" }}
        enableGetChoices={({ q }) => {
          return clicked || (q ? q.length > 1 : false);
        }}
        allowEmpty={false}
        {...props}
      >
        <AutocompleteArrayInput
          allowEmpty={false}
          className={classes.select}
          optionText={(market) => {
            return `${market?.country} (${market?.language})`;
          }}
          optionValue="id"
          id={source ? source : "markets"}
        />
      </ReferenceArrayInput>
    </span>
  );
});

MarketReferenceArrayInput.defaultProps = {
  label: "Market",
  addLabel: true,
};
