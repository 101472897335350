import {
  List as RAList,
  Datagrid,
  TextField,
  DateField,
  Filter,
  downloadCSV,
  ExportButton,
  TopToolbar,
  useNotify,
  FunctionField,
} from "react-admin";

import { WriterReferenceInput } from "../../writer/inputs/WriterReferenceInput";
import { UserReferenceField } from "../../user";
import { DateRangeDialog } from "../../reusable";
import { GROUPS, RESOURCE } from "../../constants";
import jsonExport from "jsonexport/dist";
import copy from "copy-to-clipboard";
import FileCopyIcon from "@material-ui/icons/FileCopy";

export const WriterPaymentsList = (props) => (
  <RAList
    {...props}
    filters={<Filters />}
    sort={{ field: "date_created", order: "DESC" }}
    actions={<Actions />}
  >
    <WriterPaymentDatagrid />
  </RAList>
);

export const WriterPaymentDatagrid = () => (
  <Datagrid>
    <TextField source="id" />
    <TextField source="amount" />
    <TextField source="currency" />
    <DateField source="date_created" />
    <TextField source="payment_method" />
    <TextField source="payment_reference" />
    <UserReferenceField source="created_by" label="Created By" />
    <UserReferenceField
      source="freelance"
      label="Freelance"
      link={(record) => `/${RESOURCE.financeWriter}/${record.freelance}/show`}
    />
    <FunctionField
      label="Invoice PDF"
      render={(record) => {
        if (!record.invoice_pdf_url) return null;
        return (
          <a
            href={record.invoice_pdf_url}
            target="_blank"
            rel="noopener noreferrer"
          >
            View
          </a>
        );
      }}
    />
  </Datagrid>
);

const Filters = (props) => (
  <Filter {...props}>
    <DateRangeDialog label={"Payment Date"} alwaysOn source={"date_created"} />
    <WriterReferenceInput
      resettable
      filters={{ groups__name: GROUPS.freelances }}
      source={"freelance"}
      alwaysOn
      label={"Freelance Writers"}
    />
  </Filter>
);

const Actions = (props) => {
  const today = new Date();
  const exporter = (csv) =>
    downloadCSV(csv, `Payments.${today.toISOString().slice(0, 10)}`);
  return (
    <TopToolbar>
      <ExportToClipboard />
      <ExportButton
        label={"Download as CSV"}
        exporter={makeExporter(exporter)}
      />
    </TopToolbar>
  );
};

const ExportToClipboard = (props) => {
  const notify = useNotify();
  const callBack = (csv) => {
    copy(csv.replaceAll(",", "\t").split("\n").slice(1).join("\n"), {
      format: "text/plain",
    });
    notify(
      "Text copied in the clipboard, you can paste in an excel or google sheet.",
    );
  };
  const exporter = makeExporter(callBack);
  return (
    <ExportButton
      label="Copy without headers"
      icon={<FileCopyIcon />}
      exporter={exporter}
    />
  );
};

const makeExporter = (callback) => {
  const exporter = async (payments, fetchRelatedRecords) => {
    const users = await fetchRelatedRecords(
      payments,
      "created_by",
      RESOURCE.user,
    );
    // console.info("Fetching freelancers")
    const freelances = await fetchRelatedRecords(
      payments,
      "freelance",
      RESOURCE.writer,
    );
    // console.info("Fetching markets")
    const markets = await fetchRelatedRecords(
      Object.values(freelances),
      "market",
      RESOURCE.market,
    );

    // console.info("Map-ing payments")
    const data = payments.map((payment) => {
      const freelance = freelances[payment.freelance];
      const content_descriptions = payment.contentorder_set.map(
        (content) =>
          `${content.full_url} (${content.type}) requested by ${content.created_by.username}`,
      );
      const content =
        payment.contentorder_set.length > 0
          ? payment.contentorder_set[0]
          : undefined;
      return {
        Date: payment.date_created.slice(0, 10),
        "Requested By": users[content?.created_by.id]?.full_name,
        "Requested By(username)": content?.created_by.username,
        "Supplier Name": freelance.full_name,
        Amount: `${payment.amount}`,
        Currency: payment.currency,
        "Payment Method": payment.payment_method,
        "Payment Details": freelance.payment_instruction,
        "Business Unit": "Websites",
        "Cost Category": "Content Creation",
        GEO: markets[freelance.market],
        "Product or Service Description": content_descriptions.join("\n"),
        PAID: "YES",
        "Payment ID or Screen": payment.payment_reference,
      };
    });
    jsonExport(
      data,
      {
        headers: [
          "Date",
          "Requested By",
          "Requested By(username)",
          "Supplier Name",
          "Amount",
          "Currency",
          "Payment Method",
          "Payment Details",
          "Business Unit",
          "Cost Category",
          "GEO",
          "Product or Service Description",
          "PAID",
          "Payment ID or Screen",
        ], // order fields in the export
      },
      (err, csv) => callback(csv),
    );
    // console.info("Completed")
  };
  return exporter;
};
