import React from "react";
import { Edit as RaEdit, EditProps, useRefresh } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";

import Form from "./Form";

const useStyles = makeStyles(() => ({
  extendedWidth: {
    width: "130vw",
  },
}));

export const Edit = (props: EditProps): React.ReactElement => {
  const refresh = useRefresh();
  const classes = useStyles();

  return (
    <RaEdit
      className={classes.extendedWidth}
      {...props}
      mutationMode="undoable"
      onSuccess={refresh}
    >
      <Form />
    </RaEdit>
  );
};

export default Edit;
