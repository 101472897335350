import { httpClient } from "../../utils";
import { ACTIONS } from "../../constants";

export const pagePathUniqueValidator = async (value, { website, id }) => {
  if (!website) return { message: "website must be defined!" };
  const error = await httpClient(
    ACTIONS.page.check_unique_path(id, value, website)
  ).then(
    (v) => undefined,
    (e) => {
      if (e.status === 409) {
        return { message: `duplicate found with id: ${e.body}` };
      }
      return { message: "server error" };
    }
  );
  if (error) return error;
};
