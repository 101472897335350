import { Filter, SearchInput, List, Datagrid, TextField } from "react-admin";
import { MarketReferenceField } from "../../market";
import { ChipArrayField } from "../../reusable";
import { UserReferenceField } from "../../user";
import { WebsiteReferenceField } from "../../website";
import { YoutubeReferenceField } from "../../youtube/fields/YoutubeReferenceField";

const Filters = (props) => (
  <Filter {...props}>
    <SearchInput source={"q"} alwaysOn />
  </Filter>
);

export const TemplateList = (props) => (
  <List {...props} filters={<Filters />}>
    <Datagrid rowClick="edit">
      <UserReferenceField label="Created By" source="created_by" />
      <TextField source="template_name" />
      <TextField label="Content Type" source="type" />
      <MarketReferenceField source="market" />
      <WebsiteReferenceField source="website" />
      <YoutubeReferenceField source="youtube" />
      <ChipArrayField label="Keywords" source="keywords" />
    </Datagrid>
  </List>
);
