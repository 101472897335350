import React from "react";

import {
  List as RAList,
  Datagrid,
  TextField,
  FunctionField,
  Filter,
  SearchInput,
} from "react-admin";

const Filters = (props) => (
  <Filter {...props}>
    <SearchInput source={"q"} alwaysOn />
  </Filter>
);

export const List = (props) => {
  return (
    <RAList
      {...props}
      filters={<Filters />}
      sort={{ field: "last_content_published", order: "DESC" }}
    >
      <Datagrid>
        <TextField source="domain" />
        <TextField source="latest_content_url" />
        <FunctionField
          source="last_content_published"
          render={(record) => {
            if (record.last_content_published) {
              return record.last_content_published.split("T")[0];
            }
            return "";
          }}
        />
      </Datagrid>
    </RAList>
  );
};

export default List;
