import React from "react";

import { List as RAList, Datagrid, EditButton, TextField } from "react-admin";

import { WebsiteReferenceField } from "../website";

export const List = (props) => {
  return (
    <RAList {...props}>
      <Datagrid>
        <WebsiteReferenceField source="website" label="Website" />
        <TextField source="slug" label="Slug" />
        <EditButton />
      </Datagrid>
    </RAList>
  );
};

export default List;
