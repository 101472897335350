import * as React from "react";

import Grid from "@material-ui/core/Grid";

import Typography from "@material-ui/core/Typography";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { CountedTextField } from "../../reusable";

export const ForcesWeakness = ({ readOnly, ...props }) => {
  /**
   * Forces and weakness inputs, inside an accordion.
   */
  const cons =
    props.record.structure.cons ||
    props.record.structure.number_of_forces_and_weakness;
  const pros =
    props.record.structure.pros ||
    props.record.structure.number_of_forces_and_weakness;
  if (!pros && !cons) return null;
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="forcesweakness-content"
        id="forcesweakness-header"
      >
        <Typography variant={"h5"}>{"Forces and Weakness"}</Typography>,
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={3}>
          {pros ? (
            <Grid item xs={6}>
              <Typography variant={"h6"}>{"Forces"}</Typography>
              {[...Array(pros).keys()].map((idx) => (
                <CountedTextField
                  suggestedLength={40}
                  maximumLength={80}
                  key={`forces.${idx}`}
                  fullWidth
                  source={`forces.${idx}`}
                  label={"force (max 40 characters)"}
                />
              ))}
            </Grid>
          ) : null}
          {cons ? (
            <Grid item xs={6}>
              <Typography variant={"h5"}>{"Weakness"}</Typography>

              {[...Array(cons).keys()].map((idx) => (
                <CountedTextField
                  suggestedLength={40}
                  maximumLength={80}
                  fullWidth
                  source={`weakness.${idx}`}
                  key={`weakness.${idx}`}
                  label={"weakness (max 40 characters)"}
                />
              ))}
            </Grid>
          ) : null}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
