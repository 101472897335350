import {
  SimpleForm,
  Create,
  ArrayInput,
  TextInput,
  SimpleFormIterator,
  required,
  useRedirect,
  SelectInput,
  TextField,
  NumberInput,
} from "react-admin";

import { WebsiteReferenceArrayInput } from "../website";
import { OUTREACH_TYPE_CHOICES, OUTREACH_LINK_TYPES } from "./constants";
import { useStyles } from "../useStyles";
import { RESOURCE } from "../constants";
import * as React from "react";

export const BulkCreate = (props) => {
  const classes = useStyles();
  const redirect = useRedirect();
  return (
    <Create
      {...props}
      // transform={(data) => {
      //   const website = data.website;
      //   const backlinks = data.backlinks
      //     ? data.backlinks.map((backlink) => ({
      //         ...backlink,
      //         website: website,
      //       }))
      //     : [];
      //   return { backlinks: backlinks, bulk: true };
      // }}
      onSuccess={() => {
        redirect(`/${RESOURCE.outreachLinkbuildingOrder}`);
      }}
    >
      <SimpleForm>
        <WebsiteReferenceArrayInput
          source={"websites"}
          label={"Target website"}
          fullWidth
          validate={[required()]}
        />
        <TextInput
          type="url"
          source={"linking_page"}
          target="_blank"
          label="Spot"
          validate={[required()]}
          fullWidth
        />
        <SelectInput
          source="status"
          validate={[required()]}
          choices={OUTREACH_TYPE_CHOICES}
          fullWidth
        />
        <SelectInput
          source="outreach_type"
          validate={[required()]}
          choices={OUTREACH_LINK_TYPES}
          fullWidth
        />
        <NumberInput
          onWheel={(e) => e.target.blur()}
          type="number"
          source="price"
          label="Price"
          defaultValue={0.0}
          fullWidth
        />
        <TextInput
          type="text"
          source="currency"
          label="Currency"
          defaultValue={"EUR"}
          fullWidth
        />
      </SimpleForm>
    </Create>
  );
};

export default BulkCreate;
