import {
  Show,
  SimpleShowLayout,
  RichTextField,
  TextField,
  ReferenceField,
  usePermissions,
  Loading,
} from "react-admin";

import { InfoGrid, ValidationGrid } from "../edit/InfoGrid";
import { WebpageAside } from "../edit/WebpageAside";
import { WebpageDetails } from "../edit/WebpageDetails";
import { RES_USER, GROUPS, STATUS } from "../../constants";
import { IntegratorPageLayout } from "../../page/show/IntegratorPageShow";
import { NoFormAdvancedTextInput } from "../../reusable";
import { WriterToolbar } from "../writer/writerToolbar";
import * as React from "react";
import { DisplayContentFlag, RefusalReason } from "../edit/EditForm";
import { usePage } from "../../page/usePage";
import { useStyles } from "../edit/useStyles";
import { ProofreaderToolbar } from "../proofreader/proofreaderToolbar";
import DisplayContentSections from "../edit/ContentSections";
import { checkCoupemonde } from "../utils/checkCoupemonde";

export const ContentShow = (props) => {
  const classes = useStyles();

  return (
    <Show
      {...props}
      aside={<WebpageAside className={classes.aside} />}
      style={{ marginBottom: "3rem" }}
    >
      <ContentShowLayout />
    </Show>
  );
};

export const ContentShowLayout = ({ record, ...props }) => {
  const { permissions, loading } = usePermissions();
  // If the flag is TRUE it will show a special page to manage the content publishing.
  const flag =
    permissions?.groups?.includes(GROUPS.integrators) &&
    [
      STATUS.approved,
      STATUS.integration,
      STATUS.published,
      STATUS.double_checked,
    ].includes(record.status);
  // Page should be considered only if it exists and if the flag is TRUE.
  const page = usePage(record.webpage, { enabled: flag && !!record.webpage });
  const proofreader = permissions?.groups?.includes(GROUPS.proofreader);

  const coupemondeFlag = checkCoupemonde(record, permissions);

  if (loading || !permissions) {
    return <Loading />;
  }
  if (flag | coupemondeFlag) {
    return (
      <SimpleShowLayout record={record} {...props}>
        <DisplayContentFlag />
        <IntegratorPageLayout content={record} record={page} />
      </SimpleShowLayout>
    );
  }
  return (
    <SimpleShowLayout record={record} {...props}>
      <DisplayContentFlag />
      <ReferenceField
        source="created_by"
        link={false}
        label={"Requested by"}
        reference={RES_USER}
      >
        <TextField source="full_name" disabled />
      </ReferenceField>
      <ReferenceField source="approved_by" link={false} reference={RES_USER}>
        <TextField source="full_name" label={"Approved by"} disabled />
      </ReferenceField>
      <ReferenceField source="integrator" link={false} reference={RES_USER}>
        <TextField source="full_name" label={"Assigned Integrator"} disabled />
      </ReferenceField>
      {!proofreader && (
        <ReferenceField source="writer" link={false} reference={RES_USER}>
          <TextField source="full_name" label={"Writer"} disabled />
        </ReferenceField>
      )}
      <RefusalReason />

      <InfoGrid notForm />
      <ValidationGrid />
      <WebpageDetails />
      <RichTextField
        multiline
        fullWidth
        label={"Reader's Intent"}
        source="structure.reader_intent"
        emptyText={"No Intent specified"}
      />
      <NoFormAdvancedTextInput
        label={"Instructions"}
        source="structure.instructions"
        emptyText={"No instructions provided"}
      />
      <TextField
        multiline
        fullWidth
        label={"Writer's Justification"}
        source="structure.writer_justification"
      />
      <DisplayContentSections show={true} disabled={true} />
      <TextField source="meta_title" emptyText={"No meta title provided"} />
      <TextField
        source="meta_description"
        emptyText={"No meta description provided"}
      />
      {proofreader ? (
        <ProofreaderToolbar noForm={true} saveButton={null} />
      ) : (
        <WriterToolbar noForm={true} saveButton={null} />
      )}
    </SimpleShowLayout>
  );
};
