import IconButton from "@material-ui/core/IconButton";
import { GoogleLogo, GoogleLogoCut } from "../icons";
import Tooltip from "@material-ui/core/Tooltip";
import * as React from "react";

export const GoogleIndexed = ({ isIndexed }) => {
  if (isIndexed === null || isIndexed === undefined) return null;
  return (
    <Tooltip title={isIndexed ? "Indexed" : "Not indexed"}>
      <span>
        <IconButton disabled aria-label="Payment">
          {isIndexed ? <GoogleLogo /> : <GoogleLogoCut />}
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default GoogleIndexed;
