import { AutoSave, HorizontalGroup } from "../../../reusable";
import {
  ReferenceManyField,
  SimpleForm,
  required,
  TextInput,
  TextField,
  useNotify,
  Datagrid,
  Pagination,
  DeleteWithUndoButton,
  usePermissions,
} from "react-admin";
import { Link as MLink, Typography } from "@material-ui/core";
import { NetworkReferenceInput } from "../../network/inputs";
import React from "react";
import Link from "./Link";
import ReferencedList from "../../deals/List/ReferencedList";
import { MarketReferenceArrayInput } from "../../../market";
import { RESOURCE } from "../../../constants";
import MUITextField from "@material-ui/core/TextField";
import { Button, InputAdornment } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import LaunchIcon from "@material-ui/icons/Launch";
import { WebsiteReferenceArrayInput } from "../../../website";
import {
  VerticalReferenceField,
  VerticalReferenceInput,
} from "../../../vertical";
import AddContent from "../components/AddContent";
import { makeStyles } from "@material-ui/core/styles";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import copy from "copy-to-clipboard";
import Licences from "../components/Licences";
import { isClosed } from "../List";
import SalesUserReferenceInput from "../../salesUser/input";
import { ReferencedLinksList } from "../../lists/List/ReferencedLinksList";
import { isSalesBrandAdmin } from "../utils";

export const CreateForm: React.FC<any> = (props) => (
  <SimpleForm {...props}>
    <HorizontalGroup title={"Brand info"} full>
      <TextInput
        id="name"
        source={"name"}
        label={"Brand Name"}
        validate={[required()]}
      />
      <NetworkReferenceInput id="network" source={"network"} />
      <TextInput id="email" source={"data.contact.0.value"} label={"Email"} />
      <TextInput id="skype" source={"data.contact.1.value"} label={"Skype"} />
      <TextInput id="backlink" source={"data.backlink"} label={"Backlink"} />
      <VerticalReferenceInput
        label={"Vertical"}
        id="vertical"
        source={"vertical"}
        validate={[required()]}
      />
    </HorizontalGroup>
  </SimpleForm>
);

const useStyles = makeStyles(() => ({
  button: {
    paddingLeft: "1rem",
    paddingRight: "1rem",
  },
  width: {
    width: "100vw",
  },
}));

// @ts-ignore
export const Form: React.FC<any> = (props) => {
  const { permissions } = usePermissions();
  const isAdmin = permissions && isSalesBrandAdmin(permissions?.permission);
  return (
    <SimpleForm {...props}>
      <BrandStatus props={props} />
      <Link />
      <HorizontalGroup title={"Brand info"}>
        <TextInput
          source={"name"}
          label={"Brand Name"}
          validate={[required()]}
          defaultValue={""}
        />
        <NetworkReferenceInput source={"network"} />
        {isAdmin ? (
          <VerticalReferenceInput source={"vertical"} label={"Vertical"} />
        ) : (
          <>
            <label>Vertical:</label>
            <VerticalReferenceField source={"vertical"} label={"Vertical"} />
          </>
        )}
      </HorizontalGroup>
      <HorizontalGroup title={"Brand Owner"}>
        <SalesUserReferenceInput resettable source={"owner"} label="owner" />
      </HorizontalGroup>
      <HorizontalGroup subtitle={"Contacts"}>
        <TextInput
          source={"data.contact.0.value"}
          label={"Email"}
          defaultValue={""}
        />
        <TextInput
          source={"data.contact.1.value"}
          label={"Skype"}
          defaultValue={""}
        />
      </HorizontalGroup>
      <HorizontalGroup subtitle={"Notes"}>
        <TextInput
          multiline
          source={"data.notes"}
          label={"Notes"}
          defaultValue={""}
        />
      </HorizontalGroup>
      <HorizontalGroup
        label={"Deals"}
        subtitle={
          "Here you can check and create new links, you can break up by market, website, and campaigns. No Value is assumed as ALL"
        }
      />
      <ReferencedList />
      <HorizontalGroup label="Links" />
      <ReferencedLinksList />
      <HorizontalGroup
        title={"Information, Restrictions, and Gotchas"}
        subtitle={
          "Here you can point out the Geographic restrictions and other gotcha (T&C) etc."
        }
        full
      >
        <TextInput
          fullWidth
          multiline
          label={"Instructions"}
          helperText={"What do we need to know to work with this Brand."}
          defaultValue={"\n\n"}
          source={"data.instructions"}
        />
      </HorizontalGroup>
      <HorizontalGroup
        full
        subtitle={"Geographical restrictions, restricted markets."}
      />
      <MarketReferenceArrayInput
        fullWidth
        label={"Restricted markets"}
        source={"data.restrictedCountries"}
      />
      <HorizontalGroup
        full
        title={"Content"}
        subtitle={"Review pages and other content related to the brand."}
      />
      <AddContent props={props} />
      <RelatedContent />
      <WebsiteReferenceArrayInput
        fullWidth
        label={"Website with reviews"}
        source={"data.reviewedOn"}
      />
      <Licences props={props} />
      <AutoSave seconds={3} />
    </SimpleForm>
  );
};

const Actions = (props) => {
  const notify = useNotify();
  const classes = useStyles();
  const { record } = props;

  if (!record) return null;

  const copyToClipboard = (value: any) => {
    copy(value);
    notify("Link Copied to Clipboard!");
  };

  return (
    <>
      <Button
        className={classes.button}
        onClick={() => copyToClipboard(record?.full_url)}
        variant="contained"
        color="primary"
        startIcon={<FileCopyIcon />}
      >
        Copy To Clipboard
      </Button>
      <Button
        className={classes.button}
        color="primary"
        startIcon={<LaunchIcon />}
        href={record?.full_url}
        target="_blank"
      >
        Goto Site
      </Button>
    </>
  );
};

/**
 * Related content with filters.
 * @param props
 * @constructor
 */
const RelatedContent = (props: any) => {
  const [urlFilter, setUrlFilter] = React.useState("");
  return (
    <>
      <MUITextField
        label="Search by url"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        value={urlFilter}
        onChange={(e) => setUrlFilter(e.target.value)}
      />
      <ReferenceManyField
        fullWidth
        label="Content list"
        sort={{ field: "market", order: "ASC" }}
        filter={{ s: urlFilter }}
        reference={RESOURCE.order}
        target="brand"
        perPage={10}
        pagination={<Pagination />}
        {...props}
      >
        <Datagrid>
          <TextField source="id" />
          <TextField source="full_url" />
          <TextField source="status" />
          <Actions props={props} />
          <DeleteWithUndoButton redirect={false} />
        </Datagrid>
      </ReferenceManyField>
    </>
  );
};

const BrandStatus = ({ props }) => {
  const { record } = props;
  return (
    <>
      {isClosed(record) && (
        <>
          <Typography variant="h5" color="error">
            Brand has been closed for <b> {record.data.status.reason} </b>
          </Typography>
        </>
      )}
    </>
  );
};
export default Form;
