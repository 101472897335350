import React from "react";
import {
  Edit as RaEdit,
  SimpleForm,
  TextInput,
  EditProps,
  required,
  SelectInput,
} from "react-admin";
import { HorizontalGroup } from "../../reusable";
import { UserReferenceInput } from "../../user";
import { CHANNEL_VERTICALS, LINK_TYPE_CHOICES } from "../constants";
import ProductReferenceInput from "../../product/dixitProduct/input";

export const Edit = (props: EditProps): React.ReactElement => {
  return (
    <RaEdit mutationMode="pessimistic" redirect="list" {...props}>
      <SimpleForm>
        <HorizontalGroup>
          <TextInput source={"channel_name"} label={"Name"} />
          <UserReferenceInput
            source={"owner"}
            label={"Owner"}
            validate={required()}
          />
          <TextInput source={"owner_email"} label={"Email"} />
          <TextInput source={"data.mobile_number"} label={"Mobile Number"} />
        </HorizontalGroup>
        <HorizontalGroup>
          <SelectInput
            label="Active"
            source="data.active"
            choices={[
              { id: 1, name: "✅ Active" },
              { id: 2, name: "❌ Not-Active" },
            ]}
          />
          <SelectInput
            label="Content Uploaded"
            source="data.uploaded"
            choices={[
              { id: 1, name: "✅ Content Uploaded" },
              { id: 2, name: "❌ Content Not Uploaded" },
            ]}
          />
          <SelectInput
            label="Phone Verification"
            source="data.phone_verified"
            choices={[
              { id: 1, name: "✅ Phone Verified Done" },
              { id: 2, name: "❌ Phone Verification is not Done" },
            ]}
          />
        </HorizontalGroup>
        <HorizontalGroup>
          <TextInput source="data.channel_id" label="Channel ID" />
          <TextInput source="data.channel_link" label="Channel Link" />
          <ProductReferenceInput
            source={"dixit_product"}
            key={"display_name"}
            label="Product"
            resettable
          />
        </HorizontalGroup>
        <HorizontalGroup>
          <SelectInput
            label="Link Type"
            source="links_type"
            choices={LINK_TYPE_CHOICES}
            emptyValue="All"
            allowEmpty={false}
            resettable
            alwaysOn
          />
          <TextInput
            source="data.links_affiliation"
            label="Links Affiliation"
          />
        </HorizontalGroup>
        <HorizontalGroup>
          <SelectInput
            label="Channel Vertical"
            source="data.channel_vertical"
            choices={CHANNEL_VERTICALS}
            emptyValue="All"
            allowEmpty={false}
            resettable
            alwaysOn
          />
          <TextInput source="data.accounts_player" label="Links Affiliation" />
        </HorizontalGroup>
        <TextInput source={"owner_email"} label={"Email"} />
        <TextInput
          parse={(value) => value}
          source={"comment"}
          label={"Comment"}
          multiline
        />
      </SimpleForm>
    </RaEdit>
  );
};

export default Edit;
