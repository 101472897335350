import { useState } from "react";
import { ReferenceInput, AutocompleteInput } from "react-admin";

import { RESOURCE } from "../../constants";

export const VAssistantReferenceInput = ({
  source,
  disabled,
  label,
  helperText,
  required,
  ...props
}) => {
  const [clicked, setClicked] = useState(false);
  return (
    <span onClick={() => setClicked(true)}>
      <ReferenceInput
        disabled={disabled}
        required
        source={source ? source : "vAssistant"}
        label={label ? label : "VAssistant"}
        reference={RESOURCE.vAssistant}
        filterToQuery={(searchText) => ({ full_name: searchText })}
        enableGetChoices={({ full_name }) => {
          return clicked || !!full_name;
        }}
        {...props}
      >
        <AutocompleteInput
          disabled={disabled}
          required
          optionText="full_name"
          helperText={helperText}
          optionValue="id"
        />
      </ReferenceInput>
    </span>
  );
};

VAssistantReferenceInput.defaultProps = {
  label: "VAssistant",
  addLabel: true,
};
