import { useNotify, useRefresh } from "react-admin";
import { EditForm } from "../edit/EditForm";
import { WriterToolbar } from "../writer/writerToolbar";

export const EditorContentReview = ({ ...props }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const onSuccess = () => {
    refresh();
    // notify("Element updated");
    notify("Content Saved Successfully");
  };
  return (
    <EditForm {...props} onSuccess={onSuccess} toolbar={<WriterToolbar />} />
  );
};
