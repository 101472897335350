import * as React from "react";
import SaveIcon from "@material-ui/icons/Save";
import { Button } from "react-admin";
import { Form } from "react-final-form";

/**
 * A standalone Form.
 * @param children: JSX.Element[]
 * @param withButton: bool
 * @param props: FormProps
 * @returns {JSX.Element}
 * @constructor
 */
export const StandaloneForm = ({ children, withButton, ...props }) => {
  return (
    <Form {...props}>
      {(formProps) => {
        return (
          <form>
            {React.Children.map(children, (child, idx) =>
              React.cloneElement(child, { key: idx })
            )}
            {withButton ? (
              <Button
                startIcon={<SaveIcon />}
                variant="outlined"
                color="primary"
                onClick={() => {
                  props.onSubmit(formProps.values);
                }}
                label={"Submit"}
              />
            ) : (
              []
            )}
          </form>
        );
      }}
    </Form>
  );
};

export default StandaloneForm;
