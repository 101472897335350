import React from "react";
import ReactDOM from "react-dom";
// import './index.css';
import App from "./components/App";
import reportWebVitals from "./reportWebVitals";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { API_URL } from "./components/constants";

if (
  document.location.hostname === "localhost" ||
  document.location.hostname === "127.0.0.1"
) {
} else {
  Sentry.init({
    dsn: "https://64acf512049540cebb64a954a565e306@o985910.ingest.sentry.io/5942348",
    integrations: [new Integrations.BrowserTracing()],
    tunnel: `${API_URL}/frontend/bugs`,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.0005,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
