import * as React from "react";
import { Create as RACreate, SimpleForm } from "react-admin";
import { Form } from "./Form";

export const Create = (props) => {
  const keepOnPage = (e) => {
    e.preventDefault();
    var message = "Custom message doesn't support on mordan browsers :|";
    e.returnValue = message;
    return message;
  };

  React.useEffect(() => {
    window.addEventListener("beforeunload", keepOnPage);
    return () => {
      window.removeEventListener("beforeunload", keepOnPage);
    };
  }, []);

  return (
    <RACreate {...props}>
      <SimpleForm>
        <Form />
      </SimpleForm>
    </RACreate>
  );
};
