import React from "react";
import { EditableReferenceField } from "../../../reusable/fields/EditableReferenceField";

// import { Form } from 'react-final-form';
import { RESOURCE } from "../constants";
import { useRecordContext, useCreate, useInput } from "react-admin";
import { Tooltip } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import LinkIcon from "@material-ui/icons/Link";
import LinkOffIcon from "@material-ui/icons/LinkOff";
/**
 * Use this element inside a non FORM!
 *
 * @param source
 * @param label
 * @param props
 * @constructor
 */
export const EditableNetworkReferenceField = ({
  source = "network",
  label = "Network",
  ...props
}) => {
  const [create, { loaded, data, error, loading }] = useCreate();

  // Include in the form
  return (
    <EditableReferenceField
      reference={RESOURCE}
      label={label}
      source={source}
      optiontext="name"
      sortBy={"name"}
      sort={{ field: "name", order: "ASC" }}
      createName={"name"}
      inputProps={{
        optiontext: "name",
      }}
      fieldProps={{
        source: "name",
      }}
      buttons={[<GoToButton key={"goto"} />]}
      {...props}
    />
  );
};

const GoToButton = () => {
  const record = useRecordContext();
  if (!record.link) {
    return (
      <Tooltip title={"No link"}>
        <LinkOffIcon />
      </Tooltip>
    );
  }
  return (
    <Tooltip title={`Go to ${record.link}`}>
      <IconButton
        onClick={(e) => {
          e.preventDefault();
          window.open(record.link, "_blank", "noreferrer");
        }}
      >
        <LinkIcon />
      </IconButton>
    </Tooltip>
  );
};

export default EditableNetworkReferenceField;
