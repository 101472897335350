export const API_URL = "/api";
export const RESOURCE = {
  fee: "youtube/youtube/fee",
  list: "youtube/youtube",
  order: "youtube/youtube/order",
};

const MODEL = "youtube";

export const PERMISSIONS = {
  create: `youtube.add_${MODEL}`,
  view: `youtube.view_${MODEL}`,
  edit: `youtube.change_${MODEL}`,
  delete: `youtube.delete_${MODEL}`,
};

export const LINK_TYPE_CHOICES = [
  { id: "landing_page", name: "Landing Page" },
  { id: "direct_links", name: "Direct Links" },
  { id: "linktree", name: "Linktree" },
  { id: "landing/direct_links", name: "Landing/Direct Links" },
  { id: "other", name: "Other" },
];

export const CHANNEL_TYPE_CHOICES = [
  { id: "", name: "None" },
  { id: "main_channel", name: "Main Channel" },
  { id: "duplicate_channel", name: "Duplicate Channel" },
  { id: "backup_channel", name: "Backup Channel" },
  { id: "other", name: "Other" },
];

export const CHANNEL_VERTICALS = [
  { id: "casino", name: "Casino" },
  { id: "sports", name: "Sports" },
  { id: "finance", name: "Finance" },
];
