import { cloneElement, ReactElement, Children } from "react";
import Typography from "@material-ui/core/Typography";
import { Labeled } from "react-admin";
import { useStyles } from "../../useStyles";

interface Child extends ReactElement {
  label?: string;
}

export interface HorizontalGroupProps {
  children?: Child | Child[];
  label?: string;
  title?: string;
  forceLabel?: boolean;
  subtitle?: string;
  source?: string;
  full?: boolean;
}

interface ChildProps {
  source?: string;
}

export const HorizontalGroup = (props: HorizontalGroupProps) => {
  const { children, label, title, forceLabel, subtitle, source, full } = props;
  const classes = useStyles();
  const title_ = title ? title : label;
  return (
    <div className={!full ? classes.box : classes.box2}>
      {title_ ? (
        <Typography variant="h5" gutterBottom>
          {" "}
          {title_}{" "}
        </Typography>
      ) : null}
      {subtitle ? (
        <Typography variant="subtitle2" color={"textSecondary"} gutterBottom>
          {" "}
          {subtitle}{" "}
        </Typography>
      ) : null}
      <div className="inputs">
        {Children.map(children, (child = undefined) => {
          if (!child) {
            return null;
          }
          const _props: ChildProps = {};

          if (source) {
            _props["source"] = `${source}.${child.props.source}`;
          }
          if (forceLabel && child.props.label) {
            return (
              <Labeled label={child?.label}>
                {cloneElement(child, { ..._props })}
              </Labeled>
            );
          }
          return cloneElement(child, { ..._props });
        })}
      </div>
    </div>
  );
};

export default HorizontalGroup;
