import { useState } from "react";
import ReceiptIcon from "@material-ui/icons/Receipt";
import {
  Create,
  SimpleForm,
  RichTextField,
  TextInput,
  Button,
  useDataProvider,
  Loading,
  useNotify,
  useRefresh,
  usePermissions,
} from "react-admin";
import { useFormState } from "react-final-form";

import { useStyles } from "../../useStyles";
import { GROUPS } from "../../constants";

export const Pay = (props) => {
  const classes = useStyles();

  const { permissions, loading } = usePermissions();
  if (loading) return <Loading />;
  if (!permissions.groups.includes(GROUPS.finance)) {
    return (
      <div>Apparently you do not have the permission to make a payment.</div>
    );
  }
  return (
    <Create {...props}>
      <SimpleForm actions={null} toolbar={null}>
        <RichTextField source={"payment_instruction"} />
        <FirstRow className={classes.inputRow} />
      </SimpleForm>
    </Create>
  );
};

const FirstRow = ({ className, ...props }) => (
  <div className={className}>
    <TextInput source="payment_method" {...props} />
    <TextInput source="payment_reference" {...props} />
    <div class="MuiInputBase-root MuiFormControl-marginDense">
      <PayButton {...props} />
    </div>
  </div>
);

const PayButton = ({ record, ...props }) => {
  const {
    values: { payment_method, payment_reference },
  } = useFormState();
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();
  const onClick = () => {
    setLoading(true);
    dataProvider
      .financeProofreaderPay(record.id, { payment_method, payment_reference })
      .then(({ data }) => {
        
        if (data.url) {
          window.open(data.url, "_blank");
        }
        notify("Success");
        refresh();
      })
      .catch((error) => {
        console.error(error);
        notify(`${error}`, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  if (loading) return <Loading />;
  return (
    <Button
      color={"primary"}
      // variant=""
      label="Register Payment"
      onClick={onClick}
      variant="contained"
      startIcon={<ReceiptIcon />}
    />
  );
};
