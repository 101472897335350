import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const a11yProps = (index, id) => {
  return {
    id: `vertical-tab-${index}-${id}`,
    "aria-controls": `vertical-tabpanel-${index}-${id}`,
    key: index,
  };
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    // height: 224,
    "& .MuiTabs-root": {
      overflow: "inherit",
    },
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  tabPanel: {
    width: "100%",
    "& .MuiFormControl-fullWidth": {
      width: "100%",
    },
  },
}));

export const TabbedGroup = ({ elements, id, ...props }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs"
        className={classes.tabs}
      >
        {elements.map(({ label }, index) => (
          <Tab label={label} {...a11yProps(index, id)} />
        ))}
      </Tabs>
      {elements.map(({ element }, index) => (
        <TabPanel
          className={classes.tabPanel}
          value={value}
          index={index}
          key={index}
        >
          {Array.isArray(element)
            ? element.map((el) => React.cloneElement(el, { ...props }))
            : React.cloneElement(element, { ...props })}
        </TabPanel>
      ))}
    </div>
  );
};

export default TabbedGroup;
