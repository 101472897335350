import { Filter as RAFilter, SearchInput } from "react-admin";
import { MarketReferenceInput } from "../market";

export const Filter = (props) => (
  <RAFilter {...props}>
    <SearchInput source="q" alwaysOn />
    <MarketReferenceInput source="market" alwaysOn resettable />
  </RAFilter>
);

export default Filter;
