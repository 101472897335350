import { Filter, SearchInput, NumberInput, SelectInput } from "react-admin";

export const WebsiteFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
    <NumberInput
      onWheel={(e) => e.target.blur()}
      min={0}
      step={100}
      source="visitors__gte"
      label="Min. Visitors"
      alwaysOn
      resettable
    />
    <SelectInput
      source="has_clicky"
      choices={[
        { id: 0, name: "Show only without clicky" },
        { id: 1, name: "Show only with clicky" },
      ]}
      label="Clicky"
      emptyText={"Show all"}
      alwaysOn
      resettable
    />
  </Filter>
);
