import { Card, CardHeader, CardContent } from "@material-ui/core";
import { Loading, Error, usePermissions } from "react-admin";
import { WriterContentList } from "../content/writer/WriterContentList";
import { useWriter } from "../content/writer/useWriter";

import { REQUESTED } from "../constants";
import ContentPaymentList from "../content/content_payments/ContentPaymentList";
import { makeCard } from "./utils";

export const WriterDashboard = ({ user_id }) => {
  const { loaded, error, data } = useWriter(user_id);
  const { permissions } = usePermissions();

  if (!loaded) {
    return <Loading />;
  }
  if (error) {
    return <Error error={error} />;
  }
  if (data === undefined) return null;
  const writer = writerType(data);
  const pending = Number((data.earned - data.total_paid).toFixed(2));
  return [
    <Card key={"Payments"}>
      <CardHeader title="Payments" />
      <CardContent>
        <p>
          <b>Earned:</b> {Number(data.earned).toLocaleString("en")}
        </p>
        <p>
          <b>Paid:</b> {Number(data.total_paid).toLocaleString("en")}
        </p>
        <p>
          <b>Pending:</b> {pending}
        </p>
      </CardContent>
    </Card>,
    <Card key={"pending orders"}>
      <CardHeader title="Your orders" />
      <CardContent>
        <WriterContentList
          bulkActionButtons={false}
          basePath="/content/order"
          hasCreate={false}
          hasEdit={true}
          hasList={true}
          hasShow={false}
          resource="content/order"
          permissions={permissions}
          writer={writer}
          withAside={true}
          filterDefaultValues={{ status: REQUESTED }}
        />
      </CardContent>
    </Card>,
    makeCard(
      "Content Payment Info",
      "Content Payments",
      <ContentPaymentList writer={writer} permissions={permissions} />,
    ),
  ];
};

const writerType = (props) => ({
  id: props.id,
  username: props.username,
  rate: props.rate,
  currency: props.currency,
  email: props.email,
});
