import { useQueryWithStore } from "react-admin";

import { APPROVED, INTEGRATED, RESOURCE } from "../constants";

/**
 * Gets the content from the webpage.
 * @param {number} webpage webpage id
 */
export const useContent = (filter) => {
  const { loaded, error, data } = useQueryWithStore({
    type: "getList",
    resource: RESOURCE.order,
    payload: {
      pagination: { page: 1, perPage: 1 },
      sort: { field: "id", order: "DESC" },
      filter: filter,
    },
  });
  return { loaded, error, data };
};

/**
 * Gets the content from the webpage.
 * @param {number} webpage webpage id
 */
export const useContentByPage = (webpage, filters = {}) =>
  useContent({ webpage: webpage, ...filters });

export const useUnpaidOrders = ({ writer }) =>
  useContent({
    writer: writer,
    status__in: [APPROVED, INTEGRATED],
    content_paid: null,
  });
