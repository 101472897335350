import { Resource } from "react-admin";

import { RESOURCE } from "../../constants";

const RES = RESOURCE.notifications.user;
/**
 * For some reason the APP want the resource to be instantiated with this make factory.
 * @param {*} permissions
 * @param {*} key
 * @returns
 */
export const makeUserNotifications = () => <Resource name={RES} key={RES} />;
