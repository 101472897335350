import { DataGrid } from "@mui/x-data-grid";
import * as React from "react";
import { API_URL, RESOURCE } from "../../constants";
import { HorizontalGroup } from "../../reusable";
import { httpClient } from "../../utils";

const pending_content_columns = [
  {
    field: "id",
    headerName: "ID",
    renderCell: ({ row }) => {
      return (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`https://igtm.app/#/content/order/${row.id}`}
        >
          {row.id}
        </a>
      );
    },
    width: 120,
  },
  {
    field: "content_approved",
    headerName: "Approval Date",
    width: 140,
    renderCell: ({ row }) => {
      return <span>{row.content_approved?.slice(0, 10)}</span>;
    },
  },
  { field: "content_quote", headerName: "Content Quote", width: 160 },
  { field: "written_words", headerName: "Written Words", width: 160 },
  { field: "status", headerName: "Status", width: 140 },
  { field: "type", headerName: "Type", width: 140 },
];

const paid_content_columns = [
  {
    field: "id",
    headerName: "ID",
    renderCell: ({ row }) => {
      return (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`https://igtm.app/#/content/order/${row.id}`}
        >
          {row.id}
        </a>
      );
    },
    width: 120,
  },
  {
    field: "_writer_rate",
    headerName: "Writer Rate",
    width: 160,
    renderCell: ({ row }) => {
      return (
        <span>{row._writer_rate ? row._writer_rate : "Default Rate"}</span>
      );
    },
  },
  { field: "content_quote", headerName: "Content Quote", width: 160 },
  { field: "written_words", headerName: "Written Words", width: 160 },
  {
    field: "content_approved",
    headerName: "Approval Date",
    width: 160,
    renderCell: ({ row }) => {
      return <span>{row.content_approved?.slice(0, 10)}</span>;
    },
  },
  {
    field: "content_paid",
    headerName: "Paid Date",
    width: 160,
    renderCell: ({ row }) => {
      return <span>{row.content_paid?.slice(0, 10)}</span>;
    },
  },
  { field: "status", headerName: "Status", width: 140 },
  { field: "type", headerName: "Type", width: 140 },
];

export default function ContentList({ props }) {
  const [loading, setLoading] = React.useState(false);
  const [paid_contents, setPaidContents] = React.useState([]);
  const [pending_contents, setPendingContents] = React.useState([]);
  React.useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const url = `${API_URL}/${RESOURCE.writer}/${props.id}/contents/`;
      try {
        const result = await httpClient(url);
        setPaidContents(result.json.data?.paid_contents || []);
        setPendingContents(result.json.data?.pending_contents || []);
        setLoading(false);
      } catch (err) {
        console.error(err);
        setPaidContents([]);
        setPendingContents([]);
        setLoading(false);
      }
    };
    fetchData();
  }, [props.id]);

  return (
    <>
      <div style={{ height: 460, width: "100%" }}>
        <HorizontalGroup label="Pending Content Payments" />
        <DataGrid
          density="compact"
          disableExtendRowFullWidth="true"
          loading={loading}
          rows={pending_contents}
          columns={pending_content_columns}
          pageSize={10}
          disableSelectionOnClick
        />
      </div>
      <div style={{ height: 460, width: "100%" }}>
        <HorizontalGroup label="Paid Content Payments" />
        <DataGrid
          density="compact"
          disableExtendRowFullWidth="true"
          loading={loading}
          rows={paid_contents}
          columns={paid_content_columns}
          pageSize={10}
          disableSelectionOnClick
        />
      </div>
    </>
  );
}
