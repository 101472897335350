// in src/posts.js
import * as React from "react";

import {
  Show,
  TextField,
  TabbedShowLayout,
  Tab,
  ReferenceManyField,
  NumberField,
  ReferenceField,
  Edit,
  Create,
  SimpleForm,
  useRedirect,
} from "react-admin";

import { Form } from "./Form";
import { makeStyles } from "@material-ui/core/styles";
import { PlannedPageDataGrid } from "../page/pageDatagrid";
import {
  PageDataGrid,
} from "../page/page";

import { RESOURCE } from "../constants";

export { WebsiteList } from "./list";

const useStyles = makeStyles({
  referencedDataGrid: {
    display: "inline-grid",
    width: "100%",
  },
});

export const WebsiteShow = (props) => {
  const style = useStyles();
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label={"Details"}>
          <TextField source="domain" />
          <ReferenceField
            label="Vertical"
            source="vertical"
            reference={RESOURCE.vertical}
            link={false}
          >
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField
            label="Niche"
            source="niche"
            reference={RESOURCE.niche}
            link={false}
          >
            <TextField source="name" />
          </ReferenceField>
          <TextField source="type" />
          <NumberField source="clicky_site_id" />
          <TextField source="clicky_site_key" />
          <NumberField source="total_pages" />
          <NumberField source="spent_in_content" />
        </Tab>

        <Tab label={"Pages"}>
          <ReferenceManyField
            label={"Planned pages"}
            reference="page"
            target="website"
            className={style.referencedDataGrid}
            filter={{ status: "planned" }}
            emptyText={"No planned pages."}
          >
            <PlannedPageDataGrid label={"Planned pages"} website={props.id} />
          </ReferenceManyField>
          <ReferenceManyField
            label={" "}
            reference="page"
            target="website"
            className={style.referencedDataGrid}
            filter={{ status: "requested" }}
            emptyText={"No planned pages."}
          >
            <PageDataGrid
              hasEdit={true}
              label={"Requested pages"}
              size={"medium"}
            />
          </ReferenceManyField>
          <ReferenceManyField
            label={" "}
            reference="page"
            target="website"
            className={style.referencedDataGrid}
            filter={{ status: ["approved"] }}
            emptyText={"No requested pages."}
          >
            <PageDataGrid
              label={"Approved pages"}
              hasShow={true}
              size={"medium"}
            />
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export const WebsiteEdit = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm {...props}>
        <Form />
      </SimpleForm>
    </Edit>
  );
};

export const WebsiteEditRedirect = ({ id }) => {
  const redirect = useRedirect();
  React.useEffect(() => {
    if (!id) return;
    const url = `/${RESOURCE.websiteWithStats}/${id}/`;
    redirect(url);
  }, [id, redirect]);
  return <div />;
};

export const WebsiteCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <Form />
    </SimpleForm>
  </Create>
);
