import React from "react";
import {
  useDelete,
  useUpdate,
  useListContext,
  useNotify,
  useRecordContext,
  TextInput,
  SaveButton,
} from "react-admin";
import { useCreateBrand } from "../../deals/record";
import { RESOURCE as LINKS } from "../constants";
import IconButton from "@material-ui/core/IconButton";
import CallSplitIcon from "@material-ui/icons/CallSplit";
import { InputLabel, Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { DeleteButtonWithConfirmation } from "../../../reusable/buttons/DeleteButtonWithConfirmation";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { EditableTextField } from "../../../reusable";

interface ActionsProps {
  label: string;
}

export const DeleteDealButton: React.FC<ActionsProps> = ({ label }) => {
  const record = useRecordContext();
  const notify = useNotify();
  const { refetch } = useListContext();
  const [update, state] = useUpdate();

  const { id } = record;
  const handleDelete = React.useCallback(() => {
    const _data = { deal: null };
    update(LINKS, id, _data);
  }, [update, id]);

  React.useEffect(() => {
    if (state.loaded) {
      if (state.error) {
        notify(state.error, "error");
      } else {
        refetch();
      }
    }
  }, [state.loaded, state.error, refetch, notify]);

  return (
    <Tooltip title={"Delete Deal"}>
      <IconButton onClick={handleDelete}>
        <DeleteIcon />
      </IconButton>
    </Tooltip>
  );
};

const EditButton = () => {
  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const editableStyle = {
    minHeight: "2rem",
    width: "100%",
    display: "flex",
    alignItems: "center",
  };
  return (
    <>
      <IconButton onClick={handleClick} aria-label="Edit Record">
        <EditIcon />
      </IconButton>
      <Dialog open={open} onClose={handleClose} fullWidth={true}>
        <DialogTitle id="alert-dialog-title">{"Edit Link Details"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Click on the field to edit. Press <strong>Enter</strong> to save
          </DialogContentText>
          <div>
            <InputLabel htmlFor="link">Affiliate Link</InputLabel>
            <EditableTextField
              style={editableStyle}
              label="Affiliate Link"
              source="link"
            />
          </div>
          <div>
            <InputLabel htmlFor="pretty_link">Pretty Link</InputLabel>
            <EditableTextField
              style={editableStyle}
              label="Pretty Link"
              source="pretty_link"
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export const Actions: React.FC<ActionsProps> = (props) => {
  const record = useRecordContext();
  const notify = useNotify();
  const { refetch } = useListContext();
  const [delete_, deleteState] = useDelete();
  const [create, state] = useCreateBrand(record.brand);
  const { id, ...data } = record;

  const handleDelete = React.useCallback(() => {
    delete_(LINKS, record.id);
  }, [delete_, record.id]);
  const handleClone = React.useCallback(() => {
    const _data = { ...data };
    create(LINKS, _data);
  }, [create, data]);

  React.useEffect(() => {
    if (state.loaded || deleteState.loaded) {
      if (state.error || deleteState.error) {
        notify(state.error || deleteState.error, "error");
      } else {
        refetch();
      }
    }
  }, [state.loaded, state.error, refetch, notify, deleteState]);

  return (
    <>
      <Tooltip title={"Edit"}>
        <EditButton />
      </Tooltip>
      <Tooltip title={"Clone"}>
        <IconButton onClick={handleClone}>
          <CallSplitIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={"Delete"}>
        <DeleteButtonWithConfirmation handleDelete={handleDelete} />
      </Tooltip>
    </>
  );
};

export default Actions;
