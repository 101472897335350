import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";
import Tooltip from "@material-ui/core/Tooltip";
import { useNotify, useRecordContext, useResourceContext } from "react-admin";
import Button from "@material-ui/core/Button";
import { post } from "../../../utils";
import { API_URL } from "../../../constants";
import Dialog from "@material-ui/core/Dialog";
import React from "react";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";

import SendOutlinedIcon from "@material-ui/icons/SendOutlined";
import { GridCloseIcon } from "@mui/x-data-grid";
import { SLACK_CHOICES, SLACK_CHANNEL_DEFAULT } from "../constants";

export const RequestContent = () => {
  const [dialogOpen, openDialog] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>("");
  const [channel, setChannel] = React.useState<string[]>([
    SLACK_CHANNEL_DEFAULT,
  ]);
  const notify = useNotify();

  const record = useRecordContext();
  const resource = useResourceContext();
  const handleMessageChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setMessage(e.target.value),
    [setMessage],
  );
  const handleChannelChange = React.useCallback(
    // (e: React.ChangeEvent<HTMLInputElement>) => setChannel(e.target.value),
    (e: React.ChangeEvent<{ value: unknown }>) =>
      setChannel(e.target.value as string[]),
    [setChannel],
  );
  const handleOpen = React.useCallback(() => {
    openDialog(true);
  }, [openDialog]);
  const handleClose = React.useCallback(() => {
    openDialog(false);
  }, [openDialog]);
  const handleSend = React.useCallback(async () => {
    try {
      await post(`${API_URL}/${resource}/${record.id}/request_content/`, {
        message,
        channel,
      });
      notify("The message will be sent shortly to the channel(s)");
    } catch (error: any) {
      notify(
        `${error}` + (error?.body ? ` ${JSON.stringify(error.body)}` : ""),
        "error",
      );
    }
    handleClose();
  }, [record, resource, message, notify, handleClose, channel]);
  return (
    <>
      <Tooltip title={"Request Content"}>
        <Button
          onClick={handleOpen}
          color={"primary"}
          startIcon={<RecordVoiceOverIcon />}
        >
          Request
        </Button>
      </Tooltip>
      <Dialog open={dialogOpen} onClose={handleClose} fullWidth maxWidth={"md"}>
        <DialogTitle>
          Write a message and send a request for {record.name}
        </DialogTitle>
        <DialogContent>
          <TextField
            multiline
            onChange={handleMessageChange}
            value={message}
            label={"Message"}
            fullWidth
            placeholder={"Add a message.."}
          />
          {/* <TextField
            multiline
            select
            value={channel}
            label={"Channel"}
            onChange={handleChannelChange}
            fullWidth
            placeholder={"Add a message.."}
          > */}
          <Select
            label={"Channel"}
            value={channel}
            onChange={handleChannelChange}
            multiple
            input={<Input />}
          >
            {SLACK_CHOICES.map(({ id, name }) => (
              // <MenuItem key={`#${id}`} value={id} selected={id === channel}>
              <MenuItem key={`#${id}`} value={id}>
                {name}
              </MenuItem>
            ))}
          </Select>
          {/* </TextField> */}
        </DialogContent>
        <DialogActions>
          <Button startIcon={<GridCloseIcon />} onClick={handleClose}>
            Close
          </Button>
          <Button startIcon={<SendOutlinedIcon />} onClick={handleSend}>
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RequestContent;
