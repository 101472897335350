import drfProvider from "ra-data-django-rest-framework";
import { ACTIONS } from "./constants";

// const getPaginationQuery = (pagination) => {
//   return {
//     page: pagination.page,
//     page_size: pagination.perPage,
//   };
// };

// const getFilterQuery = (filter) => {
//   const { q: search, ...otherSearchParams } = filter;
//   return {
//     ...otherSearchParams,
//     search,
//   };
// };

export const getOrderingQuery = (sort) => {
  const { field, order } = sort;
  return {
    ordering: `${order === "ASC" ? "" : "-"}${field}`,
  };
};

export const actionedDataProvider = (apiUrl, client) => ({
  ...drfProvider(apiUrl, client),
  // getList: async (resource, params) => {
  //   const query = {
  //     ...getFilterQuery(params.filter),
  //     ...getPaginationQuery(params.pagination),
  //     ...getOrderingQuery(params.sort),
  //   };
  //   const url = `${apiUrl}/${resource}/?${JSON.stringify(query)}`;
  //
  //   const { json } = await client(url);
  //
  //   return {
  //     data: json.results,
  //     total: json.count,
  //       stats: {total:100},
  //   };
  // },
  contentCreateTrello: async ({ data }) => {
    const { json } = await client(ACTIONS.content.trelloIntegration(), {
      method: "POST",
      body: JSON.stringify(data),
    });
    return { data: json };
  },
  createMany: async (resource, params) => {
    const data = await client(`${apiUrl}/${resource}/bulk_create/`, {
      method: "POST",
      body: JSON.stringify(params.data),
    });
    return { data: data.json };
  },
  integrationOrderStart: async (id) => {
    const { json } = await client(ACTIONS.integrationOrder.start(id), {
      method: "POST",
    });
    return {
      data: { ...json },
    };
  },
  financeWriterPay: async (id, data) => {
    const { json } = await client(ACTIONS.finance.payWriter(id), {
      method: "POST",
      body: JSON.stringify(data),
    });
    return {
      data: { ...json },
    };
  },
  financeProofreaderPay: async (id, data) => {
    const { json } = await client(ACTIONS.finance.payProofreader(id), {
      method: "POST",
      body: JSON.stringify(data),
    });
    return {
      data: { ...json },
    };
  },
  financeVAssistantPay: async (id, data) => {
    const { json } = await client(ACTIONS.finance.payVAssistant(id), {
      method: "POST",
      body: JSON.stringify(data),
    });
    return {
      data: { ...json },
    };
  },
  financeOutreachPay: async (id, data) => {
    const { json } = await client(ACTIONS.finance.payOutreachSite(id), {
      method: "POST",
      body: JSON.stringify(data),
    });
    return {
      data: { ...json },
    };
  },
  contentAssignIntegrator: async (id, data) => {
    const { json } = await client(ACTIONS.content.assignIntegrator(id), {
      method: "POST",
      body: JSON.stringify(data),
    });
    return {
      data: { ...json },
    };
  },
});
