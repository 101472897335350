import { ReferenceArrayInput, AutocompleteArrayInput } from "react-admin";
import { withStyles } from "@material-ui/core/styles";

import { RESOURCE } from "../constants";
import { useState } from "react";

export const YoutubeReferenceArrayInput = withStyles({
  select: {
    "& .MuiSelect-root": {
      minWidth: "6rem",
    },
  },
})(({ source, label, classes, ...props }) => {
  const [clicked, setClicked] = useState(false);

  return (
    <span onClick={() => setClicked(true)}>
      <ReferenceArrayInput
        source={source ? source : "youtube"}
        label={label ? label : "Youtube"}
        reference={RESOURCE.list}
        sort={{ field: "owner_email", order: "ASC" }}
        enableGetChoices={({ q }) => {
          return clicked || (q ? q.length > 1 : false);
        }}
        allowEmpty={false}
        {...props}
      >
        <AutocompleteArrayInput
          id={source ? source : "youtubes"}
          allowEmpty={false}
          optionText={(record) => {
            if (!record) {
              return "";
            }
            return record.deleted
              ? `${record.channel_name} (Deleted)`
              : `${record.channel_name}`;
          }}
          optionValue="id"
          // className={ classes.select }
        />
      </ReferenceArrayInput>
    </span>
  );
});

YoutubeReferenceArrayInput.defaultProps = {
  label: "Youtubes",
  addLabel: true,
  id: "youtubes",
};

export default YoutubeReferenceArrayInput;
