export const makeKeywords = (
  keywords,
  structuredContent,
  onlyValues = false,
) => {
  const entries = document.getElementsByClassName("count-words ");
  if (!entries.length) {
    return onlyValues ? keywords.map((_) => 0) : keywords;
  }

  const content = Object.values(entries).map((entry) => entry.innerText);

  const result = keywords.map(
    onlyValues
      ? (kw) => getKeywordDensity(kw, content)
      : (kw) => `${kw} (${getKeywordDensity(kw, content)}%)`,
  );
  return result;
};
export const getKeywordDensity = (kw = "", structuredContent = []) => {
  const stats = {
    total: 0,
    count: 0,
  };
  const parts = kw.split(" ");
  structuredContent.forEach((snippet) => {
    const bag = snippet.toLowerCase();
    const count = parts.every((part) => bag.includes(part.toLowerCase()))
      ? 1
      : 0;
    const length = bag.split(" ").length / parts.length;
    stats.total += length;
    stats.count += count;
  });
  const number_ = stats.total ? (stats.count / stats.total) * 100 : 0;
  return Number.parseFloat(number_).toPrecision(2);
};

export const makeDisplay = (value, limit = 25) =>
  value && value.length > limit + 3 ? `${value.slice(0, limit)}...` : value;
