import BlockIcon from "@material-ui/icons/Block";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Tooltip from "@material-ui/core/Tooltip";
import {
  useNotify,
  useRecordContext,
  useRefresh,
  useResourceContext,
} from "react-admin";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import React, { useEffect } from "react";
import { get, post } from "../../utils";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
  MenuItem,
  Select,
} from "@material-ui/core";

import SendOutlinedIcon from "@material-ui/icons/SendOutlined";
import { GridCloseIcon } from "@mui/x-data-grid";

import { API_URL, RESOURCE } from "../constants";
import { makeStyles } from "@material-ui/core/styles";
import { dataProvider } from "../../reusable/store";

const useStyles = makeStyles((theme) => {
  return {
    open: {
      color: theme.palette.success.dark,
    },
    close: {
      color: theme.palette.error.dark,
    },
  };
});

/**
 * This component will show one of two buttons
 * It will have the button to make the IP Address `SEEN`. So that Eden doens't
 * notify about this IP Addresss.
 * Another button will trigger resume of checking.
 */
export const MakeSeen = () => {
  const classes = useStyles();
  const [dialogOpen, openDialog] = React.useState(false);
  const notify = useNotify();
  const refresh = useRefresh();

  const record = useRecordContext();

  const handleOpen = React.useCallback(() => {
    openDialog(true);
  }, [openDialog]);

  const handleClose = React.useCallback(() => {
    openDialog(false);
  }, [openDialog]);

  const handleStatus = React.useCallback(
    async (status) => {
      await dataProvider.update(RESOURCE, {
        id: record.id,
        data: {
          seen: status,
        },
      });
      refresh();
      notify("List Updated Successfully");
    },
    [record.id, notify, refresh],
  );

  return (
    <>
      {record && record.seen ? (
        <>
          <Tooltip title={"Un Seen IP Address"}>
            <Button
              className={classes.open}
              onClick={() => handleStatus(false)}
              color={"primary"}
              startIcon={<VisibilityOffIcon />}
            >
              UNSEEN
            </Button>
          </Tooltip>
        </>
      ) : (
        <>
          <Tooltip title={"Make it Seen"}>
            <Button
              className={classes.close}
              onClick={handleOpen}
              color={"primary"}
              startIcon={<VisibilityIcon />}
            >
              SEEN
            </Button>
          </Tooltip>
          <Dialog
            open={dialogOpen}
            onClose={handleClose}
            fullWidth
            maxWidth={"md"}
          >
            <DialogTitle>
              This Action will stop checking this Duplication of IP Address
            </DialogTitle>
            <DialogActions>
              <Button startIcon={<GridCloseIcon />} onClick={handleClose}>
                Cancel
              </Button>
              <Button
                startIcon={<SendOutlinedIcon />}
                onClick={() => handleStatus(true)}
              >
                Mark as Seen.
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
};

export default MakeSeen;
