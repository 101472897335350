import { useGetManyReference, useCreate } from "react-admin";

import { RESOURCE } from "./constants";

export interface Deal {
  id: string;
  brand?: string;
  market?: number;
  seo?: {
    volume?: number;
  };
  details?: {
    name?: string;
    type?: string;
    details?: string;
    created?: Date;
  };
  link?: {
    link?: string;
    product?: number;
    created?: Date;
  };
}

export const useGetFromBrand = (brand: string) => {
  return useGetManyReference(
    RESOURCE,
    "brand",
    brand,
    { page: 1, perPage: 10 },
    { field: "market", order: "DESC" },
    {},
    `${RESOURCE}-${brand}`,
    { disabled: !!brand }
  );
};

export const useCreateBrand = (brand: string) => {
  return useCreate(RESOURCE, { brand: brand });
};

export default Deal;
