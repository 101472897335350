import {
  AutocompleteInput,
  ReferenceInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
} from "react-admin";

import { RESOURCE } from "../../constants";

export const OutreachSiteReferenceInput = ({
  helperText,
  initialValue,
  ...props
}) => (
  <ReferenceInput
    sort={{ field: "domain", order: "ASC" }}
    label={"outreach site"}
    {...props}
    reference={RESOURCE.outreachLinkbuildingSite}
    initialValue={initialValue}
  >
    <AutocompleteInput
      resettable
      onCreate={() => {
        prompt("Enter a new category");
      }}
      optionText={(res) => {
        return `${res?.domain}`;
      }}
      initialValue={initialValue}
      helperText={helperText}
    />
  </ReferenceInput>
);

export const OutreachSiteArrayInput = (props) => (
  <ReferenceArrayInput
    sort={{ field: "domain", order: "ASC" }}
    label={"outreach site"}
    {...props}
    reference={RESOURCE.outreachLinkbuildingSite}
  >
    <AutocompleteArrayInput
      resettable
      onCreate={() => {
        prompt("Enter a new category");
      }}
      optionText="domain"
    />
  </ReferenceArrayInput>
);
