import { ReferenceArrayField, ChipField, SingleFieldList } from "react-admin";

import { RESOURCE } from "../constants";

export const YoutubeReferenceArrayField = ({ source, label, ...props }) => (
  <ReferenceArrayField
    label={label ? label : "Youtube"}
    target={props.target ? props.target : "id"}
    source={source ? source : "youtube"}
    reference={RESOURCE.list}
    {...props}
  >
    <SingleFieldList>
      <ChipField variant="outlined" size="small" source="channel_name" />
    </SingleFieldList>
  </ReferenceArrayField>
);

YoutubeReferenceArrayField.defaultProps = {
  label: "Youtubes",
  addLabel: true,
};
