import { useNotify, usePermissions, useUpdate } from "react-admin";
import * as React from "react";
import { RESOURCE, STATUS } from "../../constants";
import Button from "@material-ui/core/Button";

/**
 * Mark as published button, active only if the record is either active or sent to an integrator.
 * @param record
 * @returns {JSX.Element}
 * @constructor
 */
export const MarkAsDoubleChecked = ({ content, ...props }) => {
  const { permissions } = usePermissions();
  const notify = useNotify();
  const [update, updateStatus] = useUpdate();
  const handleClick = React.useCallback(() => {
    update(RESOURCE.order, content.id, {
      status: STATUS.double_checked,
      double_checker_id: permissions.user_id,
    });
  }, [update, content.id, permissions?.user_id]);
  React.useEffect(() => {
    if (updateStatus.loaded) {
      notify("Marked as Double Checked.");
    }
  }, [updateStatus.loaded, notify]);
  return (
    <Button
      variant="contained"
      color="secondary"
      onClick={handleClick}
      disabled={content.status === STATUS.double_checked}
    >
      Mark as Double Checked
    </Button>
  );
};

export default MarkAsDoubleChecked;
