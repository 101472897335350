import { List as RAList, Datagrid, TextField } from "react-admin";

import { Pay } from "./Pay";
import { ChipArrayField } from "../../reusable";
import { FinanceOutreachSiteFilter } from "./Filters";

export const List = (props) => {
  return (
    <RAList {...props} filters={<FinanceOutreachSiteFilter />}>
      <Datagrid rowClick="expand" expand={<Pay />}>
        <TextField source="email" />
        <ChipArrayField source="domains" />
        <TextField source="pending" />
        <TextField source="currency" emptyText={"EUR"} />
      </Datagrid>
    </RAList>
  );
};
