import * as React from "react";
import { useState } from "react";

import { useDataProvider, useNotify, useRefresh } from "react-admin";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";

import { RES_PAGE } from "../constants";
import { PageDataGrid } from "./page";

const useStyles = makeStyles((theme) => ({
  textInput: {
    "& .uri-input": {
      position: "relative",
      display: "block",
    },
    "& > *": {
      margin: "1em",
    },
  },
}));

// const PLANNED = "planned";

// const columns = [
//   { field: "id", headerName: "ID", width: 70 },
//   { field: "full_url", headerName: "Full url", width: 300 },
//   { field: "status", headerName: "Status", width: 150 },
//   { field: "date_created", headerName: "Date Created", width: 250 },
// ];

const GenPlanPages = (props) => {
  const classes = useStyles();

  const [fields, setFields] = useState(["", "", ""]);
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const notify = useNotify();
  const createPages = () => {
    Promise.all(
      fields
        .filter((url) => url !== "")
        .map((url) =>
          dataProvider
            .create(RES_PAGE, { data: { path: url, website: props.website } })
            .then(
              (response) => [url, response, "success"],
              (response) => [url, response, "error"]
            )
        )
    ).then(
      (values) => {
        const errors = values
          .filter(([url, response, status]) => status === "error")
          .map(([url]) => url);
        const success = values
          .filter(([url, response, status]) => status === "success")
          .map(([url]) => url);
        refresh();
        if (errors.length === 0) {
          notify("Pages created successfully.");
        } else {
          notify(
            `These pages could not be created: ${errors.join(", ")}`,
            "warning"
          );
          notify(
            `These pages have been created successfully: ${success.join(", ")}`
          );
        }
      },
      (values) => console.error(values)
    );
  };

  return (
    <div ref={props.ref}>
      <h2>{"Plan more pages"}</h2>
      <form noValidate className={classes.textInput} autoComplete="off">
        {fields.map((value, index, array) => {
          return (
            <TextField
              key={index}
              value={value}
              className="uri-input"
              label={"page path"}
              onChange={(event) => {
                fields[index] = event.target.value;
                setFields([...fields]);
              }}
            />
          );
        })}
        <Button
          label={"Add another"}
          variant="contained"
          onClick={(event) => {
            setFields([...fields, ""]);
          }}
        >
          {"Add another"}
        </Button>
        <Button
          label={"Submit"}
          variant="contained"
          color="primary"
          onClick={createPages}
        >
          {"Submit"}
        </Button>
      </form>
    </div>
  );
};

export const PlannedPageDataGrid = (props) => {
  return (
    <div>
      <GenPlanPages {...props} />
      <PageDataGrid hasShow={false} hasEdit={true} {...props} />
    </div>
  );
};
