// in src/posts.js
import * as React from "react";
import { Card, CardHeader, CardContent } from "@material-ui/core";

import {
  TextField,
  DateField,
  TextInput,
  Datagrid,
  Create,
  SimpleForm,
  ShowButton,
  EditButton,
  SingleFieldList,
  ArrayField,
  ChipField,
  AutocompleteInput,
} from "react-admin";
import { WebsiteReferenceInput } from "../website";
import { MarketReferenceInput } from "../market";
import { ArrayTextInput } from "../reusable";
import { pagePathUniqueValidator } from "./validators";

export { PageEdit } from "./pageEdit";
export { PageShow, IntegratorPageShow } from "./show";
export { PageList } from "./list";
export { IntegratorPageList } from "./integrator";

export const PageDataGrid = ({ hasShow, hasEdit, label, ...props }) => (
  <Card>
    <CardHeader title={label} />
    <CardContent>
      <Datagrid {...props} isRowSelectable={(record) => true}>
        <TextField source="id" />
        <TextField source="full_url" />
        <TextField source="status" />
        <ArrayField source="keywords">
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ArrayField>
        <DateField source="date_created" />
        {hasShow ? <ShowButton /> : null}
        {hasEdit ? <EditButton /> : null}
      </Datagrid>
    </CardContent>
  </Card>
);

export const PageCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <AutocompleteInput
        source="status"
        choices={[
          { id: "planned", name: "planned" },
          { id: "published", name: "published" },
        ]}
        defaultValue={"planned"}
      />
      <WebsiteReferenceInput />
      <TextInput source="path" validate={pagePathUniqueValidator} />
      <MarketReferenceInput />
      <ArrayTextInput
        required
        source="keywords"
        label={
          "Page keywords separate with commas or new a line, the first will be used as guide for YTG"
        }
        fullWidth
      />
    </SimpleForm>
  </Create>
);
