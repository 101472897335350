import { Error } from "react-admin";
import { ContentList } from "../ContentList";

/**
 * Render the list of content for the writer.
 * @param {*} writer
 * @param {string} status
 * @returns
 */
export const VAssistantContentList = ({ vassistant, status, ...props }) => {
  const filter = { vassistant: vassistant.id };
  if (status !== undefined) filter.status = status;
  if (vassistant === undefined)
    return <Error error={"No Virtual Assistant found!"} />;
  return (
      <ContentList filter={filter} {...props} />
  );
};
