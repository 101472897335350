// import {createSvgIcon} from "@material-ui/core/utils/index";
import SvgIcon from "@material-ui/core/SvgIcon";

import { ReactComponent as PBNIconSVG } from "./pbnPlus.svg";

export const PBNPlusIcon = (props) => (
  <SvgIcon component={PBNIconSVG} {...props} />
);

export default PBNPlusIcon;
