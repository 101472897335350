import { useState } from "react";
import {
  ReferenceInput,
  AutocompleteInput,
  usePermissions,
  Loading,
} from "react-admin";

import { RESOURCE } from "../../constants";
import { GROUPS } from "../../constants";
import showUser from "../functions/showUser";

export const UserReferenceInput = ({
  source,
  label,
  withFreelances,
  defaultSelf,
  ...props
}) => {
  const [clicked, setClicked] = useState(false);
  const filters = { ...props.filters };
  const { loaded, permissions } = usePermissions();
  if (defaultSelf && !loaded && permissions?.user_id) {
    return <Loading />;
  }
  const user_id = permissions?.user_id
    ? parseInt(permissions?.user_id, 10)
    : undefined;

  if (!withFreelances) {
    filters["groups__name__ne"] = GROUPS.freelances;
  }

  return (
    <span onClick={() => setClicked(true)}>
      <ReferenceInput
        label={label ? label : "user"}
        source={source ? source : "created_by"}
        reference={RESOURCE.user}
        initialValue={defaultSelf ? user_id : undefined}
        filterToQuery={(searchText) => ({ full_name: searchText, ...filters })}
        sortBy={"username"}
        enableGetChoices={({ full_name }) => {
          return clicked || !!full_name;
        }}
        {...props}
      >
        <AutocompleteInput
          emptyText="Anyone"
          optionText={showUser}
          optionValue="id"
        />
      </ReferenceInput>
    </span>
  );
};

UserReferenceInput.defaultProps = {
  label: "User",
  addLabel: true,
};
