import * as React from "react";
import { useDispatch } from "react-redux";

import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import CardActions from "@material-ui/core/CardActions";

import {
  useNotify,
  fetchStart,
  fetchEnd,
  useRedirect,
  useRefresh,
} from "react-admin";

import { API_URL, RESOURCE } from "../../constants";
import { httpClient } from "../../utils";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "0 px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const MODAL_TEXT = {
  title: "Please add a reason",
  subheader: "Please add a reason for rejection.",
};

export const RejectContentButton = ({
  basePath,
  record,
  disabled,
  ...props
}) => {
  /**
   * Button for the mark as ready feature.
   */
  return (
    <RejectButton disabled={disabled} id={record.id} basePath={basePath} />
  );
};

const RejectButton = ({ disabled, id, basePath }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [reason, setReason] = React.useState("");

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        id="btn-reject"
        onClick={handleOpen}
        disabled={disabled}
        color="secondary"
        variant="contained"
      >
        {"Reject content"}
      </Button>
      <Modal
        aria-labelledby="Add a reason"
        aria-describedby={"Please add a reason for rejection"}
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Card className={classes.paper}>
            <CardHeader {...MODAL_TEXT} />
            <CardContent>
              <div>
                <TextField
                  id={"reject-reason"}
                  variant="outlined"
                  multiline
                  value={reason}
                  onChange={({ target }) => setReason(target.value)}
                />
              </div>
              <CardActions>
                <Reject
                  handleClose={handleClose}
                  reason={reason}
                  disabled={reason === ""}
                  id={id}
                  basePath={basePath}
                />
                <Button
                  id={"btn-no-reject"}
                  color="secondary"
                  label="No"
                  variant="contained"
                  onClick={handleClose}
                >
                  {"I changed my mind."}{" "}
                </Button>
              </CardActions>
            </CardContent>
          </Card>
        </Fade>
      </Modal>
    </div>
  );
};

/**
 * Clicked when one is sure.
 * @param {*} record
 * @returns
 */
const Reject = ({ id, basePath, reason, disabled, handleClose }) => {
  const refresh = useRefresh();
  const redirect = useRedirect();
  const dispatch = useDispatch();
  const notify = useNotify();

  const send = () => {
    dispatch(fetchStart()); // start the global loading indicator
    reject(basePath, id, reason)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return Promise.resolve(response);
      })
      .catch((e) => {
        notify(`Error: ${e}`, "warning");
        return Promise.reject();
      })
      .then((response) => {
        redirect(`/${RESOURCE.order}/${id}/show`);
        notify("Content refused!", "warning");
      })
      .finally(() => {
        dispatch(fetchEnd()); // stop the global loading indicator
        // TODO: ask if this functions are good practice inside dispath
        handleClose();
        refresh();
      });
  };
  return (
    <Button
      id={"btn-reject-final"}
      disabled={disabled}
      onClick={send}
      color="primary"
      variant="contained"
    >
      {"Reject"}
    </Button>
  );
};

/**
 * Request to check the content against copyscape.
 * @param {*} record of type ContentOrder
 * @returns A promise
 */
const reject = (basePath, id, reason) => {
  const path = `${API_URL}${basePath}/${id}/reject/`;
  return httpClient(path, {
    method: "POST",
    body: JSON.stringify({ reason: reason }),
  });
};
