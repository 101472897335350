import { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import SendIcon from "@material-ui/icons/Send";
import {
  Checkbox,
  FormControlLabel,
  FormLabel,
  Input,
  InputLabel,
} from "@material-ui/core";
import { API_URL } from "../../../../constants";
import { post } from "../../../../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    height: "100vh",
    marginBottom: theme.spacing(8),
    padding: theme.spacing(1),
    overflowX: "hidden",
  },
  inputContainer: {
    display: "flex",
    marginBottom: theme.spacing(2),
    position: "sticky",
    bottom: 0,
    backgroundColor: "white",
    zIndex: 1000,
  },
  textField: {
    marginRight: theme.spacing(2),
    flex: 1,
  },
  messageContainer: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    overflowY: "auto",
    paddingBottom: theme.spacing(2),
    overflowX: "hidden",
  },
  messageItem: {
    maxWidth: "70%",
    marginBottom: theme.spacing(0.5),
    alignSelf: "flex-start",
    backgroundColor: "#2196f3",
    padding: theme.spacing(1),
    borderRadius: theme.spacing(0.5),
    color: "white",
    whiteSpace: "pre-line",
    wordWrap: "break-word",
  },
  myMessageItem: {
    maxWidth: "70%",
    marginBottom: theme.spacing(0.5),
    alignSelf: "flex-end",
    backgroundColor: "#3f51b5",
    padding: theme.spacing(1),
    borderRadius: theme.spacing(0.5),
    color: "white",
    whiteSpace: "pre-line",
    wordWrap: "break-word",
  },
}));

const Chat = ({ record, basePath, editors }) => {
  const socketRef = useRef(null);
  const messageEndRef = useRef(null);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [sendMail, setSendMail] = useState(false);
  const [user_id, setUser_id] = useState(localStorage.getItem("user_id"));

  const classes = useStyles();

  const sendMessage = async () => {
    if (message.trim() !== "" && socketRef.current) {
      console.info("sending message: ", message);
      socketRef.current.send(
        JSON.stringify({ user_id: user_id, message: message }),
      );
      setMessage("");

      if (sendMail) {
        const path = `${API_URL}${basePath}/${record.id}/send_mail_message/`;
        await post(path, { message: message }, true);
        console.info("sending mail: ", message);
      }
    }
  };

  const scrollToBottom = () => {
    if (messageEndRef.current) {
      messageEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleSendMail = (event) => {
    setSendMail(event.target.checked);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      sendMessage();
    } else if (event.key === "Enter" && event.shiftKey) {
      setMessage((prevMessage) => prevMessage + "\n");
    }
  };

  useEffect(() => {
    setUser_id(localStorage.getItem("user_id"));
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    if (!user_id) {
      return;
    }

    socketRef.current = new WebSocket(
      `wss://edge-chat-demo.tech910.workers.dev/api/room/${record.id}/websocket`,
    );
    socketRef.current.onopen = () => {
      console.info("WebSocket connected");
      socketRef.current.send(JSON.stringify({ name: user_id }));
    };
    socketRef.current.onmessage = (event) => {
      const message = JSON.parse(event.data);
      if (message.name) {
        setMessages((prevMessages) => [...prevMessages, message]);
      }
    };

    socketRef.current.onclose = () => {
      console.info("WebSocket closed");
    };

    return () => {
      socketRef.current.close();
    };
  }, [record.id, user_id]);

  return (
    <div className={classes.root}>
      <div className={classes.messageContainer}>
        {messages.map((msg, index) => (
          <div
            key={index}
            className={
              msg.name === user_id ? classes.myMessageItem : classes.messageItem
            }
          >
            {msg.message}
          </div>
        ))}
        <div ref={messageEndRef} />
      </div>
      {editors && (
        <div className={classes.inputContainer}>
          <FormControlLabel
            control={<Checkbox onClick={handleSendMail} />}
            label="Send this Message as mail"
            labelPlacement="end"
          />
        </div>
      )}
      <div className={classes.inputContainer}>
        <TextField
          className={classes.textField}
          label="Type a message..."
          variant="outlined"
          value={message}
          multiline
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <Button startIcon={<SendIcon />} onClick={sendMessage} />
      </div>
    </div>
  );
};

export default Chat;
