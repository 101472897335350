import React from "react";
import {
  useDelete,
  useListContext,
  useNotify,
  useRecordContext,
} from "react-admin";
import { RESOURCE } from "../constants";
import IconButton from "@material-ui/core/IconButton";
import { Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

export const Actions = () => {
  const record = useRecordContext();
  const notify = useNotify();
  const { refetch } = useListContext();
  const [delete_, deleteState] = useDelete();

  const handleDelete = React.useCallback(() => {
    delete_(RESOURCE.fee, record.id);
  }, [delete_, record.id]);

  React.useEffect(() => {
    if (deleteState.loaded) {
      if (deleteState.error) {
        notify(deleteState.error, "error");
      } else {
        refetch();
      }
    }
  }, [refetch, notify, deleteState]);

  return (
    <>
      <Tooltip title={"Delete"}>
        <IconButton onClick={handleDelete} size="medium">
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </>
  );
};

export default Actions;
