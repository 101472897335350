import { makeDisplay } from "../functions";
import * as React from "react";

const makeHref = (value) => {
  if (!value) {
    return "";
  }
  if (!value.startsWith("https://") && !value.startsWith("http://")) {
    return `https://${value}`;
  }
  return value;
};

export const ForcedLink = ({ record, defaultValue, source }) => {
  const value = (source && record[source]) || defaultValue || "";
  let href = makeHref(value);
  if (!href) {
    return value;
  }
  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {makeDisplay(value)}
    </a>
  );
};

export default ForcedLink;
