import { ReferenceField, TextField } from "react-admin";

import { RESOURCE } from "../constants";
import React from "react";
// eslint-disable-next-line no-unused-vars
import get from "lodash/get";
import { EditableReferenceField } from "../../../reusable/fields/EditableReferenceField";

export const AccountReferenceField = (props) => (
  <ReferenceField
    label="Account"
    source="account"
    {...props}
    reference={RESOURCE}
  >
    <TextField source={"username"} />
  </ReferenceField>
);

export const EditableAccountReferenceField = ({
  source = "account",
  label = "Account",
  sort = { field: "username", order: "ASC" },
  showSource = "username",
  helperText = "",
  optionText = "username",
  ...props
}) => {
  return (
    <EditableReferenceField
      source={source}
      label={label}
      reference={RESOURCE}
      sort={sort}
      inputProps={{
        helperText: helperText,
        optionText: optionText,
      }}
      fieldProps={{
        source: showSource,
      }}
      {...props}
    />
  );
};

AccountReferenceField.defaultProps = {
  label: "Account",
  addLabel: true,
};
