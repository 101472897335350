import CategoryOutlinedIcon from "@material-ui/icons/CategoryOutlined";
import {
  Error,
  FilterList,
  FilterListItem,
  Loading,
  useGetList,
} from "react-admin";
import { RESOURCE } from "../../constants";

export const VerticalFilterList = () => {
  const { data, loading, error } = useGetList(
    RESOURCE.vertical,
    { page: 1, perPage: 10 },
    { field: "name", order: "ASC" }
  );
  if (loading) return <Loading />;
  if (error) return <Error error={error} />;
  const items = Object.values(data).map((vertical) => (
    <FilterListItem
      id={vertical.id}
      label={vertical.name}
      value={{ vertical: vertical.id }}
    />
  ));
  return (
    <FilterList label="Vertical" icon={<CategoryOutlinedIcon />}>
      {items}
    </FilterList>
  );
};

export default VerticalFilterList;
