import { ReferenceField, TextField, FunctionField } from "react-admin";

import { RESOURCE } from "../../constants";
import { EditableReferenceField } from "../../reusable/fields/EditableReferenceField";

export const MarketReferenceField = ({
  source = "market",
  label = "Market",
  ...props
}) => (
  <ReferenceField
    label={label}
    source={source}
    reference={RESOURCE.market}
    {...props}
  >
    <TextField source="country" />
  </ReferenceField>
);

export const MarketEditableReferenceField = ({
  source = "market",
  label = "Market",

  ...props
}) => (
  <EditableReferenceField
    reference={RESOURCE.market}
    label={label}
    source={source}
    optionText={ record => `${record.country} - ${record.language}`}
    sortBy={"country"}
    field={<FunctionField render={record =>`${record.country} (${record.language})`} />}
    sort={{ field: "country", order: "ASC" }}
    inputProps={{
      optionText: "country",
    }}
    fieldProps={{
      source: "country",
    }}
    {...props}
  />
);

MarketReferenceField.defaultProps = {
  label: "Market",
  addLabel: true,
};
