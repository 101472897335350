import {
  ArrayField,
  ChipField,
  Datagrid,
  EditButton,
  FunctionField,
  List,
  ListProps,
  SingleFieldList,
  TextField,
  TextInput,
} from "react-admin";
import { WebsiteReferenceField, WebsiteReferenceInput } from "../../../website";

export const filters = [
  <WebsiteReferenceInput
    source="website"
    label="Domain"
    filter={{ landing_page: true }}
    alwaysOn
    resettable
  />,
  <TextInput source="slug" label="Slug" alwaysOn />,
];

export default function LandingPageList(props: ListProps) {
  return (
    <List {...props} filters={filters} hasEdit={true}>
      <Datagrid rowClick={undefined}>
        <EditButton />
        <WebsiteReferenceField source="domain" label="Domain" />
        <TextField source="brand_name" label="Slug" />
        <ArrayField source="data.links" label="Links">
          <SingleFieldList>
            <ChipField source="pretty_link" />
          </SingleFieldList>
        </ArrayField>
        <FunctionField
          label="Landing Page Link"
          render={(record) => {
            console.log(record && record.landing_page_url);
            return record && record.landing_page_url ? (
              <a
                href={record.landing_page_url}
                rel="noreferrer"
                target="_blank"
              >
                {record.landing_page_url}
              </a>
            ) : null;
          }}
        />
      </Datagrid>
    </List>
  );
}
