import { Error } from "react-admin";
import { ContentList } from "../ContentList";

/**
 * Render the list of content for the writer.
 * @param {*} writer
 * @returns
 */
export default function ContentPaymentList({ writer, ...props }) {
  if (writer === undefined) return <Error error={"No writer found!"} />;
  const filter = { writer: writer.id };
  return (
    <ContentList writer={writer} filter={filter} withAside={false} {...props} />
  );
}
