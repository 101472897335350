import {
  InputProps,
  useCreate,
  AutocompleteInput,
  ReferenceInput,
  useInput,
  useUpdate,
  useRecordContext,
  useResourceContext,
} from "react-admin";

import { RESOURCE } from "../constants";
import React from "react";

const SORT = {
  field: "name",
  order: "DESC",
};
const SOURCE = "network";

interface handleNameReturnType {
  name: string;
  link?: string;
}

const handleName = (name: string): handleNameReturnType => {
  try {
    const link = new URL(name);
    return {
      name: link.hostname,
      link: link.toString(),
    };
  } catch (e) {
    return {
      name,
    };
  }
};

export const NetworkReferenceInput: React.FC<InputProps> = (props) => {
  const resource = useResourceContext();
  const { id } = useRecordContext();
  const [create, { loaded, data, error, loading }] = useCreate();
  const [update, updateStatus] = useUpdate();
  const {
    input: { onChange },
  } = useInput(props);
  const handleCreate = React.useCallback(
    (name) => {
      create(RESOURCE, handleName(name));
      return null;
    },
    [create],
  );

  React.useEffect(() => {
    if (loaded && data?.id) {
      update(resource, id, { [props.source]: data.id });
    }
  }, [onChange, loaded, data, error, props.source, id, update, resource]);

  if (loading || updateStatus.loading) {
    return <span>'One moment, loading...'</span>;
  }
  return (
    <ReferenceInput
      reference={RESOURCE}
      source={props.source || SOURCE}
      sort={props.sort || SORT}
    >
      <AutocompleteInput
        optiontext={"name"}
        onCreate={handleCreate}
        createValue={""}
      />
    </ReferenceInput>
  );
};

export default NetworkReferenceInput;
