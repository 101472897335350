import { Card, CardHeader, CardContent } from "@material-ui/core";
import { Loading, Error, usePermissions } from "react-admin";
import { VAssistantContentList } from "../content/vassistant/VAssistantContentList";
import { useVAssistant } from "../content/vassistant/useVAssistant";

import { REQUESTED, REVIEW } from "../constants";
import { List as VATaskList } from "../vassistant/tasks/List";

export const VAssistantDashboard = ({ user_id }) => {
  const { loaded, error, data } = useVAssistant(user_id);

  const { permissions } = usePermissions();

  if (!loaded) {
    return <Loading />;
  }
  if (error) {
    return <Error error={error} />;
  }
  if (data === undefined) return null;
  const vAssistant = VAssistantType(data);
  const pending = Number((data.earned - data.total_paid).toFixed(2));
  return [
    <Card key={"Payments"}>
      <CardHeader title="Payments" />
      <CardContent>
        <p>
          <b>Earned:</b> {Number(data.earned).toLocaleString("en")}
        </p>
        <p>
          <b>Paid:</b> {Number(data.total_paid).toLocaleString("en")}
        </p>
        <p>
          <b>Pending:</b> {pending}
        </p>
      </CardContent>
    </Card>,
    <Card key={"pending orders"}>
      <CardHeader title="Your orders" />
      <CardContent>
        <VAssistantContentList
          bulkActionButtons={false}
          basePath="/content/order"
          hasCreate={false}
          hasEdit={true}
          hasList={true}
          hasShow={true}
          resource="content/order"
          permissions={permissions}
          vassistant={vAssistant}
          withAside={false}
          filterDefaultValues={{ status: REVIEW }}
        />
      </CardContent>
    </Card>,
    <Card key={"Pending Tasks"}>
      <CardHeader title="Your Tasks" />
      <CardContent>
        <VATaskList
          bulkActionButtons={false}
          basePath="content/vassistant_task"
          hasCreate={false}
          hasEdit={true}
          hasList={true}
          hasShow={true}
          resource="content/vassistant_task"
          permissions={permissions}
          vassistant={vAssistant}
          withAside={false}
        />
      </CardContent>
    </Card>,
  ];
};

const VAssistantType = (props) => ({
  id: props.id,
  username: props.username,
  rate: props.rate,
  currency: props.currency,
  email: props.email,
});
