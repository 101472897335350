import React from "react";
import Edit from "./Edit";

import {
  List as RAList,
  Datagrid,
  ListProps,
  TextField,
  TopToolbar,
  ExportButton,
  useResourceContext,
  CreateButton,
  useRefresh,
  FilterButton,
  downloadCSV,
  useNotify,
} from "react-admin";

import jsonexport from "jsonexport/dist";

import EditableTextField from "../../reusable/fields/EditableTextField";
import filters from "./Filters";
import Actions from "./components/Actions";
import Dialog from "@material-ui/core/Dialog";
import Create from "./Create";
import EditableNetworkReferenceField from "../network/fields/EditableNetworkReferenceField";
import EditableReferenceArrayField from "../../reusable/fields/EditableReferenceArrayField";
import { RESOURCE } from "../../constants";
import { todayISOString } from "../../reusable";
import { IconButton, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import { SalesUserReferenceField } from "../salesUser/field";
import Button from "@material-ui/core/Button";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";

import Icon from "@material-ui/core/Icon";
import SaveIcon from "@material-ui/icons/Save";
import { post } from "../../utils";

const useStyles = makeStyles((theme) => ({
  iconButton: {
    margin: 0,
    padding: 0,
  },
  cancelSvg: {
    color: "red",
    width: "1.2rem",
    height: "1.2rem",
  },
  pendingSvg: {
    color: "orange",
    width: "1.2rem",
    height: "1.2rem",
  },
  activeSvg: {
    color: "green",
    width: "1.2rem",
    height: "1.2rem",
  },
  button: {
    minWidth: "10rem",
    marginLeft: ".2rem",
  },
}));

const ScheduledExport = () => {
  const classes = useStyles();
  const notify = useNotify();

  const sendDatatoBackend = async () => {
    const currentURL = window.location.hash;
    const splitedURL = currentURL.split("#")[1];
    const searchParams = splitedURL.split("?") ? splitedURL.split("?")[1] : "";
    const urlSearchParams = new URLSearchParams(searchParams);
    const filters = urlSearchParams.get("filter");
    let JSONObject = {};
    if (filters) {
      JSONObject = JSON.parse(filters);
    }

    try {
      await post("/api/sales/brands/export/", JSONObject);
      notify(
        "Exporting Brand Data, Please check your mail in few minutes",
        "info",
      );
    } catch (error) {
      console.error("Error:", error);
      notify("Internal Server Error", "error");
    }
  };

  return (
    <Tooltip title="Export large amount of Data">
      <div>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          startIcon={<CloudDownloadIcon />}
          onClick={() => sendDatatoBackend()}
        >
          Export All
        </Button>
      </div>
    </Tooltip>
  );
};

const Toolbar = () => {
  const resource = useResourceContext();
  const refresh = useRefresh();
  const [show, setShow] = React.useState<string>("");

  const handleSuccess = React.useCallback(() => {
    refresh();
    setShow("");
  }, [refresh, setShow]);
  const setHandleClick = React.useCallback(
    (what: string) => (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      setShow(what);
    },
    [setShow],
  );
  return (
    <>
      <TopToolbar>
        <FilterButton />

        <CreateButton onClick={setHandleClick("create")} />
        <ExportButton />
        <ScheduledExport />
      </TopToolbar>
      <Dialog
        fullWidth
        // @ts-ignore - probably a MUI bug, it works fine
        maxWidth={"90%"}
        onClose={setHandleClick("")}
        open={show === "create"}
      >
        <Create
          resource={resource}
          basePath={resource}
          onSuccess={handleSuccess}
        />
      </Dialog>
    </>
  );
};

export const isClosed = (record: any) => {
  return record.data.hasOwnProperty("status")
    ? record.data.status.closed
    : false;
};

const DealStatus = (props) => {
  const classes = useStyles();
  const { record } = props;
  if (!record) return null;
  if (record.data && record.data.status && record.data.status.closed) {
    return (
      <Tooltip title="The Brand is closed">
        <IconButton
          className={classes.iconButton}
          size="medium"
          aria-label="The Brand is closed"
        >
          <CancelIcon className={classes.cancelSvg} />
        </IconButton>
      </Tooltip>
    );
  }
  if (
    record.data &&
    record.data.deal &&
    (record.data.deal === "Revenue Share" ||
      record.data.deal === "CPA" ||
      record.data.deal === "CPL" ||
      record.data.deal === "Hybrid")
  ) {
    return (
      <Tooltip title="The brand is Active">
        <IconButton
          className={classes.iconButton}
          size="medium"
          aria-label="The brand is Active"
        >
          <CheckCircleIcon className={classes.activeSvg} />
        </IconButton>
      </Tooltip>
    );
  }
  return (
    <Tooltip title="Sales team discussing brand">
      <IconButton
        className={classes.iconButton}
        size="medium"
        aria-label="Sales team discussing brand"
      >
        <SupervisedUserCircleIcon className={classes.pendingSvg} />
      </IconButton>
    </Tooltip>
  );
};

export const List = (props: ListProps): React.ReactElement => {
  return (
    <RAList
      {...props}
      filters={filters}
      actions={<Toolbar />}
      exporter={exporter}
      sort={{ field: "created", order: "DESC" }}
    >
      <Datagrid
        rowStyle={(record) => ({
          backgroundColor: isClosed(record) ? "#FDE6E6" : "white",
        })}
        expand={<Edit />}
      >
        <EditableNetworkReferenceField
          partialUpdate
          source={"network"}
          label={"Network"}
        />
        <EditableTextField partialUpdate source={"name"} label={"Brand"} />
        <SalesUserReferenceField source={"owner"} label={"Owner"} />
        <EditableTextField
          label={"Email"}
          source={"data.contact.0.value"}
          additionalData={{
            "data.contact.0.field": "email",
          }}
        />
        <EditableTextField
          label={"Skype"}
          source={"data.contact.1.value"}
          additionalData={{
            "data.contact.1.field": "skype",
          }}
        />
        <EditableReferenceArrayField
          resource={RESOURCE.website}
          reference={RESOURCE.website}
          inputProps={{
            optionText: "domain",
          }}
          fieldProps={{
            source: "domain",
          }}
          emptyValue={(record) => record?.data?.backlink || "Click to edit..."}
          label={"Backlink on"}
          source={"data.backlinks"}
        />
        <TextField
          label={"With deal"}
          source={"data.deal"}
          emptyText={"No Deal"}
        />
        <TextField label={"Notes"} source={"data.notes"} emptyText={""} />
        <DealStatus props={props} label="Status" />
        <Actions />
      </Datagrid>
    </RAList>
  );
};

const exporter = async (stats: any, fetchRelatedRecords: any) => {
  const [network, website] = await Promise.all([
    fetchRelatedRecords(stats, "network", "sales/networks"),
    fetchRelatedRecords(stats, "website", RESOURCE.website),
  ]);

  const data = stats.map((entry: any) => {
    return {
      Name: entry.name,
      Email: entry.data?.contact?.[0]?.value,
      Skype: entry.data?.contact?.[1]?.value,
      Network: network[entry.network]?.name,
      Website: website[entry.website]?.domain,
      BackLink: entry.data?.backlink,
      "With Deal": entry.data.deal ? entry.data.deal : "No Deal",
    };
  });

  jsonexport(
    data,
    {
      headers: [
        "Name",
        "Network",
        "Email",
        "Skype",
        "Website",
        "Backlink",
        "With Deal",
      ],
    },
    (err, csv) =>
      !err
        ? downloadCSV(csv, `sales-brand.${todayISOString()}`)
        : console.error(err),
  );
};

export default List;
