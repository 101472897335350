import React from "react";
import {
  downloadCSV,
  List,
  Datagrid,
  TextField,
  Filter,
  Pagination,
} from "react-admin";

import { RESOURCE, API_URL } from "../../constants";
import jsonExport from "jsonexport/dist";

import { httpClient } from "../../utils";
import { makeStyles } from "@material-ui/core/styles";

import { DateRangeDialog, todayISOString, dayFromToday } from "../../reusable";
import Chip from "@material-ui/core/Chip";

const useStyles = makeStyles((theme) => ({
  footer: {
    display: "flex",
    justifyContent: "space-between",
    "&>p": {
      paddingLeft: ".5rem",
      fontSize: ".7rem",
      "& b": {
        paddingRight: ".5rem",
      },
    },
  },
}));

const Filters = (props) => (
  <Filter {...props}>
    <DateRangeDialog
      label={"Order Created"}
      sourceBefore={"before"}
      sourceAfter={"after"}
      alwaysOn
      resettable
    />
  </Filter>
);

const NewPagination = (props) => {
  const classes = useStyles();
  const [stats, setStats] = React.useState({
    total_spent_content: 0,
  });
  const { resource, filterValues } = props;
  React.useEffect(() => {
    const fetchData = async () => {
      const params = new URLSearchParams(filterValues);
      const url = `${API_URL}/${resource}/totals/?${params}`;

      const result = await httpClient(url);
      setStats(result.json);
    };
    fetchData();
  }, [filterValues, setStats, resource]);
  return (
    <span className={classes.footer}>
      <p>
        <span>
          Total spent:{" "}
          <Chip
            color="primary"
            label={`${stats.total_spent_content || "-"} EUR`}
          />
        </span>
      </p>
      <Pagination {...props} />
    </span>
  );
};

export const YoutubeOrderStats = ({ ...props }) => (
  <List
    {...props}
    title={" "}
    pagination={<NewPagination />}
    basePath={RESOURCE.YoutubeOrderStats}
    resource={RESOURCE.YoutubeOrderStats}
    exporter={exporter}
    syncWithLocation={false}
    filters={<Filters />}
    filterDefaultValues={{
      after: dayFromToday(30),
    }}
    // exporter={exporter}
    sort={{ field: "order_placed", order: "DESC" }}
  >
    <Datagrid {...props}>
      <TextField source="channel_name" />
      <TextField source="order_placed" label="Orders" />
      <TextField source="content_spent" label="Content" />
      <TextField source="voice_over_spent" label="Voice Over" />
      <TextField source="editing_spent" label="Editing" />
      <TextField source="design_spent" label="Design" />
      <TextField source="other_spent" label="Other" />
      <TextField source="total_spent" label="Total Spent" />
    </Datagrid>
  </List>
);

const exporter = async (stats, fetchRelatedRecords) => {
  const data = stats.map((entry) => ({
    "Channel Name": entry.channel_name,
    "Order Placed": entry.order_placed,
    "Spent on Content": entry.content_spent,
    "Spent on Voice Over": entry.voice_over_spent,
    "Spent on Editing": entry.editing_spent,
    "Spent on Design": entry.design_spent,
    "Spent on Other": entry.other_spent,
    "Total Spent": entry.total_spent,
  }));

  jsonExport(
    data,
    {
      headers: [
        "Channel Name",
        "Order Placed",
        "Spent on Content",
        "Spent on Voice Over",
        "Spent on Editing",
        "Spent on Design",
        "Spent on Other",
        "Total Spent",
      ],
    },
    (err, csv) =>
      !err
        ? downloadCSV(csv, `youtube-stats-export.${todayISOString()}`)
        : console.error(err),
  );
};
