import * as React from "react";
import { Edit, SimpleForm } from "react-admin";

import { Form } from "./Form";

export const TemplateEdit = (props) => {
  return (
    <Edit {...props} mutationMode="optimistic">
      <SimpleForm>
        <Form />
      </SimpleForm>
    </Edit>
  );
};
