import { useState } from "react";
import { ReferenceArrayInput, AutocompleteArrayInput } from "react-admin";

import { RESOURCE } from "../../constants";

export const UserReferenceArrayInput = ({
  source,
  label,
  helperText,
  ...props
}) => {
  const [clicked, setClicked] = useState(false);

  return (
    <span onClick={() => setClicked(true)}>
      <ReferenceArrayInput
        source={source ? source : "created_by"}
        label={label ? label : "user"}
        reference={RESOURCE.user}
        filterToQuery={(searchText) => ({ full_name: searchText })}
        enableGetChoices={({ q }) => {
          return clicked || (q ? q.length > 1 : false);
        }}
        allowEmpty={false}
        {...props}
      >
        <AutocompleteArrayInput
          alwaysOn={false}
          allowEmpty={false}
          optionText="full_name"
          helperText={helperText}
          optionValue="id"
        />
      </ReferenceArrayInput>
    </span>
  );
};

UserReferenceArrayInput.defaultProps = {
  label: "User",
  addLabel: true,
};
