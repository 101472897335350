import { ReferenceInput, AutocompleteInput } from "react-admin";
import { FC } from "react";
import { RESOURCE } from "../constants";

export const SalesUserReferenceInput: FC<any> = (props) => {
  return (
    <ReferenceInput
      // enableGetChoices={({ q }) => {
      //   return q ? q.length > 1 : false;
      // }}
      reference={RESOURCE}
      {...props}
    >
      <AutocompleteInput
        optionText={"display_name"}
        onSelect={(e) => console.log(e)}
      />
    </ReferenceInput>
  );
};
export default SalesUserReferenceInput;
