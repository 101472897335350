import { useDispatch } from "react-redux";

import Button from "@material-ui/core/Button";

import { useRedirect, useNotify, fetchStart, fetchEnd } from "react-admin";

import { ACTIONS, STATUS } from "../../constants";
import { httpClient } from "../../utils";
import CheckIcon from "@material-ui/icons/Check";
import * as React from "react";

/**
 * Clicked when publishing is done.
 * @param {*} record
 * @returns
 */
export const MarkAsPublished = ({ id, label, ...buttonProps }) => {
  const [loading, setLoading] = React.useState(false);
  const redirect = useRedirect();
  const dispatch = useDispatch();
  const notify = useNotify();
  const markAsPublished = () => {
    dispatch(fetchStart()); // start the global loading indicator
    setLoading(true);
    httpClient(ACTIONS.content.published(id), { method: "POST" })
      .then(
        () => {
          redirect("/");
          notify(`Page ${id} set as published, thanks!`);
        },
        (reason) => {
          if (reason?.status === 412) {
            notify(
              "Wrong status for the page, " +
                `expected ${reason.body?.expected} found ${reason.body?.found}`,
              "error",
            );
          } else {
            notify("Unknown Error", "error");
          }
        },
      )
      .finally(() => {
        dispatch(fetchEnd()); // stop the global loading indicator
        setLoading(false);
      });
  };
  return (
    <Button {...buttonProps} disabled={loading} onClick={markAsPublished}>
      {loading ? "Publishing Content ..." : label}
    </Button>
  );
};

/**
 * Mark as published button, active only if the record is either active or sent to an integrator.
 * @param record
 * @returns {JSX.Element}
 * @constructor
 */
export const MarkAsPublishedButton = ({ content, ...props }) => {
  return (
    <MarkAsPublished
      variant="contained"
      color="secondary"
      label={"Mark as published"}
      endIcon={<CheckIcon />}
      id={content.id}
      disabled={![STATUS.approved, STATUS.integration].includes(content.status)}
    />
  );
};

export default MarkAsPublished;
