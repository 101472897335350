import * as React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import CheckIcon from "@material-ui/icons/Check";
import { useNotify, fetchStart, fetchEnd, useRedirect } from "react-admin";

import { API_URL } from "../../constants";
import { httpClient } from "../../utils";
import { VAREVIEWED } from "../../constants";
import { Dialog, DialogContent, TextField } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContentText from "@material-ui/core/DialogContentText";

/**
 * Accepts the content
 * @param {*} param0
 */
export const VAssistantApproveButton = ({ basePath, record, disabled }) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [hours, setHours] = useState(0);

  const [handleClose, handleOpen] = [
    () => {
      setOpen(false);
    },
    () => {
      setOpen(true);
    },
  ];

  const dispatch = useDispatch();
  const notify = useNotify();
  const redirect = useRedirect();
  if (record.status === VAREVIEWED) return null;

  const handleClick = () => {
    const path = `${API_URL}${basePath}/${record.id}/va_submit/`;
    setLoading(true);
    dispatch(fetchStart()); // start the global loading indicator
    httpClient(path, {
      method: "POST",
      body: JSON.stringify({
        hours: hours,
      }),
    })
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json;
      })
      .catch((e) => {
        notify(`Error: ${e}`, "warning");
        return Promise.reject();
      })
      .then(({ status, valid }) => {
        redirect("/");
        notify(`Content ${record.id} submitted to PM`);
      })
      .finally(() => {
        setLoading(false);
        dispatch(fetchEnd());
      });
  };
  return (
    <span>
      <Button
        id="vassistant-accept-btn"
        startIcon={<CheckIcon />}
        disabled={disabled || loading}
        onClick={handleOpen}
        color="primary"
        variant="contained"
      >
        {"Accept"}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Approve</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You can write how much hours you have worked on this Content
          </DialogContentText>
          <TextField
            fullWidth
            type="number"
            value={hours}
            id="hours"
            label="Write how many hours you have workd on this content."
            onChange={(e) => setHours(e.target.value)}
          />
          <DialogActions>
            <Button
              id="pr-accept-final-btn"
              startIcon={<CheckIcon />}
              onClick={handleClick}
              color="primary"
              variant="contained"
            >
              {"Accept"}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </span>
  );
};
