import React from "react";
import { RESOURCE } from "../../constants";
import { todayISOString } from "../../reusable";

import {
  List as RAList,
  Datagrid,
  ListProps,
  TextField,
  downloadCSV,
  TopToolbar,
  FilterButton,
  CreateButton,
  ExportButton,
  useResourceContext,
  useRefresh,
} from "react-admin";

import jsonexport from "jsonexport/dist";
import { Dialog } from "@material-ui/core";
import Create from "./Create";
import { UserReferenceField } from "../../user";
import { YoutubeReferenceField } from "../fields/YoutubeReferenceField";
import { YoutubeReferenceInput } from "../inputs";

const filters = [
  <YoutubeReferenceInput
    alwaysOn
    resettable
    emptyText={"All"}
    source="youtube_channel"
  />,
];

const Toolbar = () => {
  const resource = useResourceContext();
  const refresh = useRefresh();
  const [show, setShow] = React.useState<string>("");

  const handleSuccess = React.useCallback(() => {
    refresh();
    setShow("");
  }, [refresh, setShow]);
  const setHandleClick = React.useCallback(
    (what: string) => (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      setShow(what);
    },
    [setShow],
  );
  return (
    <>
      <TopToolbar>
        <FilterButton />

        <CreateButton onClick={setHandleClick("create")} />
        <ExportButton />
      </TopToolbar>
      <Dialog
        fullWidth
        // @ts-ignore - probably a MUI bug, it works fine
        maxWidth={"90%"}
        onClose={setHandleClick("")}
        open={show === "create"}
      >
        <Create
          resource={resource}
          basePath={resource}
          onSuccess={handleSuccess}
        />
      </Dialog>
    </>
  );
};

export const List = (props: ListProps): React.ReactElement => {
  return (
    <RAList
      filters={filters}
      actions={<Toolbar />}
      perPage={25}
      sort={{ field: "date_created", order: "DESC" }}
      {...props}
    >
      <Datagrid rowClick="edit">
        <TextField source="id" label="Id" />
        <TextField source="title" label="Title" />
        <YoutubeReferenceField
          source="youtube_channel"
          label="Youtube Channel"
        />
        <UserReferenceField source="created_by" label="Created By" />
      </Datagrid>
    </RAList>
  );
};

export default List;
