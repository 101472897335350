import {
  AutocompleteInput,
  AutocompleteInputProps,
  ReferenceField,
  ReferenceFieldProps,
  ReferenceInput,
  TextField,
  TextFieldProps,
  useRecordContext,
  useResourceContext,
  useUpdate,
  useCreate,
} from "react-admin";
import { Form } from "react-final-form";

import React from "react";
// @ts-ignore
import get from "lodash/get";
// @ts-ignore
import set from "lodash/set";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import IconButton, { IconButtonProps } from "@material-ui/core/IconButton";

const useStyles = makeStyles((theme) => {
  return {
    field: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    deactivated: {
      ...theme.typography.caption,
      color: theme.palette.grey[500],
      opacity: 0.5,
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  };
});

export interface EditableReferenceFieldProps extends ReferenceFieldProps {
  buttons?: React.ReactElement[];
  // source used for the TextInput
  showSource?: string;

  // used for the Autocomplete
  fieldProps: TextFieldProps;
  inputProps: AutocompleteInputProps;
  optionText?: string;
  helperText?: string;
}

export const EditableReferenceField = (props: any): React.ReactElement => {
  const { source, label, reference, sort } = props;
  const { fieldProps, inputProps, buttons, createName, ...rest } = props;
  const classes = useStyles();
  const record = useRecordContext();
  const resource = useResourceContext();
  const [update] = useUpdate();
  const [updateValue, setUpdateValue] = React.useState(false);
  const value = get(record, source);
  const [create, { loaded, data, error, loading }] = useCreate();

  const handleCreate = React.useCallback(
    (name) => {
      create(reference, { [createName]: name });
      return null;
    },
    [create, createName],
  );

  const handleChange = React.useCallback(
    (e) => {
      set(record, source, e);
      update(resource, record.id, record);
      setUpdateValue(false);
    },
    [update, record, resource, source, setUpdateValue],
  );

  React.useEffect(() => {
    if (loaded && data?.id) {
      handleChange(data.id);
    }
  }, [handleChange, loaded, data, error]);

  if (loading) {
    return <span>'One moment, loading...'</span>;
  }
  if (updateValue) {
    return (
      <Form onSubmit={(e) => console.info("submit", e)}>
        {() => (
          <ReferenceInput
            source={source}
            label={label}
            reference={reference}
            sort={sort}
            {...rest}
            onBlur={() => setUpdateValue(false)}
            onFocusOut={() => setUpdateValue(false)}
            onChange={handleChange}
            fullWidth
            className={classes.field}
            enableGetChoices={({ q }) => {
              return q ? q.length > 0 : false;
            }}
            resettable
          >
            <AutocompleteInput
              optionValue="id"
              className={classes.field}
              onCreate={createName ? handleCreate : undefined}
              createValue={""}
              {...inputProps}
            />
          </ReferenceInput>
        )}
      </Form>
    );
  } else if (!value) {
    return (
      <span
        className={classes.deactivated}
        onClick={() => setUpdateValue(true)}
      >
        Click to edit...
      </span>
    );
  } else {
    return (
      <ReferenceField
        label={label}
        source={source}
        {...rest}
        reference={reference}
      >
        <EditButtonTextField
          iconButtonProps={{
            onClick: (e) => {
              e.preventDefault();
              setUpdateValue(true);
            },
          }}
          className={classes.field}
          buttons={buttons}
          {...fieldProps}
        />
      </ReferenceField>
    );
  }
};

interface EditButtonTextFieldProps extends TextFieldProps {
  iconButtonProps: IconButtonProps;
  buttons?: React.ReactElement[];
  field?: React.ReactElement;
}

const EditButtonTextField: React.FC<EditButtonTextFieldProps> = (props) => {
  const { iconButtonProps, buttons, className, field, ...rest } = props;
  return (
    <span className={className}>
      {field ? field : <TextField {...rest} />}
      <span>
        <IconButton {...iconButtonProps}>
          <EditIcon />
        </IconButton>
        {buttons}
      </span>
    </span>
  );
};
