import * as React from "react";
import { useState } from "react";
import { useLogin, useNotify, Notification } from "react-admin";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { Container, Paper, TextField, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
  paper: {
    padding: theme.spacing(3),
    width: "100%",
    maxWidth: 400,
  },
  form: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  googleLogin: {
    marginTop: theme.spacing(2),
  },
  logo: {
    height: "16rem",
    display: "block",
    margin: "0 auto",
    marginBottom: theme.spacing(2),
  },
}));

const CustomLoginPage = ({ theme }) => {
  const classes = useStyles();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const login = useLogin();
  const notify = useNotify();

  const submit = (e) => {
    e.preventDefault();
    login({ login_type: "basic", username, password }).catch(() =>
      notify("Invalid email or password"),
    );
  };

  const handleGoogleLogin = (credentialResponse) => {
    login({ login_type: "google", ...credentialResponse }).catch(() =>
      notify("Invalid email or password"),
    );
  };

  const handleGoogleLoginFailure = (response) => {
    console.log(response);
    notify("Google Login Failed");
  };

  return (
    <Container className={classes.container} maxWidth="sm">
      <Paper className={classes.paper}>
        <img
          src={process.env.PUBLIC_URL + "/logo.png"}
          className={classes.logo}
          alt="GTM Logo"
        />

        <form
          className={classes.form}
          onSubmit={submit}
          noValidate
          autoComplete="off"
        >
          <TextField
            onChange={(e) => setUsername(e.target.value)}
            fullWidth
            type="text"
            id="username"
            label="Username"
            name="username"
            variant="outlined"
          />

          <TextField
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            type="password"
            id="password"
            name="password"
            label="Password"
            variant="outlined"
          />
          <Button type="submit" fullWidth variant="contained" color="primary">
            Sign IN
          </Button>
          <GoogleOAuthProvider
            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
          >
            <GoogleLogin
              className={classes.googleLogin}
              fullWidth
              onSuccess={handleGoogleLogin}
              onError={handleGoogleLoginFailure}
            />
          </GoogleOAuthProvider>
        </form>
        <Notification />
      </Paper>
    </Container>
  );
};

export default CustomLoginPage;
