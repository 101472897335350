import { ReferenceInput, AutocompleteInput, useRefresh } from "react-admin";

import { RESOURCE } from "../../../constants";
import { useFormState } from "react-final-form";

export const ContentTemplateReferenceInput = ({
  source,
  label,
  helperText,
  fullWidth,
  ...props
}) => {
  const refresh = useRefresh();
  return (
    <ReferenceInput
      key={"reference"}
      source={source ? source : "template"}
      label={label ? label : "Template"}
      reference={RESOURCE.contentTemplate}
      sortBy={"domain"}
      enableGetChoices={({ q }) => {
        return q ? q.length > 2 : false;
      }}
      onChange={(val) => {
        if (!val) {
          refresh();
        }
      }}
      {...props}
    >
      <Input
        optionText={(record) =>
          record
            ? `${record.full_url} for ${
                record.author
              } on ${record.date_created.substring(0, 10)}`
            : "-"
        }
        optionValue="id"
        helperText={helperText}
        fullWidth={fullWidth}
      />
    </ReferenceInput>
  );
};

const Input = (props) => {
  const { input, choices } = props;
  const { values, modified } = useFormState();
  if (!modified["other.template"]) return <AutocompleteInput {...props} />;
  const value = choices
    .filter((choice) => !!choice)
    .filter((choice) => choice.id === input.value)[0];

  if (value) {
    // set the `writer_rate` to null
    values.writer_rate = null;
    // set the writer notes to ""
    if (value.structure.writer_notes) {
      value.structure.writer_notes = "";
    }
    if (value.structure.writer_justification) {
      value.structure.writer_justification = "";
    }
    if (value.flagData) {
      value.flagData = null;
    }
    const templateData = value.structure.templateData || {};
    values.page = {
      website: value.website,
      // paths: value.path,
      keywords: templateData.keywords ? templateData.keywords : value.keywords,
    };
    values.parent = {
      path: value?.parent_path,
      keyword: value?.parent_keyword,
    };

    values.content = value;
    values.content.pbn_content_title = "";
    values.content.id = undefined;
    // values.template = value;
    values.writer = value.writer;
    values.content.structure.instructions = templateData.instructions;
    // set the pbn_content_title to empty string
    values.content.pbn_content_title = "";
    const structuredContent = {
      structuredContent: value.structure.structuredContentTemplate
        ? value.structure.structuredContentTemplate
        : [
            {
              title: "Main Content",
              content: value.content,
              words: value.requested_words,
              position: 100,
            },
          ],
    };
    values.content.structure = {
      ...(values.content.structure || {}),
      ...structuredContent,
    };
  }
  return <AutocompleteInput {...props} />;
};

ContentTemplateReferenceInput.defaultProps = {
  label: "Template",
  addLabel: true,
};
