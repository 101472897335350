import * as React from "react";
import { Card as MuiCard, CardContent, withStyles } from "@material-ui/core";

import {
  FilterList,
  FilterListItem,
  useGetList,
  Loading,
} from "react-admin";

import { WEBSITE_TYPES, RESOURCE } from "../../constants";

const Card = withStyles((theme) => ({
  root: {
    [theme.breakpoints.up("sm")]: {
      order: -1, // display on the left rather than on the right of the list
      width: "15em",
      marginRight: "1em",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}))(MuiCard);

export const WebsiteFilterList = () => {
  return (
    <Card>
      <CardContent>
        <WebsiteTypes />
        <Verticals />
      </CardContent>
    </Card>
  );
};

const WebsiteTypes = () => (
  <FilterList label="Type">
    {Object.keys(WEBSITE_TYPES).map((key) => {
      const website = WEBSITE_TYPES[key];
      return (
        <FilterListItem
          label={website.label}
          key={key}
          value={{
            type: website.code,
          }}
        />
      );
    })}
  </FilterList>
);

const Verticals = () => {
  const { data, loading, error } = useGetList(
    RESOURCE.vertical,
    { page: 1, perPage: 30 },
    { field: "id", order: "ASC" },
    {}
  );
  if (error) {
    return <p>ERROR</p>;
  }
  if (loading) {
    return <Loading />;
  }
  return (
    <FilterList label="Vertical">
      {Object.values(data).map((vertical) => (
        <FilterListItem
          key={vertical.id}
          label={vertical.name}
          value={{
            vertical: vertical.id,
          }}
        />
      ))}
    </FilterList>
  );
};
