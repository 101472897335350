import { Error } from "react-admin";

import { ContentList } from "../ContentList";

/**
 * Render the list of content for the writer.
 * @param {*} writer
 * @param {string} status
 * @returns
 */
export const EditorContentList = ({ userId, status, ...props }) => {
  const filter = {};
  if (status !== undefined) filter.status = status;
  if (userId === undefined) return <Error error={"No editor found!"} />;
  return <ContentList filter={filter} hasWriter {...props} />;
};
