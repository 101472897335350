import * as React from "react";
import { useDispatch } from "react-redux";

import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Button from "@material-ui/core/Button";

import {
  useNotify,
  fetchStart,
  fetchEnd,
  useRedirect,
  useRefresh,
} from "react-admin";

import { API_URL, RESOURCE } from "../../constants";
import { httpClient } from "../../utils";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "0 px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const MODAL_TEXT = {
  title: "Did you make the Proofreading?",
  subheader: "You will not be able to edit the content any further.",
};

export const MarkAsReadyButton = ({
  basePath,
  record,
  disabled,
  pristine,
  ...props
}) => {
  /**
   * Button for the mark as ready feature.
   */
  return [
    record.copyscape_hit_count > 1 ? null : (
      <AreYouSureButton
        key={"Did You make the Proofreading"}
        disabled={disabled || record.copyscape_hit_count > 1}
        label={
          record.copyscape_hit_count <= 1
            ? "Mark as ready for review"
            : "Sorry you can't check the content anymore, please use the send anyway button"
        }
        SureButton={<IAmSureButton id={record.id} basePath={basePath} />}
      />
    ),
    record.copyscape_hit_count < 1 ? null : (
      <SendContentAnyway
        key="SendContentAnyway"
        id={record.id}
        basePath={basePath}
        disabled={!pristine}
        hasCopies={record.copyscape_count !== 0 || record.copyscape_url !== ""}
      />
    ),
  ];
};

const AreYouSureButton = ({ disabled, label, SureButton }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        onClick={handleOpen}
        disabled={disabled}
        color="secondary"
        variant="contained"
      >
        {label}
      </Button>
      <Modal
        aria-labelledby="Are you sure?"
        aria-describedby="Are you Sure?"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Card className={classes.paper}>
            <CardHeader {...MODAL_TEXT} />
            <CardContent>
              {SureButton}
              <Button
                color="secondary"
                label="No"
                variant="contained"
                onClick={handleClose}
              >
                {"No, take me back."}
              </Button>
            </CardContent>
          </Card>
        </Fade>
      </Modal>
    </div>
  );
};

/**
 * Clicked when one is sure.
 * @param {*} record
 * @returns
 */
const IAmSureButton = ({ id, basePath }) => {
  const refresh = useRefresh();
  const redirect = useRedirect();
  const dispatch = useDispatch();
  const notify = useNotify();

  const checkCopyscape = () => {
    notify(
      "Checking for similar content in the internet, it might take some time.",
      "info",
    );
    dispatch(fetchStart()); // start the global loading indicator
    findCopiesRequest(basePath, id)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return Promise.resolve(response);
      })
      .catch((e) => {
        notify(`Error: ${e}`, "warning");
        return Promise.reject();
      })
      .then((response) => {
        if (response.json.copyscape_count > 0) {
          refresh();
          notify("Found similar content on the internet!", "warning");
        } else {
          return readyRequest(basePath, id)
            .catch((e) => {
              notify(`Error: ${e}`, "warning");
              return Promise.reject();
            })
            .then(() => {
              redirect(`${RESOURCE.order}/${id}/show`);
              notify(`Thanks for your submission.`);
            });
        }
      })
      .finally(() => {
        dispatch(fetchEnd()); // stop the global loading indicator
      });
  };
  return (
    <Button onClick={checkCopyscape} color="primary" variant="contained">
      {"Yes, I did the Proofreading"}
    </Button>
  );
};

/**
 *
 * @typedef {Object} SendContentAnywayProps
 * @property {integer} id
 * @property {string} basePath
 * @property {boolean} hasCopies
 * @property {bool} diabled
 */
/**
 * Sends the content anyway.
 *
 * @param {SendContentAnywayProps} props
 * @returns
 */
export const SendContentAnyway = ({ id, basePath, hasCopies, disabled }) => {
  const notify = useNotify();
  const redirect = useRedirect();

  const onClick = () =>
    readyRequest(basePath, id)
      .catch((e) => {
        notify(`Error: ${e}`, "warning");
        return Promise.reject();
      })
      .then(() => {
        redirect(`/${RESOURCE.order}/${id}/show`);
        notify(
          `Your content has been received, within a week our editorial team will evaluate it.`,
        );
      });
  return hasCopies && !disabled ? (
    <Button onClick={onClick} variant="contained">
      {"Submit the content for review."}
    </Button>
  ) : null;
};

/**
 * Request to make the content ready.
 * @param {*} record of type ContentOrder
 * @returns A promise
 */
const readyRequest = (basePath, id) => {
  const path = `${API_URL}${basePath}/${id}/ready/`;
  return httpClient(path, { method: "POST" });
};

/**
 * Request to check the content against copyscape.
 * @param {*} record of type ContentOrder
 * @returns A promise
 */
const findCopiesRequest = (basePath, id) => {
  const path = `${API_URL}${basePath}/${id}/find_copies/`;
  return httpClient(path, { method: "POST" });
};
