import BlockIcon from "@material-ui/icons/Block";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import Tooltip from "@material-ui/core/Tooltip";
import {
  useNotify,
  useRecordContext,
  useRefresh,
  useResourceContext,
} from "react-admin";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import React, { useEffect } from "react";
import { get, post } from "../../../utils";
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
  MenuItem,
  Select,
} from "@material-ui/core";

import SendOutlinedIcon from "@material-ui/icons/SendOutlined";
import { GridCloseIcon } from "@mui/x-data-grid";

import { API_URL, RESOURCE } from "../constants";
import { makeStyles } from "@material-ui/core/styles";
import { isClosed } from "../List";

const CLOSE_REASONS = [
  "Casino Closed",
  "No Contact",
  "Bad History",
  "Bad Deal",
  "Partnership Ended",
];

const CLOSE_REASON_CHOICES = CLOSE_REASONS.map(
  (reason) => ({
    id: reason,
    name: reason,
  }),
  {},
);

const useStyles = makeStyles((theme) => {
  return {
    open: {
      color: theme.palette.success.dark,
    },
    close: {
      color: theme.palette.error.dark,
    },
  };
});

/**
 * This component will show one of two buttons,
 * It will have the button to close the brand or the
 * option to reopen if the brand is closed.
 */
export const BrandStatus = () => {
  const classes = useStyles();
  const [dialogOpen, openDialog] = React.useState<boolean>(false);
  const [reason, setReason] = React.useState<string>(CLOSE_REASONS[0]);
  const notify = useNotify();
  const refresh = useRefresh();

  const record = useRecordContext();

  const handleReasonChange = React.useCallback(
    (e: React.ChangeEvent<{ value: unknown }>) =>
      setReason(e.target.value as string),
    [setReason],
  );
  const handleOpen = React.useCallback(() => {
    openDialog(true);
  }, [openDialog]);
  const handleClose = React.useCallback(() => {
    openDialog(false);
  }, [openDialog]);

  const handleStatus = React.useCallback(
    async (status) => {
      await post(
        `${API_URL}/${RESOURCE}/${record.id}/toggle_brand_status/`,
        {
          closed: status,
          reason: status ? reason : null,
        },
        true,
      );
      refresh();
      notify("Brand Updated Successfully");
    },
    [record.id, notify, refresh, reason],
  );

  return (
    <>
      {isClosed(record) ? (
        <>
          <Tooltip title={"Reopen Brand"}>
            <Button
              className={classes.open}
              onClick={() => handleStatus(false)}
              color={"primary"}
              startIcon={<CheckCircleOutlineIcon />}
            >
              Re Open
            </Button>
          </Tooltip>
        </>
      ) : (
        <>
          <Tooltip title={"Close Brand"}>
            <Button
              className={classes.close}
              onClick={handleOpen}
              color={"primary"}
              startIcon={<BlockIcon />}
            >
              Close
            </Button>
          </Tooltip>
          <Dialog
            open={dialogOpen}
            onClose={handleClose}
            fullWidth
            maxWidth={"md"}
          >
            <DialogTitle>Choose a Reason to close the Brand</DialogTitle>
            <DialogContent>
              <Select
                label={"Reason"}
                value={reason}
                onChange={handleReasonChange}
                input={<Input />}
                required
              >
                {CLOSE_REASON_CHOICES.map(({ id, name }) => (
                  <MenuItem key={`#${id}`} value={id}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </DialogContent>
            <DialogActions>
              <Button startIcon={<GridCloseIcon />} onClick={handleClose}>
                Cancel
              </Button>
              <Button
                startIcon={<SendOutlinedIcon />}
                onClick={() => handleStatus(true)}
              >
                Close Brand
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
};

export default BrandStatus;
