import { SelectInput } from "react-admin";

export const filters = [
  <SelectInput
    label="Seen"
    source={"seen"}
    emptyValue=""
    resettable
    choices={[
      { id: "", name: "All" },
      { id: "1", name: "Seen" },
      { id: "2", name: "Not Seen" },
    ]}
    alwaysOn
    allowEmpty={false}
  />,
];

export default filters;
