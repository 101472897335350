import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import {
  Datagrid,
  DateField,
  List,
  Loading,
  ShowButton,
  TextField,
} from "react-admin";
import { RESOURCE } from "../../constants";

const useStyles = makeStyles((theme) => ({
  div: {
    padding: "1rem",
    whiteSpace: "pre-wrap",
  },
}));

export const NotificationsList = ({ hasShow = false, ...props }) => {
  const classes = useStyles();
  const { permissions } = props;

  if (!permissions) {
    return <Loading />;
  }

  const MessageField = (props) => {
    const {
      record: { data },
    } = props;

    const element = (
      <TextField {...props} source={"data.message"} label={"Message"} />
    );

    if (data.link) {
      return (
        <a href={data.link} target={"_blank"} rel={"noreferrer nofollow"}>
          {element}
        </a>
      );
    }
    return element;
  };

  const MessageDetails = ({ id, record, resource }) => (
    <Typography component="div">
      <Box
        fontsize="h4.fontsize"
        className={classes.div}
        dangerouslySetInnerHTML={{ __html: record.message }}
      ></Box>
    </Typography>
  );
  return (
    <List
      basePath={RESOURCE.notifications.feedback}
      resource={RESOURCE.notifications.feedback}
      title={" "}
      syncWithLocation={false}
      {...props}
      sort={{ field: "date_created", order: "DESC" }}
    >
      <Datagrid expand={MessageDetails}>
        <MessageField label="Message" />
        <DateField source="date_created" />
      </Datagrid>
    </List>
  );
};
