import React from "react";
import {
  List,
  Datagrid,
  TextField,
  Filter,
  SelectInput,
  downloadCSV,
  Pagination,
} from "react-admin";

import jsonExport from "jsonexport/dist";

import { RESOURCE, API_URL } from "../../constants";

import { httpClient } from "../../utils";
import { makeStyles } from "@material-ui/core/styles";

import {
  DateRangeDialog,
  dayFromToday,
  todayISOString,
  ChipArrayField,
  getLocale,
} from "../../reusable";
import { MarketReferenceInput } from "../../market";
import { VerticalReferenceInput } from "../../vertical";
import { WEBSITE_TYPES } from "../../constants";
import Chip from "@material-ui/core/Chip";

const useStyles = makeStyles((theme) => ({
  footer: {
    display: "flex",
    justifyContent: "space-between",
    "&>p": {
      paddingLeft: ".5rem",
      fontSize: ".7rem",
      "& b": {
        paddingRight: ".5rem",
      },
    },
  },
}));
const Filters = (props) => (
  <Filter {...props}>
    <DateRangeDialog
      label={"Activity Date"}
      sourceBefore={"before"}
      sourceAfter={"after"}
      alwaysOn
      resettable
    />
  </Filter>
);

const NewPagination = (props) => {
  const classes = useStyles();
  const [stats, setStats] = React.useState({
    total_spent_content: 0,
  });
  const { resource, filterValues } = props;
  React.useEffect(() => {
    const fetchData = async () => {
      const params = new URLSearchParams(filterValues);
      const url = `${API_URL}/${resource}/totals/?${params}`;

      const result = await httpClient(url);
      setStats(result.json);
    };
    fetchData();
  }, [filterValues, setStats, resource]);
  return (
    <span className={classes.footer}>
      <p>
        <span>
          Total spent:{" "}
          <Chip
            color="primary"
            label={`${stats.total_spent_content || "-"} EUR`}
          />
        </span>
      </p>
      <Pagination {...props} />
    </span>
  );
};

export const YoutubeStats = ({ ...props }) => (
  <List
    {...props}
    title={" "}
    pagination={<NewPagination />}
    basePath={RESOURCE.YoutubeStats}
    resource={RESOURCE.YoutubeStats}
    syncWithLocation={false}
    filters={<Filters />}
    filterDefaultValues={{
      after: dayFromToday(30),
    }}
    // exporter={exporter}
    sort={{ field: "ordered_content", order: "DESC" }}
  >
    <Datagrid {...props}>
      <TextField source="channel_name" />
      <TextField source="ordered_content" label="Orders" />
      <TextField source="pages_published" label="Published" />
      <TextField source="spent" label="Spent in Content" />
      <TextField source="total_spent" label="Total Spent" />
    </Datagrid>
  </List>
);
