import React from "react";
import {
  Create as RaCreate,
  SimpleForm,
  TextInput,
  CreateProps,
  useNotify,
  useRedirect,
  required,
} from "react-admin";

import { RESOURCE } from "../constants";
import { UserReferenceInput } from "../../user";
import { HorizontalGroup } from "../../reusable";

export const Create = (props: CreateProps): React.ReactElement => {
  const notify = useNotify();
  const redirect = useRedirect();
  return (
    <RaCreate
      onSuccess={() => {
        notify("Youtube Link Created Successfully!");
        redirect(`/${RESOURCE.list}`);
      }}
      {...props}
    >
      <SimpleForm>
        <TextInput source={"channel_name"} label={"Name"} />
        <HorizontalGroup>
          <UserReferenceInput
            source={"owner"}
            label={"Owner"}
            validate={required()}
          />
          <TextInput source={"owner_email"} label={"Email"} />
        </HorizontalGroup>
        <TextInput multiline source={"comment"} label={"Comment"} />
      </SimpleForm>
    </RaCreate>
  );
};

export default Create;
