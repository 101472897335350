import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { InputAdornment } from "@material-ui/core";

import {
  Filter,
  SearchInput,
  AutocompleteInput,
  ReferenceInput,
} from "react-admin";

import { MarketReferenceArrayInput } from "../market";
import { RESOURCE } from "../constants";

export const TopFilter = (props) => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
    <ReferenceInput
      alwaysOn
      label="Referring"
      source="only_domain"
      reference={RESOURCE.website}
    >
      <AutocompleteInput
        optionText="domain"
        allowEmpty
        resettable
        InputProps={{
          startAdornment: (
            <InputAdornment position="end">
              <AddIcon color="primary" />
            </InputAdornment>
          ),
        }}
        helperText={"Must have referred this domain"}
        alwaysOn
      />
    </ReferenceInput>
    <ReferenceInput
      alwaysOn
      label="Not Referring"
      source="exclude_domain"
      reference={RESOURCE.website}
    >
      <AutocompleteInput
        optionText="domain"
        allowEmpty
        resettable
        InputProps={{
          startAdornment: (
            <InputAdornment position="end">
              <RemoveIcon color="error" />
            </InputAdornment>
          ),
        }}
        helperText={"Must NOT have referred this domain"}
        alwaysOn
      />
    </ReferenceInput>
    <MarketReferenceArrayInput addLabel={false} source="markets" />
  </Filter>
);
