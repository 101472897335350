import { useQueryWithStore } from "react-admin";

import { RESOURCE, API_URL } from "../constants";
import { httpClient } from "../utils";
/**
 * get the writer data.
 * @param {number} id
 * @returns
 */

export const usePage = (id, options) => {
  const result = useQueryWithStore(
    {
      type: "getOne",
      resource: RESOURCE.page,
      payload: { id: id },
    },
    options
  );
  return result;
};

export const queryPage = async (payload) => {
  if (!payload) return { data: null, success: false };
  const queryParams = new URLSearchParams(payload).toString();
  if (!queryParams) return { data: null, success: false };
  try {
    const response = await httpClient(
      `${API_URL}/${RESOURCE.page}/get_parent/?${queryParams}`,
      {
        method: "GET",
        payload: payload,
      }
    );
    const data = response.json;
    return { data: data, success: true };
  } catch (e) {
    console.error(e);
    return { data: null, success: false };
  }
};
