import { ReferenceArrayField, ChipField, SingleFieldList } from "react-admin";

import { RESOURCE } from "../../constants";

export const MarketArrayChipField = ({ source, label, ...props }) => (
  <ReferenceArrayField
    label={label ? label : "Market"}
    target={props.target ? props.target : "id"}
    source={source ? source : "markets"}
    reference={RESOURCE.market}
    {...props}
  >
    <SingleFieldList>
      <ChipField source="country" />
    </SingleFieldList>
  </ReferenceArrayField>
);

MarketArrayChipField.defaultProps = {
  label: "Markets",
  addLabel: true,
};
