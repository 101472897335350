import {
  Show as RAShow,
  TextField,
  NumberField,
  ReferenceManyField,
  SimpleShowLayout,
} from "react-admin";

import { RESOURCE } from "../../constants";
import { ProofreaderPaymentDatagrid } from "./PaymentList";

export const Show = (props) => (
  <RAShow {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="username" />
      <NumberField source="total_paid" />
      <NumberField source="pending" />
      <TextField source="currency" />
      <TextField source="payment_instruction" />
      <ReferenceManyField
        label="Payments"
        reference={RESOURCE.financePaymentProofreader}
        target={"freelance"}
        sort={{ field: "date_created", order: "DESC" }}
      >
        <ProofreaderPaymentDatagrid />
      </ReferenceManyField>
    </SimpleShowLayout>
  </RAShow>
);
