import {
  List,
  Datagrid,
  TextField,
  Filter,
  SelectInput,
  downloadCSV,
} from "react-admin";

import jsonExport from "jsonexport/dist";

import { RESOURCE, GROUPS } from "../../constants";

import { WebsiteReferenceInput } from "../../website";

import {
  ChipArrayField,
  DateRangeDialog,
  dayFromToday,
  getLocale,
  todayISOString,
} from "../../reusable";

import { MarketReferenceInput } from "../../market";
import { UserReferenceInput } from "../../user";

const Filters = (props) => (
  <Filter {...props}>
    <DateRangeDialog
      label={"Activity Date"}
      sourceBefore={"before"}
      sourceAfter={"after"}
      alwaysOn
      resettable
    />
    <WebsiteReferenceInput
      allowEmpty={false}
      source="website"
      alwaysOn
      resettable
    />
    <SelectInput
      source="active"
      alwaysOn
      choices={[
        { id: 1, name: "Yes" },
        { id: 0, name: "No" },
      ]}
      emptyText="Any"
    />
    <MarketReferenceInput
      allowEmpty={false}
      source="market"
      alwaysOn
      resettable
    />
    <UserReferenceInput
      label="Writer"
      source={"id"}
      filter={{ groups__name: GROUPS.writers }}
      withFreelances
      allowEmpty={false}
      alwaysOn
      resettable
    />
  </Filter>
);

const exporter = async (stats, fetchRelatedRecords) => {
  const data = stats.map((entry) => ({
    username: entry.username,
    markets: entry.markets.join(", "),
    "Content Ordered": entry.ordered_content,
    "Content Written": entry.written_content,
    "Words Written": entry.written_words,
    "Average time to done": entry.average_duration,
    "Spent (EUR)": entry.earned_amount.toLocaleString(getLocale()) || "0",
  }));
  jsonExport(
    data,
    {
      headers: [
        "username",
        "markets",
        "Content Ordered",
        "Content Written",
        "Words Written",
        "Average time to done",
        "Spent (EUR)",
      ],
    },
    (err, csv) =>
      !err
        ? downloadCSV(csv, `writer-stats.${todayISOString()}`)
        : console.error(err),
  );
};

export const WriterStats = () => (
  <List
    title={" "}
    basePath={RESOURCE.writerStats}
    resource={RESOURCE.writerStats}
    syncWithLocation={false}
    exporter={exporter}
    filters={<Filters />}
    filterDefaultValues={{
      active: 1,
      after: dayFromToday(30),
    }}
    sort={{ field: "written_content", order: "DESC" }}
  >
    <Datagrid>
      <TextField source="id" />
      <TextField source="username" />
      <ChipArrayField source={"markets"} />
      <TextField source="ordered_content" label="Orders" />
      <TextField source="written_content" label="Approved" />
      <TextField source="refused" label="Refusals" />
      <TextField source="written_words" label="Words Written" />
      <TextField source="earned_amount" label="Earned (EUR)" />
    </Datagrid>
  </List>
);
