import React from "react";
import { Resource as RAResource } from "react-admin";
import { RESOURCE, PERMISSIONS } from "./constants";
import WebAssetIcon from "@material-ui/icons/WebAsset";
import { LandingPageCreate } from "./Create";
import { LandingPageEdit } from "./Edit";

import LandingPageList from "./List";

/**
 * For some reason the APP want the resource to be instantiated with this make factory.
 * @param {*} permissions
 * @param {*} key
 * @returns
 */
export const make = (permissions: any): React.ReactElement => {
  const permission = permissions?.permission || [];
  return (
    <RAResource
      name={RESOURCE}
      options={{ label: "Landing Page" }}
      key={RESOURCE}
      list={permission.includes(PERMISSIONS.view) ? LandingPageList : undefined}
      create={
        permission.includes(PERMISSIONS.create) ? LandingPageCreate : undefined
      }
      edit={permission.includes(PERMISSIONS.edit) ? LandingPageEdit : undefined}
      icon={WebAssetIcon}
    />
  );
};

export default make;
