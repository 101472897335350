import React from "react";
import {
  Create as RaCreate,
  CreateProps,
  TextInput,
  SimpleForm,
  DateInput,
} from "react-admin";
import { AdvancedTextInput, HorizontalGroup } from "../../reusable";
import { VAssistantReferenceInput } from "../inputs/VAssistantReferenceInput";

export const Create = (props: CreateProps): React.ReactElement => {
  return (
    <RaCreate {...props}>
      <SimpleForm>
        <HorizontalGroup>
          <TextInput source="task_name" />
          <VAssistantReferenceInput
            required={true}
            helperText={"Select a Virtual Assistant"}
            label={"Virtual Assistant"}
            source="vassistant"
          />
        </HorizontalGroup>
        <HorizontalGroup>
          <DateInput source="task_eta" />
        </HorizontalGroup>
        <AdvancedTextInput
          data-testid="instructions-textinput"
          source="data.instructions"
          label={"Task Description"}
          fullWidth
          multiline
          countWords={false}
        />
      </SimpleForm>
    </RaCreate>
  );
};

export default Create;
