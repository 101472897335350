import * as React from "react";

import {
  BooleanInput,
  Create as RACreate,
  NumberInput,
  SimpleForm,
  TextInput,
  required,
  DateInput,
  maxLength,
  SelectInput,
  FormDataConsumer,
  ArrayInput,
  SimpleFormIterator,
  Loading,
  usePermissions,
  Button,
  useNotify,
  useRedirect,
  useInput,
} from "react-admin";
import { useFormState } from "react-final-form";

import { WebsiteReferenceInput } from "../../website";
import { MarketReferenceInput } from "../../market";
import {
  HorizontalGroup,
  ArrayTextInput,
  CountedTextField,
  AdvancedTextInput,
  getDayFromNow,
} from "../../reusable";
import { Button as MuiButton, Paper, Typography, Box } from "@material-ui/core";

import Switch from "@material-ui/core/Switch";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { WriterReferenceInput } from "../../writer";
import { useStyles } from "../../useStyles";
import {
  CONTENT_TYPE_CHOICES,
  CONTENT_PRIORITY_CHOICES,
  GROUPS,
  CONTENT_PRIORITY,
} from "../../constants";
import { ContentTemplateReferenceInput } from "../template/";
import { queryPage } from "../../page/usePage";
import { CONTENT_WORDS } from "../constants";
import { API_URL, RESOURCE } from "../../constants";
import { ContentNewTemplateReferenceInput } from "../content_template/inputs";
import { WriterRateSelectInput } from "../../writer/inputs";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/RemoveCircleOutline";
import { YoutubeReferenceInput } from "../../youtube/inputs";
import { WriterTemplateRateSelectInput } from "../../writer/inputs/WriterTemplateRateSelectInput";
import { httpClient } from "../../utils";

const pathValidation = (value) => {
  if (!value || typeof value !== "object") return undefined;
  const urls = value.map((entry) => entry.split(" ")[0]);
  if (urls.length > 1 && urls.length !== new Set(urls).size) {
    return "Path URLs can not be the same!";
  }
  return undefined;
};

const req = [required()];

const PageOrderInfo = () => {
  const classes = useStyles();
  const { values } = useFormState();
  const [generated, setGenerated] = React.useState(false);
  const [value, setValue] = React.useState("");
  const generate = (e) => {
    e.preventDefault();
    const lines = value.split("\n");
    const items = lines.map((line) => {
      const splitted = line.split(" ");
      const path = splitted[0];
      let keyword = "";
      if (splitted.length > 1) {
        keyword = splitted.slice(1).join(" ");
      }
      return {
        path: path,
        keyword: keyword,
        order_eta: getDayFromNow(7).toISOString(),
      };
    });
    values.page.data = items;
    setGenerated(true);
  };

  const clearForm = (e) => {
    e.preventDefault();
    delete values.items;
    setGenerated(false);
  };

  return (
    <>
      <ArrayTextInput
        inputProps={{
          "data-testid": "paths.keywords",
        }}
        onChange={(e) => setValue(e.target.value)}
        source={"page.paths"}
        className={classes.bigger}
        label={"Paths and keyword"}
        helperText="Line separated path keyword (e.g. /example my keyword)."
        validate={[required(), pathValidation]}
      />
      <MuiButton
        id="btn-generate"
        className={classes.muiButton}
        color="primary"
        variant="contained"
        onClick={generate}
      >
        Generate Paths
      </MuiButton>
      {generated && (
        <>
          <MuiButton
            className={classes.muiButton}
            color="secondary"
            variant="contained"
            onClick={clearForm}
          >
            Clear Path Form
          </MuiButton>
          <HorizontalGroup>
            <h4>Path, Keyword and Estimated date of arrival</h4>
            <ArrayInput source="page.data" validate={required()}>
              <SimpleFormIterator className={classes.flex}>
                <TextInput source="path" label="Path" required />
                <TextInput source="keyword" label="Keywords" required />
                <DateInput source="order_eta" label="Content ETA" required />
              </SimpleFormIterator>
            </ArrayInput>
          </HorizontalGroup>
        </>
      )}
    </>
  );
};

export const Create = (props) => {
  const [alert, setAlert] = React.useState(false);
  const keepOnPage = (e) => {
    e.preventDefault();
    var message = "Custom message doesn't support on mordan browsers :|";
    e.returnValue = message;
    return message;
  };

  React.useEffect(() => {
    window.addEventListener("beforeunload", keepOnPage);
    return () => {
      window.removeEventListener("beforeunload", keepOnPage);
    };
  }, []);

  React.useEffect(() => {
    const location = window.location.href;
    const handlePopstate = () => {
      if (alert) {
        return;
      }
      const confirmed = window.confirm(
        "Are you sure you want to leave this page?",
      );
      if (!confirmed) {
        window.history.pushState(null, null, location);
      }
    };
    window.addEventListener("popstate", handlePopstate);
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, [alert]);

  const classes = useStyles();
  const { permissions } = usePermissions();
  const canChangeWriterRates =
    permissions?.groups?.includes &&
    permissions.groups.includes(GROUPS.changeWriterRates);
  const [product, setProduct] = React.useState("website");
  const [templatePrice, setTemplatePrice] = React.useState(false);
  const [currentWriter, setCurrentWriter] = React.useState(null);
  const [writerQuotaStatus, setWriterQuotaStatus] = React.useState("idle");
  const [writerInfo, setWriterInfo] = React.useState(null);
  const [currentWebsite, setCurrentWebsite] = React.useState(null);
  const [characterStatus, setCharacterStatus] = React.useState("idle");
  const [characterInfo, setCharacterInfo] = React.useState(null);
  const notify = useNotify();
  const redirect = useRedirect();

  React.useEffect(() => {
    if (!currentWebsite) return;
    const fetchData = async () => {
      setCharacterStatus("loading");
      const url = `${API_URL}/${RESOURCE.contentCharacter}/?website=${currentWebsite}`;
      try {
        const result = await httpClient(url);
        setCharacterInfo(result.json);
        setCharacterStatus("loaded");
      } catch (err) {
        console.error(err);
        setWriterQuotaStatus("idle");
      }
    };
    fetchData();
  }, [currentWebsite]);

  React.useEffect(() => {
    if (!currentWriter) return;
    const fetchData = async () => {
      setWriterQuotaStatus("loading");
      const url = `${API_URL}/${RESOURCE.writer}/${currentWriter}/weekly_quota/`;
      try {
        const result = await httpClient(url);
        setWriterInfo(result.json);
        setWriterQuotaStatus("loaded");
      } catch (err) {
        console.error(err);
        setWriterQuotaStatus("idle");
      }
    };
    fetchData();
  }, [currentWriter]);

  return (
    <RACreate
      {...props}
      onSuccess={({ data }) => {
        notify("ra.notification.created", { messageArgs: { smart_count: 1 } });
        setAlert(true);
        redirect("edit", `/${RESOURCE.order}`, data.id, data);
      }}
      transform={(data) => {
        data.content.structure.structuredContent =
          data.content.structure.structuredContent.sort((a, b) => {
            const [first, second] = [a.position, b.position];
            return first === second ? 0 : first > second ? 1 : -1;
          });
        return data;
      }}
    >
      <SimpleForm>
        <HorizontalGroup label="Select Product">
          <SelectInput
            source="product"
            variant="filled"
            className={classes.select}
            onChange={(e) => {
              setProduct(e.target.value);
            }}
            choices={[
              { id: "website", name: "Website" },
              { id: "youtube", name: "Youtube" },
            ]}
          />
        </HorizontalGroup>
        <HorizontalGroup label="Select a Template">
          <ContentNewTemplateReferenceInput
            source={"template"}
            fullWidth
            className={classes.bigAndBigger}
            label={"Template"}
            helperText={"Choose a template ..."}
            resettable
          />
        </HorizontalGroup>
        <HorizontalGroup label="or Select a previous order">
          <ContentTemplateReferenceInput
            source={"other.template"}
            fullWidth
            className={classes.bigAndBigger}
            label={"Template"}
            helperText={
              "Choose a template from a previous order, clear before changing."
            }
            resettable
          />
        </HorizontalGroup>
        <HorizontalGroup label="Order For">
          <WriterReferenceInput
            onChange={(e) => {
              setCurrentWriter(e);
            }}
            source={"content.writer"}
            className={classes.bigAndBigger}
            label="Writer"
            helperText="The writer"
            validate={req}
            prefetch
          />
          {writerQuotaStatus === "loading" ? (
            <HorizontalGroup>
              <Paper className={classes.fullWidthPaper}>
                <Box className={classes.fullWidthBox}>
                  <Typography variant="h6" color="textPrimary">
                    Loading Writer Quota ...
                  </Typography>
                </Box>
              </Paper>
            </HorizontalGroup>
          ) : writerQuotaStatus === "loaded" ? (
            <WriterQuotaStatus writerInfo={writerInfo} />
          ) : null}
        </HorizontalGroup>
        {canChangeWriterRates && (
          <HorizontalGroup>
            <WriterRateSelectInput
              label={"Writer Rate for this Content"}
              helperText={
                "Enter if you want to change the default writer rate, put it emplty for default rate."
              }
              className={classes.bigAndBigger}
              source="writer_rate"
              min={0}
            />
          </HorizontalGroup>
        )}

        <HorizontalGroup>
          <FormGroup row>
            <FormControlLabel
              control={
                <Switch
                  checked={templatePrice}
                  onChange={(e) => setTemplatePrice(e.target.checked)}
                  name="writer-template-rate"
                  inputProps={{
                    "aria-label":
                      "Should The writer will be paid for template?",
                  }}
                />
              }
              label="Should The writer will be paid for template?"
            />
          </FormGroup>
        </HorizontalGroup>
        <HorizontalGroup>
          {templatePrice && (
            <WriterTemplateRateSelectInput
              label={"Writer Template Rate for this Content"}
              className={classes.bigAndBigger}
              source="writer_template_rate"
              min={0}
            />
          )}
        </HorizontalGroup>
        <HorizontalGroup>
          <SelectInput
            source="content.type"
            choices={CONTENT_TYPE_CHOICES}
            className={classes.big}
          />

          <DateInput
            label="Expected date of arrival"
            className={classes.bigger}
            source="content.content_eta"
            initialValue={getDayFromNow(7).toISOString()}
          />
        </HorizontalGroup>
        <HorizontalGroup>
          {product === "youtube" ? (
            <HorizontalGroup label="Select Youtube">
              <TextInput
                className={classes.bigger}
                source={"content.title"}
                label="Youtube Order title"
              />
              <YoutubeReferenceInput
                className={classes.big}
                source={"youtube"}
                label="Youtube"
                prefetch
              />
            </HorizontalGroup>
          ) : (
            <>
              <HorizontalGroup label="Webpage details">
                <WebsiteReferenceInput
                  onChange={(e) => {
                    setCurrentWebsite(e);
                  }}
                  source={"page.website"}
                  className={classes.big}
                  label="Website"
                  helperText="Website"
                  validate={req}
                  prefetch
                />
                {characterStatus === "loading" ? (
                  <Paper className={classes.fullWidthPaper}>
                    <Box className={classes.fullWidthBox}>
                      <Typography variant="h6" color="textPrimary">
                        Loading Website Character ...
                      </Typography>
                    </Box>
                  </Paper>
                ) : characterStatus === "loaded" ? (
                  <ShowWebsiteCharacters characterInfo={characterInfo} />
                ) : null}
              </HorizontalGroup>

              <PageOrderInfo />
            </>
          )}
        </HorizontalGroup>
        <MarketReferenceInput
          source={"content.market"}
          className={classes.bigAndBigger}
          helperText="Market for the page"
          prefetch
        />
        <ReaderIntent
          multiline
          className={classes.bigAndBigger}
          label={"Reader Intent"}
          source="content.structure.reader_intent"
          helperText="What is the reader looking for?"
          // initialValue={formData.template?.structure?.reader_intent}
        />
        <ArrayTextInput
          source={"content.examples"}
          label={"Example urls"}
          className={classes.bigAndBigger}
          helperText={"Reference urls, use a new line for each one"}
        />

        <ArrayTextInput
          source={"page.keywords"}
          label={"Keywords"}
          className={classes.bigAndBigger}
          helperText={
            "keywords, [keyword] will be replaced with the one specified in the path, use a new line for each one"
          }
          validate={req}
        />
        {/* Parent page details */}
        <FormDataConsumer>
          {({ formData }) => <ParentPage formData={formData} />}
        </FormDataConsumer>

        <HorizontalGroup label="Content Meta">
          <CountedTextField
            source={"content.meta_title"}
            label={"Meta Title"}
            className={classes.bigAndBigger}
            helperText="Content Meta Title"
            suggestedLength={60}
            maximumLength={150}
            suggestedWithin={10}
          />
        </HorizontalGroup>
        <CountedTextField
          suggestedLength={180}
          maximumLength={400}
          suggestedWithin={20}
          className={classes.bigAndBigger}
          multiline
          fullWidth
          label={"Meta Description"}
          source="content.meta_description"
          // initialValue={formData.template?.meta_description}
        />
        <BooleanInput
          source="content.editable_metas"
          label={"Editable Meta"}
          className={classes.bigAndBigger}
          helperText={"Should the writer edit the meta fields?"}
          // initialValue={formData.template?.editable_metas}
        />
        <HorizontalGroup label="The Content">
          <FormDataConsumer>
            {({ formData }) => {
              // This is working such as an effect, and will be used to set the requested words
              if (formData.content) {
                const structuredContent =
                  formData.content.structure?.structuredContent?.reduce(
                    (acc, entry) => acc + (entry?.words || 0),
                    0,
                  ) || 0;
                const structure = formData.content.structure;
                const otherInputs = structure
                  ? (structure.pros || 0) * CONTENT_WORDS.pros +
                    (structure.cons || 0) * CONTENT_WORDS.pros +
                    (structure.number_of_reviews || 0) * CONTENT_WORDS.pros
                  : 0;
                const metas = formData.content.editable_metas
                  ? CONTENT_WORDS.meta
                  : 0;
                formData.content.requested_words =
                  structuredContent + otherInputs + metas;
              }
              return null;
            }}
          </FormDataConsumer>
          <SelectInput
            label="Content Priority"
            source="content.content_priority"
            choices={CONTENT_PRIORITY_CHOICES}
            defaultValue={CONTENT_PRIORITY.normal}
            className={classes.big}
          />
          <NumberInput
            onWheel={(e) => e.target.blur()}
            fullWidth
            disabled
            source="content.requested_words"
            step="400"
          />

          <NumberInput
            onWheel={(e) => e.target.blur()}
            fullWidth
            source="content.structure.pros"
            label={"Number of Pros"}
            // initialValue={formData.template?.structure.pros || 0}
            min={0}
            max={5}
          />
          <NumberInput
            onWheel={(e) => e.target.blur()}
            fullWidth
            source="content.structure.cons"
            label={"Number of cons"}
            min={0}
            max={5}
          />
          <NumberInput
            onWheel={(e) => e.target.blur()}
            fullWidth
            source="content.structure.number_of_reviews"
            label={"Number of reviews"}
            min={0}
            max={5}
          />
        </HorizontalGroup>
        <TextInput
          type="url"
          source={"content.google_docs"}
          target="_blank"
          label="Template in GDOCS"
          validate={maxLength(150)}
          fullWidth
        />

        <FormDataConsumer>
          {({ formData }) => (
            <AdvancedTextInput
              data-testid="instructions-textinput"
              source="content.structure.instructions"
              label={"Instructions"}
              fullWidth
              multiline
              countWords={false}
              // initialValue={
              //     formData.template?.structure.templateData?.instructions ?
              //     formData.template?.structure.templateData?.instructions :
              //     formData.template?.structure.instructions
              // }
            />
          )}
        </FormDataConsumer>

        <ArrayInput
          source={"content.structure.structuredContent"}
          validate={required()}
          label={"Content Fields"}
          className={classes.contentArrayInput}
        >
          <ContentIterator />
        </ArrayInput>
      </SimpleForm>
    </RACreate>
  );
};

/**
 * Small trick element.
 * When an array element was removed the AdvancedTextInput would not change.
 *  Meaning that given element A, position 1 and B, p. 2 if element A was removed
 *  element B, now in p. 1 would retain the element
 *  "A" AdvancedTextInput, and therefore its content.
 *
 * This element Will force an update and recalculation of all elements when the remove button is pushed.
 *  When this happens the SimpleFormIterator is replaced by a Loading before removing the items,
 *  and, after a small amount of time, it is recreated again, effectively forcing the redrawing all the elements.
 *
 *  I believe there is some kind of bug in the current version (v3.12.5) of react-admin,
 *   and it is not playing nicely with complex fields such as ckeditor, possibly once upgraded this workaroud
 *   will not be necessary.
 *
 * @param scopedFormData
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ContentIterator = (props) => {
  const classes = useStyles();
  const [delay, setDelay] = React.useState(false);
  const before = React.useCallback(() => {
    setDelay(true);
  }, [setDelay]);
  const after = React.useCallback(() => {
    setDelay(false);
  }, [setDelay]);
  if (delay) {
    return <Loading />;
  }
  return (
    <SimpleFormIterator
      {...props}
      addButton={<ContentIteratorAddButton />}
      removeButton={
        <ContentIteratorRemoveButton before={before} after={after} />
      }
    >
      <HorizontalGroup>
        <TextInput
          source={"title"}
          label={"Title"}
          className={classes.bigger}
          validate={req}
        />
        <NumberInput
          source={"words"}
          onWheel={(e) => e.target.blur()}
          label={"number of words"}
          className={classes.small}
          step={400}
          min={0}
          validate={req}
          resettable
        />
        <NumberInput
          onWheel={(e) => e.target.blur()}
          source={"position"}
          label={"Section Order"}
          className={classes.small}
          step={10}
          min={0}
          resettable
          helperText={"Order the content sections"}
          validate={req}
        />
      </HorizontalGroup>
      <AdvancedTextInput source={"content"} fullWidth label={" "} />
    </SimpleFormIterator>
  );
};

const ContentIteratorAddButton = ({ onClick, ...props }) => {
  const remove = () => {
    onClick();
  };
  return (
    <Button
      {...props}
      id="btn-section-add"
      label="ra.action.add"
      onClick={remove}
    >
      <AddIcon />
    </Button>
  );
};

const ContentIteratorRemoveButton = ({ before, after, onClick, ...props }) => {
  const remove = () => {
    before();
    onClick();
    setTimeout(after, 200);
  };
  return (
    <Button
      {...props}
      id="btn-section-remove"
      label="ra.action.remove"
      onClick={remove}
    >
      <CloseIcon />
    </Button>
  );
};

const ParentPage = ({ formData, ...props }) => {
  const [path, setPath] = React.useState("");
  const [keyword, setKeyword] = React.useState("");
  const classes = useStyles();

  React.useEffect(() => {
    const callback = async () => {
      const params = {};
      if (formData.page?.website && formData.page?.path) {
        params.website = formData.page?.website;
        params.path = formData.page?.path;
      } else if (formData.template?.parent) {
        params.id = formData.template.parent;
      } else if (formData.template?.website && formData.template?.path) {
        params.website = formData.template?.website;
        params.path = formData.template?.path;
      }
      const { data, success } = await queryPage(params);
      if (!success) {
        return;
      }
      setPath(data?.path);
      if (data?.keywords) {
        setKeyword(data.keywords[0]);
      }
    };
    callback();
  }, [formData.page?.path, formData.page?.website, formData?.template]);
  return (
    <HorizontalGroup label="Parent Page details" {...props}>
      <TextInput
        source={"parent.keyword"}
        label="Parent Keyword"
        className={classes.big}
        helperText="Keyword of the parent, usually it autodetects"
        initialValue={keyword}
      />
      <TextInput
        source={"parent.path"}
        label="Parent page"
        className={classes.bigger}
        helperText="Not needed if the path is in the form /parent/child"
        initialValue={path}
      />
    </HorizontalGroup>
  );
};

const WriterQuotaStatus = ({ writerInfo }) => {
  const { total_words, weekly_quota } = writerInfo;
  const classes = useStyles();
  return (
    <HorizontalGroup>
      <Paper className={classes.fullWidthPaper}>
        {total_words <= weekly_quota ? (
          <Box className={classes.fullWidthBox}>
            <Typography variant="h5" color="textPrimary">
              <strong>✅ Writer's Current Weekly Quota Status:</strong>
            </Typography>
            <Typography variant="h6" color="textPrimary">
              <strong>Writer's Weekly Quota: </strong>
              {weekly_quota} Words
            </Typography>
            <Typography variant="h6" color="textPrimary">
              <strong>Writer's Written Words: </strong>
              {total_words} Words
            </Typography>
          </Box>
        ) : (
          <Box className={classes.fullWidthBox}>
            <Typography variant="h5" color="error">
              <strong>🚨 Writer Quota Exceed! </strong>
            </Typography>
            <Typography variant="h6" color="textPrimary">
              <strong>Writer's Weekly Quota: </strong>
              {weekly_quota} Words
            </Typography>
            <Typography variant="h6" color="textPrimary">
              <strong>Writers' Written Words: </strong>
              {total_words} Words
            </Typography>
          </Box>
        )}
      </Paper>
    </HorizontalGroup>
  );
};

const ShowWebsiteCharacters = ({ characterInfo }) => {
  const { results } = characterInfo;
  const choices = results.map((entry) => ({
    id: entry.id,
    name: entry.display_name,
  }));
  return (
    <SelectInput
      resettable
      label="Character"
      source="content.structure.content_characture"
      choices={choices}
      // choices={writerInfo}
    />
  );
};

/**
 * I implemented this only to have the label on top
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ReaderIntent = (props) => <TextInput {...props} />;
ReaderIntent.defaultProps = { addLabel: true, label: "Reader Intent" };
