import { Resource } from "react-admin";

import { RESOURCE, GROUPS } from "../constants";

import { List } from "../outreachLinkbuildingOrder/List";

const RES = RESOURCE.outreachOrder;
/**
 * For some reason the APP want the resource to be instantiated with this make factory.
 * @param {*} permissions
 * @param {*} key
 * @returns
 */
export const makeOutreachOrder = (permissions) =>
  permissions.groups.includes(GROUPS.outreach) ? (
    <Resource
      name={RES}
      key={RES}
      options={{ label: "Outreach Requests" }}
      list={List}
    />
  ) : (
    <Resource name={RES} key={RES} />
  );
