import React from "react";
import { Create as RaCreate, CreateProps } from "react-admin";

import { CreateForm } from "./Form";

export const Create = (props: CreateProps): React.ReactElement => {
  return (
    <RaCreate
      {...props}
      transform={(data) => {
        const contact = data?.data?.contact;
        if (contact && contact[0]?.field) {
          contact[0].field = "email";
        }
        if (contact && contact[1]?.field) {
          contact[1].field = "skype";
        }
        return data;
      }}
    >
      <CreateForm />
    </RaCreate>
  );
};

export default Create;
