// import {createSvgIcon} from "@material-ui/core/utils/index";
import SvgIcon from "@material-ui/core/SvgIcon";

import { ReactComponent as GoogleLogoSVG } from "./GoogleLogoSVG.svg";

export const GoogleLogo = (props) => (
  <SvgIcon component={GoogleLogoSVG} {...props} />
);

export default GoogleLogo;
