import React from "react";
import { useNotify, useRecordContext } from "react-admin";
import IconButton from "@material-ui/core/IconButton";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { Tooltip } from "@material-ui/core";
import copy from "copy-to-clipboard";

interface ActionsProps {
  label: string;
}

export const CopyBrandId: React.FC<ActionsProps> = ({ label }) => {
  const record = useRecordContext();
  const notify = useNotify();

  const handleCopy = React.useCallback(() => {
    copy(`${record.id}`);
    notify("Copied successfully");
  }, [record.id, notify]);

  return (
    <>
      {record.id ? (
        <Tooltip title={`Copy Brand ID`}>
          <IconButton color={"primary"} onClick={handleCopy}>
            <FileCopyIcon />
          </IconButton>
        </Tooltip>
      ) : null}
    </>
  );
};
