import { useGetOne, useQueryWithStore, useRecordContext } from "react-admin";
import { useState } from "react";
import { RESOURCE } from "../../constants";


export const useWriterRate = (id) => {
  const [rate, setRate] = useState(undefined);
  const { loaded, error, data } = useWriter();  
  if (!loaded) {
    return { loaded, error, rate };
  }
  setRate(data.rate);
  return { loaded, error, rate };
}


/**
 * get the writer data.
 * @param {number} id
 * @returns
 */
export const useWriter = (id) => {
  const { loaded, error, data } = useGetOne(
    RESOURCE.writer,
    id,
    {enabled: !!id},
  );
  return { loaded, error, data };
};


export const useRecordWriter = () => {
  const record = useRecordContext();
  return useWriter(record.writer);
}