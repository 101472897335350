import { ListGuesser, Resource } from "react-admin";

import { RESOURCE, GROUPS } from "../constants";

import InsertCommentIcon from "@material-ui/icons/InsertComment";
import List from "./LIst";

/**
 * For some reason the APP want the resource to be instantiated with this make factory.
 * @param {*} permissions
 * @param {*} key
 * @returns
 */
export const makeOutreachLinkbuildingDuplicateIP = (permissions) => [
  permissions.groups.includes(GROUPS.linkbuilding) ? (
    <Resource
      name={RESOURCE.outreachLinkbuildingDuplicateIP}
      key={RESOURCE.outreachLinkbuildingDuplicateIP}
      options={{ label: "Duplicate IPs" }}
      list={List}
      // create={BulkCreate}
      icon={InsertCommentIcon}
    />
  ) : (
    <Resource
      name={RESOURCE.outreachLinkbuildingDuplicateIP}
      key={RESOURCE.outreachLinkbuildingDuplicateIP}
    />
  ),
];
