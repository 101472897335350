import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import { RichTextField } from "react-admin";

export const Links = ({ record }) => {
  const links = record.data.links;

  if (!links) {
    return null;
  }
  return (
    <div>
      <p>Links:</p>
      <ul>
        {links.map((record, index) => (
          <li>
            <Link key={index} href={record.link} target={"_blank"}>
              {record.linkAnchor || record.link}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export const Video = (props) => {
  const { record } = props;
  if (!record.data?.video) return null;
  return (
    <div>
      <Typography component={"h5"} variant={"h6"}>
        Video:
      </Typography>
      <RichTextField source={"data.video"} {...props} />
    </div>
  );
};
