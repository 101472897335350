import { Resource } from "react-admin";

import { RESOURCE, GROUPS } from "../../constants";
import { List } from "./List";
import { Show } from "./Show";
import { ProofreaderPaymentsList } from "./PaymentList";

/**
 * @param {*} permissions
 * @returns
 */
export const makeFinanceProofreader = (permissions) =>
  permissions.groups.includes(GROUPS.finance) ||
  permissions.groups.includes(GROUPS.editors)
    ? [
        <Resource
          name={RESOURCE.financeProofreader}
          key={RESOURCE.financeProofreader}
          options={{ label: "Proofreaders (Finance)" }}
          show={Show}
          list={List}
        />,
        <Resource
          name={RESOURCE.financePaymentProofreader}
          key={RESOURCE.financePaymentProofreader}
          options={{ label: "Freelance Proofreader Payments" }}
          list={ProofreaderPaymentsList}
        />,
      ]
    : [
        <Resource name={RESOURCE.financeWriter} key={RESOURCE.financeWriter} />,
        <Resource
          name={RESOURCE.financePaymentWriter}
          key={RESOURCE.financePaymentWriter}
        />,
      ];
