export const API_URL = "/api";
export const RESOURCE = "content/section";

const MODEL = "ContentSection";

export const PERMISSIONS = {
  create: `content_section.add_${MODEL}`,
  view: `content_section.view_${MODEL}`,
  edit: `content_section.change_${MODEL}`,
  delete: `content_section.delete_${MODEL}`,
};
