export const RESOURCE: string = "sales/pm_brands";
export { ADMIN } from "../constants";

const MODEL = "pmbrand";

export const PERMISSIONS = {
  create: `sales.add_${MODEL}`,
  view: `sales.view_${MODEL}`,
  edit: `sales.change_${MODEL}`,
  delete: `sales.delete_${MODEL}`,
};
