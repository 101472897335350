import * as React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { STATUS } from "../../constants";

import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

import { BottomBar } from "../edit/BottomBar";
import {
  SaveButton,
  usePermissions,
  useNotify,
  fetchStart,
  fetchEnd,
  useRefresh,
  useRedirect,
  Loading,
  useRecordContext,
} from "react-admin";

import { API_URL, GROUPS, RESOURCE } from "../../constants";
import { httpClient } from "../../utils";

import { MarkAsReadyButton } from "./MarkAsReadyButton";
import { Button, withStyles } from "@material-ui/core";
import { ApproveButton } from "../editor/ReviewToolbar";
import { RejectContentButton } from "../editor/RejectContentButton";
import { EstimatedQuoteField, TemplatePriceField } from "../ContentList";
import Chip from "@material-ui/core/Chip";
import { ProofreaderApproveButton } from "../editor/ProofreaderApproveButton";
import { VAssistantApproveButton } from "../editor/VAssistantApproveButton";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import TimelineIcon from "@material-ui/icons/Timeline";
import TimerIcon from "@material-ui/icons/Timer";
import { checkCoupemonde } from "../utils/checkCoupemonde";
import { checkCanApproveContent } from "../utils/checkCanApproveContent";
import { RevokeContentButton } from "../editor/RevokeContentButton";
import {
  useTotalWords,
  useTotalWordsNoForm,
} from "../../reusable/store/countWords";

const ValidateButton = ({ onClick, disabled }) => {
  return (
    <Button
      startIcon={<AutorenewIcon />}
      onClick={onClick}
      disabled={disabled}
      color="secondary"
      variant="contained"
    >
      {"Graph Update"}
    </Button>
  );
};

const CreateYTGButton = ({ onClick, disabled }) => {
  return (
    <Button
      startIcon={<TimelineIcon />}
      onClick={onClick}
      disabled={disabled}
      color="primary"
      variant="contained"
    >
      {"Create YTG Graph"}
    </Button>
  );
};

const VATimer = ({ className, record }) => {
  if (!record.va_started_reviewing) return null;
  if (record.va_finished_reviewing) {
    return (
      <Chip
        icon={<TimerIcon />}
        className={className}
        color="secondary"
        label={`Time Spent: ${record.va_duration}`}
      />
    );
  }
  return (
    <Chip
      icon={<TimerIcon />}
      className={className}
      color="secondary"
      label={`Time Spent: ${record.va_duration}`}
    />
  );
};

export const WriterToolbar = withStyles({
  backdrop: {
    zIndex: 100,
  },
  chip: {
    marginRight: ".5rem",
  },
})(({ classes, ...props }) => {
  const record = useRecordContext();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const permissions = usePermissions();
  const { noForm = false } = props;

  const writer = permissions.loading
    ? null
    : permissions.permissions.groups.includes(GROUPS.writers);
  const editorAndWriter = permissions.loading
    ? null
    : permissions.permissions.groups.includes(GROUPS.editorsAndWriters);
  const editor = permissions.loading
    ? null
    : permissions.permissions.groups.includes(GROUPS.editors);
  const proofreader = permissions.loading
    ? null
    : permissions.permissions.groups.includes(GROUPS.proofreader);
  const vassistant = permissions.loading
    ? null
    : permissions.permissions.groups.includes(GROUPS.vAssistant);
  const integrator = permissions.loading
    ? null
    : permissions.permissions.groups.includes(GROUPS.integrators);
  const linkbuilders = permissions.loading
    ? null
    : permissions.permissions.groups.includes(GROUPS.linkbuilding);

  const coupemondeFlag = checkCoupemonde(record, permissions?.permissions);
  const canApproveContent = checkCanApproveContent(
    record,
    permissions?.permissions,
  );

  // Once submitted a writer can't save the content anymore
  const disabled = writer && record.status !== STATUS.requested;

  // if the content is disabled redirect to show.
  React.useEffect(() => {
    if (disabled && writer) {
      redirect(`/${RESOURCE.order}/${record.id}/show`);
    }
  }, [disabled, writer, redirect, record.id]);

  if (permissions.loading) return <Loading />;

  const validate = () => {
    const path = `${API_URL}${props.basePath}/${record.id}/validate/`;
    setLoading(true);
    dispatch(fetchStart()); // start the global loading indicator
    httpClient(path, { method: "POST" })
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response);
        }
        return response.json;
      })
      .catch((e) => {
        const reason = e.body?.reason ? e.body.reason : e.message;
        return Promise.reject(`warning: ${reason}`);
      })
      .then(
        ({ status, valid }) => {
          notify(`Content Validated Successfully`);
        },
        (e) => notify(e, "info"),
      )
      .finally(() => {
        setLoading(false);
        dispatch(fetchEnd()); // stop the global loading indicator
        refresh();
      });
  };

  const hasYTGGraph = () => {
    if (record && record.yourtext_guru_id) return true;
    return false;
  };

  const canRevoke = () => {
    return (
      record &&
      (record.status === STATUS.approved ||
        record.status === STATUS.review ||
        record.status === STATUS.va_reviewed)
    );
  };

  const createYTG = () => {
    const path = `${API_URL}${props.basePath}/${record.id}/createYTG/`;
    setLoading(true);
    dispatch(fetchStart()); // start the global loading indicator
    httpClient(path, { method: "POST" })
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response);
        }
        return response.json;
      })
      .catch((e) => {
        const reason = e.body?.reason ? e.body.reason : e.message;
        return Promise.reject(`warning: ${reason}`);
      })
      .then(
        ({ status, valid }) => {
          notify(`The Request has been submitted`);
        },
        (e) => notify(e, "info"),
      )
      .finally(() => {
        setLoading(false);
        dispatch(fetchEnd()); // stop the global loading indicator
        refresh();
      });
  };

  if (loading)
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  return (
    <BottomBar
      withToolbar
      va_timer={
        vassistant ? <VATimer className={classes.Chip} record={record} /> : null
      }
      templatePriceField={
        editor ? <TemplatePriceField record={record} /> : null
      }
      quoteField={
        editor ? <QuoteChip className={classes.Chip} record={record} /> : null
      }
      saveButton={
        <SaveButton
          // disabled={props.pristine || disabled}
          disabled={disabled}
          pristine
          // onSuccess={validate}
        />
      }
      submitButton={
        writer | editorAndWriter && !noForm ? (
          <MarkAsReadyButton
            pristine={props.pristine}
            disabled={disabled | coupemondeFlag}
          />
        ) : vassistant ? (
          <VAssistantApproveButton />
        ) : null
      }
      validateButton={
        hasYTGGraph() ? (
          <ValidateButton
            // disabled={props.pristine || disabled}
            pristine
            onClick={validate}
          />
        ) : null
      }
      approveButton={
        canApproveContent && editor ? (
          <ApproveButton {...props} />
        ) : canApproveContent && (proofreader || coupemondeFlag) ? (
          <ProofreaderApproveButton />
        ) : null
      }
      rejectButton={
        editor | proofreader ? <RejectContentButton {...props} /> : null
      }
      createYTGButton={
        editor | integrator | linkbuilders | writer | coupemondeFlag &&
        !hasYTGGraph() ? (
          <CreateYTGButton onClick={createYTG} {...props} />
        ) : null
      }
      revokeContent={
        editor && canRevoke() ? <RevokeContentButton {...props} /> : null
      }
      {...props}
    />
  );
});

const QuoteChip = ({ className, record, ...props }) => {
  return (
    <Chip
      className={className}
      color="secondary"
      label={<EstimatedQuoteField estimate record={record} />}
    />
  );
};
