import React from "react";

import { RESOURCE } from "../constants";
import { ReferenceField, TextField } from "react-admin";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  yellowSpan: {
    backgroundColor: "#facc15",
    padding: "0.5rem",
    borderRadius: "0.5rem",
  },
  redSpan: {
    color: "white",
    backgroundColor: "#ef4444",
    padding: "0.5rem",
    borderRadius: "0.5rem",
  },
  greenSpan: {
    color: "#052e16",
    backgroundColor: "#00f500",
    padding: "0.5rem",
    borderRadius: "0.5rem",
  },
  blueSpan: {
    color: "white",
    backgroundColor: "#3b82f6",
    padding: "0.5rem",
    borderRadius: "0.5rem",
  },
}));

const getCSS = (classes: any, color: string | undefined) => {
  switch (color) {
    case "yellow":
      return classes.yellowSpan;
    case "red":
      return classes.redSpan;
    case "green":
      return classes.greenSpan;
    case "blue":
      return classes.blueSpan;
    default:
  }
};

export const SalesUserReferenceField = ({
  source = "owner",
  label = "Owner",
  ...props
}) => {
  const { record } = props;
  const owner_label = record?.owner_label;
  const classes = useStyles();
  return (
    <ReferenceField
      resource={RESOURCE}
      reference={RESOURCE}
      label={label}
      source={source}
      {...props}
    >
      <TextField
        className={getCSS(classes, owner_label)}
        source={"display_name"}
      />
    </ReferenceField>
  );
};
