export { ADMIN } from "../constants";
export const API_URL = "/api";
export const RESOURCE: string = "sales/listing_fees";

const MODEL = "listingfee";
const FINANCEMODEL = "financelistingfee";

export const PERMISSIONS = {
  create: `sales.add_${MODEL}`,
  view: `sales.view_${MODEL}`,
  edit: `sales.change_${MODEL}`,
  delete: `sales.delete_${MODEL}`,
};

export const FINANCEPERMISSIONS = {
  create: `sales.add_${FINANCEMODEL}`,
  view: `sales.view_${FINANCEMODEL}`,
  edit: `sales.change_${FINANCEMODEL}`,
  delete: `sales.delete_${FINANCEMODEL}`,
};
