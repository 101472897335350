import { Link, makeStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import { Loading, useDataProvider } from "react-admin";
import { RESOURCE } from "../../../../constants";
import CallMadeIcon from "@material-ui/icons/CallMade";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "1rem",
  },
  link: {
    display: "flex",
    justifyItems: "center",
    alignItems: "center",
    fontSize: "1rem",
    marginLeft: "1rem",
  },
  div: {
    display: "flex",
    justifyItems: "center",
  },
}));

const SERP = ({ market, keywords }) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();

  const [marketData, setMarketData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (market) {
        setLoading(true);
        const data = await dataProvider.getOne(RESOURCE.market, {
          id: market,
        });
        // console.log("aside", data);
        setMarketData(data.data);
        setLoading(false);
      }
    };
    fetchData();
  }, [dataProvider, market]);

  /*
  https://www.google.fr           --> .fr means french
  /search?q=meilleur+casino       --> search query
  &gl=fr                          --> location
  &hl=fr                          -->
  &gws_rd=cr                      --> location redirect
  &pws=0                          --> personalized search disabled
  */

  const generateLink = (countryCode, query) => {
    if (
      countryCode === "" ||
      countryCode === null ||
      countryCode === undefined
    ) {
      countryCode = "fr";
    }

    let searchLink = `https://www.google.${countryCode}`;
    searchLink += `/search?q=${query}&gl=${countryCode}&hl=${countryCode}`;
    searchLink += `&gws_rd=cr&pws=0`;

    return searchLink;
  };

  return (
    <div className={classes.root}>
      <h2>SERP</h2>
      {!market && <h3>No Market Available!</h3>}
      {loading && <Loading />}
      {marketData && keywords && (
        <div className={classes.div}>
          <h3>Search for: </h3>
          <Link
            className={classes.link}
            target="_blank"
            htmlRef="noreferrer"
            href={generateLink(marketData.country_iso_code, keywords[0])}
          >
            {keywords[0]}
            <CallMadeIcon />
          </Link>
        </div>
      )}
    </div>
  );
};
export default SERP;
