import React, { Suspense, lazy } from "react";
import { Loading } from "react-admin";

const YtgChartElement = lazy(() => import("./ytgChartElement"));

export const YtgChart = (props) => (
  <Suspense fallback={<Loading />}>
    <YtgChartElement {...props} />
  </Suspense>
);
