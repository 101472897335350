export const OUTREACH_LINK = {
  pbn: "PBN",
  guestPost: "Guest Post",
  freeLinks: "Free Links",
  sales: "Sales",
};

export const OUTREACH_LINK_TYPES = Object.values(OUTREACH_LINK).map(
  (value) => ({ id: value, name: value }),
);

export const OUTREACH_TYPE = {
  requested: "requested",
  first_mail: "first_mail",
  negotiating: "negotiating",
  waiting_for_content: "WFContent",
  waiting_for_publication: "WFPublication",
  done: "done",
  indexed: "indexed",
  suggested: "suggested",
};

export const OUTREACH_TYPE_CHOICES = Object.values(OUTREACH_TYPE).map(
  (value) => ({ id: value, name: value }),
);
