import { useState } from "react";
import EditIcon from "@material-ui/icons/Edit";
import {
  Button,
  useNotify,
  useRefresh,
  usePermissions,
  Loading,
} from "react-admin";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useStyles } from "../../../useStyles";
import { RESOURCE, GROUPS, API_URL } from "../../../constants";
import { StandaloneForm } from "../../../reusable";
import { httpClient } from "../../../utils";
import { WriterReferenceInput } from "../../../writer";

const ACTION_URI = `${API_URL}/${RESOURCE.order}/bulk_edit/`;

export const BulkEdit = ({ selectedIds, filterValues }) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();

  const { permissions, loading } = usePermissions();
  const handleClose = () => {
    setOpen(false);
  };
  if (loading || !permissions) return <Loading />;
  if (permissions.groups.includes(GROUPS.freelances)) {
    return null;
  }
  const basePath = ACTION_URI;

  const handleSubmit = async (values) => {
    try {
      await httpClient(basePath, {
        method: "POST",
        body: JSON.stringify({ ids: selectedIds, ...values }),
      });
    } catch (error) {}
    refresh();
    notify("Updated successfully.");
  };
  return (
    <span>
      <Button
        variant="outlined"
        color="primary"
        startIcon={<EditIcon />}
        label={"Edit"}
        onClick={() => setOpen(true)}
      />
      <Dialog
        aria-labelledby="assign-integrator-dialog-title"
        aria-describedby="assign-integrator--dialog-description"
        maxWidth={"lg"}
        className={classes.dialogForm}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle id={"assign-integrator-dialog-title"}>
          Assign integration
          <Button label={"Close"} onClick={() => setOpen(false)} />
        </DialogTitle>
        <StandaloneForm onSubmit={handleSubmit} withButton>
          <WriterReferenceInput source={"writer"} />
        </StandaloneForm>
      </Dialog>
    </span>
  );
};

// const BulkEditToolbar = props => {
//     const {basePath, selectedIds} = props;
//     const refresh = useRefresh();
//     const { values } = useFormState();
//
//     return <Toolbar {...props}  >
//         <SaveButton
//             label="post.action.save_and_show"
//             redirect="show"
//             submitOnEnter={true}
//         />
//     </Toolbar>
// }
