import React from "react";

import { List as RAList, Datagrid, ListProps, downloadCSV } from "react-admin";

import EditableTextField from "../../reusable/fields/EditableTextField";
import jsonexport from "jsonexport/dist";
import { todayISOString } from "../../reusable";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => {
  return {
    field: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      wordBreak: "break-word",
    },
    textBreak: {
      wordBreak: "break-word",
    },
    alignedRight: {
      display: "flex",
      flexDirection: "row-reverse",
    },
  };
});

export const List = (props: ListProps): React.ReactElement => {
  const classes = useStyles();

  return (
    <RAList {...props} exporter={exporter}>
      <Datagrid>
        <EditableTextField
          className={classes.textBreak}
          source={"username"}
          label={"Account Username"}
        />
      </Datagrid>
    </RAList>
  );
};

const exporter = async (stats: any, fetchRelatedRecords: any) => {
  const data = stats.map((entry: any) => ({
    Username: entry.username,
  }));

  jsonexport(
    data,
    {
      headers: ["Username"],
    },
    (err, csv) =>
      !err
        ? downloadCSV(csv, `sales-account.${todayISOString()}`)
        : console.error(err),
  );
};

export default List;
