import {
  ArrayField,
  Datagrid,
  EditButton,
  FunctionField,
  List,
  ListProps,
  SingleFieldList,
  TextField,
  TextInput,
} from "react-admin";
import { WebsiteReferenceField, WebsiteReferenceInput } from "../../../website";
import Chip from "@material-ui/core/Chip";

const filters = [
  <WebsiteReferenceInput
    source="website"
    label="Domain"
    filter={{ landing_page: true }}
    alwaysOn
    resettable
  />,
  <TextInput source="slug" label="Slug" alwaysOn />,
];

export default function GlobalLandingPageList(props: ListProps) {
  return (
    <List {...props} filters={filters} hasEdit={true}>
      <Datagrid rowClick={undefined}>
        <EditButton />
        <WebsiteReferenceField source="domain" label="Domain" />
        <TextField source="slug" label="Slug" />
        <ArrayField source="data.links" label="Links">
          <SingleFieldList>
            <FunctionField
              render={(record) => (
                <Chip
                  variant="outlined"
                  size="small"
                  label={`${record.landing_page} (${record.geo})`}
                />
              )}
            />
          </SingleFieldList>
        </ArrayField>
        <FunctionField
          label="Landing Page Link"
          render={(record) => {
            return record && record.global_lp_url ? (
              <a href={record.global_lp_url} rel="noreferrer" target="_blank">
                {record.global_lp_url}
              </a>
            ) : null;
          }}
        />
      </Datagrid>
    </List>
  );
}
