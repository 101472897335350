import { Box, Divider } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import {
  List,
  Datagrid,
  TextField,
  DataProviderContext,
  FunctionField,
  WrapperField,
} from "react-admin";
import { UserReferenceField } from "../../user";
import { Filters as ContentFilters } from "../ContentList";
import { RESOURCE } from "../refusal/constants";

const DisplayContentFeedbacks = ({ record }) => {
  const [reasons, setReasons] = useState("");
  const [toggle, setToggle] = useState(true);

  const dataProvider = useContext(DataProviderContext);

  useEffect(() => {
    const getData = async () => {
      const response = await dataProvider.getList(RESOURCE, {
        pagination: {},
        sort: {},
        filter: { content: record.id },
      });
      let reasons_string = "";
      for (const data of response.data) {
        reasons_string += data?.reason + "<br/>";
      }
      setReasons(reasons_string);
    };
    getData();
  }, [record, dataProvider]);

  return (
    <div
      onClick={() => {
        setToggle(!toggle);
      }}
      style={{
        maxHeight: toggle ? "5rem" : "100%",
        textOverflow: "ellipsis",
        overflowY: "hidden",
        cursor: "pointer",
        whiteSpace: "pre-wrap",
        paddingBottom: "1rem",
      }}
    >
      {record.feedback && (
        <>
          <strong>Final Feedback:</strong>
          <p>{record.feedback}</p>
          <Divider light={true} />
        </>
      )}
      <p
        dangerouslySetInnerHTML={{
          __html: reasons,
        }}
      />
    </div>
  );
};

export const FeedbackList = (props) => (
  <List
    sort={{ field: "date_created", order: "DESC" }}
    filters={<ContentFilters />}
    {...props}
  >
    <Datagrid>
      <Box label="Content Info" display={"flex"} flexDirection={"column"}>
        <Box>
          <strong>ID: </strong>
          <TextField label="ID" source="id" />
        </Box>
        <Box>
          <strong>URL: </strong>
          <TextField label="Content URL" source="url" />
        </Box>
        <Box>
          <strong>Approved by: </strong>
          <UserReferenceField label="Approved By" source="approved_by" />
        </Box>
      </Box>
      <DisplayContentFeedbacks label="Rejection Comments" {...props} />
    </Datagrid>
  </List>
);
