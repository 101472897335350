export const outreachOrderStatus = {
  requested: "requested",
  choices: [
    { id: "requested", name: "Requested" },
    { id: "first_mail", name: "First Email" },
    { id: "negotiating", name: "Negotiating" },
    { id: "WFContent", name: "Waiting for content" },
    { id: "WFPublication", name: "Waiting for publication" },
    // { id: 'WFPayment', name: 'Waiting for payment' },
    { id: "done", name: "Done" },
    // { id: 'suggested', name: 'Suggest new' },
  ],
};
