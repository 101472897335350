import { EditorContentList } from "../../content/editor/EditorContentList";
import { STATUS } from "../../constants";

import { WriterStats } from "./WriterStats";
import { POStats } from "./POStats";
// import { WebsiteStats } from "./WebsiteStats";
import {List as WebsiteStats} from '../../stats/website/List';
import {RESOURCE as WebsiteStatsResource} from '../../stats/website/constants';
import { YoutubeStats } from "./YoutubeStats";
import { makeCard } from "../utils";
import { usePermissions } from "react-admin";
import { ProofreaderStats } from "./ProofreaderStats";
import { YoutubeOrderStats } from "./YoutubeOrderStats";

/**
 * Editor Dashboard Element
 * @param {*} param0
 * @returns
 */
export const EditorDashboard = ({ userId, ...props }) => {
  const { permissions } = usePermissions();

  return [
    makeCard(
      "Your orders ready for QC",
      "Orders that you can review, defaults to the orders requested by you.",
      <EditorContentList
        userId={userId}
        filterDefaultValues={{
          created_by: userId,
          status: STATUS.review,
        }}
        status={STATUS.review}
        hasEdit
        permissions={permissions}
        withAside={false}
        key={"Content List"}
      />,
    ),
    makeCard(
      "Website Stats",
      "Aggregated information for the websites",
      <WebsiteStats
       resource={WebsiteStatsResource}
       basePath={WebsiteStatsResource}

        />,
    ),
    makeCard(
      "Youtube Content Stats",
      "Aggregated information for the youtube Content",
      <YoutubeStats />,
    ),
    makeCard(
      "Youtube Order Stats",
      "Aggregated information for the youtube orders",
      <YoutubeOrderStats />,
    ),
    makeCard(
      "Writer Stats",
      "Aggregated information for the writers",
      <WriterStats />,
    ),
    makeCard(
      "Proofreader Stats",
      "Aggregated information for the Proofreader",
      <ProofreaderStats />,
    ),
    makeCard(
      "PO Stats",
      "Aggregated information for the product owners",
      <POStats />,
    ),
  ];
};
