import * as React from "react";
import { API_URL, RESOURCE } from "../../constants";
import { get } from "../../utils";

export const useSalesUsers = () => {
  const [salesUsers, setSalesUsers] = React.useState([]);

  React.useEffect(() => {
    const fetchPicUsers = async () => {
      try {
        const response = await get(`${API_URL}/${RESOURCE.salesUsers}/`);
        const data = response.json;
        const results = data.results.map((result) => ({
          value: result.email,
          label: result.display_name,
          color: result.label_color,
        }));
        setSalesUsers(results);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchPicUsers();
  }, []);
  return salesUsers;
};
