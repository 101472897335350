import React from "react";

import { List as RAList, Datagrid, useNotify } from "react-admin";

import { WebsiteReferenceField } from "../website";
import { ChipArrayField } from "../reusable";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import filters from "./Filters";
import Actions from "./components/Actions";
import { Button, Divider, IconButton } from "@material-ui/core";
import copy from "copy-to-clipboard";

const OutreachInfo = ({ record }) => {
  const outreachs = record.data?.data;
  const notify = useNotify();
  if (!outreachs) return null;

  const copyToClipboard = (value) => {
    copy(value);
    notify("Link Copied to Clipboard!");
  };

  return (
    <>
      {outreachs.map((outreach) => (
        <>
          <b>{outreach.id}: </b>
          <a href={outreach.linking_page} target="_blank" rel="noreferrer">
            {outreach.linking_page}
          </a>
          <IconButton
            onClick={() => copyToClipboard(outreach.linking_page)}
            variant="contained"
            size="medium"
            color="secondary"
          >
            <FileCopyIcon />
          </IconButton>
          <Divider />
        </>
      ))}
    </>
  );
};

export const List = (props) => {
  return (
    <RAList
      {...props}
      filters={filters}
      sort={{ field: "last_modified", order: "DESC" }}
    >
      <Datagrid>
        <WebsiteReferenceField source="website" label="Website" />
        <ChipArrayField source="sites" label="Sites" />
        <ChipArrayField source="ips" label="IPs" />
        <OutreachInfo label="Outreach Live Links" {...props} />
        <Actions label="Actions" />
      </Datagrid>
    </RAList>
  );
};

export default List;
