import { SelectInput, useGetOne } from "react-admin";

import { useFormState } from "react-final-form";
import { RESOURCE } from "../../constants";

export const WriterRateSelectInput = (props) => {
  const values = useFormState();
  const writer = values?.values?.content?.writer;
  const { data, loaded, error } = useGetOne(RESOURCE.writer, writer, {
    enabled: !!writer,
  });

  const getChoices = () => {
    if (data && data.rates.length === 0) {
      return [{ id: data.rate, name: data.rate }];
    }
    return data && data.rates.map((rate) => ({ id: rate, name: rate }));
  };

  return (
    <SelectInput
      resettable
      defaultValue={data && data.rate}
      choices={writer ? getChoices() : []}
      {...props}
    />
  );
};
