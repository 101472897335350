import React from "react";
import {
  Create as RaCreate,
  SimpleForm,
  required,
  TextInput,
  CreateProps,
} from "react-admin";
import { HorizontalGroup } from "../../reusable";

export const CreateForm: React.FC<any> = (props) => (
  <SimpleForm {...props}>
    <HorizontalGroup title={"Account info"} full>
      <TextInput
        source={"username"}
        label={"Account UserName"}
        validate={[required()]}
      />
      <TextInput
        source={"login_url"}
        label={"Account Login URL"}
        validate={[required()]}
      />
    </HorizontalGroup>
  </SimpleForm>
);

export const Create = (props: CreateProps): React.ReactElement => {
  return (
    <RaCreate {...props}>
      <SimpleForm>
        <HorizontalGroup title={"Account info"} full>
          <TextInput
            source={"username"}
            label={"Account UserName"}
            validate={[required()]}
          />
          <TextInput
            source={"login_url"}
            label={"Account Login URL"}
            validate={[required()]}
          />
        </HorizontalGroup>
      </SimpleForm>
    </RaCreate>
  );
};

export default Create;
