import { ReferenceField, TextField } from "react-admin";

import { RESOURCE } from "../../constants";

export const WriterReferenceField = ({
  source,
  label,
  reference,
  ...props
}) => {
  return (
    <ReferenceField
      label={label ? label : "Writer"}
      source={source ? source : "writer"}
      reference={reference ? reference : RESOURCE.writer}
      {...props}
    >
      <TextField source="full_name" />
    </ReferenceField>
  );
};

WriterReferenceField.defaultProps = {
  label: "Writer",
  addLabel: true,
};
