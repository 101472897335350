import * as React from "react";
import {
  useNotify,
  useUpdate,
  useResourceContext,
  useRecordContext,
} from "react-admin";
import { useFormState } from "react-final-form";

const DEFAULT_SECONDS_TO_WAIT = 30;
interface State {
  pristine: boolean;
  values: any;
}
/**
 * This component is responsible to useUpdate periodically on the form.
 * @param seconds number of seconds to wait before auto-saving.
 * @returns {React.ReactElement}
 * @constructor
 */
export const AutoSave = ({
  seconds = DEFAULT_SECONDS_TO_WAIT,
}): React.ReactElement => {
  const { values, pristine } = useFormState();
  const formState = React.useRef<State>({
    pristine,
    values,
  });
  const updatingForm = React.useRef<boolean>(false);
  const notified = React.useRef<boolean>(false);

  const resource = useResourceContext();
  const record = useRecordContext();
  const { id } = record;
  const [update, state] = useUpdate();
  const notify = useNotify();

  // updates the form status regularly
  React.useEffect(() => {
    formState.current = {
      pristine,
      values,
    };
  }, [pristine, values]);

  React.useEffect(() => {
    // the task will run every 1 second
    const mseconds = 1000;
    const interval = setInterval(() => {
      // update if there is any change and is not saving
      if (!formState.current.pristine && !updatingForm.current) {
        updatingForm.current = true;
        update(resource, id, formState.current.values);
        // unlock the notify
        notified.current = false;
      }
    }, mseconds);
    return () => clearInterval(interval);
  }, [id, update, resource, notify]);

  React.useEffect(() => {
    // Update the updatingForm flag
    if (state.loaded || !state.loading) {
      updatingForm.current = false;
    }
    if (state.error && !notified.current) {
      notified.current = true;
      notify(`Error while auto-saving: ${state.error.message}`);
      console.error(state.error.body);
    }
  }, [notify, state]);
  return <></>;
};
