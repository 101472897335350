import {
  /**
   * Edit
   */
  Edit as RAEdit,
  SimpleForm,
} from "react-admin";

import Form from "./Form";

export const Edit = (props) => (
  <RAEdit {...props}>
    <SimpleForm>
      <Form />
    </SimpleForm>
  </RAEdit>
);

export default Edit;
