import React from "react";

import {
  List as RAList,
  Datagrid,
  ListProps,
  ReferenceField,
  TextField,
  downloadCSV,
  useNotify,
  useRecordContext,
  FunctionField,
  DateField,
} from "react-admin";
import EditableTextField from "../../../reusable/fields/EditableTextField";
import { MarketEditableReferenceField } from "../../../market/fields/MarketlReferenceField";
import { RESOURCE as BRAND } from "../../brands/constants";
import { RESOURCE as DEAL } from "../../deals/constants";
import { RESOURCE as ACCOUNT } from "../../accounts/constants";
import { EditableWebsiteReferenceField } from "../../../website/fields/WebsiteReferenceField";
import { RESOURCE } from "../../../constants";
import jsonexport from "jsonexport/dist";
import { todayISOString } from "../../../reusable";
import { makeStyles } from "@material-ui/core/styles";
import { EditableYoutubeReferenceField } from "../../../youtube/fields";
import { Button, IconButton, Tooltip } from "@material-ui/core";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { EditableAccountReferenceField } from "../../accounts/input";
import { LinkCopyAction } from "../../deals/List/Actions";
import filters from "./Filters";
import Actions from "./Actions";

const useStyles = makeStyles((theme) => {
  return {
    horizontalScroll: {
      width: "130vw",
    },
    field: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      wordBreak: "break-word",
    },
    textBreak: {
      wordBreak: "break-word",
    },
    alignedRight: {
      display: "flex",
      flexDirection: "row-reverse",
    },
  };
});

export const NotesToolTip = () => {
  const record = useRecordContext();
  if (!record) return null;
  if (!record.data?.notes) return null;
  return (
    <Tooltip title={record.data?.notes}>
      <IconButton>❗️️️</IconButton>
    </Tooltip>
  );
};

export const IsClickTrackedField = (props: any) => {
  const record = useRecordContext();
  if (!record) return null;
  // display check mark if is_click_tracked is true
  return record.is_click_tracked ? <span>✅</span> : <span>❌</span>;
};

export const List = (props: ListProps): React.ReactElement => {
  const classes = useStyles();
  const notify = useNotify();

  const render = React.useCallback(
    (record: any) =>
      `${record?.market_country} - ${record?.data?.revenue_share} + ${record?.data?.cpa} + ${record?.data?.baseline}`,
    [],
  );

  return (
    <RAList {...props} filters={filters} exporter={exporter}>
      <>
        <span className={classes.alignedRight}>
          <CopyToClipboard
            text={"€"}
            onCopy={() => notify(`'€' copied to clipboard.`)}
          >
            <Button variant="outlined">Copy €</Button>
          </CopyToClipboard>
        </span>
        <Datagrid
          className={classes.horizontalScroll}
          rowStyle={(record, index) => {
            return {
              backgroundColor: record.deal_end_date ? "#fef08a" : "white",
            };
          }}
        >
          <NotesToolTip />
          <DateField source={"created"} label={"Created"} />
          <ReferenceField reference={BRAND} source={"brand"}>
            <TextField source={"name"} />
          </ReferenceField>
          <ReferenceField reference={DEAL} source={"deal"}>
            <FunctionField render={render} />
          </ReferenceField>
          <MarketEditableReferenceField source={"market"} />
          <TextField label={"Channel"} source={"data.channel"} />
          <EditableWebsiteReferenceField
            className={classes.textBreak}
            source={"website"}
          />
          <EditableYoutubeReferenceField
            className={classes.textBreak}
            source={"youtube"}
          />
          <EditableAccountReferenceField
            className={classes.textBreak}
            source={"account"}
          />
          <EditableTextField
            className={classes.textBreak}
            source={"campaign"}
            label={"Campaign"}
          />
          <EditableTextField
            source={"product"}
            label={"Product"}
            choices={[
              { id: 1, name: "Casino" },
              { id: 2, name: "Sportbetting" },
              { id: 3, name: "Poker" },
              { id: 4, name: "Finance" },
            ]}
          />
          <EditableTextField source={"link"} label={"Affiliate link"} />
          <LinkCopyAction
            label="Copy"
            source={"link"}
            tooltip={"Affiliate Link"}
          />
          <EditableTextField source={"pretty_link"} label={"Pretty Link"} />
          <LinkCopyAction
            label="Copy"
            source={"pretty_link"}
            tooltip={"Pretty Link"}
          />
          <EditableTextField
            source={"pretty_link_yt"}
            label={"Pretty Link (Youtube)"}
          />
          <LinkCopyAction
            label="Copy"
            source={"pretty_link_yt"}
            tooltip={"Pretty Link Youtube"}
          />
          <EditableTextField source={"data.notes"} label={"Notes"} />
          <IsClickTrackedField source={"is_click_tracked"} />
          <Actions label="Actions" />
        </Datagrid>
      </>
    </RAList>
  );
};

const exporter = async (stats: any, fetchRelatedRecords: any) => {
  const clearDate = (value: string | undefined) => {
    if (value) {
      return value.split("T")[0];
    }
    return "";
  };
  const [brand, deal, account, market, website, youtube] = await Promise.all([
    fetchRelatedRecords(stats, "brand", BRAND),
    fetchRelatedRecords(stats, "deal", DEAL),
    fetchRelatedRecords(stats, "account", ACCOUNT),
    fetchRelatedRecords(stats, "market", RESOURCE.market),
    fetchRelatedRecords(stats, "website", RESOURCE.website),
    fetchRelatedRecords(stats, "youtube", RESOURCE.youtube),
  ]);

  const data = stats.map((entry: any) => ({
    Brand: brand[entry.brand]?.name,
    Market: market[entry.market]?.country,
    Channel: entry.data.channel,
    Website: website[entry.website]?.domain,
    Youtube: youtube[entry.youtube]?.channel_name,
    Account: account[entry.account]?.name,
    Campaign: entry.data.campaign,
    "Affiliate link": entry.link,
    "Pretty Link": entry.pretty_link,
    "Admin Fee": deal[entry.deal]?.data?.admin_fee,
    "Effective Revenue Share": deal[entry.deal]?.data?.effective_revenue_share,
    "Revenue Share": deal[entry.deal]?.data?.revenue_share,
    CPA: deal[entry.deal]?.data?.cpa,
    Baseline: deal[entry.deal]?.data?.baseline,
    "Baseline Cumulative": deal[entry.deal]?.data?.is_baseline_cumulative,
    "Min Dep": deal[entry.deal]?.data?.min_dep,
    Currency: deal[entry.deal]?.data?.currency,
    "Bl Cumulative": deal[entry.deal]?.data?.is_bl_cumulative,
    NNCO: deal[entry.deal]?.data?.nnco,
    "Closing Date": clearDate(deal[entry.deal]?.closing_date),
    "Starting Date": clearDate(deal[entry.deal]?.starting_date),
    "End Date": clearDate(deal[entry.deal]?.end_date),
  }));

  jsonexport(
    data,
    {
      headers: [
        "Brand",
        "Market",
        "Channel",
        "Website",
        "Youtube",
        "Account",
        "Campaign",
        "Affiliate link",
        "Pretty Link",
        "Admin Fee",
        "Effective Revenue Share",
        "Revenue Share",
        "CPA",
        "Baseline",
        "Baseline Cumulative",
        "Min Dep",
        "Currency",
        "Bl Cumulative",
        "NNCO",
        "Closing Date",
        "Starting Date",
        "End Date",
      ],
    },
    (err, csv) =>
      !err
        ? downloadCSV(csv, `sales-link.${todayISOString()}`)
        : console.error(err),
  );
};

export default List;
