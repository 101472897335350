import React from "react";
import { ListGuesser, Resource as RAResource } from "react-admin";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import { RESOURCE, PERMISSIONS } from "./constants";
import List from "./List";
import Create from "./Create";

/**
 * For some reason the APP want the resource to be instantiated with this make factory.
 * @param {*} permissions
 * @param {*} key
 * @returns
 */
export const make = (permissions: any): React.ReactElement => {
  const permission = permissions?.permission || [];
  return (
    <RAResource
      name={RESOURCE}
      list={permission.includes(PERMISSIONS.view) ? List : undefined}
      create={permission.includes(PERMISSIONS.create) ? Create : undefined}
      options={{ label: "Accounts" }}
      key={RESOURCE}
      icon={AccountBoxIcon}
    />
  );
};

export default make;
