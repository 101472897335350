import { RESOURCE } from ".././constants";
import React from "react";
import { EditableReferenceField } from "../../../reusable/fields/EditableReferenceField";

export const EditbaleDealsReferenceField = ({
  source = "deal",
  label = "Deal",
  ...props
}) => {
  return (
    <EditableReferenceField
      reference={RESOURCE}
      label={label}
      source={source}
      fieldProps={{
        source: "id",
      }}
      {...props}
    />
  );
};
