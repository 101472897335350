import React from "react";
import { Edit as RaEdit } from "react-admin";
import { Form } from "./Form";

export const Edit = (props) => {
  return (
    <RaEdit mutationMode="pessimistic" {...props}>
      <Form />
    </RaEdit>
  );
};

export default Edit;
