import React, { useEffect } from "react";

import { List as RAList, Datagrid, ListProps, TextField } from "react-admin";

import { MarketReferenceField } from "../../../market/fields/MarketlReferenceField";
import filters from "./Filters";
import { WebsiteReferenceField } from "../../../website/fields/WebsiteReferenceField";
import { makeStyles } from "@material-ui/core/styles";
import { YoutubeReferenceField } from "../../../youtube/fields/YoutubeReferenceField";
import { Actions, LinkCopyAction } from "./Actions";
import { RESOURCE as PMUTMRESOURCE } from "../../pmUTMMedium/constants";
import { dataProvider } from "../../../reusable/store";
import { IsClickTrackedField } from "../../lists/List/List";

export const useStyles = makeStyles((theme) => {
  return {
    hidden: {
      display: "none",
    },
    disableFadeEnter: {
      "&>.fade-enter": {
        opacity: 1,
        transform: "none",
      },
    },
    flex: {
      "&>li>section": {
        display: "flex",
      },
    },
    itemsCenter: {
      "&>li>section": {
        alignItems: "center",
      },
    },
    fullWidth: {
      width: "100%",
    },
    field: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      wordBreak: "break-word",
    },
    textBreak: {
      wordBreak: "break-word",
    },
    alignedRight: {
      display: "flex",
      flexDirection: "row-reverse",
    },
  };
});

export const List = (props: ListProps): React.ReactElement => {
  const classes = useStyles();
  const [UTMMediums, setUTMMediums] = React.useState<any>([]);

  useEffect(() => {
    const getAllPMUTMMediums = async () => {
      try {
        const data = await dataProvider.getList(
          PMUTMRESOURCE,

          {
            pagination: { page: 1, perPage: 100 },
            sort: { field: "id", order: "DESC" },
            filter: {},
          },
        );
        setUTMMediums(data?.data);
      } catch (error) {
        console.error(error);
      }
    };
    getAllPMUTMMediums();
  }, []);

  return (
    <RAList {...props} filters={filters}>
      <Datagrid>
        <TextField source={"brand_name"} />
        <MarketReferenceField source={"market"} />
        <TextField label={"Channel"} source={"data.channel"} />
        <WebsiteReferenceField
          className={classes.textBreak}
          source={"website"}
        />
        <YoutubeReferenceField
          className={classes.textBreak}
          source={"youtube"}
        />
        <TextField source={"data.product"} label={"Product"} />
        <TextField
          className={classes.textBreak}
          source={"data.campaign"}
          label={"Campaign"}
        />
        <TextField source={"pretty_link"} label={"Pretty Link"} />
        <Actions
          label={"Actions"}
          UTMMediums={UTMMediums}
          source={"pretty_link"}
          tooltip="Pretty Link"
        />
        <TextField source={"pretty_link_yt"} label={"Pretty Link (Youtube)"} />
        <LinkCopyAction
          label={"Actions"}
          source={"pretty_link_yt"}
          tooltip="Youtube Pretty Link"
        />
        <IsClickTrackedField source={"is_click_tracked"} />
      </Datagrid>
    </RAList>
  );
};

export default List;
