import * as React from "react";

import { useNotify, useRecordContext, useRefresh } from "react-admin";

import { WriterToolbar } from "./writer/writerToolbar";

import { EditForm } from "./edit/EditForm";

export const WriterWrite = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const onSuccess = () => {
    refresh();
    notify("Element updated");
  };

  return (
    <EditForm {...props} toolbar={<WriterToolbar />} onSuccess={onSuccess} />
  );
};
