import * as React from "react";
import { Error, Loading } from "react-admin";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Chip from "@material-ui/core/Chip";
import CardHeader from "@material-ui/core/CardHeader";
import { useStyles } from "../edit/useStyles";

export const YtgField = ({ aside, error, loading, record, ...props }) => {
  const classes = useStyles();
  if (record === undefined) return null;
  if (record.guide_id === null) return null;
  /**
   * YTG may not be very solid so each entity is used on its own.
   */

  const guide = record.guide_data || {};
  const ensure = (maybe_array) =>
    maybe_array === undefined ? [] : maybe_array;
  const chippize = (array) =>
    ensure(array).map((val, idx) => (
      <Chip className={classes.chippedList} label={val} key={idx} />
    ));

  const entities = chippize(guide.entities);
  const grammes1 = chippize(guide.grammes1);
  const grammes2 = chippize(guide.grammes2);
  const grammes3 = chippize(guide.grammes3);

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;

  return (
    <Card className={aside ? classes.aside : null}>
      <CardHeader title="Yourtext guru" />
      <CardContent>
        <Card title={record.guide}>
          <CardHeader title="Guide keyword" subheader={record.guide} />
          <CardContent>
            <Chip
              component="a"
              href={`https://yourtext.guru/view/${record.guide_id}`}
              label={"View"}
              clickable
              className={classes.chippedList}
              color="secondary"
              target="_blank"
            ></Chip>
            <Chip
              component="a"
              href={`https://yourtext.guru/stats/${record.guide_id}`}
              label={"Check stats"}
              clickable
              className={classes.chippedList}
              color="primary"
              target="_blank"
            ></Chip>
          </CardContent>
        </Card>
        {grammes1 ? (
          <Card title="One word keywords">
            <CardHeader title="One word keywords"></CardHeader>
            <CardContent className={classes.flexWrappedItems}>
              {grammes1}
            </CardContent>
          </Card>
        ) : null}

        {grammes2 ? (
          <Card title="Two word keywords">
            <CardHeader title="Two word keywords"></CardHeader>
            <CardContent className={classes.flexWrappedItems}>
              {grammes2}
            </CardContent>
          </Card>
        ) : null}

        {grammes3 ? (
          <Card title="Three word keywords">
            <CardHeader title="Three word keywords"></CardHeader>
            <CardContent className={classes.flexWrappedItems}>
              {grammes3}
            </CardContent>
          </Card>
        ) : null}

        {entities ? (
          <Card title="Entities">
            <CardHeader title="Entities"></CardHeader>
            <CardContent className={classes.flexWrappedItems}>
              {entities}
            </CardContent>
          </Card>
        ) : null}
      </CardContent>
    </Card>
  );
};
