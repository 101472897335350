import { ReferenceInput, AutocompleteInput } from "react-admin";

import { RESOURCE } from "../../constants";

export const PBNReferenceInput = ({ source, label, helperText, ...props }) => (
  <ReferenceInput
    source={source ? source : "website"}
    label={label ? label : "PBN"}
    reference={RESOURCE.pbn}
    sortBy={"domain"}
    {...props}
  >
    <AutocompleteInput
      optionText="domain"
      optionValue="id"
      helperText={helperText}
    />
  </ReferenceInput>
);

PBNReferenceInput.defaultProps = {
  label: "PBN",
  addLabel: true,
};

export default PBNReferenceInput;
