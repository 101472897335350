import { ReferenceArrayField, ChipField, SingleFieldList } from "react-admin";

import { RESOURCE } from "../../constants";

export const WebsiteReferenceArrayField = ({ source, label, ...props }) => (
  <ReferenceArrayField
    label={label ? label : "Website"}
    target={props.target ? props.target : "id"}
    source={source ? source : "website"}
    reference={RESOURCE.website}
    {...props}
  >
    <SingleFieldList>
      <ChipField size="small" source="domain" />
    </SingleFieldList>
  </ReferenceArrayField>
);

WebsiteReferenceArrayField.defaultProps = {
  label: "Websites",
  addLabel: true,
};
