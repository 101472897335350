import { TextInput, NumberInput, useNotify, required } from "react-admin";

import {
  ArrayTextInput,
  generatePassword,
  HorizontalGroup,
} from "../../reusable";
import { MarketReferenceInput } from "../../market";
import { useStyles } from "../../useStyles";
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";
import { Chip } from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { useState } from "react";

const Form = ({ withPassword = false, props }) => {
  const classes = useStyles();
  const notify = useNotify();
  const [password, setPassword] = useState(generatePassword(14));
  return [
    <HorizontalGroup
      key={"Personal information"}
      label={"Personal information"}
    >
      <TextInput
        className={classes.big}
        source="username"
        validate={required()}
      />
      <TextInput className={classes.big} source="first_name" />
      <TextInput className={classes.big} source="last_name" />
    </HorizontalGroup>,
    withPassword ? (
      <HorizontalGroup key={"password"} label={"Set the password"}>
        <TextInput
          className={classes.bigAndBigger}
          type={"password"}
          source={"user_password"}
          label={"Password"}
          onChange={(e) => setPassword(e.target.value)}
          initialValue={password}
          validate={required()}
        />
        <CopyToClipboard
          text={password}
          onCopy={() => notify(`Password copied to clipboard.`)}
        >
          <Chip
            clickable
            variant={"outlined"}
            icon={<FileCopyIcon />}
            label={"Copy"}
          />
        </CopyToClipboard>
      </HorizontalGroup>
    ) : null,
    <HorizontalGroup key={"Contact Information"} label={"Contact Information"}>
      <TextInput className={classes.big} source="skype" label="Skype/IM" />
      <TextInput className={classes.bigger} source="email" type="email" />
      <TextInput
        className={classes.big}
        source="slack_channel"
        type="text"
        defaultValue={"#proofreader_feedback"}
      />
    </HorizontalGroup>,
    <HorizontalGroup label={"Writer Information"} key={"Writer Information"}>
      <ArrayTextInput
        className={classes.big}
        source="favorite_subjects"
        label="Favorite Subjects"
      />
      <NumberInput
        onWheel={(e) => e.target.blur()}
        className={classes.big}
        source="words_per_week"
      />
      <MarketReferenceInput className={classes.big} validate={required()} />
    </HorizontalGroup>,
    <HorizontalGroup
      label={"Financial Information"}
      key={"Financial Information"}
    >
      <NumberInput
        onWheel={(e) => e.target.blur()}
        className={classes.big}
        source="rate"
        validate={required()}
      />
      <TextInput
        className={classes.big}
        source="currency"
        validate={required()}
        initialValue={"EUR"}
      />
    </HorizontalGroup>,
    <TextInput
      className={classes.bigAndBigger}
      multiline
      source="payment_instruction"
      key="payment_instruction"
      validate={required()}
    />,
  ];
};

export const makeForm = (withPassword = false) => (
  <Form withPassword={{ withPassword }} />
);
export default Form;
