import { Resource } from "react-admin";

import { RESOURCE, GROUPS } from "../../constants";

const RES = RESOURCE.contentTemplate;
/**
 * For some reason the APP want the resource to be instantiated with this make factory.
 * @param {*} permissions
 * @param {*} key
 * @returns
 */
export const makeContentTemplate = (permissions) =>
  permissions.groups.includes(GROUPS.editors) ||
  permissions.groups.includes(GROUPS.integrators) ? (
    <Resource
      name={RES}
      key={RES}
      // options={{label: 'Outreach Requests'}}
      // list={ List }
    />
  ) : (
    <Resource name={RES} key={RES} />
  );
