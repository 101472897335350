import { PERMISSIONS } from "./constants";

export const isSalesBrandAdmin = (permissions: string | undefined) => {
  if (
    permissions &&
    permissions.includes(PERMISSIONS.view) &&
    permissions.includes(PERMISSIONS.edit) &&
    permissions.includes(PERMISSIONS.create) &&
    permissions.includes(PERMISSIONS.delete)
  ) {
    return true;
  }
  return false;
};
