import * as React from "react";

import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import debounce from "lodash.debounce";
import { useDataProvider, useNotify } from "react-admin";

import { CountedTextField } from "../../reusable";
import { RESOURCE } from "../../constants";

export const MetaData = (props) => {
  const { record } = props;

  const notify = useNotify();
  const dataProvider = useDataProvider();
  const [metaTitle, setMetaTitle] = React.useState(record.meta_title);
  const [metaDescription, setMetaDescription] = React.useState(
    record.meta_description,
  );

  const handleMetaData = React.useMemo(
    () =>
      debounce((field, value) => {
        if (!record?.id) return;
        dataProvider
          .update(RESOURCE.order, {
            id: record?.id,
            data: {
              [field]: value,
            },
          })
          .then(({ data }) => {
            notify(`Meta data updated successfully`);
          })
          .catch((err) => {
            console.err(err);
            notify("Something went wrong! Please try again", "warning");
          });
      }, 500),
    [record?.id, dataProvider, notify],
  );

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="metadata-content"
        id="metadata-header"
      >
        <Typography variant={"h5"}>
          {`Meta data${props.record.editable_metas ? "" : " (Not requested)"}`}
        </Typography>
        ,
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <CountedTextField
              suggestedLength={50}
              maximumLength={150}
              suggestedWithin={10}
              multiline
              fullWidth
              source="meta_title"
              label={"Meta title"}
              initialValue=""
              value={metaTitle}
              onChange={(e) => {
                setMetaTitle(e.target.value);
                handleMetaData("meta_title", e.target.value);
              }}
              disabled={!props.record.editable_metas && props.isFreelance}
            />
          </Grid>
          <Grid item xs={12}>
            <CountedTextField
              suggestedLength={150}
              maximumLength={400}
              suggestedWithin={10}
              multiline
              fullWidth
              source="meta_description"
              label={"Meta description"}
              initialValue=""
              value={metaDescription}
              onChange={(e) => {
                setMetaDescription(e.target.value);
                handleMetaData("meta_description", e.target.value);
              }}
              disabled={!props.record.editable_metas && props.isFreelance}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
