import React from "react";
import { usePermissions } from 'react-admin';

import { Resource as RAResource } from "react-admin";
import BusinessIcon from "@material-ui/icons/Business";
import { PERMISSIONS, ADMIN, RESOURCE } from "./constants";

import List from "./List";
// import Edit from "./Edit";
// import { PERMISSIONS } from "../brands/constants";

/**
 * For some reason the APP want the resource to be instantiated with this make factory.
 * @param {*} permissions
 * @param {*} key
 * @returns
 */
export const make = (permissions: any): React.ReactElement | null => {
  const isAdmin = permissions?.role === ADMIN;
  const permission = permissions?.permission || [];
  const canView = permission.includes(PERMISSIONS.view) || isAdmin;
  return (
    <RAResource
      name={RESOURCE}
      options={{ label: "Website Stats" }}
      list={canView ? List : undefined}
      key={RESOURCE}
      icon={BusinessIcon}
    />
  );
};

export default make;
