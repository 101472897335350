import {
  /**
   * Show
   */
  Show,
  SimpleShowLayout,
  TextField,
  NumberField,
  EmailField,

  /**
   * Edit
   */
  Edit,
  SimpleForm,
  TextInput,
} from "react-admin";

export const WriterSelfEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextField source="id" />
      <TextInput source="username" />
      <NumberField source="rate" />
      <TextField source="currency" />
      <TextInput multiline source="payment_instruction" />
      <TextInput source="skype" />
      <TextInput source="email" type="email" />
    </SimpleForm>
  </Edit>
);

export const WriterShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="username" />
      <NumberField source="rate" />
      <TextField source="currency" />
      <TextField source="payment_instruction" />
      <TextField source="skype" />
      <EmailField source="email" />
    </SimpleShowLayout>
  </Show>
);
