import { List as RAList, ShowButton } from "react-admin";

import { CardGrid } from "../reusable";
import { Filter } from "./Filter";
import { Links, Video } from "./utils";

export const List = (props) => (
  <RAList
    {...props}
    filters={<Filter />}
    sort={{ field: "date_updated", order: "DESC" }}
  >
    <CardGrid
      title={"title"}
      content={"data.description"}
      hasEdit={props.hasEdit}
      hasDelete={props.hasEdit}
      hasShow
      ShowButtonElement={ShowButton_}
      otherContent={[<OtherContent />]}
    />
  </RAList>
);

const OtherContent = (props) => {
  const { record } = props;
  const links = record.data.links;
  const video = record.data?.video;

  return [
    video ? <Video key={"video"} {...props} /> : null,
    links ? <Links key={"links"} {...props} /> : null,
  ];
};
const ShowButton_ = (props) =>
  props.record.data?.content ? (
    <ShowButton {...props} label={"Show more"} />
  ) : null;

export default List;
