import { Resource } from "react-admin";

import { RESOURCE, GROUPS } from "../../constants";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";
import { ContentList } from "../ContentList";
import { FeedbackList } from "./List";

const RES = RESOURCE.contentFeedbacks;

/**
 * For some reason the APP want the resource to be instantiated with this make factory.
 * @param {*} permissions
 * @param {*} key
 * @returns
 */
export const makeContentFeedback = (permissions) =>
  permissions.groups.includes(GROUPS.editors) ||
  permissions.groups.includes(GROUPS.integrators) ||
  permissions.groups.includes(GROUPS.writers) ? (
    <Resource
      name={RES}
      key={RES}
      icon={RecordVoiceOverIcon}
      options={{ label: "Content Feedback" }}
      list={FeedbackList}
    />
  ) : (
    <Resource name={RES} key={RES} options={{ label: "Content Feedback" }} />
  );
