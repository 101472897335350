import * as React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { STATUS } from "../../constants";

import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

import { BottomBar } from "../edit/BottomBar";
import {
  SaveButton,
  usePermissions,
  useNotify,
  fetchStart,
  fetchEnd,
  useRefresh,
  useRedirect,
  Loading,
  useRecordContext,
} from "react-admin";

import { API_URL, GROUPS, RESOURCE } from "../../constants";
import { httpClient } from "../../utils";

import { MarkAsReadyButton } from "./MarkAsReadyButton";
import { withStyles } from "@material-ui/core";
import { ApproveButton } from "../editor/ReviewToolbar";
import { RejectContentButton } from "../editor/RejectContentButton";
import { EstimatedQuoteField } from "../ContentList";
import Chip from "@material-ui/core/Chip";
import { ProofreaderApproveButton } from "../editor/ProofreaderApproveButton";

export const ProofreaderToolbar = withStyles({
  backdrop: {
    zIndex: 100,
  },
  chip: {
    marginRight: ".5rem",
  },
})(({ classes, ...props }) => {
  const record = useRecordContext();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const permissions = usePermissions();

  const writer = permissions.loading
    ? null
    : permissions.permissions.groups.includes(GROUPS.writers);

  const proofreader = permissions.loading
    ? null
    : permissions.permissions.groups.includes(GROUPS.proofreader);

  // Once submitted a writer can't save the content anymore
  const disabled = writer && record.status !== STATUS.requested;

  // if the content is disabled redirect to show.
  React.useEffect(() => {
    if (disabled && writer) {
      redirect(`/${RESOURCE.order}/${record.id}/show`);
    }
  }, [disabled, writer, redirect, record.id]);

  if (permissions.loading) return <Loading />;
  const validate = () => {
    const path = `${API_URL}${props.basePath}/${record.id}/validate/`;
    setLoading(true);
    dispatch(fetchStart()); // start the global loading indicator
    httpClient(path, { method: "POST" })
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response);
        }
        return response.json;
      })
      .catch((e) => {
        const reason = e.body?.reason ? e.body.reason : e.message;
        return Promise.reject(`warning: ${reason}`);
      })
      .then(
        ({ status, valid }) => {
          notify(`Content Saved Successfully`);
        },
        (e) => notify(e, "info"),
      )
      .finally(() => {
        setLoading(false);
        dispatch(fetchEnd()); // stop the global loading indicator
        refresh();
      });
  };
  if (loading)
    return (
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  return (
    <BottomBar
      withToolbar={true}
      quoteField={null}
      // <QuoteChip className={classes.Chip} record={record} />
      saveButton={
        <SaveButton
          disabled={props.pristine || disabled}
          pristine
          onSuccess={validate}
        />
      }
      // submitButton={
      //   writer ? (
      //     <MarkAsReadyButton
      //       pristine={props.pristine}
      //       disabled={!props.pristine || disabled}
      //     />
      //   ) : null
      // }
      approveButton={
        proofreader ? <ProofreaderApproveButton {...props} /> : null
      }
      rejectButton={proofreader ? <RejectContentButton {...props} /> : null}
      {...props}
    />
  );
});

const QuoteChip = ({ className, record, ...props }) => {
  return (
    <Chip
      className={className}
      color="secondary"
      label={<EstimatedQuoteField estimate record={record} />}
    />
  );
};
