import React, { Suspense, lazy } from "react";
import { Loading } from "react-admin";

const ListElement = lazy(() => import("./ListElement"));

export const List = (props) => (
  <Suspense fallback={<Loading />}>
    <ListElement {...props} />
  </Suspense>
);
