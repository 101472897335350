import { GROUPS } from "../../constants";

export const checkCoupemonde = (record, permissions) => {
  return (
    record &&
    permissions &&
    record.url &&
    record.url.includes("coupemonde.fr") &&
    permissions?.groups?.includes &&
    permissions?.groups?.includes(GROUPS.coupemonde)
  );
};
