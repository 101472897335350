import { TextInput, SearchInput, SelectInput } from "react-admin";
import { MarketReferenceInput } from "../../market";
import { CHANNEL, DEAL } from "../deals/constants";
import { VerticalReferenceArrayInput } from "../../vertical";
import { makeStyles, Chip } from "@material-ui/core";
import LicenceReferenceInput from "../licences/input";
import SalesUserReferenceInput from "../salesUser/input";

const useQuickFilterStyles = makeStyles((theme) => ({
  chip: {
    marginBottom: theme.spacing(1),
  },
}));
const QuickFilter = (props: any) => {
  const classes = useQuickFilterStyles();
  return <Chip className={classes.chip} label={props.label} />;
};

export const filters = [
  <SearchInput source="q" alwaysOn />,
  <SalesUserReferenceInput
    source={"owner"}
    label={"Sales User"}
    helperText={""}
    emptyValue={"All"}
    resettable
    alwaysOn
    allowEmpty={false}
  />,
  <MarketReferenceInput
    source={"market"}
    label={"Market"}
    helperText={""}
    emptyValue={"All"}
    resettable
    alwaysOn
    allowEmpty={false}
  />,
  <TextInput label="Link" source="link" defaultValue="" alwaysOn />,
  <SelectInput
    label="Deal"
    source="deal"
    emptyValue=""
    resettable
    choices={DEAL}
    allowEmpty={false}
    alwaysOn
  />,
  <SelectInput
    label="Channel"
    source="channel"
    emptyValue=""
    resettable
    choices={CHANNEL}
    allowEmpty={false}
    alwaysOn
  />,
  <LicenceReferenceInput
    source={"licences"}
    label={"License"}
    helperText={""}
    emptyValue={"All"}
    resettable
    alwaysOn
    allowEmpty={false}
  />,
  <SelectInput
    label="Brand Status"
    source={"brand_status"}
    emptyValue=""
    resettable
    choices={[
      { id: "open_brands", name: "All Open Brands" },
      { id: "close_brands", name: "All Closed Brands" },
      { id: "active_brands", name: "All Active Brands" },
      { id: "in_dicussion", name: "Brands in Discussion" },
      { id: "no_contact", name: "No Contact" },
      { id: "bad_history", name: "Bad History" },
      { id: "bad_deal", name: "Bad Deal" },
    ]}
    alwaysOn
    allowEmpty={false}
  />,
  <VerticalReferenceArrayInput
    label="Vertical"
    source="vertical"
    resettable
    allowEmpty={false}
    alwaysOn
  />,
  <QuickFilter
    label="With Flat Fee"
    source="has_flat_fee"
    defaultValue={true}
  />,
];

export default filters;
