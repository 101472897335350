import * as React from "react";

import { CopyToClipboard } from "react-copy-to-clipboard";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ForwardIcon from "@material-ui/icons/Forward";
import CheckIcon from "@material-ui/icons/Check";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import Button from "@material-ui/core/Button";

import MarkAsDoubleChecked from "../../content/buttons/MarkAsDoubleChecked";
import MarkAsPublished from "../../content/buttons/MarkAsPublished";

import {
  Show,
  SimpleShowLayout,
  TextField,
  Loading,
  Error,
  useNotify,
  ReferenceField,
} from "react-admin";

import { useStyles } from "../../useStyles";
import { useContentByPage } from "../../content/useContent";
import { PageDetails } from "./details";
import { RES_USER, STATUS } from "../../constants";
import { NoFormAdvancedTextInput } from "../../reusable";
import DisplayContentSections from "../../content/edit/ContentSections";
import { ShowSectionText } from "../../reusable/fields/SectionTextInput";
import { Tooltip } from "@material-ui/core";

export const IntegratorPageShow = ({ permissions, ...props }) => {
  const { loaded, error, data } = useContentByPage(props.id, {
    status: STATUS.approved,
  });
  if (error) return <Error error={error} />;
  if (!loaded) return <Loading />;

  return (
    <Show {...props}>
      <SimpleShowLayout>
        <PageDetails />
        <CreateURL source={"create_url"} />
        {data.length > 0 ? <ContentShowLayout content={data[0]} /> : null}
      </SimpleShowLayout>
    </Show>
  );
};

export const IntegratorPageLayout = ({ order, content, ...props }) => {
  return (
    <SimpleShowLayout {...props}>
      <PersonInfo page={content} />

      <PageDetails />
      <CreateURL source={"create_url"} />
      <ContentShowLayout content={content} />
      {[STATUS.published, STATUS.double_checked].includes(content.status) ? (
        <MarkAsDoubleChecked content={content} />
      ) : (
        <MarkAsPublishedButton content={content} />
      )}
    </SimpleShowLayout>
  );
};

const PersonInfo = (props) => [
  <p>
    <small>Requested by: </small>
    <ReferenceField
      key={1}
      source="created_by"
      link={false}
      label={"Requested by"}
      reference={RES_USER}
      {...props}
      record={props.page}
    >
      <TextField source="full_name" disabled />
    </ReferenceField>
  </p>,
  <p>
    <small>Approved by: </small>
    <ReferenceField
      key={2}
      source="approved_by"
      link={false}
      reference={RES_USER}
      {...props}
      record={props.page}
    >
      <TextField source="full_name" label={"Approved by"} disabled />
    </ReferenceField>
  </p>,
  <p>
    <small>Written by: </small>
    <ReferenceField
      key={3}
      source="writer"
      link={false}
      reference={RES_USER}
      {...props}
      record={props.page}
    >
      <TextField source="full_name" label={"Writer"} disabled />
    </ReferenceField>
  </p>,
];

/**
 * Mark as published button, active only if the record is either active or sent to an integrator.
 * @param record
 * @returns {JSX.Element}
 * @constructor
 */
const MarkAsPublishedButton = ({ content, ...props }) => {
  const classes = useStyles();
  return (
    <MarkAsPublished
      className={classes.contentCopy}
      variant="contained"
      color="secondary"
      label={"Mark as published"}
      endIcon={<CheckIcon />}
      id={content.id}
      disabled={![STATUS.approved, STATUS.integration].includes(content.status)}
    />
  );
};

const CreateURL = ({ record, source }) => {
  const classes = useStyles();

  return record[source] ? (
    <div className={classes.contentCopy}>
      <Typography variant="body1" display="block">
        Create the page in the CMS.
      </Typography>
      <Typography variant="subtitle2">
        (please remember to assign the parent page)
      </Typography>

      <Button
        disabled={
          ![STATUS.approved, STATUS.integration].includes(record.status)
        }
        variant="outlined"
        color="secondary"
        href={record[source]}
        target="_blank"
        endIcon={<ForwardIcon />}
      >
        Go to the CMS admin.
      </Button>
    </div>
  ) : null;
};

const ContentShowLayout = ({ content, ...props }) => (
  <div>
    <NoFormAdvancedTextInput
      record={content}
      disabled
      source="structure.instructions"
      label={"instructions"}
    />
    <DisplayContentSections record={content} integrator={true} />
    <CopyAccordionShowMeta
      record={content}
      source="meta_title"
      label="Meta-Title"
    />
    <CopyAccordionShowMeta
      record={content}
      source="meta_description"
      label="Meta-Description"
    />
    <CopyAccordionIter record={content} source="reviews" label="Review" />
    <CopyAccordionIter record={content} source="forces" label="Force" />
    <CopyAccordionIter record={content} source="weakness" label="Weakness" />
  </div>
);

const CopyAccordionIter = ({ source, record, label, rich }) => {
  return (
    <div>
      {record[source].map((value, idx) => (
        <CopyAccordionShowMeta
          key={`${source}_${idx}`}
          record={record[source]}
          label={`${label} #${idx + 1}`}
          rich={rich}
          source={idx.toString()}
          id={`${source}_${idx}`}
        />
      ))}
    </div>
  );
};

export const CopyAccordionShow = ({ source, record, label, rich, id }) => {
  const notify = useNotify();
  const classes = useStyles();
  const id_ = id ? id : source;
  const props = { record, label, source };
  const child = rich ? (
    <ShowSectionText id={id_} sourceValue={record.content_text} label={label} />
  ) : (
    <TextField {...props} />
  );

  if (!record.content_text) {
    record.content_text = "test";
  }

  const [text] = React.useState(record.content_text);
  // const text = record[source] || 'text';
  // Skip if there is no content.

  const processContent = (content) => {
    // set `contenteditable` to false
    content = content.replaceAll(
      /contenteditable="true"/gi,
      'contenteditable="false"',
    );

    // remove the <figure></figure> tags
    // more info at: https://trello.com/c/
    // hpvbSZgu/1036-eden-deleting-figure-tag-on-copy-pasted-elements
    content = content.replaceAll(/<figure[^>]*>/gi, "");
    content = content.replaceAll(/<\/figure[^>]*>/gi, "");

    return content;
  };

  const processContentClean = (content) => {
    // remove the <figure></figure> tags
    // more info at: https://trello.com/c/
    // hpvbSZgu/1036-eden-deleting-figure-tag-on-copy-pasted-elements
    content = content.replaceAll(/<figure[^>]*>/gi, "");
    content = content.replaceAll(/<\/figure[^>]*>/gi, "");

    // remove the `svg` tags
    content = content.replaceAll(/<svg[^>]*>/gi, "");
    content = content.replaceAll(/<\/svg[^>]*>/gi, "");

    // remove the `paths` tags
    content = content.replaceAll(/<path[^>]*>/gi, "");
    content = content.replaceAll(/<\/path[^>]*>/gi, "");

    // remove the `contenteditable`
    content = content.replaceAll(/contenteditable="true"/gi, "");

    // remove all the `class` inside of the divs.
    content = content.replaceAll(/class="[^"]+"/gi, "");
    // remove all the empty divs
    content = content.replaceAll(/<div[^>\/]*>[\s]*<\/div>/gi, "");
    return content;
  };

  return (
    <div id={id_} className={classes.contentCopy}>
      <Tooltip
        title={
          <Typography className={classes.tooltip} fontSize={20}>
            Clean Copy copies the content <i>without</i> any HTML Classes.
            <br />
            Use <b>Copy to Clipboard</b> if you find any mismatch between Eden
            Content and Copied Content.
          </Typography>
        }
        placement="top"
        className={classes.tooltip}
      >
        <CopyToClipboard
          text={processContentClean(text)}
          options={{ format: rich ? "text/html" : "text/plain" }}
          onCopy={(text) => {
            notify(`${label} copied to clipboard!`);
          }}
        >
          <Button
            variant="outlined"
            color={"primary"}
            endIcon={<FileCopyOutlinedIcon />}
          >
            Clean Copy
          </Button>
        </CopyToClipboard>
      </Tooltip>

      <Tooltip
        title={
          <Typography className={classes.tooltip} fontSize={20}>
            This button copies all the content with HTML Classes
            <br />
            Use <b>Clean Copy</b> if you want the HTML without any classes.
          </Typography>
        }
        placement="top"
      >
        <CopyToClipboard
          text={processContent(text)}
          options={{ format: rich ? "text/html" : "text/plain" }}
          onCopy={(text) => {
            notify(`${label} copied to clipboard!`);
          }}
        >
          <Button
            variant="outlined"
            color={"secondary"}
            endIcon={<FileCopyOutlinedIcon />}
          >
            Copy {label} to clipboard
          </Button>
        </CopyToClipboard>
      </Tooltip>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`content-${id_}`}
          id={`header-${id_}`}
        >
          <Typography variant="h5">{label}</Typography>
        </AccordionSummary>
        <AccordionDetails>{child}</AccordionDetails>
      </Accordion>
    </div>
  );
};

const CopyAccordionShowMeta = ({ source, record, label, rich, id }) => {
  const notify = useNotify();
  const classes = useStyles();
  const id_ = id ? id : source;
  const props = { record, label, source };
  const child = rich ? (
    <NoFormAdvancedTextInput {...props} />
  ) : (
    <TextField {...props} />
  );
  if (!record[source]) {
    record[source] = "test";
  }

  const [text] = React.useState(record[source]);
  // const text = record[source] || 'text';
  // Skip if there is no content.
  return (
    <div id={id_} className={classes.contentCopy}>
      <CopyToClipboard
        text={text.replaceAll(
          /contenteditable="true"/gi,
          'contenteditable="false"',
        )}
        options={{ format: rich ? "text/html" : "text/plain" }}
        onCopy={(text) => {
          // console.log("result", text, text.includes('contenteditable="true"'));
          notify(`${label} copied to clipboard!`);
        }}
      >
        <Button
          variant="outlined"
          color={"primary"}
          endIcon={<FileCopyOutlinedIcon />}
        >
          Copy {label} to clipboard
        </Button>
      </CopyToClipboard>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`content-${id_}`}
          id={`header-${id_}`}
        >
          <Typography variant="h5">{label}</Typography>
        </AccordionSummary>
        <AccordionDetails>{child}</AccordionDetails>
      </Accordion>
    </div>
  );
};
