import { SelectInput, useGetOne } from "react-admin";

import { useFormState } from "react-final-form";
import { RESOURCE } from "../../constants";

export const WriterTemplateRateSelectInput = (props) => {
  const values = useFormState();
  const writer = values?.values?.content?.writer;
  const { data, loaded, error } = useGetOne(RESOURCE.writer, writer, {
    enabled: !!writer,
  });

  const getChoices = () => {
    if (data && data.content_template_rates.length === 0) {
      return [];
    }
    return (
      data &&
      data.content_template_rates.map((rate) => ({ id: rate, name: rate }))
    );
  };

  return (
    <SelectInput
      resettable
      defaultValue={data && data.rate}
      choices={writer ? getChoices() : []}
      {...props}
    />
  );
};

export const WriterTemplateRateSelectNoFormInput = ({ record }) => {
  const writer = record && record.writer;
  const { data, loaded, error } = useGetOne(RESOURCE.writer, writer, {
    enabled: !!writer,
  });

  const getChoices = () => {
    if (data && data.content_template_rates.length === 0) {
      return [];
    }
    return (
      data &&
      data.content_template_rates.map((rate) => ({ id: rate, name: rate }))
    );
  };

  return (
    <SelectInput
      resettable
      defaultValue={data && data.rate}
      choices={writer ? getChoices() : []}
    />
  );
};
