import { useCallback, useEffect, useState, ReactElement } from "react";
import { useFormState } from "react-final-form";

import {
  ReferenceInputProps,
  ReferenceInput,
  AutocompleteInput,
  useRefresh,
  useInput,
  useNotify,
  useGetOne,
} from "react-admin";

import { RESOURCE } from "../../../constants";

/*
After choosing the content this element will
 override the rest of the form with the values of the template.
 */
export const ContentNewTemplateReferenceInput = (
  props: ReferenceInputProps,
): ReactElement => {
  const refresh = useRefresh();
  const { label, helperText, fullWidth } = props;
  const {
    input: { onChange },
  } = useInput(props);

  // this will automatically take care of overriding the form.
  useTemplateOverride(props);

  return (
    <ReferenceInput
      {...props}
      key={"reference"}
      enableGetChoices={({ q }) => (q ? q.length > 2 : false)}
      onChange={(val: integer): void => {
        if (!val) {
          refresh();
        } else {
          onChange(val);
        }
      }}
      label={label ? label : "Template"}
      reference={RESOURCE.contentTemplateV2}
    >
      <AutocompleteInput
        optionValue="id"
        optionText={(record) => (record ? `${record?.template_name}` : "-")}
        helperText={helperText}
        fullWidth={fullWidth}
      />
    </ReferenceInput>
  );
};

/*
 Implementation of the template overwrite.
 */
const useTemplateOverride = (inputProps: ReferenceInputProps): void => {
  // utility function to keep this small
  const handleChange = useHandleChange(inputProps);

  // get the template from the form status
  const {
    values: { template },
  } = useFormState();

  // error reporting
  const notify = useNotify();

  // used to avoid loops
  const [previousValue, setValue] = useState(undefined);

  // Once the template id has been chosen an API request will be made.
  const { data, loaded, error } = useGetOne(
    RESOURCE.contentTemplateV2,
    template,
    {
      // only if it is chosen, and it is new.
      enabled: !!template && template !== previousValue,
    },
  );

  // This will run pretty frequently
  // but will not do much most of the times
  return useEffect(() => {
    // API request must be finished, and template must be new
    if (!loaded || !template || template === previousValue) {
      return;
    } else if (error) {
      // some HTTP error
      notify(error, "error");
      return;
    }
    // set the new value flag
    setValue(template);

    // call out utility function
    handleChange(data);
  }, [
    loaded,
    previousValue,
    data,
    template,
    setValue,
    handleChange,
    notify,
    error,
  ]);
};

/*
THis will do the work to actually change the form
 */
const useHandleChange = (inputProps: ReferenceInputProps) => {
  // Calls useInput with only the input source changed.
  const page = useInput({
    ...inputProps,
    source: "page",
  });
  const content = useInput({
    ...inputProps,
    source: "content",
  });
  const parent = useInput({
    ...inputProps,
    source: "parent",
  });

  const product = useInput({
    ...inputProps,
    source: "product",
  });
  const youtube = useInput({
    ...inputProps,
    source: "youtube",
  });

  // when called change the input values using
  // react-admin API.
  return useCallback(
    (value) => {
      page.input.onChange({
        website: value?.website,
        paths: value?.paths,
        keywords: value?.keywords,
      });
      content.input.onChange({
        structure: value?.structure,
        type: value?.type,
        market: value?.market,
        examples: value?.examples,
        meta_title: value?.meta_title,
        meta_description: value?.meta_description,
        editable_metas: value?.editable_metas,
        requested_words: value?.requested_words,
        google_docs: value?.google_docs,
        // TODO, it is not too specific to declare the writer here?
        writer: value?.writer,
      });
      parent.input.onChange({
        path: value?.parent?.path,
        keyword: value?.parent?.keyword,
      });
      product.input.onChange(value?.product);
      youtube.input.onChange(value?.youtube);
    },
    [page, content, parent, product, youtube],
  );
};

ContentNewTemplateReferenceInput.defaultProps = {
  label: "Template",
  addLabel: true,
};
