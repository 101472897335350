export const makeModelPermission = (
  app_name,
  model,
  additionalPermissions
) => ({
  create: `${app_name}.add_${model}`,
  edit: `${app_name}.change_${model}`,
  show: `${app_name}.view_${model}`,
  remove: `${app_name}.delete_${model}`,
});

export const hasPermission = (permission, modelPermission) => {
  return permission.permission.includes(modelPermission);
};

export const ifHasPermission = (permission, modelPermission, then) =>
  hasPermission(permission, modelPermission) ? then : null;
