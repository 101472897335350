import React, { useState, lazy, Suspense, useCallback } from "react";

import { useRecordContext, Loading, useDataProvider } from "react-admin";
import IconButton from "@material-ui/core/IconButton";
import { sha256 } from "../functions";

import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";

import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
// import GraphemeSplitter from "grapheme-splitter";
import { RESOURCE as SECTION_RESOURCE } from "../../content/section/constants";
import ReusableEditor, { IMAGE_REGEX } from "../ckeditor/ReusableEditor";

const CkEditorLazy = lazy(() => import("./CkEditorLazy"));

const useStyles = makeStyles({
  editor: {
    "& .ck-content": {
      background: "#f5f5f5",
    },
  },
  mimetizedDocumentEditor: {
    position: "relative",
    width: "100%",
    "& .document-editor__toolbar": {
      display: "none",
    },
  },
  documentEditor: {
    // border: '1px solid var(--ck-color-base-border)',
    // borderRadius: 'var(--ck-border-radius)',
    position: "relative",
    backgroundColor: "#fff",
    /* Set vertical boundaries for the document editor. */
    height: "800px",
    maxHeight: "90vh",
    width: "100%",
    overflowWrap: "break-word",
    margin: "1.5rem 0",
    /* This element is a flex container for easier rendering. */
    display: "flex",
    flexFlow: "column nowrap",
    boxShadow: "0px 14px 8px 0px #fafafa",
    "& label": {
      paddingTop: "1.3rem",
      paddingBottom: ".5rem",
      fontSize: "1.3rem",
      "& button": {
        float: "right",
        "& > small": {
          paddingLeft: "1rem",
        },
      },
    },
    "& .counter": {
      position: "absolute",
      right: "4rem",
      bottom: "3rem",
    },
    "& .status": {
      position: "absolute",
      left: "4rem",
      bottom: "3rem",
    },
    "& .document-editor__toolbar": {
      /* Make sure the toolbar container is always above the editable. */
      zIndex: 1,

      /* Create the illusion of the toolbar floating over the editable. */
      boxShadow: "0 0 5px hsla( 0,0%,0%,.2 )",

      /* Use the CKEditor CSS variables to keep the UI consistent. */
      borderBottom: "1px solid var(--ck-color-toolbar-border)",
      "&.disabled": {
        display: "none",
      },
    },

    /* Adjust the look of the toolbar inside the container. */
    "& .document-editor__toolbar .ck-toolbar": {
      border: 0,
      borderRadius: 0,
    },
    "& .document-editor__editable-container": {
      padding: "calc( 2 * var(--ck-spacing-large) )",
      // background: 'var(--ck-color-base-foreground)',

      /* Make it possible to scroll the "page" of the edited content. */
      overflowY: "scroll",
      background: "#fafafa",
    },
    "& .document-editor__editable-container > .ck-editor__editable": {
      /* Set the dimensions of the "page". */

      width: "80%",
      minHeight: "600px",

      /* Keep the "page" off the boundaries of the container. */
      padding: "20px 10% 30px",

      border: "1px hsl( 0,0%,82.7% ) solid",
      borderRadius: "var(--ck-border-radius)",
      background: "white",

      /* The "page" should cast a slight shadow (3D illusion). */
      boxShadow: "0 0 5px hsla( 0,0%,0%,.1 )",

      /* Center the "page". */
      margin: "0 auto",
      "&.ck-read-only": {
        background: "#fbfffa",
        minHeight: 0,
      },
    },
    "& .document-editor__editable-container > .ck-editor__editable > *": {
      wordBreak: "break-word",
    },
  },
});

const configuration = {
  heading: {
    options: [
      { model: "paragraph", title: "Paragraph", class: "ck-heading_paragraph" },
      {
        model: "heading1",
        view: "h1",
        title: "Heading 1",
        class: "ck-heading_heading1",
      },
      {
        model: "heading2",
        view: "h2",
        title: "Heading 2",
        class: "ck-heading_heading2",
      },
      {
        model: "heading3",
        view: "h3",
        title: "Heading 3",
        class: "ck-heading_heading3",
      },
      {
        model: "heading4",
        view: "h4",
        title: "Heading 4",
        class: "ck-heading_heading4",
      },
    ],
  },
  table: {
    contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
  },
};

const CONTENT_STATUS = {
  SAVED: "Saved",
  NOT_SAVED: "Not Saved",
  SAVING: "Saving...",
  FAILED: "Failed!",
};

const Editor = ({
  id,
  disabled,
  noSpaces,
  elementId,
  label,
  countWords,
  suggestedWords,
  initial = false,
  initialValue,
  sourceValue = "",
  record,
  sectionList,
  setSectionList,
  ...props
}) => {
  const [status, setStatus] = useState(CONTENT_STATUS.SAVED);
  const { keywords } = useRecordContext();
  const dataProvider = useDataProvider();
  const value = sourceValue;
  const onChange = useCallback(
    (value) => {
      const base64Images = value.match(IMAGE_REGEX);
      let currentValue = value;
      // Throw an exception, do not trigger the save
      if (base64Images) {
        throw new Error(
          "Base64 image found - Further work will not be saved until fixed.",
        );
      }
      console.debug(`Saving the text on editor ${label}`);
      return dataProvider.update(SECTION_RESOURCE, {
        id: record.id,
        data: {
          content_text: currentValue,
        },
      });
    },
    [dataProvider, record.id],
  );

  const prps = {
    value,
    onChange,
    disabled,
    noSpaces,
    elementId,
    label,
    countWords,
    keywords,
    writerWords: record.writer_written,
    keywords_data: record.keywords,
    // setWrittenWords,
    suggestedWords,
    initial,
    initialValue,
    changeQueuedCallback: () => {
      // Change the status to not saved only if the status
      // has not failed
      if (status !== CONTENT_STATUS.FAILED) {
        setStatus(CONTENT_STATUS.NOT_SAVED);
      }
    },
    changeStartedCallback: () => setStatus(CONTENT_STATUS.SAVING),
    changeFailedCallback: () => setStatus(CONTENT_STATUS.FAILED),
    changeSuccessfulCallback: () => setStatus(CONTENT_STATUS.SAVED),
    ...props,
  };
  return [
    <ReusableEditor {...prps} key={"editor"} />,
    <Chip
      key={`editor-status-${id}`}
      className={"status"}
      // data-words={writtenWords}
      label={`${status}`}
      variant={"outlined"}
    />,
  ];
};

/**
 * Returns an advanced text editor
 * @param { boolean } noCountWords
 * @param { string } label
 * @param { boolean } disabled
 * @param { InputProps } props
 * @returns {JSX.Element} noSpaces
 * @constructor
 */
export const SectionTextInputElement = ({
  countWords = false,
  label = "content",
  disabled = false,
  id = "",
  suggestedWords,
  noSpaces,
  sourceValue = "",
  ...props
}) => {
  const classes = useStyles();
  // const noSpaces = props.record?.language_has_no_spaces || false;
  const [fullscreen, setFullscreen] = useState(false);
  sourceValue.replaceAll('<br data-cke-filler="true">', "");
  const [elementId, setElementId] = useState("");

  React.useEffect(() => {
    const setId = async () =>
      setElementId(`advanced-editor-${await sha256(id || label)}`);
    setId();
  }, [id, label]);

  React.useEffect(() => {
    if (document.fullscreenElement) {
      try {
        document.exitFullscreen();
      } catch (error) {
        console.log(error, "warning");
      }
    }
    if (fullscreen) {
      try {
        fullscreen.parentElement.parentElement.requestFullscreen();
      } catch (error) {
        console.log(error, "warning");
      }
    }
  }, [fullscreen]);

  const toggleFullscreen = React.useCallback(
    (event) => setFullscreen(fullscreen ? false : event.target),
    [setFullscreen, fullscreen],
  );

  if (!elementId) return <Loading />;

  return (
    <div
      id={elementId}
      className={`${classes.documentEditor} ${
        countWords ? "counted-advanced-editor" : "advanced-editor"
      }'}`}
    >
      <label>
        {label}
        <IconButton onClick={toggleFullscreen} aria-label="set fullscreen">
          {fullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
          <small>&nbsp;Toggle fullscreen</small>
        </IconButton>
      </label>
      <div
        id={`toolbar-${elementId}`}
        className={`document-editor__toolbar ${disabled ? "disabled" : ""}`}
      ></div>
      <div
        id={`content-${elementId}`}
        className={`document-editor__editable-container ${
          countWords ? "count-words" : ""
        } ${disabled ? "disabled" : ""}`}
      >
        <Editor
          key={`editor-${id}`}
          id={`editor-${id}`}
          suggestedWords={suggestedWords}
          countWords={countWords}
          elementId={elementId}
          label={label}
          noSpaces={noSpaces}
          disabled={disabled}
          sourceValue={sourceValue}
          {...props}
        />
      </div>
    </div>
  );
};

export const ShowSectionTextElement = ({
  countWords = false,
  label = "content",
  disabled = true,
  mimetize = false,
  sourceValue = "",
  id = "",
  ...props
}) => {
  const classes = useStyles();
  const [elementId, setElementId] = useState("");

  React.useEffect(() => {
    const setId = async () =>
      setElementId(`advanced-editor-${await sha256(id || label)}`);
    setId();
  }, [id, label]);

  if (!elementId) return <Loading />;

  return (
    <div
      id={elementId}
      className={
        mimetize ? classes.mimetizedDocumentEditor : classes.documentEditor
      }
    >
      {mimetize ? null : <label>{label}</label>}
      <div
        id={`toolbar-${elementId}`}
        className={`document-editor__toolbar ${disabled ? "disabled" : ""}`}
      ></div>
      <div
        id={`content-${elementId}`}
        className={`document-editor__editable-container ${
          countWords ? "count-words" : ""
        } ${disabled ? "disabled" : ""}`}
      >
        <CkEditorLazy
          disabled={disabled}
          config={configuration}
          data={sourceValue}
          onError={(error) => {
            console.error(error);
          }}
        />
      </div>
    </div>
  );
};

export const SectionTextInput = (props) => (
  <Suspense fallback={<Loading />}>
    <SectionTextInputElement {...props} />
  </Suspense>
);

export const ShowSectionText = (props) => (
  <Suspense fallback={<Loading />}>
    <ShowSectionTextElement {...props} />
  </Suspense>
);
