import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import NotificationsIcon from "@material-ui/icons/Notifications";
import * as React from "react";

import { httpClient } from "../../utils";
import { ENDPOINTS } from "./endpoints";
import { setInterval, clearInterval } from "timers";
import Popover from "@material-ui/core/Popover";

import { List } from "./List";
import { useNotify } from "react-admin";
import { withStyles } from "@material-ui/core";

const DEFAULT_STATE = {
  count: null,
  hide: [],
};

const countReducer = (state, action) => {
  const newState = state ? { ...state } : { ...DEFAULT_STATE };
  if (action.action === "new") {
    newState.count = action.count;
  } else if (action.action === "update") {
    newState.count = action.count;
  } else if (action.action === "reduce" && state.count) {
    newState.count -= 1;
    newState.hide.push(action.hide);
  } else if (action.action === "resetHide") {
    newState.hide = [];
  }
  if (state.count && state.count < newState.count && action.callback) {
    action.callback();
  }
  return { ...newState };
};

export const NotificationMenuItem = withStyles({
  list: {
    width: "35rem",
  },
})(({ classes, ...props }) => {
  const countState = React.useReducer(countReducer, { ...DEFAULT_STATE });
  const [{ count }, countStateDispatch] = countState;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const notify = useNotify();
  React.useEffect(() => {
    const closure = async () => {
      const response = await httpClient(ENDPOINTS.count);
      const data = response.json;
      return data.count;
    };
    // make a first request on mounting
    const call = (first = false) =>
      closure()
        .catch((error) => (error !== undefined ? console.info(error) : null))
        .then((count) => {
          if (!first) {
            countStateDispatch({
              action: "update",
              count: count,
              callback: () => notify("New notification message."),
            });
          } else {
            countStateDispatch({
              action: "new",
              count: count,
            });
          }
        });
    call(true).then();
    // make a request every two minutes
    const interval = setInterval(call, 120000);
    return () => clearInterval(interval);
    // This needs to run just once!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = (event) => {
    if (count === 0) return;
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    countStateDispatch({ action: "resetHide" });
  };

  const open = Boolean(anchorEl);
  const id = open ? "userNotificationsBell" : undefined;

  return (
    <span>
      <IconButton
        aria-label="show 11 new notifications"
        color="inherit"
        onClick={handleClick}
      >
        <Badge badgeContent={count} color="error">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <List
          countState={countState}
          className={classes.list}
          basePath={ENDPOINTS.basePath}
          resource={ENDPOINTS.basePath}
        />
      </Popover>
    </span>
  );
});

export default NotificationMenuItem;
