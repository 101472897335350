import React from "react";
import { Resource as RAResource } from "react-admin";
import BusinessIcon from "@material-ui/icons/Business";
import { ADMIN, RESOURCE } from "./constants";

import List from "./List";
import Edit from "./Edit";
import { PERMISSIONS } from "../brands/constants";

/**
 * For some reason the APP want the resource to be instantiated with this make factory.
 * @param {*} permissions
 * @param {*} key
 * @returns
 */
export const make = (permissions: any): React.ReactElement | null => {
  const isAdmin = permissions?.role === ADMIN;
  const permission = permissions?.permission || [];
  return (
    <RAResource
      name={RESOURCE}
      options={{ label: "Networks" }}
      list={isAdmin ? List : undefined}
      edit={permission.includes(PERMISSIONS.edit) ? Edit : undefined}
      key={RESOURCE}
      icon={BusinessIcon}
    />
  );
};

export default make;
