import { FC, useCallback } from "react";
import {
  AutocompleteInput,
  ReferenceInput,
  usePermissions,
  useRecordContext,
  useUpdate,
} from "react-admin";
import {
  PERMISSIONS as PMPERMISSIONS,
  RESOURCE as PMRESOURCE,
} from "../../sales/pmBrands/constants";

export const BrandReferenceInput: FC<any> = (props) => {
  const { permissions } = usePermissions();
  const record = useRecordContext();
  const [update] = useUpdate();
  const permission = permissions?.permission;

  const handleSelect = useCallback(
    (brand) => {
      if (!brand.data.content) {
        brand.data.content = [];
      }
      if (!brand.data.reviewedOn) {
        brand.data.reviewedOn = [];
      }
      brand.data.content.push(record.id);
      brand.data.reviewedOn.push(record.website);
      update(PMRESOURCE, brand.id, brand);
    },
    [record.id, record.website, update],
  );
  if (
    permission?.includes(PMPERMISSIONS.view) &&
    permission?.includes(PMPERMISSIONS.edit)
  ) {
    return (
      <ReferenceInput reference={PMRESOURCE} {...props}>
        <AutocompleteInput
          optionText={"name"}
          resettable={true}
          onSelect={handleSelect}
        />
      </ReferenceInput>
    );
  }
  return null;
};

export default BrandReferenceInput;
