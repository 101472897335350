import {
  AutocompleteArrayInput,
  AutocompleteArrayInputProps,
  ReferenceArrayField,
  ReferenceArrayInput,
  useRecordContext,
  useResourceContext,
  SingleFieldList,
  useUpdate, ChipField,
} from "react-admin";

import { Form } from 'react-final-form';

import React from "react";
// @ts-ignore
import get from "lodash/get";
// @ts-ignore
import set from "lodash/set";
import { makeStyles } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import IconButton  from "@material-ui/core/IconButton";


const useStyles = makeStyles((theme) => {
  return {
    field: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    deactivated: {
      ...theme.typography.caption,
      color: theme.palette.grey[500],
      opacity: 0.5,
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  };
});

export interface EditableReferenceFieldProps {
  buttons?: React.ReactElement[];
  // source used for the TextInput
  showSource?: string;

  // used for the Autocomplete
  fieldProps?: EditableFieldProps;
  inputProps?: AutocompleteArrayInputProps;
  optionText?: string;
  helperText?: string;
  emptyValue?: ( record: any ) => string;
  [key: string]: any;
}

interface EditableFieldProps {
  iconButtonProps?: any;
  buttons?: React.ReactElement[];
  field?: React.ReactElement;
  className?: string
  source: string;

  [key: string]: any;
}


export const EditableReferenceArrayField = (props: EditableReferenceFieldProps): React.ReactElement => {
  const { source, label, reference, sort } = props;
  const { fieldProps, inputProps, buttons, emptyValue, ...rest } = props;
  const classes = useStyles();
  const record = useRecordContext();
  const resource = useResourceContext();
  const [update] = useUpdate();
  const [updateValue, setUpdateValue] = React.useState(false);
  const value = get(record, source);
  const handleChange = React.useCallback(
    (e) => {
      set(record, source, e);
      update(resource, record.id, record);
      setUpdateValue(false);
    },
    [update, record, resource, source, setUpdateValue]
  );

  if (updateValue) {
    return (
      <Form onSubmit={handleChange}>
        {()=>(
          <ReferenceArrayInput
            source={source}
            label={label}
            reference={reference}
            sort={sort}
            {...rest}
            onBlur={() => setUpdateValue(false)}
            onFocusOut={() => setUpdateValue(false)}
            onChange={handleChange}
            fullWidth
            className={classes.field}
            resettable
          >
            <AutocompleteArrayInput
              optionValue="id"
              className={classes.field}
              {...inputProps}
            />
          </ReferenceArrayInput>
        )}
      </Form>
    );
  } else if (!value) {
    return (
      <span
        className={ emptyValue ? undefined : classes.deactivated }
        onClick={() => setUpdateValue(true)}
      >
        {emptyValue ? emptyValue(record) : 'Click to edit...'}
      </span>
    );
  } else {
    return (
      <ReferenceArrayField
        label={label}
        source={source}
        {...rest}
        reference={reference}
      >
        <EditableField
          className={classes.field}
          buttons={buttons}
          iconButtonProps={{
              onClick: (e: MouseEvent): void => {
                e.preventDefault();
                setUpdateValue(true);
              },
            }}
          source={source}
          {...fieldProps}

        />
      </ReferenceArrayField>
    );
  }
};



const EditableField: React.FC<EditableFieldProps> = (props) => {
  const { iconButtonProps, buttons, className, field, source, ...rest } = props;
  return (
    <span className={className}>
      <span className={'editable-reference-array-list-container'}>
       <SingleFieldList {...rest}>
           { field ? field :  <ChipField source={source} /> }
        </SingleFieldList>
      </span>
      <span>
        <IconButton {...iconButtonProps}>
          <EditIcon />
        </IconButton>
        { buttons }
      </span>
    </span>
  );
};


export default EditableReferenceArrayField;
