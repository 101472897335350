import { ReferenceField, FunctionField } from "react-admin";

import { RESOURCE } from "../../constants";

export const ContentReferenceField = ({
  source,
  label,
  withStatus,
  ...props
}) => {
  return (
    <ReferenceField
      label={props.label ? props.label : "Content"}
      source={props.source ? props.source : "content"}
      reference={RESOURCE.contentMinimal}
      link={false}
      {...props}
    >
      <FunctionField
        render={(record) => (
          <a href={`#/${RESOURCE.order}/${record.id}`}>
            {record.id}
            {withStatus ? ` ( ${record.status} )` : ""}
          </a>
        )}
      />
    </ReferenceField>
  );
};

ContentReferenceField.defaultProps = {
  label: "Content",
  addLabel: true,
};

export default ContentReferenceField;
