import { useDataProvider } from "react-admin";
import { useRef, useState } from "react";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import { RESOURCE } from "../../../../constants";
import ContentEditable from "react-contenteditable";

const useStyles = makeStyles((theme) => ({
  textfield: {
    fontFamily: "sans-serif, system-ui",
    border: "1px dashed #09090b",
    background: "#e4e4e7",
    margin: "0.5rem",
    padding: "0.5rem",
    minHeight: "256px",
    maxHeight: "256px",
    overflowY: "auto",
  },
  button: {
    margin: "0.5rem",
    padding: "0.5rem",
  },
  progress: {
    marginTop: "0.5rem",
    marginLeft: "0.5rem",
    marginRight: "0.5rem",
  },
}));

const Notes = ({ record }) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(false);
  const text = useRef(record.structure?.writer_notes || "");

  const handleChange = (evt) => {
    text.current = evt.target.value;
  };

  const saveNotes = async () => {
    try {
      setLoading(true);
      await dataProvider.update(RESOURCE.order, {
        id: record.id,
        data: {
          structure: { ...record.structure, writer_notes: text.current },
        },
      });
      setLoading(false);
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <>
      <ContentEditable
        className={classes.textfield}
        placeholder="Write your notes here ..."
        html={text.current}
        onChange={handleChange}
        onBlur={saveNotes}
      />
      {loading && <LinearProgress className={classes.progress} />}
      <Button
        className={classes.button}
        onClick={saveNotes}
        variant="contained"
        color="primary"
      >
        SAVE
      </Button>
    </>
  );
};

export default Notes;
