import React from "react";
import {
  Edit as RaEdit,
  SimpleForm,
  TextInput,
  EditProps,
  PasswordInput,
  SelectInput,
  required,
  List,
  Datagrid,
  TextField,
  ReferenceField,
  SearchInput,
} from "react-admin";
import { HorizontalGroup } from "../reusable";
import { RESOURCE } from "../constants";
import { HOSTING_STATUS_CHOICES } from "./constants";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  website: {
    width: "100%",
  },
}));

const filters = [<SearchInput source="q" alwaysOn />];

export const Edit = (props: EditProps): React.ReactElement => {
  const { id } = props;
  const classes = useStyles();

  return (
    <RaEdit mutationMode="undoable" redirect="list" {...props}>
      <SimpleForm>
        <HorizontalGroup label="Hosting Information">
          <TextInput
            source="hosting_name"
            label="Hosting"
            validate={required()}
          />
          <TextInput
            source="hosting_username"
            label="Hosting Username"
            parse={(value) => value}
            // validate={required()}
          />
          <PasswordInput source="hosting_password" label="Hosting Password" />
        </HorizontalGroup>
        <HorizontalGroup label="CPanel Information">
          <TextInput
            source="cpanel_url"
            label="CPanel URL"
            parse={(value) => value}
          />
          <TextInput
            source="cpanel_username"
            label="CPanel Username"
            parse={(value) => value}
          />
          <PasswordInput
            source="cpanel_password"
            label="CPanel Password"
            parse={(value) => value}
          />
        </HorizontalGroup>
        <TextInput
          source="host_pricing"
          label="Host Pricing"
          parse={(value) => value}
        />
        <SelectInput source="status" choices={HOSTING_STATUS_CHOICES} />
        <TextInput source="notes" parse={(value) => value} label="Notes" />

        <HorizontalGroup label="Websites">
          <List
            className={classes.website}
            empty={<div />}
            filters={filters}
            key="list"
            hasBulkActions={false}
            filter={{ hosting: id }}
            {...props}
            resource={RESOURCE.website}
            bulkActionButtons={false}
            actions={false}
          >
            <Datagrid isRowSelectable={() => false}>
              <TextField source="domain" />
              <ReferenceField
                label="Vertical"
                source="vertical"
                reference={RESOURCE.vertical}
                link={false}
              >
                <TextField source="name" />
              </ReferenceField>
              <ReferenceField
                label="Niche"
                source="niche"
                reference={RESOURCE.niche}
                link={false}
              >
                <TextField source="name" />
              </ReferenceField>
              <TextField source="type" />
            </Datagrid>
          </List>
        </HorizontalGroup>
      </SimpleForm>
    </RaEdit>
  );
};

export default Edit;
