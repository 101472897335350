import * as React from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { useMediaQuery } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { useRedirect } from "react-admin";

import MonetizationOnOutlinedIcon from "@material-ui/icons/MonetizationOnOutlined";
import SendOutlinedIcon from "@material-ui/icons/SendOutlined";
import InsertLinkOutlinedIcon from "@material-ui/icons/InsertLinkOutlined";
import BuildOutlinedIcon from "@material-ui/icons/BuildOutlined";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import TocOutlinedIcon from "@material-ui/icons/TocOutlined";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import EuroIcon from "@material-ui/icons/Euro";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { makeStyles } from "@material-ui/core/styles";
import { DashboardMenuItem, MenuItemLink, getResources } from "react-admin";

import { RESOURCE } from "../constants";

const useStyles = makeStyles((theme) => {
  return {
    menu: {
      display: "flex",
      flex: "1 1 100%",
      color: theme.palette.gray,
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
      "&>*": {
        color: theme.palette.common.white,
      },

      "& > div": {
        "& > div": {
          top: "2.5rem",
          position: "fixed",
          background: "white",
          color: "black",
          borderRadius: "0 0 5px 5px",
          boxShadow: "0px 3px 1px 1px #ddd",
          "& > li:hover": {
            background: theme.palette.grey[200],
          },
        },
        "& > div:last-child": {
          display: "none",
        },
        "&:hover > div:last-child": {
          display: "block",
        },
      },
    },
    mobileMenu: {
      [theme.breakpoints.up("ms")]: {
        display: "none",
      },
      position: "fixed",
      left: 0,
      zIndex: 10,
      background: theme.palette.background.default,
      "& > *:first-child": {
        background: theme.palette.background.default,
      },
    },
    mobileButton: {
      background: theme.palette.background.default,
      color: theme.palette.getContrastText(theme.palette.background.default),
      "&:hover": {
        background: theme.palette.grey[100],
      },
    },
  };
});

export const LayoutMenu = withRouter(({ asMenu, ...props }) => {
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const classes = useStyles();

  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  const resources = useSelector(getResources);
  const subMenu = {
    finance: {
      resources: [],
      label: "Finance",
      icon: <MonetizationOnOutlinedIcon className="MuiListItemIcon-root" />,
    },
    outreach: {
      resources: [],
      label: "Outreach",
      icon: <SendOutlinedIcon className="MuiListItemIcon-root" />,
    },
    linkbuilding: {
      resources: [],
      label: "Linkbuilding",
      icon: <InsertLinkOutlinedIcon className="MuiListItemIcon-root" />,
    },
    integration: {
      resources: [],
      label: "Integration",
      icon: <BuildOutlinedIcon className="MuiListItemIcon-root" />,
    },
    content: {
      resources: [],
      label: "Content",
      icon: <CreateOutlinedIcon className="MuiListItemIcon-root" />,
      other: [
        {
          filter: (resources) =>
            resources.filter(
              (res) => res.name === RESOURCE.order && res.hasCreate,
            ).length > 0,
          props: {
            primaryText: "Make a new request",
            to: `/${RESOURCE.order}/create`,
            leftIcon: <AddIcon />,
          },
        },
      ],
    },
    sales: {
      resources: [],
      label: "Sales",
      icon: <EuroIcon className="MuiListItemIcon-root" />,
    },
    products: {
      resources: [],
      label: "Products",
      icon: <TocOutlinedIcon className="MuiListItemIcon-root" />,
    },
    youtube: {
      resources: [],
      label: "Youtube",
      icon: <TocOutlinedIcon className="MuiListItemIcon-root" />,
    },
    notifications: {
      resources: [],
      label: "Notifications",
      icon: <TocOutlinedIcon className="MuiListItemIcon-root" />,
    },
    virtual_assistant: {
      resources: [],
      label: "Virtual Assistant",
      icon: <TocOutlinedIcon className="MuiListItemIcon-root" />,
    },
  };
  resources.forEach((res) =>
    res.subMenu
      ? subMenu[res.subMenu].push(res)
      : res.name.includes("finance")
      ? subMenu.finance.resources.push(res)
      : res.name.includes("linkbuilding") || res.name.includes("pbn")
      ? subMenu.linkbuilding.resources.push(res)
      : res.name.includes("outreach")
      ? subMenu.outreach.resources.push(res)
      : res.name.includes("integration")
      ? subMenu.integration.resources.push(res)
      : res.name.includes("sales")
      ? subMenu.sales.resources.push(res)
      : res.name.includes("notifications")
      ? subMenu.notifications.resources.push(res)
      : res.name.includes("youtube")
      ? subMenu.youtube.resources.push(res)
      : res.name.includes("proof")
      ? subMenu.content.resources.push(res)
      : res.name.includes("vassistant")
      ? subMenu.virtual_assistant.resources.push(res)
      : res.name.includes("content") ||
        res.name === RESOURCE.flatPages.writerGuidelines
      ? subMenu.content.resources.push(res)
      : subMenu.products.resources.push(res),
  );
  return asMenu ? (
    <div className={classes.menu}>
      <DashboardMenuItem dense />
      <Button
        onClick={() => {
          window.location.href = "/brand-data/";
        }}
      >
        Brand Data
      </Button>

      {Object.values(subMenu).map((entry) => (
        <SubMenu classes={classes} key={entry.label} {...entry} {...props} />
      ))}
    </div>
  ) : (
    <div
      style={{ display: open || !isXSmall ? "none" : "block" }}
      className={classes.MobileMenu}
    >
      <DashboardMenuItem className={classes.mobileButton} />
      {Object.values(subMenu).map((entry) => (
        <SubMenuAccordion
          key={entry.label}
          {...entry}
          {...props}
          classes={classes}
          open={open}
        />
      ))}
    </div>
  );
});

/**
 * Builds up the submenu items
 *
 * // TODO: RIGHT-CLICK uses the sharp symbol I would avoid hard coding it.
 * @param resources
 * @param label
 * @param onMenuClick
 * @param icon
 * @param open
 * @param classes
 * @param props
 * @returns {JSX.Element|null}
 * @constructor
 */
const SubMenu = ({
  resources,
  label,
  onMenuClick,
  icon,
  open,
  classes,
  ...props
}) => {
  const redirect = useRedirect();
  const handleMenuClick = (resource) => (e) => {
    if (e.ctrlKey) {
      window.open(`/#${resource}`, "_blank");
    } else {
      redirect(resource);
    }
  };
  if (!resources.some((res) => res.hasList)) return null;

  const other = props.other || [];
  return (
    <div>
      <Button
        style={{ color: "#fff" }}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={() => null}
      >
        {label}
      </Button>
      <div>
        {resources
          .map((resource) =>
            resource.hasList ? (
              <MenuItem
                key={resource.name}
                onClick={handleMenuClick(`/${resource.name}`)}
                onContextMenu={(event) => {
                  event.preventDefault();
                  window.open(`/#/${resource.name}`, "_blank");
                }}
              >
                <ListItemIcon>
                  {resource.icon
                    ? React.createElement(resource.icon)
                    : React.createElement(TocOutlinedIcon)}
                </ListItemIcon>
                {resource.options ? resource.options.label : resource.name}
              </MenuItem>
            ) : null,
          )
          .concat(
            other
              .filter((elem) => elem.filter(resources))
              .map((elem, index) => {
                return (
                  <MenuItem
                    key={`${other}.${index}`}
                    onClick={handleMenuClick(`${elem.props.to}`)}
                    onContextMenu={(event) => {
                      event.preventDefault();
                      window.open(`/#/${elem.props.to}`, "_blank");
                    }}
                  >
                    <ListItemIcon>{elem.props.leftIcon}</ListItemIcon>
                    {elem.props.primaryText}
                  </MenuItem>
                );
              }),
          )}
      </div>
    </div>
  );
};

const SubMenuAccordion = ({
  resources,
  label,
  onMenuClick,
  icon,
  open,
  ...props
}) => {
  if (!resources.some((res) => res.hasList)) return null;
  const other = props.other || [];
  return (
    <Accordion className="MuiMenuItem-root">
      <AccordionSummary
        style={{ display: "inline-flex", padding: 0 }}
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${label}-menu-content`}
        id={`${label}-menu-header`}
      >
        {icon ? icon : null}
        <Typography>{label}</Typography>
      </AccordionSummary>
      <AccordionDetails style={{ padding: 0 }}>
        <div>
          {resources
            .map((resource) =>
              resource.hasList ? (
                <MenuItemLink
                  key={resource.name}
                  to={`/${resource.name}`}
                  primaryText={
                    (resource.options && resource.options.label) ||
                    resource.name
                  }
                  leftIcon={
                    resource.icon
                      ? React.createElement(resource.icon)
                      : React.createElement(TocOutlinedIcon)
                  }
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                />
              ) : null,
            )
            .concat(
              other
                .filter((elem) => elem.filter(resources))
                .map((elem, index) => (
                  <MenuItemLink
                    key={`${other}.${index}`}
                    {...elem.props}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                  />
                )),
            )}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};
