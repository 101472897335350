import React from "react";
import { Card, CardHeader, CardContent, Chip } from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  useQueryWithStore,
  Loading,
  Error,
  useNotify,
  usePermissions,
} from "react-admin";

import { usePage } from "../usePage";
import { useStyles } from "../../useStyles";
import { GROUPS, RESOURCE } from "../../constants";
import { useCountWordsGetKeywordsData } from "../../reusable/store/countWords";

export const PageDetails = ({ record = {}, ...props }) => {
  const { full_url, market, parent, keywords } = record;
  return (
    <PageDetailsB
      keywords={keywords}
      record={{ full_url, market, parent }}
      {...props}
    />
  );
};

const parseKeyword = (keywords, keywordsData) => {
  const parse = (keywordData) => {
    if (!keywordData) return "0";
    const { total, count } = keywordData;
    return Number.parseFloat(total ? (count / total) * 100 : 0)
      .toPrecision(2)
      .toString();
  };
  return keywords.map((kw) => `${kw} (${parse(keywordsData[kw])} %)`);
};

/**
 * only the internal users will able to view this button
 * */
const canSeeAhref = (permissions) => {
  if (
    permissions &&
    (permissions.groups.includes(GROUPS.editors) ||
      permissions.groups.includes(GROUPS.integrators) ||
      permissions.groups.includes(GROUPS.linkbuilding))
  ) {
    return true;
  }
  return false;
};

const PageDetailsB = ({
  keywords = [],
  structuredContent = [],
  market = undefined,
  fullUrl = undefined,
  loading = false,
  record,
}) => {
  const classes = useStyles();
  const notify = useNotify();
  const { permissions, permissionLoading } = usePermissions();
  const keywordData = useCountWordsGetKeywordsData();
  const kws = parseKeyword(keywords, keywordData);
  const keyword_chips = kws.map((keyword, idx) => (
    <Chip
      key={`keyword.${idx}`}
      label={keyword}
      className={classes.chippedList}
    />
  ));
  const _fullURL = fullUrl ? fullUrl : record.full_url;
  if (permissionLoading) return <Loading />;
  return (
    <Card>
      <CardHeader
        title={
          _fullURL && (
            <span>
              Page:
              <Chip
                clickable
                target={"_blank"}
                color="primary"
                component="a"
                id="page_full_url"
                href={_fullURL}
                label={_fullURL}
                className={classes.chippedList}
              />
              {canSeeAhref(permissions) && (
                <Chip
                  clickable
                  target={"_blank"}
                  color="secondary"
                  component="a"
                  href={`https://app.ahrefs.com/site-explorer/overview/v2/prefix/live?target=${_fullURL}`}
                  label={"Ahref Explorer"}
                  className={classes.chippedList}
                  icon={<TrendingUpIcon />}
                />
              )}
              <CopyToClipboard
                text={_fullURL}
                onCopy={() => notify(`${_fullURL} copied to clipboard.`)}
              >
                <Chip
                  clickable
                  variant={"outlined"}
                  icon={<FileCopyIcon />}
                  label={"Copy"}
                />
              </CopyToClipboard>
            </span>
          )
        }
        subheader={
          <div>
            Market: <Market market={market ? market : record.market} />
            <br />
            Main keyword:{" "}
            <Chip label={kws[0]} className={classes.chippedList} />
          </div>
        }
      />
      <Card>
        <CardHeader title={"keywords"} />

        <CardContent>
          {keyword_chips}
          {loading ? (
            <Loading />
          ) : record.parent ? (
            <ParentKeywords page={record.parent} />
          ) : null}
        </CardContent>
      </Card>
    </Card>
  );
};

const Market = ({ market }) => {
  const { loaded, error, data } = useQueryWithStore({
    type: "getOne",
    resource: RESOURCE.market,
    payload: { id: market },
  });

  if (!loaded) {
    return <Loading />;
  }
  if (error) {
    return <Error error={error} />;
  }
  return <Chip label={`${data?.country} (${data?.language})`} />;
};

const ParentKeywords = ({ page }) => {
  const classes = useStyles();

  const { loaded, error, data } = usePage(page);

  if (!loaded) {
    return <Loading />;
  }
  if (error) return null;
  const keywords = data?.keywords.map((keyword, idx) => (
    <Chip
      key={`parentKeyword.${idx}`}
      label={keyword}
      className={classes.chippedList}
    />
  ));
  const Title = () => (
    <span>
      From parent page:&nbsp;
      <Chip
        clickable
        color="primary"
        component="a"
        href={data?.full_url}
        label={data?.full_url}
        className={classes.chippedList}
      />
    </span>
  );
  return [<CardHeader key="parentTitle" subheader={<Title />} />, ...keywords];
};
