import { makeStyles } from "@material-ui/core/styles";

const bottomBarHeight = 100;

export const useStyles = makeStyles((theme) => ({
  flex: {
    "&>li>section": {
      display: "flex",
    },
  },
  muiButton: {
    height: "25%",
    marginTop: "1rem",
  },
  contentArrayInput: {
    "& .fade-enter": {
      opacity: 1,
      transform: "none",
    },
  },
  editForm: {
    marginBottom: 50,
    "& .document-editor": {
      backgroundColor: "#fafcff",
      marginBottom: "1rem",
      "& .document-editor__editable-container": {
        border: "1px solid #d2d0d0",
        "& .ck": {
          minHeight: "300px",
        },
      },
    },
  },
  bottomBar: {
    maxHeight: theme.content.bottomBarHeight,
    zIndex: theme.content.zIndex,
  },
  tableLikeArrayInput: {
    "&>ul>li": {
      borderBottom: "none",
      "&>p": {
        display: "none",
      },
      "&>section": {
        display: "flex",
        "&>.ra-input": {
          display: "inline-flex",
          "&:last-child": {
            flexGrow: 4,
            "&>div": {
              width: "100%",
            },
          },
        },
        "& .MuiFormHelperText-root": {
          display: "none",
        },
      },
    },
  },
  inputRow: {
    display: "inline-flex",
    width: "100%",
    "&>div": {
      marginLeft: "2rem",
      "&.bigger": {
        width: "30%",
      },
      "&>button": {
        marginBottom: "1.5rem",
      },
    },
  },
  aside: {
    flex: "0 0 20%",
    overflowY: "auto",
    zIndex: 50,
  },
  chippedList: {
    margin: ".2rem .2rem",
  },
  fancyToolbar: {
    "& .MuiToolbar-root": {
      background: "#f1f1f1",
      borderRadius: "10px 10px 0px 0px",
    },
  },
  dialogForm: {
    margin: "10px",
    padding: "10px",
    "& form": {
      margin: "10px",
      padding: "10px",
    },
  },
  form: {
    "& .document-editor": {
      backgroundColor: "#fafcff",
      // borderRadius: '10px',
      marginBottom: "1rem",
      "& .document-editor__editable-container": {
        border: "1px solid #d2d0d0",
        "& .ck": {
          minHeight: "300px",
        },
      },
    },
  },
  accordion: {
    width: "98%",
    margin: "16px!important",
  },
  // a light pink for a light alert
  wrongWordsCount: {
    backgroundColor: "#fee",
  },
  okWordsCount: {
    backgroundColor: "#fff",
  },
  contentCopy: {
    marginTop: "10px",
  },
  contentCopyAccordion: {},
  box: {
    marginTop: "2rem",
    width: "100%",
    "& > .inputs": {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "flex-start",
      "&  > *": {
        flexGrow: 0,
        marginRight: "1rem",
      },
    },
  },
  box2: {
    marginTop: "2rem",
    width: "100%",
    "& > .inputs": {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-around",
      "&  > *": {
        "&>div": {
          width: "100%",
        },
        flexGrow: 1,
        marginRight: "1rem",
      },
      "&  > *:last-child": {
        marginRight: "0",
      },
    },
  },
  /* size for input elements */
  normal: {
    "& > div": {
      width: "9.5rem",
      maxWidth: "100%",
    },
    "&  .MuiFormHelperText-contained": {
      width: "8rem",
      maxWidth: "100%",
    },
  },
  small: {
    "& > div": {
      width: "9rem",
      maxWidth: "100%",
    },
    "&  .MuiFormHelperText-contained": {
      width: "7rem",
      maxWidth: "100%",
    },
  },
  big: {
    "& > div": {
      width: "20rem",
      maxWidth: "100%",
    },
    "&  .MuiFormHelperText-contained": {
      width: "15rem",
      maxWidth: "100%",
    },
  },
  bigger: {
    "& > div": {
      width: "30rem",
      maxWidth: "100%",
    },
    "& .MuiFormHelperText-contained": {
      width: "20rem",
      maxWidth: "100%",
    },
  },
  bigAndBigger: {
    "& > div": {
      width: "51rem",
      maxWidth: "100%",
    },
    "&  .MuiFormHelperText-contained": {
      width: "40rem",
      maxWidth: "100%",
    },
  },
  maxWithIcon: {
    "& > div": {
      width: "48rem",
      maxWidth: "100%",
    },
    "&  .MuiFormHelperText-contained": {
      width: "40rem",
      maxWidth: "100%",
    },
  },
  alternatedColorArray: {
    "& li:nth-child(even)": {
      backgroundColor: "#fafafa",
    },
  },
  flexWrappedItems: {
    display: "flex",
    flexWrap: "wrap",
  },
  tooltip: {
    whiteSpace: "pre-wrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxHeight: "32rem",
  },
  select: {
    width: "50rem",
  },
  fullWidthPaper: {
    width: "51rem",
    maxWidth: "100%",
  },
  fullWidthBox: {
    width: "51rem",
    padding: "1rem",
    maxWidth: "100%",
  },
  paper: {
    paddingTop: "5rem",
    paddingBottom: "5rem",
    width: "100%",
    backgroundColor: "#fef2f2",
  },
  lightYellow: {
    paddingTop: "1rem",
    paddingBottom: "1rem",
    textAlign: "center",
    backgroundColor: "lightyellow",
  },
  lightRed: {
    paddingTop: "0.25rem",
    paddingBottom: "0.25rem",
    textAlign: "center",
    color: "white",
    backgroundColor: "#f87171",
  },
  editableDiv: {
    border: "1px solid #000",
    padding: "0.5rem",
    margin: "0.5rem",
    minHeight: "64px",
    maxHeight: "128px",
    overflow: "scroll",
  },
}));
