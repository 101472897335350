import { ReferenceField, FunctionField } from "react-admin";
import { makeDisplay } from "../../reusable";

import { RESOURCE } from "../../constants";
import * as React from "react";

export const OutreachReferenceField = ({ source, label, limit, ...props }) => (
  <ReferenceField
    label={label ? label : "Outreach"}
    source={source ? source : "outreach"}
    reference={RESOURCE.outreachLinkbuildingOrder}
    link={false}
    {...props}
  >
    <FunctionField
      render={(record) => {
        return (
          <a
            href={record.linking_page}
            rel={"noopener nofollow noreferrer"}
            target={"_blank"}
          >
            {makeDisplay(record.linking_page, limit)}
          </a>
        );
      }}
    />
  </ReferenceField>
);

OutreachReferenceField.defaultProps = {
  label: "Content",
  addLabel: true,
};

export default OutreachReferenceField;
