import { Resource } from "react-admin";
import ListAltIcon from "@material-ui/icons/ListAlt";
// import { Create } from "./Create";
import { RESOURCE, GROUPS } from "../../constants";
// import { TemplateList } from "./List";
// import { TemplateEdit } from "./Edit";

const RES = RESOURCE.contentCharacter;
/**
 * For some reason the APP want the resource to be instantiated with this make factory.
 * @param {*} permissions
 * @param {*} key
 * @returns
 */
export const makeContentCharacter = (permissions) => (
  <Resource
    name={RES}
    key={RES}
    // list={TemplateList}
    // create={Create}
    // edit={TemplateEdit}
    options={{ label: "Content Characters" }}
    icon={ListAltIcon}
  />
);
