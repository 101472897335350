import { TextInput, SearchInput, SelectInput } from "react-admin";
import {
  MarketReferenceArrayInput,
  MarketReferenceInput,
} from "../../../market";
import { CHANNEL, DEAL, PRODUCT } from "../constants";

export const filters = [
  <SearchInput source="q" alwaysOn />,
  <MarketReferenceInput
    source={"market"}
    label={"Market"}
    helperText={""}
    emptyValue={"All"}
    allowEmpty={false}
    resettable
    alwaysOn
  />,
  <MarketReferenceArrayInput
    source={"multiple_markets"}
    label={"Markets"}
    helperText={""}
    emptyValue={"All"}
    allowEmpty={false}
    resettable
    alwaysOn
  />,
  <TextInput label="Link" source="link" defaultValue="" alwaysOn />,
  <SelectInput
    label="Deal"
    source="deal"
    emptyValue=""
    resettable
    choices={DEAL}
    alwaysOn
    allowEmpty={false}
  />,
  <SelectInput
    label="Channel"
    source="channel"
    emptyValue=""
    resettable
    choices={CHANNEL}
    alwaysOn
    allowEmpty={false}
  />,
  <SelectInput
    label="Product"
    source="deal_product"
    choices={PRODUCT}
    emptyValue="All"
    allowEmpty={false}
    resettable
    alwaysOn
  />,
];

export default filters;
