import { useDispatch } from "react-redux";

import Button from "@material-ui/core/Button";

import { useRedirect, useNotify, fetchStart, fetchEnd } from "react-admin";

import { ACTIONS } from "../../../constants";
import { httpClient } from "../../../utils";
import CheckIcon from "@material-ui/icons/Check";
import * as React from "react";

export const MarkAsApproveButton = ({ record }: { record: any }) => {
  const [loading, setLoading] = React.useState(false);
  const redirect = useRedirect();
  const dispatch = useDispatch();
  const notify = useNotify();

  const markAsApproved = () => {
    dispatch(fetchStart()); // start the global loading indicator
    setLoading(true);
    httpClient(ACTIONS.vAssistantTask.approve(record.id), {
      method: "POST",
    })
      .then(
        () => {
          redirect("/");
          notify(`Task ${record.id} is set as Approved. Thanks!`);
        },
        (reason) => {
          if (reason?.status === 412) {
            notify(
              "Wrong status for the Task, " +
                `expected ${reason.body?.expected} found ${reason.body?.found}`,
              "error",
            );
          } else {
            notify("Unknown Error", "error");
          }
        },
      )
      .finally(() => {
        dispatch(fetchEnd()); // stop the global loading indicator
        setLoading(false);
      });
  };
  return (
    <span>
      <Button
        startIcon={<CheckIcon />}
        onClick={markAsApproved}
        disabled={loading}
        color="primary"
        variant="contained"
      >
        {loading ? "Setting task to Approved ..." : "Mark as Approved"}
      </Button>
    </span>
  );
};
