import { Edit as RAEdit, SimpleForm } from "react-admin";

import Form from "./Form";
import WriterRateHistoryList from "./RateHistoryList";

export const Edit = (props) => (
  <>
    <RAEdit {...props}>
      <SimpleForm>
        <Form editMode />
      </SimpleForm>
    </RAEdit>
    <WriterRateHistoryList props={props} />
  </>
);

export default Edit;
