import { defaultTheme } from "react-admin";
import merge from "lodash/merge";
import red from "@material-ui/core/colors/red";

const getTopBarStyle = () => {
  const style = {};
  const location = document.location.toString();
  if (
    location.startsWith("http://127.0.0.1") ||
    location.startsWith("http://localhost")
  ) {
    style.background = "#147200";
  } else if (location.startsWith("https://stg.")) {
    style.background = "#8d0000";
  } else {
    style.background = "#2196f3";
  }
  return style;
};

export const theme = merge(defaultTheme, {
  content: {
    bottomBarHeight: 100,
    bottomBarZIndex: 100,
    asideZIndex: 50,
  },
  palette: {
    // primary: indigo,
    // secondary: pink,
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    // fontFamily: ['-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Arial', 'sans-serif'].join(','),
    fontSize: 12,
  },
  menu: {},
  overrides: {
    RaAppBar: {
      toolbar: getTopBarStyle(),
    },
    RaTopToolbar: {
      // override the styles of all instances of this component
      root: {
        // Name of the rule
        justifyContent: "flex-start",
      },
    },
    ".dsg-container": {
      width: "100%",
      "&>*": {
        width: "100%",
      },
    },
    RaSidebar: {
      root: {
        // display: 'none'
      },
      drawerPaper: {
        width: "auto",
      },
      fixed: {
        zIndex: 100,
      },
    },
    RaEdit: {
      main: {
        display: "block",
      },
    },
    MuiTableCell: {
      root: {
        fontSize: ".7rem",
        padding: "1rem .5rem 1px .5rem",
        maxWidth: "6rem",
        overflowWrap: "break-word",
        border: "1px solid #00000005",
        "&.column-id": {
          overflowWrap: "normal",
        },
        "& *": {
          fontSize: ".7rem",
        },
      },
      sizeSmall: {
        padding: "1rem .5rem 1px .5rem",
      },
    },
    RaLayout: {
      contentWithSidebar: {
        maxWidth: "100vw",
        // margin: '0 5vw',
      },
      content: {
        marginRight: "1vw",
        padding: 0,
      },
    },
    RaCardContentInner: {
      root: {
        // padding: 0
      },
    },
  },
});

// export default theme;
