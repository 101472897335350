import { Resource } from "react-admin";

import { RESOURCE } from "../constants";
import Show from "./Show";
import { Edit, Create } from "./Edit";
import List from "./List";
import DescriptionIcon from "@material-ui/icons/Description";
import {
  makeModelPermission,
  ifHasPermission,
} from "../reusable";

const WRITER_GUIDELINES = RESOURCE.flatPages.writerGuidelines;

const PERMISSION = makeModelPermission("flat_pages", "flatpage");
/**
 * For some reason the APP want the resource to be instantiated with this make factory.
 * @param {*} permissions
 * @param {*} key
 * @returns
 */
export const makeFlatPages = (permissions) => [
  <Resource
    name={WRITER_GUIDELINES}
    key={WRITER_GUIDELINES}
    list={ifHasPermission(permissions, PERMISSION.show, List)}
    show={ifHasPermission(permissions, PERMISSION.show, Show)}
    create={ifHasPermission(permissions, PERMISSION.create, Create)}
    edit={ifHasPermission(permissions, PERMISSION.edit, Edit)}
    options={{ label: "Guidelines" }}
    icon={DescriptionIcon}
  />,
];
