import { Card as MuiCard, CardContent, withStyles } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { FilterList, FilterListItem } from "react-admin";

const Card = withStyles((theme) => ({
  root: {
    [theme.breakpoints.up("sm")]: {
      order: -1, // display on the left rather than on the right of the list
      width: "15em",
      marginRight: "1em",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}))(MuiCard);
//
// class SingleValueAsideFilterListFilters {
//   constructor({ label, ...filter }) {
//     this.label = label;
//     this.filter = filter;
//   }
// }
/**
 *
 * @param {SingleValueAsideFilterListFilters[]} param0
 * @returns
 */
export const GenericFilterList = ({ filters }) => {
  return (
    <Card>
      <CardContent>
        <FilterList label="Status" icon={<SearchIcon />}>
          {filters.map((filter, key) => (
            <FilterListItem
              key={key}
              label={filter.label}
              value={filter.filter}
            />
          ))}
        </FilterList>
      </CardContent>
    </Card>
  );
};
