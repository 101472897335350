// @ts-nocheck
import React, { useState } from "react";
import { HorizontalGroup } from "../../../reusable";
import { Button, TextField, Modal } from "@material-ui/core";
import { useDataProvider, useNotify, useRefresh } from "react-admin";
import { makeStyles } from "@material-ui/styles";
import { RESOURCE, STATUS } from "../../../constants";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    width: "80%",
    backgroundColor: "#fff",
    border: "4 px",
    padding: theme.spacing(2, 4, 3),
  },
  button: {
    marginTop: "2rem",
    marginRight: "2rem",
    padding: "1rem",
  },
}));

const AddContent = ({ props }) => {
  const refresh = useRefresh();
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const [open, setOpen] = useState(false);
  const [data, setData] = useState({
    url: "",
    brand: props.record.id,
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log("submitted", data);
    if (data.url.trim().length === 0) {
      notify("Please fill the URL field!", {
        type: "warning",
      });
      setData({ ...data, url: "" });
      return;
    }
    try {
      const savedData = await dataProvider.create(RESOURCE.order, {
        data: {
          url: data.url,
          content: data.title,
          status: STATUS.published,
          // TODO: ask what to put here
          requested_words: 100,
          // writer: 112,
          structure: {
            brand: data.brand,
          },
        },
      });

      // await dataProvider.update(RESOURCE.order, {
      //   id: savedData.data.id,
      //   data: { status: "published" },
      // });

      notify("New Content Created!");
    } catch (error) {
      notify(error.message);
    }
    handleClose();
    refresh();
  };
  return (
    <>
      <Button
        startIcon={<AddIcon />}
        color="primary"
        variant="outlined"
        onClick={handleOpen}
      >
        Add New Content
      </Button>
      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form className={classes.paper} onSubmit={handleSubmit}>
          <HorizontalGroup
            title={`Create New Content for Brand ${props?.record?.name}`}
            full
          >
            <TextField
              id="url"
              value={data.url}
              onChange={(e) => {
                setData({ ...data, url: e.target.value });
              }}
              label="URL"
              variant="standard"
              required={true}
            />
            <TextField
              disabled={true}
              id="brand"
              value={props.record.name}
              label="Brand"
              variant="standard"
            />
          </HorizontalGroup>
          <Button
            onClick={handleSubmit}
            className={classes.button}
            variant="contained"
            color="primary"
          >
            Create New Content
          </Button>
          <Button
            onClick={handleClose}
            className={classes.button}
            variant="contained"
            color="secondary"
          >
            Cancel
          </Button>
        </form>
      </Modal>
    </>
  );
};

export default AddContent;
