// in src/comments.js
import * as React from "react";
import {
  useListContext,
  TextField,
  EditButton,
  ShowButton,
  RichTextField,
  DeleteButton,
} from "react-admin";
import {
  Card,
  CardContent,
  CardHeader,
  CardActions,
} from "@material-ui/core";

const cardStyle = {
  width: "31%",
  minHeight: 600,
  maxHeight: 800,
  overflow: "auto",
  margin: "1% 0.5%",
  display: "inline-block",
  verticalAlign: "top",
};
export const CardGrid = ({
  title,
  content,
  subheader,
  avatar,
  hasEdit,
  hasShow,
  ShowButtonElement = ShowButton,
  otherContent = [],
  hasDelete,
  ...props
}) => {
  const { ids, data, basePath } = useListContext();
  return (
    <div style={{ margin: "1em" }}>
      {ids.map((id) => (
        <Card key={id} style={cardStyle}>
          <CardHeader
            title={
              title ? (
                <TextField
                  record={data[id]}
                  source={title}
                  component={"h4"}
                  variant={"h5"}
                />
              ) : null
            }
            subheader={
              subheader ? (
                <TextField record={data[id]} source={subheader} />
              ) : null
            }
            avatar={avatar ? avatar : null}
          />
          <CardContent>
            <RichTextField record={data[id]} source={content} />
          </CardContent>
          {otherContent.map((elem, index) => (
            <CardContent>
              {React.cloneElement(elem, {
                key: index,
                record: data[id],
              })}
            </CardContent>
          ))}
          <CardActions>
            {hasEdit ? (
              <EditButton
                resource={props.resource}
                basePath={basePath}
                record={data[id]}
              />
            ) : null}
            {hasShow
              ? React.createElement(ShowButtonElement, {
                  resource: props.resource,
                  basePath: basePath,
                  record: data[id],
                })
              : null}
            {hasEdit ? (
              <DeleteButton
                resource={props.resource}
                basePath={basePath}
                record={data[id]}
              />
            ) : null}
          </CardActions>
        </Card>
      ))}
    </div>
  );
};

export default CardGrid;
