import * as React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  SaveButton,
  Toolbar,
  Button,
  useNotify,
  fetchStart,
  fetchEnd,
  useRefresh,
} from "react-admin";

import { API_URL, STATUS } from "../../constants";
import { httpClient } from "../../utils";

export const ContentEditToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton disabled={props.pristine} />
      <OrderButton
        disabled={
          (!props.pristine && props.record.writer !== null) ||
          props.record.status !== STATUS.planned
        }
      />
    </Toolbar>
  );
};

const OrderButton = (props) => {
  /**
   * Button that will make sure the order is being sent.
   */
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const notify = useNotify();
  const refresh = useRefresh();
  const handleClick = () => {
    const path = `${API_URL}/${props.resource}/${props.record.id}/order/`;
    setLoading(true);
    dispatch(fetchStart()); // start the global loading indicator
    httpClient(path, { method: "POST" })
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json;
      })
      .catch((e) => {
        notify(`Error: order not sent (${e})`, "warning");
        return Promise.reject();
      })
      .then(({ status, valid }) => {
        notify(`Success`);
      })
      .finally(() => {
        setLoading(false);
        dispatch(fetchEnd()); // stop the global loading indicator
        refresh();
      });
  };

  return (
    <Button
      disabled={props.disabled && !loading}
      onClick={handleClick}
      label={"Order content"}
    />
  );
};
