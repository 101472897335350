import {
  /**
   * List
   */
  List as RAList,
  Datagrid,
  TextField,
  EmailField,
  Filter,
  SearchInput,
} from "react-admin";

import { ChipArrayField } from "../../reusable";
import { MarketReferenceField, MarketReferenceInput } from "../../market";

const Filters = (props) => (
  <Filter {...props}>
    <MarketReferenceInput alwaysOn resettable />
    <SearchInput source="q" alwaysOn />
  </Filter>
);

export const List = (props) => (
  <RAList {...props} filters={<Filters />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <MarketReferenceField />
      <TextField source="username" />
      <TextField source="full_name" />
      <TextField source="words_per_week" />
      <ChipArrayField source="favorite_subjects" label="Favorite Subjects" />
      <TextField source="last_name" />
      <TextField source="skype" />
      <EmailField source="email" />
    </Datagrid>
  </RAList>
);

export default List;
