import React from "react";
import { useGetOne, useRecordContext } from "react-admin";
import { RESOURCE } from "../../network/constants";

export const Link: React.FC<any> = () => {
  const record = useRecordContext();
  const networkRequest = useGetOne(RESOURCE, record?.network, {
    enabled: !!record?.network,
  });

  const { data } = networkRequest;
  if (!data) {
    return null;
  } else if (!data.link) {
    return (
      <p>
        Affiliate network: <b>{data.name}</b> <small>(no link)</small>
      </p>
    );
  } else {
    return (
      <p>
        Link to the affiliate platform:&nbsp;
        <a
          href={data.link}
          rel={"noreferrer noopener nofollow"}
          target={"_blank"}
        >
          {data.name} <small>{data.link}</small>
        </a>
      </p>
    );
  }
};

export default Link;
