import React, { ChangeEvent, KeyboardEventHandler, ReactElement } from "react";
// @ts-ignore
import get from "lodash/get";
// @ts-ignore
import set from "lodash/set";
import MuiTextField from "@material-ui/core/TextField";
import {
  TextField as RATextField,
  TextFieldProps as RATextFieldProps,
  useRecordContext,
  useResourceContext,
  useUpdate,
} from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { MenuItem } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  field: {
    width: "100%",
    display: "block",
  },
  fieldEmpty: {
    width: "100%",
    display: "block",
    opacity: 0.5,
    color: theme.palette.grey[500],
  },
  errorText: {
    color: "red",
  },
}));

interface Choice {
  id: string;
  name: string;
}

export interface EditableTextFieldProps extends RATextFieldProps {
  choices?: Choice[];
  partialUpdate?: boolean;
  multiline?: boolean;
  coerce?: CallableFunction;
  additionalData?: any;
  before?: React.ReactElement;
}

/**
 * An expansion to ReactAdmin Text field that allows in place
 * edit and update.
 *
 * To be used in a ReactAdmin DataGrid.
 * @param props
 * @constructor
 */
export const EditableTextField = (
  props: EditableTextFieldProps,
): ReactElement | null => {
  // deconstruct props.
  const {
    choices = [],
    additionalData = {},
    partialUpdate,
    multiline,
    coerce = (v: any): any => v,
    before,
    ...rest
  } = props;
  const { source, label } = rest;
  const record = useRecordContext();
  const resource = useResourceContext();
  const { id } = record || { id: "" };

  // Get the styles and the update handler.
  const classes = useStyles();
  const [update, { loading, error }] = useUpdate();

  // States.
  const [updateValue, setUpdateValue] = React.useState<boolean>(false);

  const [value, setValue] = React.useState<string>(get(record, source, ""));

  // Callbacks
  const handleChange = React.useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value);
    },
    [setValue],
  );
  const handleBlur = React.useCallback(() => {
    if (!source) return;
    const data: any = partialUpdate ? {} : record;
    set(data, source, coerce(value));
    Object.keys(additionalData).forEach((k) =>
      set(data, k, coerce(additionalData[k])),
    );
    setUpdateValue(false);
    update(resource, id, data);
  }, [
    resource,
    update,
    coerce,
    partialUpdate,
    id,
    source,
    value,
    record,
    additionalData,
  ]);

  const handlePressEnter = React.useCallback(
    (event) => (event?.key === "Enter" && !multiline ? handleBlur() : null),
    [handleBlur, multiline],
  ) as KeyboardEventHandler;

  // conditions, no id or record, return nothing.
  if (!id || !record) return null;

  if (error) {
    return <span className={classes.errorText}>Error!</span>;
  }

  if (loading) {
    return <span>Loading...</span>;
  }

  // if updateValue is true, return an input element.
  if (updateValue) {
    return (
      <span>
        {before}
        <MuiTextField
          className={classes.field}
          value={value}
          fullWidth
          select={choices.length > 0}
          multiline={multiline}
          onBlur={handleBlur}
          onChange={handleChange}
          onSelect={choices ? handleChange : undefined}
          onKeyPress={handlePressEnter}
          label={label}
        >
          {choices
            ? choices.map(({ id, name }) => (
                <MenuItem key={id} value={id}>
                  {name}
                </MenuItem>
              ))
            : null}
        </MuiTextField>
      </span>
    );
  }

  const choice = choices?.filter((choice) => choice.id === value)[0] || {
    id: value,
    name: value,
  };

  // By default return a text field.
  return (
    <span>
      {before}
      <RATextField
        className={value ? classes.field : classes.fieldEmpty}
        {...rest}
        source={"source"}
        record={
          value
            ? { id, source: choice.name }
            : {
                id,
                source: "Click to edit...",
              }
        }
        onClick={() => setUpdateValue(true)}
      />
    </span>
  );
};

export default EditableTextField;
