import { ReferenceField, TextField } from "react-admin";

import { RESOURCE } from "../../constants";

export const VerticalReferenceField = ({ source, label, ...props }) => (
  <ReferenceField
    label={props.label ? props.label : "Vertical"}
    source={props.source ? props.source : "vertical"}
    reference={RESOURCE.vertical}
    {...props}
  >
    <TextField source="name" />
  </ReferenceField>
);

VerticalReferenceField.defaultProps = {
  label: "Vertical",
  addLabel: true,
};
