import { Resource } from "react-admin";

import { RESOURCE, GROUPS } from "../constants";

import InsertCommentIcon from "@material-ui/icons/InsertComment";
import List from "./LIst";
import Create from "./Create";
import Edit from "./Edit";

/**
 * For some reason the APP want the resource to be instantiated with this make factory.
 * @param {*} permissions
 * @param {*} key
 * @returns
 */
export const makeOutreachLinkbuildingRedirection = (permissions) => [
  permissions.groups.includes(GROUPS.linkbuilding) ? (
    <Resource
      name={RESOURCE.outreachLinkbuildingRedirection}
      key={RESOURCE.outreachLinkbuildingRedirection}
      options={{ label: "Linkbuilding Redirection" }}
      list={List}
      create={Create}
      edit={Edit}
      icon={InsertCommentIcon}
    />
  ) : (
    <Resource
      name={RESOURCE.outreachLinkbuildingRedirection}
      key={RESOURCE.outreachLinkbuildingRedirection}
    />
  ),
];
