import { Card, CardHeader, CardContent } from "@material-ui/core";
import { Datagrid, downloadCSV, Filter, List, TextField } from "react-admin";
import { ContentList } from "../content/ContentList";
import { RESOURCE, STATUS } from "../constants";
import { MarketArrayChipField } from "../market";
import {
  DateRangeDialog,
  fetchRelatedNoNull,
  todayISOString,
} from "../reusable";
import { UserReferenceField } from "../user";
import jsonExport from "jsonexport/dist";
import { makeCard } from "./utils";

/**
 * Editor Dashboard Element
 * @param {*} param0
 * @returns
 */
export const IntegratorDashboard = ({ permissions }) => {
  return [
    <Card key={"integration orders"}>
      <CardHeader
        title={"Pages ready for integration."}
        subheader={"Assign and see the published pages here."}
      />
      <CardContent>
        <ContentList
          basePath={RESOURCE.order}
          resource={RESOURCE.order}
          hasShow={true}
          title={" "}
          permissions={permissions}
          filterDefaultValues={{
            status: STATUS.approved,
            type__ne: "linkbuilding",
          }}
          defaultColumns={[
            "id",
            "full_url",
            "total_words_written",
            "integrator",
            "created_by",
            "approved_by",
            "status",
          ]}
        />
      </CardContent>
    </Card>,
    // <ReviewIntegrations key="review integrations" groups={permissions.groups} />,
    makeCard(
      "Integrator Stats",
      "Stats for the integrators",
      <IntegratorStats key={"integrator stats"} />,
    ),
  ];
};

const exporter = async (stats, fetchRelatedRecords) => {
  const [users, markets] = await Promise.all([
    fetchRelatedNoNull(fetchRelatedRecords, stats, "id", RESOURCE.user),
    fetchRelatedNoNull(fetchRelatedRecords, stats, "markets", RESOURCE.market),
  ]);
  const data = stats.map((entry) => ({
    Integrator: users[entry.id].full_name,
    Markets: entry.markets.map((market) => markets[market].country).join(", "),
    Assigned: entry.assigned,
    Published: entry.published,
  }));
  jsonExport(
    data,
    {
      headers: ["Integrator", "Markets", "Assigned", "Published"],
    },
    (err, csv) =>
      !err
        ? downloadCSV(csv, `integrator-stats.${todayISOString()}`)
        : console.error(err),
  );
};

const Filters = (props) => (
  <Filter {...props}>
    <DateRangeDialog
      label={"Date Published"}
      sourceBefore={"before"}
      sourceAfter={"after"}
      alwaysOn
      resettable
    />
  </Filter>
);

export const IntegratorStats = () => (
  <List
    title={" "}
    basePath={RESOURCE.integratorStats}
    resource={RESOURCE.integratorStats}
    syncWithLocation={false}
    filterDefaultValues={{
      active: 1,
    }}
    exporter={exporter}
    filters={<Filters />}
    sort={{ field: "published", order: "DESC" }}
  >
    <Datagrid>
      <UserReferenceField source={"id"} />
      <MarketArrayChipField source={"markets"} />
      <TextField source="assigned" label="Assigned" />
      <TextField source="published" label="Published" />
    </Datagrid>
  </List>
);
