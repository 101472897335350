import { Box, Button } from "@material-ui/core";
import { HorizontalGroup } from "../../../reusable";
import { makeStyles } from "@material-ui/core/styles";
import ButtonBase from "@material-ui/core/ButtonBase";
import Typography from "@material-ui/core/Typography";
import { useCallback, useEffect, useState } from "react";
import { get, post } from "../../../utils";
import { API_URL, RESOURCE } from "../constants";
import { Loading, useDataProvider, useNotify, useRefresh } from "react-admin";

const useStyles = makeStyles({
  box: {
    height: "6rem",
  },
  btn: {
    height: "100%",
    position: "relative",
    marginLeft: "1rem",
  },
  imgDiv: {
    width: "fit-content !important",
  },
  imgSrc: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    overflow: "hidden",
  },
  imgBlur: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    filter: "opacity(0.3)",
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    overflow: "hidden",
  },
});

const Licences = ({ props }) => {
  const classes = useStyles();
  const { record } = props;
  const notify = useNotify();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();

  const [licences, setLicences] = useState([]);

  useEffect(() => {
    const getAllLicences = async () => {
      const licences = await dataProvider.getList(
        "sales/licences",
        {
          pagination: { page: 1, perPage: 25 },
          sort: { field: "id", order: "DESC" },
          filter: {},
        },
        // {},
      );
      return licences ? licences.data : licences;
    };

    const getBrandLicences = async () => {
      const licencesResponse = await get(
        `${API_URL}/${RESOURCE}/get_licenses/?pk=${record.id}`,
      );
      const licences = await licencesResponse.json;
      return licences;
    };

    const getLicenceList = async () => {
      const allLicences = await getAllLicences();
      const brandLicences = await getBrandLicences();

      allLicences.forEach((licence) => {
        const brandLicence = brandLicences.find(
          (brandLicence) => brandLicence.id === licence.id,
        );
        if (brandLicence !== undefined) {
          licence.isActive = true;
        } else {
          licence.isActive = false;
        }
      });
      setLicences(allLicences);
    };
    getLicenceList();
  }, [dataProvider, record.id]);

  const toggleLicence = async (id: number, isActive: boolean) => {
    await post(
      `${API_URL}/${RESOURCE}/${id}/toggle_licenses/`,
      {
        licence: id,
        brandId: record.id,
        is_active: isActive,
      },
      true,
    );
    refresh();
    notify("Licence Updated Successfully");
  };

  return (
    <HorizontalGroup
      full
      title={"license"}
      subtitle={
        "Review what licences the brand has and also toggle any licence."
      }
    >
      <Box
        display="flex"
        m={1}
        className={classes.box}
        justifyContent="flex-start"
      >
        {licences.map((licence, i) => (
          <Box
            className={classes.imgDiv}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            justifyItems="center"
            alignItems="center"
            key={i}
          >
            <Button
              className={classes.btn}
              onClick={() => toggleLicence(licence.id, licence.isActive)}
            >
              <span
                className={licence.isActive ? classes.imgSrc : classes.imgBlur}
                style={{
                  backgroundImage: `url(${licence.default})`,
                }}
              />
            </Button>
            <Typography variant="button">{licence.name}</Typography>
          </Box>
        ))}
      </Box>
    </HorizontalGroup>
  );
};

export default Licences;
