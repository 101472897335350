import {
  TextInput,
  SelectInput,
  required,
  AutocompleteInput,
  ReferenceInput,
} from "react-admin";
import { VerticalReferenceInput } from "../vertical";
import { MarketReferenceArrayInput } from "../market";

import { HorizontalGroup } from "../reusable";

import { useStyles } from "../useStyles";
import { WEBSITE_TYPES, RESOURCE } from "../constants";
import { UserReferenceInput } from "../user";
import { SLACK_CHOICES, SLACK_CHANNEL_DEFAULT } from "./constants";
import ProductReferenceInput from "../product/dixitProduct/input";

export const Form = (props) => {
  const classes = useStyles();
  return [
    <HorizontalGroup label={"Domain"} key={"domain"}>
      <TextInput
        source={"domain"}
        validate={required()}
        className={classes.bigAndBigger}
      />
    </HorizontalGroup>,
    <HorizontalGroup>
      <MarketReferenceArrayInput
        {...props}
        source={"markets"}
        validate={required()}
        className={classes.bigAndBigger}
      />
      <ProductReferenceInput
        {...props}
        source={"dixit_product"}
        key={"display_name"}
        label="Product"
        resettable
      />
    </HorizontalGroup>,
    <HorizontalGroup label={"Vertical"} key={"vertical"} validate={required()}>
      <VerticalReferenceInput
        {...props}
        source={"vertical"}
        validate={required()}
        className={classes.bigger}
      />
      <ReferenceInput
        className={classes.big}
        label={"niche"}
        source={"niche"}
        reference={RESOURCE.niche}
        sortBy={"name"}
        {...props}
      >
        <AutocompleteInput optionText={"name"} optionValue="id" />
      </ReferenceInput>
    </HorizontalGroup>,
    <HorizontalGroup label={"Clicky"} key={"clicky"}>
      <TextInput
        {...props}
        source={"clicky_site_id"}
        className={classes.bigger}
      />
      <TextInput
        {...props}
        source={"clicky_site_key"}
        className={classes.bigger}
      />
    </HorizontalGroup>,
    <HorizontalGroup label={"Slack"} key={"slack"} />,
    <SelectInput
      {...props}
      choices={SLACK_CHOICES}
      source={"data.pm_slack_channel"}
      label={"Slack Channel for PM"}
      defaultValue={SLACK_CHANNEL_DEFAULT}
      className={classes.bigger}
      key={"pm_slack_channel"}
    />,
    <HorizontalGroup label={"Other"} key={"other"} />,
    <SelectInput
      {...props}
      validate={required()}
      className={classes.bigger}
      source={"type"}
      key={"type"}
      choices={Object.values(WEBSITE_TYPES).map((e) => ({
        id: e.code,
        name: e.label,
      }))}
    />,
    <UserReferenceInput
      className={classes.bigger}
      defaultSelf
      key={"owner"}
      source={"owner"}
      label={"owner"}
      validate={required()}
    />,
  ];
};
