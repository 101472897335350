import * as React from "react";

import {
  SimpleForm,
  TextInput,
  required,
  ArrayInput,
  SimpleFormIterator,
  useUnselectAll,
  NumberInput,
} from "react-admin";
import { useFormState, useForm } from "react-final-form";

import { HorizontalGroup } from "../../reusable";
import { Button as MuiButton } from "@material-ui/core";

import { useStyles } from "../../sales/pmDeals/List/List";
import { WebsiteReferenceInput } from "../../website";
import { RESOURCE as PMLinks } from "../../sales/pmDeals/constants";

const DisplayLinks = ({ selectedData, edit = false, ...props }) => {
  const classes = useStyles();
  const { values } = useFormState();
  const form = useForm();
  const [generated, setGenerated] = React.useState(edit ? true : false);

  const unselectAll = useUnselectAll();
  const generate = (e) => {
    e.preventDefault();

    // if `values` is empty then initialize it
    if (!values.data) {
      form.change("data", {});
      form.change("data.links", selectedData);
    } else {
      // if `values` is not empty then append to it
      let existingLinks = values.data.links || [];
      form.change("data", {});
      form.change("data.links", [...selectedData, ...existingLinks]);
    }

    setGenerated(true);
    unselectAll(PMLinks);
  };

  const clearForm = (e) => {
    e.preventDefault();
    delete values.data.links;
    setGenerated(false);
  };

  const positionValidation = (value: number, allValues) => {
    if (value < 0) {
      return "Position should be greater than 0";
    }
    const links = allValues?.data?.links;
    if (links) {
      const positions = links.filter((link) => link.position === value);
      if (positions.length > 1) {
        return "Position already exists";
      }
    }
    return undefined;
  };

  return (
    <>
      <MuiButton
        id="btn-generate"
        color="primary"
        variant="contained"
        onClick={generate}
      >
        Generate Links
      </MuiButton>
      {generated && (
        <>
          <MuiButton color="secondary" variant="contained" onClick={clearForm}>
            Clear Links
          </MuiButton>
          <HorizontalGroup>
            <h4>Links </h4>
            <ArrayInput source="data.links" validate={required()}>
              <SimpleFormIterator
                className={`${classes.flex} ${classes.disableFadeEnter}`}
                disableAdd
                disableReordering
              >
                <NumberInput
                  validate={[required(), positionValidation]}
                  source="position"
                  label="Position"
                  helperText="Enter Position if you have muliple links"
                />
                <TextInput className={classes.hidden} source="brand_pk" />
                <TextInput source="brand_name" label="Brand Name" disabled />
                <TextInput source="pretty_link" label="Pretty Link" disabled />
                <TextInput source="promotion" label="Promotion" />
                <TextInput
                  source="cta"
                  label="CTA"
                  defaultValue={"Click Here"}
                />
                <TextInput
                  source="exclusive_text"
                  label="Exclusive Text"
                  defaultValue={"Exclusive Text"}
                />
              </SimpleFormIterator>
            </ArrayInput>
          </HorizontalGroup>
        </>
      )}
    </>
  );
};

const inputValidation = (value: string) => {
  if (value.includes(" ")) {
    return "Brand name should not contain spaces";
  }
  value = value.trim();
  if (value.startsWith("/")) {
    return "Slug should not start with '/'";
  }
  return undefined;
};

export default function Form({ selectedData, edit, ...props }) {
  return (
    <SimpleForm {...props}>
      <HorizontalGroup>
        <WebsiteReferenceInput
          label={"domain"}
          source="domain"
          validate={required()}
          filter={{ landing_page: true }}
        />
        <TextInput
          label="slug"
          source="brand_name"
          helperText="This will be the slag of the landing page and will be put after 'domain/lp/'. Example: site.com/lp/brand_name"
          validate={[required(), inputValidation]}
        />
      </HorizontalGroup>
      <h4>Select Pretty Links and then click Generate Links</h4>
      <DisplayLinks edit={edit} selectedData={selectedData} />
    </SimpleForm>
  );
}
