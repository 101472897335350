import { ReferenceField, TextField } from "react-admin";

import { RESOURCE } from "../constants";
import React from "react";
// eslint-disable-next-line no-unused-vars
import get from "lodash/get";
import { EditableReferenceField } from "../../../reusable/fields/EditableReferenceField";

export const BrandReferenceField = (props) => (
  <ReferenceField label="brand" source="brand" {...props} reference={RESOURCE}>
    <TextField source={"name"} />
  </ReferenceField>
);

export const EditableBrandReferenceField = ({
  source = "brand",
  label = "Brand",
  sort = { field: "id", order: "ASC" },
  showSource = "name",
  helperText = "",
  optionText = "name",
  ...props
}) => {
  return (
    <EditableReferenceField
      source={source}
      label={label}
      reference={RESOURCE}
      sort={sort}
      link={(props) => {
        // redirects to the full edit view with stats.
        const id = get(props, source);
        return `/${RESOURCE}/${id}/`;
      }}
      inputProps={{
        helperText: helperText,
        optionText: optionText,
      }}
      fieldProps={{
        source: showSource,
      }}
      {...props}
    />
  );
};

BrandReferenceField.defaultProps = {
  label: "Brand",
  addLabel: true,
};
