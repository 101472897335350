import React from "react";
import {
  useDelete,
  useListContext,
  useNotify,
  useRecordContext,
} from "react-admin";
import { useCreateBrand } from "../record";
import { RESOURCE as DEALS } from "../constants";
import IconButton from "@material-ui/core/IconButton";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import CallSplitIcon from "@material-ui/icons/CallSplit";
import { Tooltip } from "@material-ui/core";
import copy from "copy-to-clipboard";
import DeleteIcon from "@material-ui/icons/Delete";
import { DeleteButtonWithConfirmation } from "../../../reusable/buttons/DeleteButtonWithConfirmation";

interface ActionsProps {
  label: string;
}

export const Actions: React.FC<ActionsProps> = () => {
  const record = useRecordContext();
  const notify = useNotify();
  const { refetch } = useListContext();
  const [delete_, deleteState] = useDelete();
  const [create, state] = useCreateBrand(record.brand);
  const { id, ...data } = record;

  const handleDelete = React.useCallback(() => {
    delete_(DEALS, record.id);
  }, [delete_, record.id]);
  const handleClone = React.useCallback(() => {
    const _data = { ...data };
    create(DEALS, _data);
  }, [create, data]);
  const handleCopy = React.useCallback(() => {
    copy(record.link);
    notify("Copied successfully");
  }, [record.link, notify]);

  React.useEffect(() => {
    if (state.loaded || deleteState.loaded) {
      if (state.error || deleteState.error) {
        notify(state.error || deleteState.error, "error");
      } else {
        refetch();
      }
    }
  }, [state.loaded, state.error, refetch, notify, deleteState]);

  return (
    <>
      <Tooltip title={"Clone"}>
        <IconButton onClick={handleClone}>
          <CallSplitIcon />
        </IconButton>
      </Tooltip>
      {record.link ? (
        <Tooltip title={"Copy Link"}>
          <IconButton onClick={handleCopy}>
            <FileCopyIcon />
          </IconButton>
        </Tooltip>
      ) : null}
      <Tooltip title={"Delete"}>
        <DeleteButtonWithConfirmation handleDelete={handleDelete} />
      </Tooltip>
    </>
  );
};

interface LinkCopyActionsProps {
  source: string;
  label: string;
  tooltip: string;
}

export const LinkCopyAction: React.FC<LinkCopyActionsProps> = ({
  label,
  source,
  tooltip,
}) => {
  const record = useRecordContext();
  const notify = useNotify();

  const handleCopy = React.useCallback(() => {
    copy(record[source]);
    notify("Copied successfully");
  }, [record, source, notify]);

  return (
    <>
      {record[source] ? (
        <Tooltip title={`Copy ${tooltip}`}>
          <IconButton onClick={handleCopy}>
            <FileCopyIcon />
          </IconButton>
        </Tooltip>
      ) : null}
    </>
  );
};

// export const LinkCopyAction: React.FC<ActionsProps> = () => {
//   const record = useRecordContext();
//   const notify = useNotify();

//   const handleCopy = React.useCallback(() => {
//     copy(record.link);
//     notify("Copied successfully");
//   }, [record.link, notify]);

//   return (
//     <>
//       {record.link ? (
//         <Tooltip title={"Copy Affilicate Link"}>
//           <IconButton onClick={handleCopy}>
//             <FileCopyIcon />
//           </IconButton>
//         </Tooltip>
//       ) : null}
//     </>
//   );
// };

export default Actions;
