import { Resource } from "react-admin";

import { RESOURCE, GROUPS } from "../constants";
import { List } from "./List";
import { Edit } from "./Edit";
import WebIcon from "@material-ui/icons/Web";

/**
 * For some reason the APP want the resource to be instantiated with this make factory.
 * @param {*} permissions
 * @param {*} key
 * @returns
 */
export const makeOutreachLinkbuildingSite = (permissions, key) => [
  permissions.groups.includes(GROUPS.linkbuilding) ? (
    <Resource
      name={RESOURCE.outreachLinkbuildingSite}
      key={RESOURCE.outreachLinkbuildingSite}
      options={{ label: "Outreach Sites" }}
      list={List}
      edit={Edit}
      icon={WebIcon}
    />
  ) : (
    <Resource
      name={RESOURCE.outreachLinkbuildingSite}
      key={RESOURCE.outreachLinkbuildingSite}
    />
  ),
  <Resource name={RESOURCE.outreachTopics} key={RESOURCE.outreachTopics} />,
];
