import { useDataProvider, usePermissions } from "react-admin";
import { useState } from "react";
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Menu,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import { API_URL, RESOURCE } from "../../../../constants";
import { httpClient, post } from "../../../../utils";

const useStyles = makeStyles((theme) => ({
  textfield: {
    margin: "0.5rem",
  },
  button: {
    margin: "0.5rem",
    padding: "0.5rem",
  },
  progress: {
    marginTop: "0.5rem",
    marginLeft: "0.5rem",
    marginRight: "0.5rem",
  },
}));

const Flag = ({ record, basePath }) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();

  const [flag, setFlag] = useState(record.structure?.flagData?.flag || "");
  const [flagDescription, setFlagDescription] = useState(
    record.structure?.flagData?.flagDescription || "",
  );
  const [loading, setLoading] = useState(false);

  const handleFlagChange = (event) => {
    setFlag(event.target.value);
  };

  const saveNotes = async () => {
    try {
      setLoading(true);
      await dataProvider.update(RESOURCE.order, {
        id: record.id,
        data: {
          structure: {
            ...record.structure,
            flagData: { flag, flagDescription },
          },
        },
      });
      setLoading(false);
    } catch (error) {
      console.error(error.message);
    }
  };

  const sendMail = async () => {
    try {
      setLoading(true);
      const path = `${API_URL}${basePath}/${record.id}/send_mail/`;
      await post(path, { flag: flag, message: flagDescription }, true);
      setLoading(false);
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <>
      <FormControl variant="filled">
        <InputLabel>Set Flag</InputLabel>
        <Select value={flag} onChange={handleFlagChange}>
          <MenuItem value="">None</MenuItem>
          <MenuItem value="late">Late</MenuItem>
          <MenuItem value="red">Red</MenuItem>
        </Select>
        {flag === "red" ? (
          <TextField
            className={classes.textfield}
            label="Reason"
            onChange={(e) => setFlagDescription(e.target.value)}
            multiline
            rows={4}
            variant="filled"
          />
        ) : flag === "late" ? (
          <>
            <TextField
              className={classes.textfield}
              label="Write message to writer"
              multiline
              rows={4}
              variant="filled"
            />
            <Button
              className={classes.button}
              onClick={sendMail}
              variant="contained"
              color="secondary"
            >
              Send Mail to Writer
            </Button>
          </>
        ) : null}
      </FormControl>
      <Button
        className={classes.button}
        onClick={saveNotes}
        variant="contained"
        color="primary"
      >
        SAVE
      </Button>
      {loading && <LinearProgress className={classes.progress} />}
    </>
  );
};

export default Flag;
