import {
  Button,
  useRefresh,
  fetchStart,
  fetchEnd,
  useNotify,
  useDataProvider,
} from "react-admin";
import { useFormState } from "react-final-form";

import { useDispatch } from "react-redux";

/**
 *
 * @param {string} method: DataProvider function
 * @param {*} methodProps: The arguments to pass to the function
 * @param {object} data: Additional data, not present in the form, to be sent.
 * @param {callback} onSuccess: The function that will be run if success
 * @param {callback} onFailure: TThe function that will be run if fail
 * @param {ButtonProps} props: ButtonProps
 * @returns {JSX.Element}
 */
export const ActionButton = ({
  method,
  methodProps,
  data,
  onSuccess,
  onFailure,
  ...props
}) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const dispatch = useDispatch();
  const { values } = useFormState();
  const dataProvider = useDataProvider();
  const onClick = () => {
    dispatch(fetchStart());
    dataProvider[method]({
      data: { ...data, ...values },
    })
      .then(
        (e) => {
          if (onSuccess) return onSuccess(e);
          notify("Success");
          refresh();
        },
        (error) => {
          if (onFailure) return onFailure(error);
          notify("An error occurred.");
          console.error(error);
        }
      )
      .finally(() => dispatch(fetchEnd()));
  };
  return <Button {...props} onClick={onClick} />;
};
