import { ReferenceField, FunctionField } from "react-admin";

import { RESOURCE } from "../../constants";
import showUser from "../functions/showUser";

export const UserReferenceField = ({ source, label, ...props }) => {
  return (
    <ReferenceField
      label={label ? label : "user"}
      source={source ? source : "created_by"}
      reference={RESOURCE.user}
      {...props}
    >
      <FunctionField render={showUser} />
    </ReferenceField>
  );
};

UserReferenceField.defaultProps = {
  label: "User",
  addLabel: true,
};
