import { Fragment, useState } from "react";
import Button from "@material-ui/core/Button";
import copy from "copy-to-clipboard";
import { BulkEdit } from "./BulkEdit";
import { API_URL } from "../../../constants";
import {
  BulkDeleteWithConfirmButton,
  Loading,
  useDataProvider,
  useNotify,
} from "react-admin";

import { BulkTrelloCreate } from "./BulkTrelloCreate";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { RESOURCE } from "../../../constants";
import { httpClient } from "../../../utils";
// import { BulkAssignIntegrator } from "./BulkAssignIntegrator";

export const BulkActions = (props) => (
  <Fragment>
    {/*Not used at the moment*/}
    {/*<BulkAssignIntegrator {...props} />*/}
    <BulkContentAccept {...props} />
    <BulkCopyTOClipBoard {...props} />
    <BulkTrelloCreate {...props} />
    <BulkDeleteWithConfirmButton {...props} />
    <BulkEdit {...props} />
  </Fragment>
);

const BulkContentAccept = ({ basePath, selectedIds }) => {
  const notify = useNotify();
  const [loading, setLoading] = useState(false);

  const callback = async () => {
    setLoading(true);
    await Promise.all(
      selectedIds.map(async (id) => {
        const path = `${API_URL}${basePath}/${id}/approve/`;
        try {
          const resp = await httpClient(path, {
            method: "POST",
            body: JSON.stringify({
              vote: 4,
              feedback: "",
              quote: null,
            }),
          });
          return resp.status;
        } catch (err) {
          setLoading(false);
          notify(`Error while approving content: ${id}`);
        }
      }),
    );
    notify("All Content Accepted Successfully!");
    setLoading(false);
  };
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Button
          variant="outlined"
          color="primary"
          startIcon={<CheckCircleIcon />}
          onClick={callback}
        >
          Bulk Content Accept
        </Button>
      )}
    </>
  );
};

const BulkCopyTOClipBoard = (props) => {
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const callback = async () => {
    const { data } = await dataProvider.getMany(RESOURCE.order, {
      ids: props.selectedIds,
    });
    const urls = data.map((entry) => entry.full_url);
    copy(urls.join("\n"));
    notify(`Copied ${urls.length} URLs`);
  };
  return (
    <Button startIcon={<FileCopyIcon />} onClick={callback}>
      Copy URLs
    </Button>
  );
};
