import {
  List,
  Datagrid,
  TextField,
  Filter,
  SelectInput,
  downloadCSV,
} from "react-admin";

import jsonExport from "jsonexport/dist";

import { RESOURCE } from "../../constants";

import { WebsiteReferenceInput } from "../../website";

import {
  ChipArrayField,
  DateRangeDialog,
  dayFromToday,
  getLocale,
  todayISOString,
} from "../../reusable";

import { MarketReferenceInput } from "../../market";

const Filters = (props) => (
  <Filter {...props}>
    <DateRangeDialog
      label={"Activity Date"}
      sourceBefore={"before"}
      sourceAfter={"after"}
      alwaysOn
      resettable
    />
    <WebsiteReferenceInput
      allowEmpty={false}
      source="website"
      alwaysOn
      resettable
    />
    <SelectInput
      source="active"
      alwaysOn
      choices={[
        { id: 1, name: "Yes" },
        { id: 0, name: "No" },
      ]}
      emptyText="Any"
    />
    <MarketReferenceInput
      allowEmpty={false}
      source="market"
      alwaysOn
      resettable
    />
  </Filter>
);

const exporter = async (stats, fetchRelatedRecords) => {
  const data = stats.map((entry) => ({
    username: entry.username,
    markets: entry.markets.join(", "),
    "Content Ordered": entry.ordered_content,
    "Content Approved": entry.written_content,
    "Content Refused": entry.refused,
    "Average time to done": entry.average_duration,
    "Spent (EUR)": entry.earned_amount.toLocaleString(getLocale()) || "0",
  }));
  jsonExport(
    data,
    {
      headers: [
        "username",
        "markets",
        "Content Ordered",
        "Content Approved",
        "Content Refused",
        "Average time to done",
        "Spent (EUR)",
      ],
    },
    (err, csv) =>
      !err
        ? downloadCSV(csv, `proofreader-stats.${todayISOString()}`)
        : console.error(err),
  );
};

export const ProofreaderStats = () => (
  <List
    title={" "}
    basePath={RESOURCE.proofreaderStats}
    resource={RESOURCE.proofreaderStats}
    syncWithLocation={false}
    exporter={exporter}
    filters={<Filters />}
    filterDefaultValues={{
      active: 1,
      after: dayFromToday(30),
    }}
    sort={{ field: "written_content", order: "DESC" }}
  >
    <Datagrid>
      <TextField source="id" />
      <TextField source="username" />
      <ChipArrayField source={"markets"} />
      <TextField source="ordered_content" label="Orders" />
      <TextField source="written_content" label="Approved" />
      <TextField source="refused" label="Refusals" />
      <TextField source="earned_amount" label="Earned (EUR)" />
    </Datagrid>
  </List>
);
