
import {
  API_URL
} from "../../constants";

import {
  post
} from "../../utils";


interface Data {
	default: string;
}


/**
 * ImageUpload class
 *  I used the template from CKEditor
 *  but for some reason it wasn't working out of the box:
 * 		the image src was the base64 representation, 
 * 		even if the upload was successfull
 *  so I rewrote it using the fetch API.
 */
class ImageUpload {
	/**
	 * Creates a new adapter instance.
	 *
	 */
	options: any;
	loader: any;
	controller: AbortController;

	constructor( loader: any ) {
		/**
		 * FileLoader instance to use during the upload.
		 */
		this.loader = loader;
		this.controller = new AbortController();
	}

	/**
	 * Starts the upload process.
	 *
	 * @see module:upload/filerepository~UploadAdapter#upload
	 * @returns {Promise<Data>}
	 */
	upload () :	Promise<Data> {
		// returns a Promise.
		return this._upload();
	}


	/**
	 * Upload implementation.
	 *
	 * @see module:upload/filerepository~UploadAdapter#upload
	 * @returns {Promise<Data>}
	 */
	async _upload(): Promise<Data> {

		// takes the file from the ckeditor file loader.
		const file = await this.loader.file;

		// creates a multipartForm
		const data = new FormData();

		data.append( 'upload', file );
		
		// makes the actual request.
		const response = await post(
			`${API_URL}/file_manager/images/`,
			 data, 
			 false,
			 {signal: this.controller.signal}
		)
		return response.json;
	}

	/**
	 * Aborts the upload process.
	 *
	 * @see module:upload/filerepository~UploadAdapter#abort
	 * @returns {Promise}
	 */
	abort() {
		this.controller.abort()
	}

}


export const imageUploadAdapter = (editor : any) : void => {
	// Removing base64 upload, included in the build
	editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader: any ) : ImageUpload => {
        // Configure the URL to the upload script in your back-end here!
        return new ImageUpload( loader );
    };
}

export const installImageUploadAdapter = (editor: any) : void => {
	if (!editor){ return }
	// avoid overriding the existing config.
	const original = editor.config.removePlugins ? [...editor.config.removePlugins] : [];
	// Removing base64 upload, included in the build
	editor.config.removePlugins = [...original, 'Base64UploadAdapter']
	// Install the actual adapter.
	imageUploadAdapter(editor);
};

export default installImageUploadAdapter;
