import React, { ChangeEventHandler, useEffect } from "react";
import Chip from "@material-ui/core/Chip";

import { useCountWordsAdd } from "../store/countWords";
import CkEditorLazy from "../fields/CkEditorLazy";
import configuration from "./configuration";
import useUpdateProcedure from "./useUpdateProcedure";

// Regex to remove blank lines, usually they happen when copy / pasting
// from google docs
const EMPTY_ROW =
  '(?:<p>(?:(?:<br ?>)|(?:<br data-cke-filler="true">)|(?:&nbsp;))</p>)';
const REGEX = new RegExp(`${EMPTY_ROW}{3,}`, "gm");
const FAILING_IMAGE_TEXT = "--- FAILING IMAGE - REMOVE THIS AFTER FIXING ---";

const FAILING_IMAGE = `<p style="text-align:center;"><span style="background-color:hsl(0, 75%, 60%);color:hsl(0, 0%, 100%);">${FAILING_IMAGE_TEXT}</span></p>`;
const IMAGE_REGEX_ = '<figure.*?><img.*?src="data.*?base64.*?".*?>.*?figure>';
export const IMAGE_REGEX = new RegExp(IMAGE_REGEX_, "gm");

const IMAGE_FOUND_REGEX = new RegExp(FAILING_IMAGE_TEXT, "m");

interface ReusableEditorProps {
  disabled: boolean;
  noSpaces: boolean;
  countWords: boolean;
  elementId: string;
  label: string;
  value: string;
  suggestedWords: number;
  onChange: ChangeEventHandler;
  changeQueuedCallback?: () => void;
  changeStartedCallback?: () => void;
  changeFailedCallback?: () => void;
  changeSuccessfulCallback?: () => void;
  [key: string]: any;
}

export const ReusableEditor = ({
  disabled,
  noSpaces,
  elementId,
  label,
  countWords,
  writerWords,
  keywords_data,
  // setWrittenWords,
  suggestedWords,
  initial = false,
  initialValue,
  value,
  keywords,
  onChange,
  changeQueuedCallback = () => null,
  changeStartedCallback = () => null,
  changeFailedCallback = () => null,
  changeSuccessfulCallback = () => null,
}: ReusableEditorProps) => {
  const countWordsAdd: any = useCountWordsAdd();
  const editorRef = React.useRef<any>(null);
  const lastUserInput = React.useRef<number | null>(null);
  const lastUpdate = React.useRef<number | null>(null);
  const [writtenWords, setWrittenWords] = React.useState(writerWords);
  const [writtenKeywords, setWrittenKeywords] = React.useState(keywords_data);
  // const [updateProcedureWorker, data]: any = useUpdateProcedure(
  //   noSpaces,
  //   elementId,
  //   keywords,
  // );

  // useEffect(() => {
  //   if (data?.data) {
  //     // setWrittenWords(data.writtenWords);
  //     // setWrittenKeywords(data.writtenKeywords);
  //   }
  // }, [data, setWrittenWords, setWrittenKeywords]);

  /**
   * Clear the input before saving.
   * @type {function(): Promise<null|*>}
   */
  const valueSetter = React.useCallback(() => {
    const callback = async () => {
      const editor_ = editorRef.current;
      if (!editor_) {
        console.error("editor ref not found!");
        return null;
      }
      const inner = editor_.getData();
      // Do not continue if the status failed
      if (IMAGE_FOUND_REGEX.test(inner)) {
        return inner;
      }
      changeStartedCallback();
      const data = inner
        .replaceAll(REGEX, "<p><br ></p><p><br ></p>")
        .replaceAll(IMAGE_REGEX, (image: string) => {
          return `${image}${FAILING_IMAGE}`;
        });

      try {
        // send the data to the onChange method,
        // if it is not thenable await will convert
        // to one. see:
        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/await#conversion_to_promise
        const returnedValue: any = await onChange(data);
        if (returnedValue) {
          setWrittenWords(returnedValue.data.writer_written);
          setWrittenKeywords(returnedValue.data.keywords);
        }
        changeSuccessfulCallback();
      } catch (error) {
        console.error(error);
        editorRef.current.setData(data);
        changeFailedCallback();
        alert(`Error while saving:\n${error}`);
      } finally {
        return data;
      }
    };
    return callback();
  }, [
    onChange,
    changeFailedCallback,
    changeStartedCallback,
    changeSuccessfulCallback,
  ]);

  // This effect will call the countWords redux store.
  useEffect(() => {
    if (countWords) {
      countWordsAdd(
        writtenWords,
        label.trim() ? label : elementId,
        writtenKeywords,
      );
    }
  });

  useEffect(() => {
    if (countWords) {
      countWordsAdd(
        writtenWords,
        label.trim() ? label : elementId,
        writtenKeywords,
      );
    }
  }, [
    countWordsAdd,
    writtenWords,
    writtenKeywords,
    label,
    elementId,
    countWords,
  ]);

  React.useEffect(() => {
    // This will run every 500 milliseconds,
    // and will update the input value if the user has been
    // idle for that much time.
    const time = 500;

    const runner = async () => {
      const now = Math.floor(Date.now());
      const skipIf =
        // if no input updates skip
        !lastUserInput.current ||
        // if the last update was too recent skip.
        // e.g.
        // first cycle:
        //  lastUserInput = 700, now = 1000, time = 400: 700 > 600
        //  -> skip
        // second cycle:
        //  lastUserInput = 700, now = 2000, time = 500: 700 < 1600
        //  -> don't skip
        lastUserInput.current > now - time ||
        // Do not update if the input was already updated before any new change.
        (lastUpdate.current && lastUpdate.current > lastUserInput.current);
      if (skipIf) {
        return;
      }
      console.debug("updating", label, elementId);
      lastUpdate.current = now;
      lastUserInput.current = 0;
      await valueSetter();
      // updateProcedureWorker(noSpaces, elementId, label);
    };
    const interval = setInterval(runner, time);
    return () => clearInterval(interval);
  }, [valueSetter, /*updateProcedureWorker,*/ noSpaces, elementId, label]);

  // This callback will control the shouldInputUpdate flag
  const update = React.useCallback(() => {
    // handleReady is responsible to call the editor.setData
    // to avoid having the pristine set to false at the very beginning
    // the input will not update.
    if (lastUserInput.current === null) {
      console.debug(
        `Activating listener on editor ${label} after external update.`,
      );
      lastUserInput.current = 0;
      return;
    }
    const now = new Date();
    lastUserInput.current = Math.floor(now.getTime());
    changeQueuedCallback();
  }, [changeQueuedCallback, label]);

  // Callback called when the CKEditor is ready.
  const handleReady = React.useCallback(
    (editor: any) => {
      // Add these two lines to properly position the toolbar
      if (!editor) return null;
      editorRef.current = editor;
      editor.config.pasteFromWordPromptCleanup = true;
      console.debug(`editor for ${label} ready, setting up!`);

      // to avoid rendering the input not pristine,
      // should input update has three states.
      // editor.setData(value.replaceAll(REGEX, ""));
      const toolbarContainer = document.querySelector(`#toolbar-${elementId}`);

      // Once the toolbarContainer is found update the written words.
      if (toolbarContainer) {
        toolbarContainer.appendChild(editor.ui.view.toolbar.element);
        // setWrittenWords(
        // this update procedure will change the keywords.
        // updateProcedureWorker(noSpaces, elementId, label);
      }
      console.debug(`editor for ${label} set up successfully!`);
    },
    [label, /*noSpaces,*/ elementId /*updateProcedureWorker */],
  );

  return [
    <CkEditorLazy
      key={"editor"}
      disabled={disabled}
      config={configuration}
      onChange={update}
      onReady={handleReady}
      onError={(error: Error) => {
        console.error(error);
      }}
      onFocus={() => {
        // Activate the listeners when the user clicks
        // on it the first time
        if (lastUserInput.current === null) {
          console.debug(
            `Activating listener on editor ${label} after user focus.`,
          );
          lastUserInput.current = 0;
        }
      }}
      data={value}
    />,
    <Chip
      key={"editor-counter"}
      className={"counter"}
      data-words={writtenWords}
      label={`${writtenWords}${
        suggestedWords ? " / " + suggestedWords : ""
      } words`}
      variant={"outlined"}
    />,
  ];
};

export default ReusableEditor;
