export const API_URL = "/api";
export const RESOURCE: string = "hosting/hosting";

const MODEL = "hosting";

export const PERMISSIONS = {
  create: `hosting.add_${MODEL}`,
  view: `hosting.view_${MODEL}`,
  edit: `hosting.change_${MODEL}`,
  delete: `hosting.delete_${MODEL}`,
};

export const HOSTING_STATUS = {
  working: "Working",
  should_be_discontinued: "Should be discontinued",
  can_be_discontinued: "Can be discontinued",
  discontinued: "Discontinued",
};

export const HOSTING_STATUS_CHOICES = Object.keys(HOSTING_STATUS).map(
  (key) => ({ id: key, name: HOSTING_STATUS[key] }),
);
