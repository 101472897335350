import { useQueryWithStore } from "react-admin";

import { RESOURCE } from "../../constants";
/**
 * get the writer data.
 * @param {number} id
 * @returns
 */
export const useProofreader = (id) => {
  const { loaded, error, data } = useQueryWithStore({
    type: "getOne",
    resource: RESOURCE.proofReader,
    payload: { id: id },
  });
  return { loaded, error, data };
};
