import {
  Datagrid,
  DateField,
  EditButton,
  Filter,
  List as RAList,
  SelectInput,
  TextField,
  TextInput,
} from "react-admin";
import { UserReferenceField } from "../../user";
import { VASSISTANT_TASK_STATUS_CHOICES } from "../../constants";

const Filters = ({ ...props }) => (
  <Filter {...props}>
    <TextInput source={"s"} label={"Search"} resettable alwaysOn />
    <SelectInput
      choices={VASSISTANT_TASK_STATUS_CHOICES}
      label="Task Status"
      emptyText={"All"}
      source="task_status"
      resettable
      alwaysOn
    />
  </Filter>
);

export const List = (props: any) => {
  return (
    <RAList {...props} filters={<Filters />}>
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="task_name" label="Task Name" />
        <TextField source="task_status" label="Task Status" />
        <DateField source="task_eta" label="ETA" />
        <UserReferenceField label="Virtual Assistant" source="vassistant" />
        <UserReferenceField label="Requested By" source="created_by" />
        <EditButton />
      </Datagrid>
    </RAList>
  );
};
