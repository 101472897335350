import * as React from "react";

import { CreateProps, Create as RACreate } from "react-admin";

import LinksReferenceList from "./List/LinksReferenceList";
import Form from "./Form";

export const LandingPageCreate = (props: CreateProps) => {
  const [selectedData, setSelectedData] = React.useState([]);

  return (
    <>
      <RACreate {...props}>
        <Form {...props} selectedData={selectedData} edit={false} />
      </RACreate>
      <LinksReferenceList setSelectedData={setSelectedData} />
    </>
  );
};
