import React, { useState, useEffect, lazy, Suspense } from "react";

import {
  useInput,
  useRecordContext,
  Loading,
  useResourceContext,
  useUpdate,
} from "react-admin";
import IconButton from "@material-ui/core/IconButton";
import get from "lodash/get";
import { sha256 } from "../functions";

import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import { useCountWordsAdd } from "../store/countWords";

import FullscreenIcon from "@material-ui/icons/Fullscreen";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import { useWorker, WORKER_STATUS } from "@koale/useworker";
// import GraphemeSplitter from "grapheme-splitter";
import calculateWrittenWords from "../workers/calculateWrittenWords";
import { useFormState } from "react-final-form";
import { AutoSave } from "../inputs";
import ReusableEditor from "../ckeditor/ReusableEditor";
import configuration from "../ckeditor/configuration";

const CkEditorLazy = lazy(() => import("./CkEditorLazy"));

// Regex to remove fastidious blank lines, usually they happen when xopy / pasting
// from google docs
const REGEX = /<p>(<br>)+((<br data-cke-filler="true">)|&nbsp;)+<\/p>/gm;

const useStyles = makeStyles({
  editor: {
    "& .ck-content": {
      background: "#f5f5f5",
    },
  },
  mimetizedDocumentEditor: {
    position: "relative",
    width: "100%",
    "& .document-editor__toolbar": {
      display: "none",
    },
  },
  documentEditor: {
    // border: '1px solid var(--ck-color-base-border)',
    // borderRadius: 'var(--ck-border-radius)',
    position: "relative",
    backgroundColor: "#fff",
    /* Set vertical boundaries for the document editor. */
    height: "800px",
    maxHeight: "90vh",
    width: "100%",
    overflowWrap: "break-word",
    margin: "1.5rem 0",
    /* This element is a flex container for easier rendering. */
    display: "flex",
    flexFlow: "column nowrap",
    boxShadow: "0px 14px 8px 0px #fafafa",
    "& label": {
      paddingTop: "1.3rem",
      paddingBottom: ".5rem",
      fontSize: "1.3rem",
      "& button": {
        float: "right",
        "& > small": {
          paddingLeft: "1rem",
        },
      },
    },
    "& .counter": {
      position: "absolute",
      right: "4rem",
      bottom: "3rem",
    },
    "& .document-editor__toolbar": {
      /* Make sure the toolbar container is always above the editable. */
      zIndex: 1,

      /* Create the illusion of the toolbar floating over the editable. */
      boxShadow: "0 0 5px hsla( 0,0%,0%,.2 )",

      /* Use the CKEditor CSS variables to keep the UI consistent. */
      borderBottom: "1px solid var(--ck-color-toolbar-border)",
      "&.disabled": {
        display: "none",
      },
    },

    /* Adjust the look of the toolbar inside the container. */
    "& .document-editor__toolbar .ck-toolbar": {
      border: 0,
      borderRadius: 0,
    },
    "& .document-editor__editable-container": {
      padding: "calc( 2 * var(--ck-spacing-large) )",
      // background: 'var(--ck-color-base-foreground)',

      /* Make it possible to scroll the "page" of the edited content. */
      overflowY: "scroll",
      background: "#fafafa",
    },
    "& .document-editor__editable-container > .ck-editor__editable > h1": {
      fontSize: "2em",
    },
    "& .document-editor__editable-container > .ck-editor__editable": {
      /* Set the dimensions of the "page". */

      width: "80%",
      minHeight: "600px",

      /* Keep the "page" off the boundaries of the container. */
      padding: "20px 10% 30px",

      border: "1px hsl( 0,0%,82.7% ) solid",
      borderRadius: "var(--ck-border-radius)",
      background: "white",

      /* The "page" should cast a slight shadow (3D illusion). */
      boxShadow: "0 0 5px hsla( 0,0%,0%,.1 )",

      /* Center the "page". */
      margin: "0 auto",
      "&.ck-read-only": {
        background: "#fbfffa",
        minHeight: 0,
      },
    },
    "& .document-editor__editable-container > .ck-editor__editable > p": {
      wordBreak: "break-word",
    },
    "& .ck-content >  .image > img": {
      maxHeight: "400px",
    },
  },
});

const Editor = ({
  disabled,
  noSpaces,
  elementId,
  label,
  countWords,
  // setWrittenWords,
  suggestedWords,
  initial = false,
  initialValue,
  ...props
}) => {
  const record = useRecordContext();
  const { keywords } = useRecordContext();
  const { input } = useInput(props);
  const { value, onChange } = input;
  const prps = {
    value,
    onChange,
    disabled,
    noSpaces,
    elementId,
    label,
    countWords,
    keywords,
    writerWords: 0,
    // setWrittenWords,
    suggestedWords,
    initial,
    initialValue,
    ...props,
  };
  return <ReusableEditor {...prps} />;
};

/**
 * Returns an advanced text editor
 * @param { boolean } noCountWords
 * @param { string } label
 * @param { boolean } disabled
 * @param { InputProps } props
 * @returns {JSX.Element}noSpaces
 * @constructor
 */
export const AdvancedTextInputElement = ({
  countWords = false,
  label = "content",
  disabled = false,
  id = "",
  suggestedWords,
  noSpaces,
  ...props
}) => {
  const classes = useStyles();
  // const noSpaces = props.record?.language_has_no_spaces || false;
  const useInputResult = useInput(props);
  const { input } = useInputResult;
  const [fullscreen, setFullscreen] = useState(false);
  const [value, setValue] = useState(
    input.value.replaceAll('<br data-cke-filler="true">', ""),
  );
  const [elementId, setElementId] = useState("");
  if (!value && input.value) {
    setValue(input.value.replaceAll('<br data-cke-filler="true">', ""));
  }

  React.useEffect(() => {
    let mounted = true;
    const setId = async () => `advanced-editor-${await sha256(id || label)}`;
    setId().then((id) => (mounted ? setElementId(id) : null));
    return () => {
      mounted = false;
    };
  }, [id, label]);

  React.useEffect(() => {
    if (document.fullscreenElement) {
      try {
        document.exitFullscreen();
      } catch (error) {
        console.log(error, "warning");
      }
    }
    if (fullscreen) {
      try {
        fullscreen.parentElement.parentElement.requestFullscreen();
      } catch (error) {
        console.log(error, "warning");
      }
    }
  }, [fullscreen]);
  const toggleFullscreen = React.useCallback(
    (event) => setFullscreen(fullscreen ? false : event.target),
    [setFullscreen, fullscreen],
  );

  if (!elementId) return <Loading />;

  return (
    <div
      id={elementId}
      className={`${classes.documentEditor} ${
        countWords ? "counted-advanced-editor" : "advanced-editor"
      }'}`}
    >
      <label>
        {label}
        <IconButton onClick={toggleFullscreen} aria-label="set fullscreen">
          {fullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
          <small>&nbsp;Toggle fullscreen</small>
        </IconButton>
      </label>
      <div
        id={`toolbar-${elementId}`}
        className={`document-editor__toolbar ${disabled ? "disabled" : ""}`}
      ></div>
      <div
        id={`content-${elementId}`}
        className={`document-editor__editable-container ${
          countWords ? "count-words" : ""
        } ${disabled ? "disabled" : ""}`}
      >
        <Editor
          suggestedWords={suggestedWords}
          countWords={countWords}
          elementId={elementId}
          label={label}
          noSpaces={noSpaces}
          disabled={disabled}
          {...props}
        />
      </div>
    </div>
  );
};

export const NoFormAdvancedTextInputElement = ({
  countWords = false,
  label = "content",
  mimetize = false,
  ...props
}) => {
  const { source } = props;
  const record = useRecordContext(props);
  const classes = useStyles();
  const disabled = true;
  const elementId = `advanced-editor-ro-${label}`;
  return (
    <div
      id={elementId}
      className={
        mimetize ? classes.mimetizedDocumentEditor : classes.documentEditor
      }
    >
      {mimetize ? null : <label>{label}</label>}
      <div
        id={`toolbar-${elementId}`}
        className={`document-editor__toolbar ${disabled ? "disabled" : ""}`}
      ></div>
      <div
        id={`content-${elementId}`}
        className={`document-editor__editable-container ${
          countWords ? "count-words" : ""
        } ${disabled ? "disabled" : ""}`}
      >
        <CkEditorLazy
          disabled={disabled}
          config={configuration}
          data={get(record, source)}
          onError={(error) => {
            console.error(error);
          }}
        />
      </div>
    </div>
  );
};

export const AdvancedTextInput = (props) => (
  <Suspense fallback={<Loading />}>
    <AdvancedTextInputElement {...props} />
  </Suspense>
);

export const NoFormAdvancedTextInput = (props) => (
  <Suspense fallback={<Loading />}>
    <NoFormAdvancedTextInputElement {...props} />
  </Suspense>
);
AdvancedTextInput.defaultProps = {
  label: "Main Content",
  // addLabel: true,
};
