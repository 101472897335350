import {
  List as RAList,
  TextField,
  UrlField,
  EmailField,
  DateField,
  FunctionField,
  EditButton,
  NumberField,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  SimpleForm,
  TextInput,
  useNotify,
  downloadCSV,
  SaveContextProvider,
} from "react-admin";
import React from "react";
import CustomizableDatagrid from "ra-customizable-datagrid";
import jsonExport from "jsonexport/dist";

import { MarketArrayChipField } from "../market";
import { UserReferenceField } from "../user";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { TopFilter } from "./ListFilter";
import { AccordionCreate } from "./Create";
import { CreateOutreachOrder } from "./CreateOutreachOrder";
import Button from "@material-ui/core/Button";
import { RESOURCE, API_URL } from "../constants";
import { post } from "../utils";
import { makeStyles } from "@material-ui/core/styles";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Paper from "@material-ui/core/Paper";
import { todayISOString } from "../reusable";

const useStyles = makeStyles((theme) => ({
  creations: {
    display: "flex",
    justifyContent: "space-around",
    gap: 10,
    "&>div:first-child": {
      flex: "1 1 auto",
    },
    "&>div:last-child": {
      display: "flex",
      "& button": {
        alignItems: "center",
        padding: "1rem",
      },
    },
  },
}));

export const List = (props) => {
  const [open, setOpen] = React.useState(false);
  const notify = useNotify();
  const classes = useStyles();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const uploadSheet = `${API_URL}/${RESOURCE.outreachLinkbuildingSite}/check_sheet/`;
  const onSave = async (data) => {
    const url = uploadSheet;
    try {
      const response = await post(url, data);
      const { success, total } = response.json;
      notify(`${success} of ${total} successfully created.`);
      handleClose();
    } catch (error) {
      if (error.body.errors) {
        return error.body ? error.body.errors : "Error";
      }
    }
  };
  return [
    <div key={"Creations"} className={classes.creations}>
      <AccordionCreate {...props} key="add new" title={" "} />
      <Paper elevation={2}>
        <Button
          {...props}
          key="bulk import"
          onClick={handleOpen}
          title={" "}
          startIcon={<CloudDownloadIcon />}
        >
          Import from google sheet
        </Button>
      </Paper>
    </div>,

    <Dialog open={open} key={"dialog"} onClose={handleClose}>
      <SaveContextProvider value={uploadSheet}>
        <SimpleForm save={onSave} onSubmit={(props) => null}>
          <DialogTitle>Bulk import google sheet</DialogTitle>
          <DialogContent>
            <TextInput fullWidth source={"sheet"} />
          </DialogContent>
        </SimpleForm>
      </SaveContextProvider>
    </Dialog>,
    <RAList
      filters={<TopFilter />}
      exporter={exporter}
      {...props}
      key="list"
      title={"Outreach Sites"}
      hasEdit
    >
      <CustomizableDatagrid
        rowClick="expand"
        expand={<CreateOutreachOrder />}
        defaultColumns={[
          "domain",
          "price",
          "currency",
          "href.domain_rating",
          "email",
          "markets",
        ]}
      >
        <TextField source="id" />

        <FunctionField
          label="domain"
          render={(record) => (
            <UrlField
              source="domain"
              record={{ domain: `https://${record.domain}` }}
              target="_blank"
            />
          )}
        />
        <ReferenceArrayField
          source={"accepted_topics"}
          reference={RESOURCE.outreachTopics}
        >
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <TextField source="price" />
        <TextField source="currency" />
        <NumberField source="ahref.domain_rating" />
        <NumberField source="ahref.total_keywords" />
        <NumberField source="ahref.total_traffic" />
        <NumberField source="majestic.trust_flow" />
        <NumberField source="majestic.citation_flow" />
        <NumberField source="majestic.referring_domain" />
        <TextField source="majestic.topical_trust_flow" />
        <EmailField source="email" />
        <UserReferenceField label="Created By" source="created_by" />
        <MarketArrayChipField source="markets" />
        <DateField source="created" />
        <DateField source="updated" />
        <EditButton />
      </CustomizableDatagrid>
    </RAList>,
  ];
};

const exporter = async (stats, fetchRelatedRecords) => {
  const [markets] = await Promise.all([
    fetchRelatedRecords(stats, "markets", RESOURCE.market),
  ]);
  const data = stats.map((entry) => {
    return {
      Id: entry.id,
      email: entry.email,
      domain: entry.domain,
      price: entry.price,
      currency: entry.currency,
      markets:
        entry.markets && entry.markets.map((market) => markets[market].country),
    };
  });

  jsonExport(
    data,
    {
      headers: ["Id", "email", "domain", "price", "currency", "markets"],
    },
    (err, csv) =>
      !err
        ? downloadCSV(csv, `outreach-linkbuilding-sites.${todayISOString()}`)
        : console.error(err),
  );
};
