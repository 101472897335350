import { Resource } from "react-admin";
import { RESOURCE, GROUPS } from "../../constants";

import List from "./List";
import Edit from "./Edit";
import Create from "./Create";

import { WriterSelfEdit, WriterShow } from "../../user/Writer";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
export const makeProofReaderAdmin = (permissions) => {
  const props = {
    name: RESOURCE.proofReader,
    key: RESOURCE.proofReader,
  };
  const freelance =
    permissions.groups.includes(GROUPS.proofreaders) &&
    permissions.groups.includes(GROUPS.freelances);

  if (freelance) {
    return <Resource {...props} edit={WriterSelfEdit} show={WriterShow} />;
  }
  const groups = [
    GROUPS.editors,
    GROUPS.linkbuilding,
    GROUPS.outreach,
    GROUPS.productOwners,
    // GROUPS.proofreader,
  ];
  const contained = groups.some((group) => permissions.groups.includes(group));
  if (contained) {
    return (
      <Resource
        {...props}
        options={{ label: "Proof Readers" }}
        list={List}
        create={Create}
        edit={Edit}
        icon={PermIdentityIcon}
      />
    );
  }
  return <Resource {...props} />;
};

export default makeProofReaderAdmin;
