import React from "react";
import { TextInput, required } from "react-admin";
import { SimpleForm } from "react-admin";
import { WebsiteReferenceInput } from "../website";
import { ArrayTextInput } from "../reusable";

const isValidURL = (urlString) => {
  try {
    const newUrl = new URL(urlString);
    return newUrl.protocol === "http:" || newUrl.protocol === "https:";
  } catch (e) {
    return false;
  }
};

const URLValidation = (value) => {
  if (!value || typeof value !== "object") return undefined;
  if (value.some((item) => item.length === 0)) {
    return "URL cannot be empty";
  }
  if (!value.every((item) => isValidURL(item))) {
    return "Enter Valid URL!";
  }
  return undefined;
};

export const Form = (props) => {
  return (
    <SimpleForm {...props} redirect={"list"}>
      <WebsiteReferenceInput
        validate={[required()]}
        label="website"
        source="website"
      />
      <TextInput
        source="slug"
        label="Slug"
        helperText="The slug you have to type to trigger the redirection."
        placeholder="https://site.com/query="
        validate={[required()]}
      />
      {/* <ArrayTextInput
        source={"urls"}
        label={"URLs"}
        helperText="Put each URL on each line."
        validate={[required(), URLValidation]}
      /> */}
    </SimpleForm>
  );
};
