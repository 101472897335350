import { DataGrid } from "@mui/x-data-grid";
import * as React from "react";
import { API_URL, RESOURCE } from "../../constants";
import { ChipArrayField, HorizontalGroup } from "../../reusable";
import { httpClient } from "../../utils";
import { UserReferenceField } from "../../user";
import { Chip } from "@material-ui/core";

const columns = [
  {
    field: "updated_by_id",
    headerName: "Updated By",
    width: 160,
    renderCell: ({ row }) => {
      return <UserReferenceField record={row} source={"updated_by_id"} />;
    },
  },
  {
    field: "date_created",
    headerName: "Date Created",
    width: 160,
    renderCell: ({ row }) => {
      return <span>{row.date_created.slice(0, 10)}</span>;
    },
  },
  {
    field: "previous_rates",
    headerName: "Previous Rates",
    width: 160,
    renderCell: ({ row }) => {
      return (
        <>
          {row.previous_rates.map((rate) => (
            <Chip variant="outline" size="small" label={rate} />
          ))}
        </>
      );
    },
  },
  {
    field: "previous_currency",
    headerName: "Previous Currency",
    width: 200,
  },
  {
    field: "new_rates",
    headerName: "New Rates",
    width: 160,
    renderCell: ({ row }) => {
      return (
        <>
          {row.new_rates.map((rate) => (
            <Chip variant="outline" size="small" label={rate} />
          ))}
        </>
      );
    },
  },
  { field: "new_currency", headerName: "New Currency", width: 160 },
];

export default function WriterRateHistoryList({ props }) {
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState([]);
  React.useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const url = `${API_URL}/${RESOURCE.writer}/${props.id}/rate_history/`;
      try {
        const result = await httpClient(url);
        console.log(result.json.data);
        setData(result.json.data);
        setLoading(false);
      } catch (err) {
        console.error(err);
        setData([]);
        setLoading(false);
      }
    };
    fetchData();
  }, [props.id]);

  return (
    <div style={{ height: 460, width: "100%" }}>
      <HorizontalGroup label="Writer Rate Change History" />
      <DataGrid
        density="compact"
        disableExtendRowFullWidth="true"
        loading={loading}
        rows={data}
        columns={columns}
        pageSize={10}
        disableSelectionOnClick
      />
    </div>
  );
}
