import { makeStyles } from "@material-ui/core";
import { useEffect } from "react";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

const useStyles = makeStyles((theme) => ({
  instructions: {
    padding: "0.5rem",
    whiteSpace: "pre-wrap",
    marginBottom: theme.content.bottomBarHeight,
  },
  btn: {
    position: "fixed",
    width: "42px",
    height: "42px",
    bottom: "40%",
    right: "25rem",
    border: "transparent",
    borderTopLeftRadius: "25%",
    borderBottomLeftRadius: "25%",
    backgroundColor: "#2196f3",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#1a78c2",
    },
    color: "white",
    textalign: "center",
  },
}));

const Instructions = ({ toggleDrawer, record }) => {
  const classes = useStyles();
  useEffect(() => {
    console.log(record);
  }, [record]);
  return (
    <>
      <div
        className={classes.instructions}
        dangerouslySetInnerHTML={{ __html: record.structure.instructions }}
      ></div>
      <button className={classes.btn} onClick={toggleDrawer}>
        <ChevronRightIcon />
      </button>
    </>
  );
};

export default Instructions;
